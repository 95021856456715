import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {Formik, Field} from 'formik';
import Modal from "react-responsive-modal";
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from "@material-ui/core/styles";
import {AccordionRowV2} from '../../../../components/Accordion';
import OrganizationRowContent from './OrganizationRowContent';

const OrganizationInfoToolTip = withStyles({
  tooltip: {
    fontSize: '1em'
  }
})(Tooltip);

const suspendInformation = "Suspended organizations will appear in the Suspended Accounts tab when you refresh this page. Users will no longer be able to see the organization from the public drop down list.";

const approveInformation = "If you approve this application, it will appear in the Approved Accounts tab when you refresh this page.";

const denyInformation = "If you deny this application, it will appear in the Denied Accounts tab when you refresh this page.";

const reinstateInformation = "If you reinstate this organization, it will appear in the Approved Accounts tab when you refresh this page.";

const deleteInformation = (organization) => (
  organization.suspended ? ` Delete is permanent. Deleted organizations won’t be in any tab when you refresh this page.`
    :
    organization.approved ? ` Delete is permanent. Deleted organizations won’t be in any tab when you refresh this page.`
      :
      ` Delete is permanent. Deleted applications won’t be in any tab when you refresh this page.`
);

const getEventDate = (organization, type) => {
  const events = organization && organization.events && organization.events.filter((event) => event.type === type);
  if(!events || !events[events.length - 1]) return moment();
  return events[events.length - 1].date;
};

class OrganizationRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      approved: props.organization.approved,
      suspended: props.organization.suspended,
      denied: props.organization.denied,
      notes: props.organization.notes,
      successMessage: null,
      approveModalOpen: false,
      deleteModalOpen: false,
      suspendModalOpen: false,
      denyModalOpen: false,
      reinstateModalOpen: false,
      loading: true
    };
  }

  static propTypes = {
    organization: PropTypes.shape({
      approved: PropTypes.bool,
      suspended: PropTypes.bool,
      denied: PropTypes.bool,
      notes: PropTypes.arrayOf(PropTypes.shape({
        details: PropTypes.string
      })),
      events: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string,
        date: PropTypes.string
      })),
      created_at: PropTypes.string,
      user_count: PropTypes.number,
      account_owner: PropTypes.shape({
        email: PropTypes.string
      })
    }),
    organizationStore: PropTypes.any,
    forceOpen: PropTypes.bool,
  };

  rowTitle = () => (
    <div>
      <p className="no-margin">
        {this.props.organization.name} 
        <i className="margin-left-2">
          {
            !this.props.organization.approved ? 
              `Applied on: ${moment(this.props.organization.application_date).parseZone().format('LL')}` 
              :
              this.state.denied ?
                `Denied on: ${moment(getEventDate(this.props.organization, 'denied')).parseZone().format('LL')}`
                :
                this.state.suspended && `Suspended on: ${moment(getEventDate(this.props.organization, 'suspended')).parseZone().format('LL')}`
          }
        </i>
      </p>
    </div>
  );
  
  rowContent = () => (
    <Formik
      initialValues={{note: ''}}
      onSubmit={(values, formikProps, callback) => this.handleAddNote(values.note, callback) || formikProps.setFieldValue('note', '')}
    >
      {
        formikProps => 
          <OrganizationRowContent 
            index={this.props.index}
            formikProps={formikProps} 
            organizationId={this.props.organization.id} 
            setApproveModalOpen={()=> this.setState({approveModalOpen: true})}
            setDenyModalOpen={()=> this.setState({denyModalOpen: true})}
            setSuspendModalOpen={()=> this.setState({suspendModalOpen: true})}
            setReinstateModalOpen={()=> this.setState({reinstateModalOpen: true})}
            approveInformation={approveInformation}
            denyInformation={denyInformation}
            suspendInformation={suspendInformation}
            reinstateInformation={reinstateInformation}
            approvedState={this.state.approved}
            deniedState={this.state.denied}
            suspendedState={this.state.suspended}
            successMessage={this.state.successMessage}
            handleDelete={this.handleDelete}
          />
      }
    </Formik>
  );

  renderApproveContent = () => (
    <div>
      <h4>Are you sure you want to approve {this.props.organization.name}'s application?</h4>
      <p className="margin-bottom-6">
        {approveInformation} The organization will get an email notification when you approve the application.
      </p>
      <button 
        onClick={this.handleApprove} 
        className="approve-confirmation-button usa-button usa-button--secondary margin-right-4"
      >
        Approve
      </button>
      <button onClick={() => this.setState({approveModalOpen: false})} className="usa-button usa-button--outline">Cancel</button>
    </div>
  );

  renderDenyContent = () => (
    <div>
      <h4>Are you sure you want to deny {this.props.organization.name}'s application?</h4>
      <p className="margin-bottom-6">
        {denyInformation} The organization will receive an email notification when you deny the application.
      </p>
      <button 
        onClick={this.handleDeny} 
        className="deny-confirmation-button usa-button usa-button--secondary margin-right-4"
      >
        Deny
      </button>
      <button onClick={() => this.setState({denyModalOpen: false})} className="usa-button usa-button--outline">Cancel</button>
    </div>
  );

  renderDeleteWarningContent = () => (
    <div>
      <h4>Are you sure you want to delete {this.props.organization.name}{!this.props.organization.approved && "'s application"}?</h4>
      <p className="margin-bottom-6">
        {
          this.props.organization.suspended ? ` You cannot undo this action. Deleted organizations won’t be in any tab when you refresh this page. The organization will get an email notification when deleted.`
            :
            this.props.organization.approved ? ` You cannot undo this action. If you delete this organization, all users associated with this organization will no longer be linked. Deleted organizations won’t be in any tab when you refresh this page. The organization will get an email notification when deleted.`
              :
              ` You cannot undo this action. Deleted applications won’t be in any tab when you refresh this page. The organization will NOT receive an email notification when you delete the application.`
        }
      </p>
      <button 
        onClick={() => this.props.organizationStore.deleteOrganization(this.props.organization.id, () => window.location.reload())} 
        className="delete-confirmation-button usa-button usa-button--secondary margin-right-4"
      >
        Delete
      </button>
      <button onClick={() => this.setState({deleteModalOpen: false})} className="usa-button usa-button--outline">Cancel</button>
    </div>
  );

  renderReinstateContent = () => (
    <div>
      <h4>Are you sure you want to reinstate {this.props.organization.name}'s application?</h4>
      <p className="margin-bottom-6">
        {reinstateInformation} The organization will get an email notification when reinstated.
      </p>
      <button 
        onClick={this.handleUnsuspend} 
        className="confirm-unsuspend-button usa-button usa-button--secondary margin-right-4"
      >
        Reinstate
      </button>
      <button onClick={() => this.setState({reinstateModalOpen: false})} className="usa-button usa-button--outline">Cancel</button>
    </div>
  );

  renderSuspendWarningContent = () => (
    <div className="suspend-warning-modal">
      <h4>Are you sure you want to suspend {this.props.organization.name}?</h4>
      <p className="margin-bottom-6">
      If you suspend this organization, all users associated with this organization will no longer be linked. Suspended organizations will appear in the Suspended Accounts tab when you refresh this page. The organization will get an email notification when suspended.
      </p>
      <button 
        onClick={this.handleSuspend} 
        className="confirm-suspend-button usa-button usa-button--secondary margin-right-4"
      >
        Suspend
      </button>
      <button onClick={() => this.setState({suspendModalOpen: false})} className="usa-button usa-button--outline">Cancel</button>
    </div>
  );


  renderNotes = (formikProps) => {
    const id = `fdic-notes-${this.props.index}`;
    return(
      <div className="grid-row">
        <div className="grid-col-12 margin-bottom-2">
          <label className="usa-label" id={id}><b>FDIC Notes:</b></label>
        </div>
        <div className="grid-col-12 margin-bottom-2 padding-right-4">
          {this.state.notes.map((note, index) => (
            <div key={`note-${index}`} className="grid-row note-row">
              <li><span>{note.details} <i>{moment(note.date).parseZone().format('MM/DD/YY')}</i></span></li> 
            </div>
          ))}
        </div>
        <div className="grid-col-12 padding-right-4">
          <Field aria-labelledby={id} name="note" onChange={formikProps.handleChange} rows={3} maxLength="255" className="full-width margin-bottom-105 note-input" component="textarea" />
          <button className="usa-button add-note-button" onClick={formikProps.handleSubmit} disabled={!formikProps.values.note}>Add note</button>
        </div>
      </div>
    );
  };

  renderActions = () => {
    return(
      <div>
        <div className="grid-row margin-bottom-2">
          <h3 className="margin-bottom-2 no-margin">Actions</h3>
        </div>
        <div className="grid-row margin-bottom-2 grid-col-12">
          {!this.props.organization.approved && !this.state.approved && 
          <OrganizationInfoToolTip title={approveInformation} placement="bottom">
            <button onClick={()=> this.setState({approveModalOpen: true})} className="usa-button usa-button--outline approve-button">Approve</button>
          </OrganizationInfoToolTip>
          }
          {!this.props.organization.approved && !this.state.approved && !this.state.denied && 
          <OrganizationInfoToolTip title={denyInformation} placement="bottom">
            <button onClick={()=> this.setState({denyModalOpen: true})} className="usa-button usa-button--outline deny-button">Deny</button>
          </OrganizationInfoToolTip>
          }

          {this.props.organization.approved && !this.props.organization.suspended && !this.state.suspended &&
          <OrganizationInfoToolTip title={suspendInformation} placement="bottom">
            <button onClick={() => this.setState({suspendModalOpen: true})} className="usa-button usa-button--outline suspend-button">Suspend</button>
          </OrganizationInfoToolTip> 
          }
          {this.props.organization.approved && this.props.organization.suspended && this.state.suspended &&
          <OrganizationInfoToolTip title={reinstateInformation} placement="bottom">
            <button onClick={()=> this.setState({reinstateModalOpen: true})} className="usa-button usa-button--outline unsuspend-button">Reinstate</button>
          </OrganizationInfoToolTip> 
          }
          <OrganizationInfoToolTip title={deleteInformation(this.props.organization)} placement="bottom">
            <button onClick={this.handleDelete} className="usa-button usa-button--secondary delete-button">Delete</button>
          </OrganizationInfoToolTip>
          {this.props.organization.approved &&
          <button className="usa-button usa-button--unstyled margin-right-2" onClick={() => window.location = `/account/reports?organizationId=${this.props.organization.id}`}>Reports</button>
          }
          <button className="usa-button usa-button--unstyled" onClick={() => window.location = `/account/organizations/${this.props.organization.id}`}>Details</button>
        </div>
        {
          this.state.successMessage &&
          <div className="grid-row success-message margin-right-4">
            <span>{this.state.successMessage}</span>
          </div>
        }
      </div>
    );
  };

  handleAddNote = (note, callback) => (
    this.props.organizationStore.addNote(this.props.organization.id, note, () => callback(note))
  );

  handleApprove = () => (
    this.props.organizationStore.approveOrganization(this.props.organization.id, () => this.props.history.push(`/account/organizations?tab=${this.props.tab}`))
  );

  handleDeny = () => (
    this.props.organizationStore.denyOrganization(this.props.organization.id, () => this.props.history.push(`/account/organizations?tab=${this.props.tab}`))
  );

  handleSuspend = () => (
    this.props.organizationStore.suspendOrganization(this.props.organization.id, () => this.props.history.push(`/account/organizations?tab=${this.props.tab}`))
  );

  handleUnsuspend = () => (
    this.props.organizationStore.unsuspendOrganization(this.props.organization.id, () => this.props.history.push(`/account/organizations?tab=${this.props.tab}`))
  );

  handleDelete = () => (
    this.setState({deleteModalOpen: true})
  );

  render() {
    return(
      <div>
        <AccordionRowV2 
          title={this.rowTitle(this.props.organization)}
          content={this.rowContent()}
          onlyOpenOnTitle
          forceOpen={this.props.forceOpen}
        />
        <Modal 
          open={this.state.approveModalOpen} 
          onClose={() => this.setState({approveModalOpen: false})}
        >
          {this.renderApproveContent()}
        </Modal>
        <Modal 
          open={this.state.denyModalOpen} 
          onClose={() => this.setState({denyModalOpen: false})}
        >
          {this.renderDenyContent()}
        </Modal>
        <Modal 
          open={this.state.reinstateModalOpen} 
          onClose={() => this.setState({reinstateModalOpen: false})}
        >
          {this.renderReinstateContent()}
        </Modal>

        <Modal 
          open={this.state.deleteModalOpen} 
          onClose={() => this.setState({deleteModalOpen: false})}
        >
          {this.renderDeleteWarningContent()}
        </Modal>
        <Modal 
          open={this.state.suspendModalOpen} 
          onClose={() => this.setState({suspendModalOpen: false})}
        >
          {this.renderSuspendWarningContent()}
        </Modal>
      </div>
    );
  }
};

export default OrganizationRow;