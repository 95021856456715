import React from 'react';
import {Formik, Form} from 'formik';
import Inputs, {ExpandableTextarea} from '../utilities/Inputs';
import EditableContent from '../../../components/EditableContent/EditableContent';
import config from '../../../config';
import {useTranslation} from 'react-i18next';

const savingsGoalOptions = [100, 250, 400, 500, 600, 750, 1000];
const savingsLocationOptions = [
  'In a savings account used only for my emergency savings fund',
  'On a prepaid card',
  'In a savings account with my other money',
];
const savingsTimeOptions = ['1 month', '2 months', '6 months', '1 year', '2 years'];
const savingsFrequencyOptions = ['Every Day', 'Every Week', 'Every Two Weeks', 'Twice a month', 'Every Month'];
const savingsTimeConversionArrays = [
  [30, 4, 2, 2, 1],
  [60, 8, 4, 4, 2],
  [365 / 2, 26, 13, 12, 6],
  [365, 52, 26, 24, 12],
  [365 * 2, 104, 52, 48, 24],
];

const initialValues = {
  savingsGoal: savingsGoalOptions[0],
  savingsLocation: savingsLocationOptions[0],
  savingsTime: savingsTimeOptions[0],
  savingsFrequency: savingsFrequencyOptions[0],
  savingsGoalOther: '',
  savingsLocationOther: '',
};

const EmergencySavingsFundPlan = ({tool, canEdit}) => {
  const {t, i18n} = useTranslation();
  const tableTitle = `${t('Table')}: ${t('My Emergency Savings Fund Plan')}`;
  return (
    <div>
      <Formik initialValues={initialValues}>
        {({values, errors, touched}) => {
          return (
            <Form>
              <EditableContent
                htmlType='div'
                initialContent={i18n.language === 'es' ? tool.contentsEs || tool.contents : tool.contents}
                fieldName={i18n.language === 'es' ? 'contents_es' : 'contents'}
                updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
                canEdit={canEdit}
                richText
              />
              <table className='usa-table' title={tableTitle}>
                <tbody>
                  <tr>
                    <th className='col-4 border-top' scope='row'>
                      <h4 className='no-margin'>{t('Amount')}</h4>
                      <p>
                        {t(
                          'My emergency savings fund goal: (amount of money I want to save in my emergency savings fund)'
                        )}
                      </p>
                    </th>
                    <td className='border-top'>
                      {Inputs.radioButtonGroup({
                        id: 'savingsGoal',
                        labelPrefix: '$ ',
                        label: '',
                        value: values.savingsGoal,
                        error: errors.savingsGoal,
                        touched: touched.savingsGoal,
                        options: savingsGoalOptions,
                        includeOther: true,
                        money: true,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>
                      <h4 className='no-margin'>{t('How')}</h4>
                      <p>{t('I will use these strategies to save money regularly toward this goal:')}</p>
                    </th>
                    <td>
                      <ExpandableTextarea
                        inputOnly={true}
                        id='goal'
                        name='goal'
                        rows={5}
                        title={t('Strategies for saving')}
                      />
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>
                      <h4 className='no-margin'>{t('Where')}</h4>
                      <p>{t('I will save my money...')}</p>
                    </th>
                    <td>
                      {Inputs.radioButtonGroup({
                        customOption: 'In another location',
                        id: 'savingsLocation',
                        label: '',
                        value: values.savingsLocation,
                        error: errors.savingsLocation,
                        touched: touched.savingsLocation,
                        options: savingsLocationOptions,
                        stackbuttons: true,
                        includeOther: true,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>
                      <h4 className='no-margin'>{t('Time')}</h4>
                      <p>{t('I want to reach this goal in:')}</p>
                    </th>
                    <td>
                      {Inputs.radioButtonGroup({
                        id: 'savingsTime',
                        label: '',
                        value: values.savingsTime,
                        error: errors.savingsTime,
                        touched: touched.savingsTime,
                        options: savingsTimeOptions,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th scope='row'>
                      <h4 className='no-margin'>{t('Frequency')}</h4>
                      <p>{t('I will save money:')}</p>
                    </th>
                    <td>
                      {Inputs.radioButtonGroup({
                        id: 'savingsFrequency',
                        label: '',
                        value: values.savingsFrequency,
                        error: errors.savingsFrequency,
                        touched: touched.savingsFrequency,
                        options: savingsFrequencyOptions,
                      })}
                    </td>
                  </tr>
                  <tr>
                    <th colSpan='2' scope='row'>
                      <h3 tabIndex='0' title={summaryText(values, t)} role='status' aria-live='polite'>
                        {summaryJsx(values, t)}
                      </h3>
                      <p>
                        {t(
                          'These calculations assume 30 days in a month and 4 weeks in a month. Some numbers have been rounded to the nearest cent.'
                        )}
                      </p>
                    </th>
                  </tr>
                </tbody>
              </table>
              <p>
                {t(
                  'Note: These calculations do not include any interest earned on your savings and assume you are not using your savings during these time periods. In reality you may need to use your savings and build it up again.'
                )}{' '}
              </p>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

const summaryFirstPart = (values, t) => {
  const updatedSavingsGoal =
    values.savingsGoal !== 'Other' ? values.savingsGoal : values.savingsGoalOther >= 0 ? values.savingsGoalOther : 0;
  const updatedWhere =
    values.savingsLocation !== 'In another location'
      ? values.savingsLocation
      : values.savingsLocationOther
        ? values.savingsLocationOther
        : t('In another location');
  const parsedupdatedSavingsGoal = parseInt(updatedSavingsGoal).toFixed(2).withCommas();
  return `${t('In order to reach my goal of having ')}$${parsedupdatedSavingsGoal}${' '}${t(
    updatedWhere
  ).toLowerCase()}${t(' in ')}${t(values.savingsTime)},`;
};
const summaryLastPart = (values, t) => {
  const calculatedSavingsAmount = calculateSavingsAmount(values, t).toFixed(2).withCommas();
  return `$${calculatedSavingsAmount}
  ${t(values.savingsFrequency).toLowerCase()}.`;
};
const isSummaryValid = (values, t) => {
  const savingsAmount = calculateSavingsAmount(values, t);
  if (isNaN(savingsAmount)) {
    return t('Please fill out all the fields.');
  } else {
    return '';
  }
};
const summaryText = (values, t) => {
  const summaryTest = isSummaryValid(values, t);
  if (summaryTest === '') {
    const part1 = summaryFirstPart(values, t);
    const part2 = summaryLastPart(values, t);
    return `${part1}${t(' I need to save ')}${part2}`;
  } else {
    return summaryTest;
  }
};
const summaryJsx = (values, t) => {
  const summaryTest = isSummaryValid(values, t);
  const part1 = summaryFirstPart(values, t);
  const part2 = summaryLastPart(values, t);
  if (summaryTest === '') {
    return (
      <span>
        {part1}
        <span className='text-bold'>
          {t(' I need to save ')}
          {part2}
        </span>
      </span>
    );
  } else {
    return <span>{summaryTest}</span>;
  }
};

const calculateSavingsAmount = (values) => {
  const {savingsGoal, savingsGoalOther, savingsTime, savingsFrequency} = values;
  const updatedSavingsGoal = savingsGoal !== 'Other' ? savingsGoal : savingsGoalOther >= 0 ? savingsGoalOther : 0;
  const savingsTimeIndex = getSavingsTimeOptionsIndex(savingsTime);
  const savingsFrequencyIndex = getSavingsFrequencyOptionsIndex(savingsFrequency);
  if (savingsTimeIndex === -1 || savingsFrequencyIndex === -1) {
    return 0; // send back 0 if we can't get the proper indexes
  }
  const convertedSavingsTime = parseFloat(savingsTimeConversionArrays[savingsTimeIndex][savingsFrequencyIndex]);
  return Math.round((parseFloat(updatedSavingsGoal) / parseFloat(convertedSavingsTime)) * 100) / 100;
};

const getSavingsTimeOptionsIndex = (savingsTime) => {
  let savingsTimeOptionsIndex = -1;
  for (let i = 0; i < savingsTimeOptions.length; i++) {
    if (savingsTimeOptions[i] === savingsTime) {
      savingsTimeOptionsIndex = i;
      break;
    }
  }
  return savingsTimeOptionsIndex;
};

const getSavingsFrequencyOptionsIndex = (savingsFrequency) => {
  let savingsFrequencyOptionsIndex = -1;
  for (let i = 0; i < savingsFrequencyOptions.length; i++) {
    if (savingsFrequencyOptions[i] === savingsFrequency) {
      savingsFrequencyOptionsIndex = i;
      break;
    }
  }
  return savingsFrequencyOptionsIndex;
};

export default EmergencySavingsFundPlan;
