import {observable, action, decorate} from 'mobx';
import RequestService from '../services/RequestService';
import config from '../config';

import {refStartDateTime} from './OrganizationStore';

class GameSessionsStore {
  constructor() {
    this.buildFromSession();
  }

  sessions = [];
  currentSessionId = null;
  sessionsLoading = true;
  // certificate data
  certificateLoading = false;
  certificate = {};

  getSessions = (userId) => {
    RequestService.get(
      `${config.apiGateway.URL}/game-sessions/${userId}`,
      (response) => {
        this.sessions = response.data;
        this.writeToSession();
      },
      (error) => console.log(error),
    );
  };

  setSession = (userId, gameId) => {
    RequestService.get(
      `${config.apiGateway.URL}/game-sessions/${userId}/${gameId}`,
      (response) => {
        response.data && this.setSessionValues(response.data);
      },
      (error) => console.log(error),
    );
  };

  createSession = (userId, gameId) => {
    RequestService.post(
      `${config.apiGateway.URL}/game-sessions/${userId}/${gameId}`,
      {},
      (response) => {
        response.data && this.setSessionValues(response.data);
      },
      (error) => console.log(error),
    );
  };

  setSessionValues = ({id, session_key, session_value}) => {
    this.currentSessionId = id;
    localStorage.setItem(session_key, session_value);
  };

  updateSession = (userId, gameId) => {
    const sessionKey = this.getSessionKey();
    sessionKey && this.saveSession(sessionKey, localStorage.getItem(sessionKey), userId, gameId);
  };

  saveSession = (sessionKey, sessionValue, userId, gameId) => {
    const gameSession = {
      game_session: {
        session_key: sessionKey ? sessionKey : null,
        session_value: sessionValue ? sessionValue : null,
      },
    };
    RequestService.post(
      `${config.apiGateway.URL}/game-sessions/update-session/${userId}/${gameId}`,
      gameSession,
      () => {
        localStorage.clear();
      },
    );
  };

  hasCertificate = (userId, gameId) => {
    this.certificateLoading = true;
    RequestService.get(
      `${config.apiGateway.URL}/game-sessions/has-certificate/${userId}/${gameId}`,
      (response) => {
        if (response.data) this.certificate = response.data;
        this.certificateLoading = false;
      },
      (error) => console.log(error),
    );
  };

  getMasterCertificateUserSession = () => {
    // ASSUMPTION: 14 games, and their id's are: 1 - 14
    const numberOfGames = 14;
    let sessionsStarted = 0;
    let gamesCompleted = 0;
    let mostCurrentSession = {complete_date: refStartDateTime};
    for (let i = 0; i < numberOfGames; i++) {
      const session = this.sessions.find(({game_id}) => game_id === i + 1);
      if (!session) continue;
      sessionsStarted++;
      if (session.complete_date === null) continue;
      if (mostCurrentSession.complete_date < session.complete_date) {
        mostCurrentSession = session;
      }
      gamesCompleted++;
    }
    if (sessionsStarted === 0) return null;

    const earned = gamesCompleted === numberOfGames;
    const completeDate = earned ? mostCurrentSession.complete_date : null;
    const masterGameSession = {
      ...mostCurrentSession,
      game_id: 15,
      game_name: 'Master Certificate',
      earned,
      complete_date: completeDate,
    };
    return masterGameSession;
  };

  completeAnonymousGame = () => {
    RequestService.post(
      `${config.apiGateway.URL}/game-sessions/anonymous-complete/${this.currentSessionId}`,
      {},
      () => {
        localStorage.clear();
      },
      () => console.log('Error completing anonymous game.'),
    );
  };

  // Need to improve this, grabbed another session value
  getSessionKey = () => {
    let sessionKey = null;
    Object.keys(localStorage).forEach((localStorageKey) => {
      if (localStorageKey && localStorage.getItem(localStorageKey) && localStorageKey !== 'i18nextLng')
        sessionKey = localStorageKey;
    });
    return sessionKey;
  };

  writeToSession = () => {
    let json = JSON.stringify(this);
    sessionStorage.setItem('GameSessionsStore', json);
  };

  clearSession = () => {
    sessionStorage.removeItem('GameSessionsStore');
  };

  buildFromSession = () => {
    let json = JSON.parse(sessionStorage.getItem('GameSessionsStore'));
    if (json) {
      this.sessions = json.sessions;
      this.sessionsLoading = json.sessionsLoading;
      this.currentSessionId = json.currentSessionId;
    }
  };
}

decorate(GameSessionsStore, {
  sessions: observable,
  sessionsLoading: observable,
  currentSessionId: observable,
  certificate: observable,
  setSession: action,
  getSessions: action,
  hasCertficate: action,
});

export default GameSessionsStore;
