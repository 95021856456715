import React, {Component} from 'react';
import PropTypes from 'prop-types';

class AccordionContent extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    let {children} = this.props;

    return (
      <div className="accordion-content">
        {children}
      </div>
    );
  }
}

export default AccordionContent;