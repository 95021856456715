import React, {Fragment} from 'react';
import {Formik, Field, Form} from 'formik';
import * as Yup from 'yup';
import {Typeahead} from 'react-bootstrap-typeahead';
import moment from 'moment';
import queryString from 'query-string';
import {clearFilter} from '../../containers/account/organization/OrganizationReport';
import {getOrganizationName} from '../../services/OrganizationUtilities';

const filterSchema = Yup.object().shape({
  endDate: Yup.date().when(
    'startDate',
    (startDate, schema) => (startDate && schema.min(startDate, 'Start date must be before end date.')),
  ),
});

const DateFilter = ({startDate, endDate, applyFilter, orgFilterOptions, history}) => {
  const organizationId = queryString.parse(history.location.search).organizationId;

  return (
    <Fragment>
      <Formik
        initialValues={{
          startDate: startDate,
          endDate: endDate,
          organizationId: organizationId
        }}
        onSubmit={applyFilter}
        validationSchema={filterSchema}
      >
        {formikProps => 
          <Form className={orgFilterOptions ? "" : "full-width grid-row"}>
            {(orgFilterOptions && orgFilterOptions.display && orgFilterOptions.isAdmin) ? 
              <OrgAndDateFilter formikProps={formikProps} orgFilterOptions={orgFilterOptions} /> :
              <div className="grid-col-6">
                <div className="grid-row margin-bottom-2">
                  <div className="grid-col-12">
                    <h4 className="no-margin">Filter by Date</h4>
                  </div>
                  <div className="grid-col-6 mobile-full-width">
                    <div className="grid-row">
                      <label htmlFor="startDate" className="color-primary font-size-14">Start date:</label>
                    </div>
                    <div className="grid-row">
                      <Field id="startDate" name="startDate" type="date" className={`${formikProps.touched.startDate && formikProps.errors.startDate && 'alert'} fdic-input mobile-full-width start-date-input`}/>
                    </div>
                  </div>
                  <div className="grid-col-5 mobile-full-width margin-right-2">
                    <div className="grid-row">
                      <label htmlFor="endDate" className="color-primary font-size-14">End date:</label>
                    </div>
                    <div className="grid-row">
                      <Field id="endDate" name="endDate" type="date" className={`${formikProps.touched.endDate && formikProps.errors.endDate && 'alert'} fdic-input mobile-full-width end-date-input`} placeholder="test"/>
                    </div>
                  </div>
                </div>
                <div className="grid-row">
                  <div className="grid-col-5 mobile-full-width">
                    <button className="usa-button full-width apply-filter" onClick={formikProps.handleSubmit}>Apply Filter</button>
                  </div>
                </div>
                {formikProps.touched.endDate && formikProps.errors.endDate && 
                  <div className="grid-row alert full-width margin-top-105">
                    {formikProps.errors.endDate}
                  </div>
                }
              </div>
            }
          </Form>
        }
      </Formik>

      <ClearFilters history={history} orgFilterOptions={orgFilterOptions} />
    </Fragment>
  );
};

export default DateFilter;

const OrgAndDateFilter = ({formikProps, orgFilterOptions}) => {
  return (
    <div className="grid-row background-color-light-grey padding-left-3 padding-top-2 padding-right-3 padding-bottom-3 margin-bottom-4 margin-top-2">
      <div className="grid-col-12">

        <div>
          <div className="grid-row">
            <h4 className="no-margin">Filter by Organization</h4>
          </div>
          <div className="grid-row">
            <label htmlFor="organization-list-choice" className="color-primary font-size-14">Organization:</label>
          </div>
          <div id="org-choic-container" className="grid-row grid-col-8 margin-bottom-2 mobile-full-width">
            <Typeahead
              id="organization-list-choices"
              selected={formikProps.values.organizationId && orgFilterOptions.getOrganizationFromOptions(orgFilterOptions.organizationOptions, formikProps.values.organizationId)}
              multiple={false}
              options={orgFilterOptions.organizationOptions}
              onChange={(values) => values[0] && formikProps.setFieldValue('organizationId', values[0].id)}
            />
          </div>
        </div>

        <div className="grid-row">
          <div className="grid-col-12">
            <h4 className="no-margin">Filter by Date</h4>
          </div>
          <div className="grid-col-4 mobile-full-width">
            <div className="grid-row">
              <label htmlFor="startDate" className="color-primary font-size-14">Start date:</label>
            </div>
            <div className="grid-row">
              <Field title="Enter start date" id="startDate" name="startDate" type="date" className={`${formikProps.touched.endDate && formikProps.errors.endDate && 'alert'} fdic-input mobile-full-width`}/>
            </div>
          </div>
          <div className="grid-col-4 mobile-full-width">
            <div className="grid-row">
              <label htmlFor="endDate" className="color-primary font-size-14">End date:</label>
            </div>
            <div className="grid-row">
              <Field title="Enter end date" id="endDate" name="endDate" type="date" className={`${formikProps.touched.endDate && formikProps.errors.endDate && 'alert'} fdic-input mobile-full-width`} placeholder="test"/>
            </div>
          </div>
          <div className="grid-col-3 mobile-full-width">
            <button className="usa-button full-width" onClick={formikProps.handleSubmit}>Apply Filter{orgFilterOptions.isAdmin ? 's' : ''}</button>
          </div>
        </div>

      </div>
    </div>
  );
};

const ClearFilters = ({history, orgFilterOptions}) => {
  let startDate = queryString.parse(history.location.search).startDate;
  if (!startDate) startDate = "2021-09-30";
  const endDate = queryString.parse(history.location.search).endDate;
  const organizationId = queryString.parse(history.location.search).organizationId;

  return (
    <div>
      {((endDate && startDate) || organizationId) &&
        <Fragment>
          <p>Current filters:</p>
          <div className="grid-row background-color-light-grey padding-left-3 padding-top-2 padding-right-3 padding-bottom-3 margin-bottom-4">
            {
              endDate && startDate &&
                <div className="grid-col-12 margin-bottom-3">
                  <div className="grid-col-8">
                    <p>Date range from: <b>{moment(startDate).parseZone().format('LL')} to {moment(endDate).parseZone().format('LL')}</b></p>
                  </div>
                  <div className="grid-col-4">
                    <button className="usa-button usa-button--outline" onClick={() => clearFilter(history, 'dateRange')}>Clear Filter</button>
                  </div>
                </div>
            }
            {
              orgFilterOptions.isAdmin && organizationId &&
              <div className="grid-col-12">
                <div className="grid-col-8">
                  <p>Organization: <b>{getOrganizationName(orgFilterOptions.organizationOptions, organizationId)}</b></p>
                </div>
                <div className="grid-col-4">
                  <button className="usa-button usa-button--outline" onClick={() => clearFilter(history, "organization")}>Clear Filter</button>
                </div>
              </div>
            }
          </div>
        </Fragment>
      }
    </div>
  );
};