const reorderDomForRechartAccessibility = () => {
  const chartWrapperEls = document.querySelectorAll('.recharts-wrapper');
  if (chartWrapperEls) {
    for (let i = 0; i < chartWrapperEls.length; i++) {
      const chartWrapperEl = chartWrapperEls[i];
      const legendEl = chartWrapperEl.querySelector('.recharts-legend-wrapper');
      if (legendEl) {
        chartWrapperEl.removeChild(legendEl);
        chartWrapperEl.insertBefore(legendEl, chartWrapperEl.firstChild);
      }
    }
  }
};

const downloadCsvAsFile = (csvData, filename, appendDatetimeToFilename = true) => {
  const datetime = new Date();
  const date = datetime.toLocaleDateString();
  const time = datetime.toLocaleTimeString();

  const dateTimeRow = `Current as of ${date} ${time}\n`;
  const timestampedCsvData = dateTimeRow + csvData;

  if (appendDatetimeToFilename) {
    const dateTimeForFilename = `${date.replace(/\W/g, '-')}_${time.toLowerCase().replace(/\W/g, '-')}`;
    filename += `_${dateTimeForFilename}.csv`;
  } else {
    filename += '.csv';
  }

  const blob = new Blob([timestampedCsvData], {type: 'text/csv'});
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  a.click();
};

const dateFormatter = (date, locale = undefined, options = {}) => {
  return new Date(date).toLocaleDateString(locale, options);
};

export {reorderDomForRechartAccessibility, downloadCsvAsFile, dateFormatter};