const getOrganizationName = (organizationOptions, organizationId) => {
  if (!organizationOptions || organizationOptions.length === 0) return "";
  return getOrganizationFromOptions(organizationOptions, organizationId)[0].label;
}; 

const getOrganizationDisplayName = (organizationOptions, organizationId) => {
  return getOrganizationFromOptions(organizationOptions, organizationId)[0].displayName;
}; 

const getOrganizationFromOptions = (organizationOptions, organizationId) => {
  return organizationOptions.filter((organization) => organization.id.toString() === organizationId.toString());
};

export {getOrganizationName, getOrganizationFromOptions, getOrganizationDisplayName};
