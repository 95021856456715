import React from "react";
import {Formik, Form, Field} from "formik";
import Tables from "../utilities/Tables";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation, Translation} from "react-i18next";

const emptyItem = (
  item = "",
  amount = "",
  time = "",
  disabled = false,
  allowRemoval = false
) => ({
  item: {
    value: item,
    inputType: "textareaTrans",
    disabled: disabled,
    disabledText: "Save for medical copayments",
    allowRemoval: allowRemoval,
    title: "Enter your goal",
  },
  amount: {value: amount, inputType: "moneyTrans", disabled: disabled},
  time: {
    value: time,
    inputType: "calculated",
    calculation: savingTimeField,
    disabled: disabled,
    title: "Amount of Time",
  },
  frequency: {
    value: frequencyOptions[1],
    inputType: "none",
    disabled: disabled,
    title: "Units of time",
  },
  savingsPlan: {
    value: "",
    inputType: "calculated",
    calculation: savingsPlanCalculation,
  },
});

const savingTimeField = (formikProps, sectionIdentifier, key, index) => {
  return (
    <Translation>
      {(t) => (

        <div className="grid-row">
          <div className="grid-col-5">
            <Field
              title={t(`${formikProps.values[sectionIdentifier][key][index].time.title}`)}
              id={`${key}-${index}`}
              placeholder="0"
              step="1"
              type="number"
              name={`${sectionIdentifier}.${key}.${index}.time.value`}
              disabled={
                formikProps.values[sectionIdentifier][key][index].time.disabled
              }
            />
          </div>
          <div className="grid-col-7">
            <select
              name={`${sectionIdentifier}.${key}.${index}.frequency.value`}
              value={
                formikProps.values[sectionIdentifier][key][index].frequency.value
              }
              title={
                t(formikProps.values[sectionIdentifier][key][index].frequency.title)
              }
              onChange={formikProps.handleChange}
              onBlur={formikProps.handleBlur}
              disabled={
                formikProps.values[sectionIdentifier][key][index].frequency.disabled
              }
            >
              {frequencyOptions.map((option, index) => (
                <option key={`select-${key}-${index}-${option}`} value={option}>
                  {t(option)}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </Translation>
      
  );
};

const savingsPlanCalculation = (formikProps, sectionIdentifier, key, index) => {
  let amount = formikProps.values[sectionIdentifier][key][index].amount.value;
  let time = formikProps.values[sectionIdentifier][key][index].time.value;
  let frequency =
  formikProps.values[sectionIdentifier][key][index].frequency.value;
  let calculatedAmount = parseFloat(amount) / parseFloat(time);
  calculatedAmount = Math.round(calculatedAmount * 100) / 100;
  let string = (
    <Translation>
      {(t) => (
        <span>
      ${parseFloat(amount).toFixed(2).withCommas()} &divide; {time.withCommas()} =
      ${calculatedAmount.toFixed(2).withCommas()}
          <br></br>
          <br></br>
          <b>
            {t('I need to save ')}${calculatedAmount.toFixed(2).withCommas()}{t(' each ')}
            {frequency.substring(0, frequency.length - 1)}{t(' for the next ')}
            {time.withCommas()} {t(frequency)}.
          </b>
        </span>
      )}
    </Translation>
      
  );

  return calculatedAmount ? string : "";
};

const frequencyOptions = ["days", "weeks", "months", "years"];

const initialValues = {
  increasesSection: {
    Goal: [
      emptyItem(
        <Translation>
          {(t) => (
            <div>
              {t('Example:')}<br />$32.00 &divide; 4 = $8.00<br /><br />
              <b>{t('I need to save $8.00 each week for the next 4 weeks.')}</b>
            </div>
          )}
        </Translation>,
        "32",
        "4",
        true
      ),
      emptyItem("", "", "", false, false, ""),
    ],
  },
};

const SaveMoneyForGoals = ({tool, canEdit}) => {
  const {t, i18n} = useTranslation();
  return (
    <div>
      <Formik initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <EditableContent
              htmlType="div"
              initialContent={
                i18n.language === "es"
                  ? tool.contentsEs || tool.contents
                  : tool.contents
              }
              fieldName={i18n.language === "es" ? "contents_es" : "contents"}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            {Tables.renderSimpleTable(
              [
                t('Enter Your Goal'),
                t('Amount of Money You Need to Save'),
                `${t('Amount of Time')} ${t('You Have to Save the Money')}`,
                t('Savings Plan'),
              ],
              initialValues.increasesSection,
              formikProps,
              "increasesSection",
              true,
              () => emptyItem("", "", "", false, true),
              undefined,
              false,
              true,
              "",
              t('Table')
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SaveMoneyForGoals;
