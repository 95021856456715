import React from "react";
import {Formik, Form} from "formik";
import Tables from "../utilities/Tables";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation, Translation} from "react-i18next";

const emptyItem = (item = "", inputType = "label") => ({
  item: {value: item, inputType: inputType, columnWidth: 8},
  financial1: {
    value: "",
    inputType: "radioButtonGroup",
    options: ["Yes", "No"],
    columnWidth: 4,
  },
});

const emptyItemHTML = (item = "", inputType = "labelHTML") => ({
  item: {value: item, inputType: inputType, columnWidth: 8},
  financial1: {
    value: "",
    inputType: "radioButtonGroup",
    options: ["Yes", "No"],
    columnWidth: 4,
  },
});


const otherRow = (allowRemoval = false) => ({
  item: {
    value: "",
    label: "",
    inputType: "textarea",
    allowRemoval: allowRemoval,
    columnWidth: 8,
  },
  financial1: {inputType: "calculated", calculation: () => "", columnWidth: 4},
});

const initialValues = {
  month: "January",
  personalInfo: {
    "Personal information: Is the following information completely correct?": [
      emptyItem("Name(s)"),
      emptyItem("Address(s)"),
      emptyItem("Social Security number"),
      emptyItem("Date of birth"),
      emptyItem("Phone number(s)"),
      emptyItem("Employment"),
    ],
  },
  publicRecord: {
    "Public records": [
      emptyItem("Do you recognize all of the entries?"),
      emptyItem("Do the amounts listed as owed seem accurate?"),
      emptyItemHTML(
        <Translation>
          {(t) => (
            <div>
              {t('Are any of these being reported beyond the Fair Credit Reporting Act allowed time limits? Most negative items can be reported for seven years except:')}
              <ul>
                <li>
                  <b>
                    {t('Civil judgments: These can be reported for up to seven years from the date they are filed with the courts.')}
                  </b>
                </li>
                <li>
                  <b>
                    {t('Chapter 7 bankruptcies: These can be reported for up to ten years.')}
                  </b>
                </li>
                <li>
                  <b>
                    {t('Tax liens: These can be reported for seven years after they are paid in full.')}
                  </b>
                </li>
                <li>
                  <b>{t('Unpaid tax liens: These can be reported for 15 years.')}</b>
                </li>
              </ul>
            </div>
          )}
        </Translation>
          
      ),
    ],
  },
  collections: {
    Collections: [
      emptyItem("Do you recognize the account(s)?"),
      emptyItem(
        "If yes, is the balance listed as zero with the original creditor? Check the credit accounts section too."
      ),
      emptyItemHTML(
        <Translation>
          {(t) => (
            <div>
              {t('If yes, is the account too old to still be reported?')}
              <ul>
                <li>
                  <b>
                    {t('Civil judgments: These can be reported for up to seven years from the date they are filed with the courts.')}
                  </b>
                </li>
                <li>
                  <b>
                    {t('Chapter 7 bankruptcies: These can be reported for up to ten years.')}
                  </b>
                </li>
                <li>
                  <b>
                    {t('Tax liens: These can be reported for seven years after they are paid in full.')}
                  </b>
                </li>
                <li>
                  <b>{t('Unpaid tax liens: These can be reported for 15 years.')}</b>
                </li>
              </ul>
            </div>
          )}
        </Translation>
          
      ),
    ],
  },
  accounts: {
    Accounts: [
      emptyItem("Do all of the accounts belong to you?"),
      emptyItem("Do the balances reported seem accurate?"),
      emptyItem("Do the credit limits seem accurate?"),
      emptyItem("Are any accounts showing late payments?"),
      emptyItem("If yes, are the late payments accurate?"),
      emptyItem(
        "Are debts that have been assigned or sold to debt collectors showing a zero balance?"
      ),
      emptyItem(
        "Is corrected information (information you have disputed in the past) being reported accurately? Sometimes inaccurate information is reinserted in reports."
      ),
    ],
  },
  inquiries: {
    Inquiries: [
      emptyItem("Do all of the applications for credit listed belong to you?"),
    ],
  },
  other: {
    "Is there anything else that doesn’t look right, or you need to investigate further? List it: ":
      [
        emptyItem("Is there anything else that doesn’t look right, or you need to investigate further? List it:"),
        otherRow(),
      ],
  },
};

const MyBankingChecklist = ({tool, canEdit}) => {
  const {i18n} = useTranslation();
  return (
    <Translation>
      {(t) => (
        <div>
          <Formik initialValues={initialValues}>
            {(formikProps) => (
              <Form>
                <EditableContent
                  htmlType="div"
                  initialContent={
                    i18n.language === "es"
                      ? tool.contentsEs || tool.contents
                      : tool.contents
                  }
                  fieldName={i18n.language === "es" ? "contents_es" : "contents"}
                  updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
                  canEdit={canEdit}
                  richText
                />
                <div className="grid-col-9">
                  {Tables.renderSimpleTable(
                    [t('Personal information: What to look for'), t('Yes or No')],
                    initialValues.personalInfo,
                    formikProps,
                    "personalInfo",
                    true,
                    () => {},
                    undefined,
                    false,
                    true,
                    "",
                    t('Table')
                  )}

                  {Tables.renderSimpleTable(
                    [t('Public records: What to look for'), t('Yes or No')],
                    initialValues.publicRecord,
                    formikProps,
                    "publicRecord",
                    true,
                    () => {},
                    undefined,
                    false,
                    true,
                    "",
                    t('Table')
                  )}

                  {Tables.renderSimpleTable(
                    [t('Collections: What to look for'), t('Yes or No')],
                    initialValues.collections,
                    formikProps,
                    "collections",
                    true,
                    () => {},
                    undefined,
                    false,
                    true,
                    "",
                    t('Table')
                  )}

                  {Tables.renderSimpleTable(
                    [t('Accounts: What to look for'), t('Yes or No')],
                    initialValues.accounts,
                    formikProps,
                    "accounts",
                    true,
                    () => {},
                    undefined,
                    false,
                    true,
                    "",
                    t('Table')
                  )}

                  {Tables.renderSimpleTable(
                    [t('Inquiries: What to look for'), t('Yes or No')],
                    initialValues.inquiries,
                    formikProps,
                    "inquiries",
                    true,
                    () => {},
                    undefined,
                    false,
                    true,
                    "",
                    t('Table')
                  )}

                  {Tables.renderSimpleTable(
                    [t('Other: What to look for'), t('Yes or No')],
                    initialValues.other,
                    formikProps,
                    "other",
                    true,
                    () => otherRow(true),
                    undefined,
                    false,
                    true,
                    "",
                    t('Table')
                  )}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Translation>
      
  );
};
export default MyBankingChecklist;
