import React, {useState} from 'react';
import {Formik, Field, ErrorMessage} from 'formik';
import {Redirect} from 'react-router-dom';
import RequestService from '../../services/RequestService';
import config from '../../config';
import * as Yup from 'yup';
import Tooltip from '@material-ui/core/Tooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/free-regular-svg-icons';
import {withStyles} from '@material-ui/core/styles';
import {observer} from 'mobx-react';
import Filter from 'bad-words';
import MaskedInput from 'react-text-mask';
import AddressForm, {addressSchemaObject} from '../../components/Formik/AddressForm';
import InputError from '../../components/Formik/InputError';
import {hasAlert} from '../../components/Formik/FormInput';
import EyeIconButton from '../../components/Buttons/EyeIconButton';
import MandatoryDisclosure from '../MandatoryDisclosure';

const filter = new Filter();
// whitelist for filter
filter.removeWords('cox', 'knob');
const orgApplySuccess = '?orgapply=success';

const AccountToolTip = withStyles({
  tooltip: {
    fontSize: '1em',
  },
})(Tooltip);

const phoneNumberMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

const phoneNumberRegex = /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/;

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/;

const passwordRequirements =
  'Password must be at least 8 characters and have at least one lowercase letter, one uppercase letter, and one number.';

const organizationNameInfo = 'This is the name of your organization.';

const organizationDisplayNameInfo = (
  <span>
    This is the name displayed when players search for your organization. It can be the same as or different from
    “Organization name.” <br />
    For a local entity of a larger organization, use an Organization Display Name that is unique and specific to YOUR
    entity.
  </span>
);

function equalTo(ref, msg) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test: (value) => {
      return value === this.resolve(ref);
    },
  });
}

Yup.addMethod(Yup.string, 'equalTo', equalTo);

let registrationSchemaObject = {
  email: Yup.string()
    .email('Must be a valid email.')
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .min(2)
    .max(50)
    .required('Email is required.'),
  password: Yup.string().matches(passwordRegex, passwordRequirements).required('Password is required.'),
  firstName: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .min(2, 'First name must be at least two characters.')
    .max(50, 'First name must be at most 50 characters.')
    .required('First name is required.'),
  lastName: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .min(2, 'Last name must be at least two characters.')
    .max(50, 'Last name must be at most 50 characters.')
    .required('Last name is required.'),
  passwordConfirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match.')
    .required('You must confirm your password.'),
  emailConfirmation: Yup.string()
    .oneOf([Yup.ref('email'), null], 'Emails must match.')
    .required('You must confirm your email.'),
  phoneNumber: Yup.string()
    .matches(phoneNumberRegex, 'Phone number must be valid.')
    .required('Phone number is required.'),
  organizationName: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .min(2, 'Organization name must be at least two characters.')
    .max(50, 'Organization name must be at most 50 characters.')
    .required('Organization name is required.'),
  organizationDisplayName: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .min(2, 'Organization display name must be at least two characters.')
    .max(50, 'Organization display name must be at most 50 characters.')
    .required('Organization display name is required.'),
};

registrationSchemaObject = Object.assign(registrationSchemaObject, addressSchemaObject);

const registrationSchema = Yup.object().shape(registrationSchemaObject);

const organizationApply = (values, authStore, history, formikBag, setDuplicateDisplayNameError) => {
  setDuplicateDisplayNameError(false);
  let body = {
    user: {
      email: values.email,
      password: values.password,
      first_name: values.firstName,
      last_name: values.lastName,
    },
    organization: {
      name: values.organizationName,
      details: values.details,
      display_name: values.organizationDisplayName,
      phone_number: values.phoneNumber,
      address_line_one: values.addressLineOne,
      address_line_two: values.addressLineTwo,
      city: values.city,
      zip_code: values.zipCode,
      state: values.state,
    },
  };
  RequestService.post(
    `${config.apiGateway.URL}/organizations/apply`,
    body,
    (response) => {
      if (response.data.success) {
        RequestService.delete(`${config.apiGateway.URL}/logout`, () => {
          authStore.clearSession();
          history.push(`/login${orgApplySuccess}`);
        });
      } else {
        setDuplicateDisplayNameError(true);
        formikBag.setFieldError('organizationDisplayName', 'This Display Name is already in use. Please change');
      }
    },
    (error) => {
      if (error.response.status === 400) {
        authStore.registrationEmailFailure = true;
      }
    },
  );
};

const OrganizationApplication = observer((props) => {
  const [showPwd, setShowPwd] = useState(false);
  const [showPwdConf, setShowPwdConf] = useState(false);
  const [duplicateDisplayNameError, setDuplicateDisplayNameError] = useState(false);
  const [showMandatoryStatements, setShowMandatoryStatements] = useState(false);

  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPwd(!showPwd);
  };
  const toggleShowReenterPassword = (e) => {
    e.preventDefault();
    setShowPwdConf(!showPwdConf);
  };
  config.setUniqueTitle('Request an Organization Account');
  let emailTaken = props.authStore.registrationEmailFailure;

  if (props.authStore.loggedIn && !props.authStore.isAdmin) {
    return <Redirect to='/account' />;
  }

  return (
    <div className='registration-wrapper margin-left-auto margin-right-auto'>
      <Formik
        validationSchema={props.skipValidation ? null : registrationSchema}
        initialValues={{
          email: '',
          emailConfirmation: '',
          password: '',
          passwordConfirmation: '',
          firstName: '',
          lastName: '',
          phoneNumber: '',
          organizationName: '',
          // details: '',
          organizationDisplayName: '',
          addressLineOne: '',
          addressLineTwo: '',
          city: '',
          zipCode: '',
          state: 'Please select',
          additionalUsers: [{email: '', password: '', emailConfirmation: '', passwordConfirmation: ''}],
        }}
        onSubmit={(values, formikBag) =>
          organizationApply(values, props.authStore, props.history, formikBag, setDuplicateDisplayNameError)
        }
      >
        {(formikProps) => (
          <form className='reg-form'>
            <fieldset>
              <h2 className='margin-bottom-1'>Request an Organization Account</h2>
              <div>
                <p className='margin-bottom-0 text-center'>
                  <strong>This is only for Organization accounts.</strong>
                </p>
                <p className='margin-top-0 text-center'>
                  Individual players should create an account <a href='/register'>here</a>.
                </p>
              </div>
              <p className='margin-top-0 margin-bottom-3 padding-left-2'>All fields marked with * are required.</p>
              <div className='container padding-left-2'>
                <div className='grid-row margin-bottom-2'>
                  <div className='grid-col-6 padding-right-2'>
                    <label htmlFor='email'>Email: *</label>
                    <Field
                      id='email'
                      name='email'
                      type='email'
                      className={`${hasAlert(formikProps, 'email') && 'alert'}`}
                      required
                    />
                    <InputError formikProps={formikProps} fieldName='email' />
                  </div>
                  <div className='grid-col-6 padding-right-2'>
                    <label htmlFor='emailConfirmation'>Re-enter email: *</label>
                    <Field
                      id='emailConfirmation'
                      name='emailConfirmation'
                      type='email'
                      className={`${hasAlert(formikProps, 'emailConfirmation') && 'alert'}`}
                      required
                    />
                    <InputError formikProps={formikProps} fieldName='emailConfirmation' />
                  </div>
                </div>
                <div className='grid-row margin-bottom-2'>
                  <div className='grid-col-6 padding-right-2'>
                    <label htmlFor='password'>
                      <AccountToolTip title={passwordRequirements} placement='top'>
                        <span>
                          Password: * <FontAwesomeIcon icon={faQuestionCircle} />
                        </span>
                      </AccountToolTip>
                    </label>
                    <div style={{position: 'relative'}}>
                      <Field
                        id='password'
                        name='password'
                        type={showPwd ? 'text' : 'password'}
                        className={`${hasAlert(formikProps, 'password') && 'alert'}`}
                        required
                        autoComplete='off'
                      />
                      <EyeIconButton onClickToggle={toggleShowPassword} show={showPwd} />
                      <InputError formikProps={formikProps} fieldName='password' />
                    </div>
                  </div>
                  <div>
                    <label htmlFor='password'>Re-enter password: *</label>
                    <div style={{position: 'relative'}}>
                      <Field
                        id='passwordConfirmation'
                        name='passwordConfirmation'
                        type={showPwdConf ? 'text' : 'password'}
                        className={`${hasAlert(formikProps, 'passwordConfirmation') && 'alert'}`}
                        required
                        autoComplete='off'
                      />
                      <EyeIconButton onClickToggle={toggleShowReenterPassword} show={showPwdConf} />
                      <InputError formikProps={formikProps} fieldName='passwordConfirmation' />
                    </div>
                  </div>
                </div>
                <div className='grid-row margin-bottom-6'>
                  <div className='grid-col-6 padding-right-2'>
                    <label htmlFor='firstName'>
                      <span htmlFor='firstName'>Primary Contact’s First Name: *</span>
                    </label>
                    <Field
                      id='firstName'
                      name='firstName'
                      type='text'
                      className={`${hasAlert(formikProps, 'firstName') && 'alert'}`}
                      required
                    />
                    <InputError formikProps={formikProps} fieldName='firstName' />
                  </div>
                  <div>
                    <label htmlFor='lastName'>
                      <span htmlFor='lastName'>Primary Contact’s Last Name: *</span>
                    </label>
                    <Field
                      id='lastName'
                      name='lastName'
                      type='text'
                      className={`${hasAlert(formikProps, 'lastName') && 'alert'}`}
                      required
                    />
                    <InputError formikProps={formikProps} fieldName='lastName' />
                  </div>
                </div>
                <div className='grid-row margin-bottom-2'>
                  <div className='grid-col-9 padding-right-2'>
                    <label htmlFor='phoneNumber'>
                      <span htmlFor='phoneNumber'>Phone number: *</span>
                    </label>
                    <Field id='phoneNumber' name='phoneNumber'>
                      {({field}) => (
                        <MaskedInput
                          {...field}
                          mask={phoneNumberMask}
                          id='phoneNumber'
                          type='text'
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          className={formikProps.errors.phoneNumber && formikProps.touched.phoneNumber ? 'alert' : ''}
                        />
                      )}
                    </Field>
                    <InputError formikProps={formikProps} fieldName='phoneNumber' />
                  </div>
                </div>
                <div className='grid-row margin-bottom-2'>
                  <div className='grid-col-6 padding-right-2'>
                    <label htmlFor='organizationName'>
                      <AccountToolTip title={organizationNameInfo} placement='top'>
                        <span htmlFor='organizationName'>
                          Organization name: * <FontAwesomeIcon icon={faQuestionCircle} />
                        </span>
                      </AccountToolTip>
                    </label>
                    <Field
                      id='organizationName'
                      name='organizationName'
                      type='text'
                      className={`${hasAlert(formikProps, 'organizationName') && 'alert'}`}
                      required
                    />
                    <InputError formikProps={formikProps} fieldName='organizationName' />
                  </div>
                  <div className='grid-col-6'>
                    <label htmlFor='organizationDisplayName'>
                      <AccountToolTip title={organizationDisplayNameInfo} placement='top'>
                        <span htmlFor='organizationDisplayName'>
                          Organization display name: * <FontAwesomeIcon icon={faQuestionCircle} />
                        </span>
                      </AccountToolTip>
                    </label>
                    <Field
                      id='organizationDisplayName'
                      name='organizationDisplayName'
                      type='text'
                      className={`${hasAlert(formikProps, 'organizationDisplayName') && 'alert'}`}
                      required
                    />
                    <InputError formikProps={formikProps} fieldName='organizationDisplayName' />
                    {duplicateDisplayNameError && <ErrorMessage name='organizationDisplayName' />}
                  </div>
                </div>
                <AddressForm formikProps={formikProps} />
                {emailTaken && (
                  <div className='margin-top-1 margin-bottom-1 alert alert-box'>
                    <p>
                      This email is already associated with an account.{' '}
                      <a href='/password-reset' title='Reset password'>
                        Forgot password?
                      </a>
                    </p>
                  </div>
                )}
                <div>
                  <strong>Adding Additional Staff</strong>
                  <p>
                    After we approve your request, you will be able to add Additional Staff who can access your
                    Organization Account.
                  </p>
                </div>
                <div>
                  <strong>Email Communications</strong>
                  <p>
                    You may receive periodic emails from the FDIC about How Money Smart Are You? If you do not wish to
                    receive these emails, please do not submit this request.
                  </p>
                </div>
                <div className='reg-form-btn margin-bottom-4'>
                  <button
                    id='apply'
                    className='usa-button fdic-button square-button'
                    type='button'
                    onClick={() => setShowMandatoryStatements(true)}
                    disabled={!formikProps.isDirty && (!formikProps.isValid || formikProps.isSubmitting)}
                  >
                    Submit
                  </button>
                </div>
              </div>
              <MandatoryDisclosure
                {...props}
                submit={() => formikProps.handleSubmit()}
                show={showMandatoryStatements}
                setShow={setShowMandatoryStatements}
              />
            </fieldset>
          </form>
        )}
      </Formik>
    </div>
  );
});

export {
  registrationSchema,
  organizationApply,
  phoneNumberMask,
  orgApplySuccess,
  organizationDisplayNameInfo,
  organizationNameInfo,
  phoneNumberRegex,
};
export default OrganizationApplication;
