import React from 'react';
import {Typeahead} from 'react-bootstrap-typeahead';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

import {getOrganizationFromOptions} from '../../services/OrganizationUtilities';

const OrganizationInput = ({organizationId, organizationOptions, handleChange, handleSave}) => {
  var selectOptions = organizationId ? getOrganizationFromOptions(organizationOptions, organizationId) : [];
  const {t} = useTranslation();

  return (
    <div className='full-width'>
      <div className='text-align-left'>
        <label htmlFor='organizationName'>
          {t('Enter new Organization Display Name (given to you by the organization)')}:
        </label>
        <Typeahead
          id='change-organization'
          selected={selectOptions}
          multiple={false}
          minLength={3}
          options={organizationOptions}
          onChange={handleChange}
          className='full-width'
          inputProps={{title: 'Change organization'}}
          emptyLabel={`${t('No matches found')}.`}
          filterBy={(option, props) => option.label.toLowerCase().indexOf(props.text.toLowerCase()) === 0}
        />
      </div>
      <div className='grid-row margin-top-2 reg-form-btn'>
        <button
          className='usa-button fdic-button tan-button square-button margin-right-2 save-button'
          onClick={handleSave}
        >
          {t('Save')}
        </button>
      </div>
      <div className='grid-row margin-top-2 text-align-center reg-form-btn'>
        <Link to='/account'>{t('Cancel')}</Link>
      </div>
    </div>
  );
};

export default OrganizationInput;
