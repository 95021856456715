import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter as Router} from 'react-router-dom';
import {useLocation}from 'react-router-dom';
import Footer from './Footer';

import './i18n';

import './index.scss';
require('uswds');

const ScrollToTop = () => {
  const {pathname} = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

ReactDOM.render(
  <Router>
    <ScrollToTop />
    <App />
    <div id="footer">
      <Footer />
    </div>
  </Router>,
  document.getElementById('root')
);
  
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
