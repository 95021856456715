import React, {Component, Suspense} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import Modal from 'react-responsive-modal';
import IdleTimer from 'react-idle-timer';

import Routes from './Routes';
import Navigator from './components/Navigator';
import AuthStore from './stores/AuthStore';
import GamesStore from './stores/GamesStore';
import GlossaryItemsStore from './stores/GlossaryItemsStore';
import GameSessionsStore from './stores/GameSessionsStore';
import OrganizationStore from './stores/OrganizationStore';
import AdminReportsStore from './stores/AdminReportsStore';
import HelpCenterStore from './stores/HelpCenterStore';
import SideNavigator from './components/Navigator/SideNavigator';
import SurveyStore from './stores/SurveyStore';
import EmailsStore from './stores/EmailsStore';
import FeatureToggleStore from './stores/FeatureToggleStore';
import {Loader} from './components/Loader';
import AuditLogStore from './stores/AuditLogStore';
import TempMessage from './containers/TempMessage';

const USER_IDLE_WARNING_TIME_IN_MINUTES = 10;
const USER_IDLE_LOGOUT_TIME_IN_MINUTES = 15;

const ADMIN_IDLE_WARNING_TIME_IN_MINUTES = 10;
const ADMIN_IDLE_LOGOUT_TIME_IN_MINUTES = 15;

const eventOnLink = (event) =>
  event.target &&
  (event.target.tagName === 'A' || (event.target.parentNode && event.target.parentNode.tagName === 'A'));

const isExternalLink = (event) =>
  event.target.hostname !== window.location.hostname && event.target.parentNode.hostname !== window.location.hostname;

class App extends Component {
  constructor() {
    super();
    this.idleTimer = null;
    this.logoutTimer = null;
    this.handleOnIdle = this.handleOnIdle.bind(this);
    this.handleIdleLogout = this.handleIdleLogout.bind(this);
    this.state = {
      timeOutModalOpen: false,
    };
  }
  static propTypes = {
    history: PropTypes.object,
  };

  componentDidMount() {
    const externalLinkHandler = (e) => {
      var event = window.e || e;
      if (eventOnLink(event) && isExternalLink(event)) {
        event.preventDefault();
        window.open(event.target.href || event.target.parentNode.href, '_blank');
      }
    };

    if (document.addEventListener) document.addEventListener('click', externalLinkHandler, false);
    else document.attachEvent('onclick', externalLinkHandler);

    // analytics:
    if (window.s && window.s.t) {
      var s_code = window.s.t();
      if (s_code) document.write(s_code);
    }
    if (navigator.appVersion.indexOf('MSIE') >= 0)
      // eslint-disable-next-line
      document.write(unescape('%3C') + '!-' + '-');
  }

  handleOnIdle() {
    this.setState({timeOutModalOpen: true});
  }

  handleIdleLogout(setSessionWarning = false) {
    sessionStorage.clear();
    this.setState({timeOutModalOpen: false});
    this.props.history.push('/login');
    setSessionWarning && sessionStorage.setItem('inactivityLogout', true);
  }

  onCloseModal = () => {
    return;
  };

  render() {
    const childProps = {
      title: "FDIC's How Money Smart Are You?",
      authStore: new AuthStore(),
      gamesStore: new GamesStore(),
      glossaryItemsStore: new GlossaryItemsStore(),
      gameSessionsStore: new GameSessionsStore(),
      organizationStore: new OrganizationStore(),
      adminReportsStore: new AdminReportsStore(),
      helpCenterStore: new HelpCenterStore(),
      surveyStore: new SurveyStore(),
      emailsStore: new EmailsStore(),
      featureToggleStore: new FeatureToggleStore(),
      auditLogStore: new AuditLogStore(),
    };

    return (
      <Suspense fallback={<Loader />}>
        <div id='main-content' className='app'>
          <div>
            <div className='site-content'>
              <div className='flex-fill position-relative'>
                <div className='grid-container-widescreen'>
                  <div className='grid-row margin-bottom-5'>
                    <div className='grid-col-12 desktop:grid-col-2 hide-print'>
                      <SideNavigator {...childProps} />
                    </div>
                    <div className='grid-col-10 print-full-width mobile-full-width'>
                      <div className='navigator-container margin-bottom-2'>
                        <Navigator {...childProps} />
                      </div>
                      <TempMessage />
                      <Routes childProps={childProps} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal
            open={this.state.timeOutModalOpen}
            closeOnOverlayClick={false}
            closeOnEsc={false}
            showCloseIcon={false}
            onClose={this.onCloseModal}
          >
            <div className='usa-alert usa-alert--warning margin-bottom-5'>
              <div className='usa-alert__body'>
                <h4 className='usa-alert__heading'>Are you still there?</h4>
                <p className='usa-alert__text margin-0'>Your session is about to expire.</p>
                <p className='usa-alert__text'>
                  You will be logged out in{' '}
                  <b>{USER_IDLE_LOGOUT_TIME_IN_MINUTES - USER_IDLE_WARNING_TIME_IN_MINUTES}</b> minutes due to
                  inactivity.
                </p>
              </div>
            </div>
            <div className='grid-row text-center margin-bottom-2'>
              <button onClick={() => this.setState({timeOutModalOpen: false})} className='usa-button centered'>
                Keep me logged in
              </button>
            </div>
            <div className='grid-row text-center'>
              <button onClick={() => this.handleIdleLogout(false)} className='usa-button usa-button--unstyled centered'>
                Logout
              </button>
            </div>
          </Modal>
          {childProps.authStore.loggedIn && (
            <>
              <IdleTimer
                ref={(ref) => {
                  this.idleTimer = ref;
                }}
                timeout={
                  (childProps.authStore.isAdmin
                    ? ADMIN_IDLE_WARNING_TIME_IN_MINUTES
                    : USER_IDLE_WARNING_TIME_IN_MINUTES) *
                  1000 *
                  60
                }
                onIdle={this.handleOnIdle}
                debounce={250}
              />
              <IdleTimer
                ref={(ref) => {
                  this.logoutTimer = ref;
                }}
                timeout={
                  (childProps.authStore.isAdmin
                    ? ADMIN_IDLE_LOGOUT_TIME_IN_MINUTES
                    : USER_IDLE_LOGOUT_TIME_IN_MINUTES) *
                  1000 *
                  60
                }
                onIdle={() => this.handleIdleLogout(true)}
                debounce={250}
              />
            </>
          )}
        </div>
      </Suspense>
    );
  }
}

export default withRouter(App);
export {App as TestableApp, isExternalLink, eventOnLink};