import {observable, action, decorate, computed} from 'mobx';
import moment from 'moment';

const ADMIN = 'admin';
const ORGANIZATION = 'organization';
const EDITOR = 'editor';

const MAX_LOGIN_ATTEMPTS = 5;
const ACCOUNT_LOCKED_PERIOD_MINUTES = 120;

class AuthStore {
  constructor() {
    this.buildFromSession();
  }

  passwordFailure = false;
  emailFailure = false;
  loggedIn = false;
  userId = '';
  email = '';
  firstName = '';
  lastName = '';
  accountType = '';
  organizationId = '';
  primaryOrganizationAccount = false;
  failures = 0;

  registrationEmailFailure = false;

  storeAuth = (email, firstName, lastName, userId, accountType, organizationId, primaryOrganizationAccount, token) => {
    this.email = email;
    this.firstName = firstName;
    this.lastName = lastName;
    this.userId = userId;
    this.accountType = accountType;
    this.organizationId = organizationId;
    this.primaryOrganizationAccount = primaryOrganizationAccount;
    this.loggedIn = true;

    this.writeToSession(token);
  };

  clearSession = () => {
    sessionStorage.clear();
    localStorage.clear();
  };

  writeToSession = (token) => {
    let json = JSON.stringify(this);
    sessionStorage.setItem('AuthStore', json);
    token && sessionStorage.setItem('Token', token);
  };

  buildFromSession = () => {
    let json = JSON.parse(sessionStorage.getItem('AuthStore'));
    if (json) {
      this.passwordFailure = json.passwordFailure;
      this.loggedIn = json.loggedIn;
      this.email = json.email;
      this.firstName = json.firstName;
      this.lastName = json.lastName;
      this.userId = json.userId;
      this.accountType = json.accountType;
      this.primaryOrganizationAccount = json.primaryOrganizationAccount;
      this.organizationId = json.organizationId;
      this.failures = json.failures;
      this.accountLocked = json.accountLocked;
    }
  };

  loginFailure = () => {
    this.failures = this.failures + 1;
    if (this.failures >= MAX_LOGIN_ATTEMPTS) this.accountLocked = moment();
    this.writeToSession();
  };

  resetAccountLocked = () => {
    this.failures = 0;
    this.accountLocked = null;
    this.writeToSession();
  };

  updateAccount = (update) => {
    Object.keys(update).forEach((key) => {
      if (this[key] !== undefined) {
        this[key] = update[key];
      }
    });
    this.writeToSession();
  };

  get isEditor() {
    return this.accountType === EDITOR;
  }

  get isAdmin() {
    return this.accountType === ADMIN || this.accountType === EDITOR;
  }

  get isOrganization() {
    return this.accountType === ORGANIZATION;
  }
}

decorate(AuthStore, {
  storeAuth: action,
  clearSession: action,
  updateOrganizationId: action,
  passwordFailure: observable,
  emailFailure: observable,
  loggedIn: observable,
  firstName: observable,
  lastName: observable,
  userId: observable,
  organizationId: observable,
  registrationEmailFailure: observable,
  isAdmin: computed,
  isEditor: computed,
  isOrganization: computed,
});

export default AuthStore;
export {ADMIN, EDITOR, ORGANIZATION, ACCOUNT_LOCKED_PERIOD_MINUTES};
