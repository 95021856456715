import React from 'react';
import {Formik, Form} from 'formik';
import Tables from '../utilities/Tables';
import Inputs from '../utilities/Inputs';
import EditableContent from '../../../components/EditableContent/EditableContent';
import config from '../../../config';
import {useTranslation} from 'react-i18next';

const emptyItem = (item = '', inputType = 'questionlabel', label = '', allowRemoval = false) => ({
  item: {
    value: item,
    inputType: inputType,
    label: label,
    columnWidth: 2,
    allowRemoval: allowRemoval,
  },
  amount: {value: '', inputType: 'money', columnWidth: 2},
  frequency: {
    value: 'Every month',
    inputType: 'select',
    options: Object.keys(Tables.frequencies),
    columnWidth: 2,
  },
  monthlyAmount: {
    value: '',
    inputType: 'calculatedMoney',
    calculation: displayMonthlyAmount,
    columnWidth: 1,
  },
  type: {
    value: '',
    inputType: 'radioButtonGroup',
    options: typeOptions,
    columnWidth: 3,
  },
});

const emptyIncrementingItem = (
  item = '',
  inputType = 'lightIncrementingLabel',
  increment = 1,
  allowRemoval = false
) => ({
  item: {
    value: item,
    label: item,
    inputType: inputType,
    columnWidth: 2,
    increment: increment,
    allowRemoval: allowRemoval,
  },
  amount: {value: '', inputType: 'money', columnWidth: 1},
  frequency: {
    value: 'Every month',
    inputType: 'select',
    options: Object.keys(Tables.frequencies),
    columnWidth: 2,
  },
  monthlyAmount: {
    value: '',
    inputType: 'calculatedMoney',
    calculation: displayMonthlyAmount,
    columnWidth: 1,
  },
  type: {
    value: '',
    inputType: 'radioButtonGroup',
    options: typeOptions,
    columnWidth: 3,
  },
});

const totalRow = () => ({
  item: {
    inputType: 'calculated',
    calculation: displaySummaryValue,
    columnWidth: 2,
  },
  amount: {value: '', inputType: 'label', columnWidth: 1},
  frequency: {value: '', inputType: 'label', columnWidth: 1},
  monthlyAmount: {
    inputType: 'calculated',
    calculation: totalMonthlyAmount,
    columnWidth: 1,
  },
  type: {inputType: 'calculated', calculation: () => '', columnWidth: 3},
});

const displaySummaryValue = (formikProps, _sectionIdentifier, _key, _index, t, i18n) => {
  let displayMonth = t(formikProps.values.month);
  if (displayMonth && i18n.language === 'es') {
    displayMonth = displayMonth.toLowerCase();
  }
  return (
    <b tabIndex='0' className='subtotal-row'>
      {t('My Total Net Income for')} {displayMonth}
    </b>
  );
};
const displayMonthlyAmount = (formikProps, sectionIdentifier, key, index) =>
  `${monthlyAmount(formikProps.values[sectionIdentifier][key][index]).toFixed(2).withCommas()}`;

const monthlyAmount = (item) => {
  const amount = item.amount.value;
  const convertedAmount = Tables.frequencies[item.frequency.value](amount);
  return Math.round(convertedAmount * 100) / 100;
};

const totalMonthlyAmount = (formikProps, sectionIdentifier) => {
  let total = 0;
  Object.keys(formikProps.values[sectionIdentifier]).forEach((subSection) =>
    formikProps.values[sectionIdentifier][subSection].forEach((row, index) => {
      if (row.amount.value !== '') total += monthlyAmount(row);
    })
  );
  return <b tabIndex='0' className='subtotal-row'>{`$${total.toFixed(2).withCommas()}`}</b>;
};

const typeOptions = [
  {value: 'R', label: 'netMonthlyIncomeLog.labelR', title: 'Regular'},
  {value: 'U', label: 'netMonthlyIncomeLog.labelU', title: 'Unpredictable'},
  {value: 'S', label: 'netMonthlyIncomeLog.labelS', title: 'Seasonal'},
  {value: 'O', label: 'netMonthlyIncomeLog.labelO', title: 'One-time'},
];

const initialValues = {
  month: 'January',
  increasesSection: {
    Income: [emptyIncrementingItem('Net (take-home) pay')],
    'Self-Employment Income': [emptyIncrementingItem('Net self-employment income')],
    'Public Benefits': [emptyIncrementingItem('Public benefit')],
    Other: [
      emptyItem('Interest'),
      emptyItem('Dividends'),
      emptyItem('Child support'),
      emptyItem('Alimony'),
      emptyItem('Gifts'),
      emptyItem('', 'textWithLabel', 'Other Income:'),
    ],
    Total: [totalRow()],
  },
};

const NetMonthlyIncomeLog = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  return (
    <div>
      <Formik initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <EditableContent
              htmlType='div'
              initialContent={i18n.language === 'es' ? tool.contentsEs || tool.contents : tool.contents}
              fieldName={i18n.language === 'es' ? 'contents_es' : 'contents'}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            {Inputs.monthSelect(formikProps, t('My Net Income for the Month of:'))}
            {Tables.renderSimpleTable(
              [
                t('Source of Income'),
                t('Amount of Income'),
                t('How often do you receive this income?'),
                t('Monthly Amount'),
                t('Regular (R) Unpredictable (U) Seasonal (S) One-Time (O)'),
              ],
              initialValues.increasesSection,
              formikProps,
              'increasesSection',
              true,
              (value, label, increment) =>
                increment
                  ? emptyIncrementingItem(label, 'lightIncrementingLabel', increment, true)
                  : emptyItem('', 'textWithLabel', 'Other Income:', true),
              undefined,
              true,
              true
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NetMonthlyIncomeLog;
