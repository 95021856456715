import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {Redirect, useHistory} from 'react-router-dom';
import moment from 'moment';
import {useTranslation} from 'react-i18next';
import html2canvas from 'html2canvas';
import CertificatePDF from './CertificatePDF';

import Loader from '../../components/Loader/Loader';
import LangaugeSelector from '../../components/Buttons/LanguageSelector';

const na = 'n/a';

const printNotice =
  "You can print your certificate from this page or you can note your Player ID to share it with your linked organization to verify that you've earned a certificate.";

const filterSession = (sessions, params) => {
  const filteredSessions = sessions.filter(
    (session) =>
      session.game_id.toString() === params.gameId.toString() &&
      session.user_id.toString() === params.userId.toString(),
  );
  return filteredSessions.length > 0 ? filteredSessions[0] : filteredSessions;
};

const renderCertificate = (
  gameSession,
  gameName,
  t,
  isSpanish,
  isBlankCertificate,
  isMasterCertificate,
  playerIdText,
) => {
  const blankInput = <input className='certificate-input' type='text' />;
  let name;
  let date;
  if (isBlankCertificate) {
    name = blankInput;
    date = blankInput;
  } else {
    name = gameSession.full_name
      ? gameSession.full_name
      : `${gameSession.user_first_name} ${gameSession.user_last_name}`;
    date = moment.utc(gameSession.complete_date).local().format('LL');
    if (isSpanish) {
      const dateParts = date.replace(',', '').split(' ');
      if (dateParts.length === 3) {
        const spanishMonth = t(dateParts[0]);
        date = `${dateParts[1]} de ${spanishMonth} de ${dateParts[2]}`;
      }
    }
  }
  const certTopImageAltText = `${t('Money smart logo')}, ${t('Certificate of completion')}`;
  let certBottomImageAltText = `${t('Money smart logo')}, fdic.gov/moneysmart, `;
  certBottomImageAltText += t('Note: This certificate does not convey continuing education credits.');
  const topImage = `/images/certificate-top${isSpanish ? '-es' : ''}.png`;
  const bottomImage = `/images/certificate-bottom${isSpanish ? '-es' : ''}.png`;
  const completedStatement = isMasterCertificate
    ? t('Successfully Completing all 14 Financial Education Games')
    : `${t('Successfully Completing the Financial Education Game')}:`;

  return (
    <div id='game-certificate-container'>
      <div id='game-certificate' className='certificate' tabIndex='0'>
        <div className='grid-row'>
          <img src={topImage} alt={certTopImageAltText} />
        </div>
        <div className='grid-row text-center cert-text-row'>
          <div className='grid-col margin-0 certificate-content'>
            <h4>{t('Presented to')}</h4>
            <h1>{name}</h1>
            {!isBlankCertificate && (
              <div className='display-flex flex-row flex-justify-center'>
                <h5 className='margin-0'>{playerIdText}</h5>
              </div>
            )}
            {gameSession.organization_display_name && (
              <div>
                <h5 id='cert-org-display-name' className='margin-0'>
                  {t('Linked to')}: {gameSession.organization_display_name}
                </h5>
              </div>
            )}
            <div></div>
            <h4>{t('for')}</h4>
            <h3>{completedStatement}</h3>
            <h1>{gameName || blankInput}</h1>
            <h4>
              {t('on')} {date}
            </h4>
          </div>
        </div>
        <div className='grid-row certificate-border bottom-border bottom-image-row'>
          <img src={bottomImage} alt={certBottomImageAltText} />
        </div>
      </div>
    </div>
  );
};

const Certificate = observer((props) => {
  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(false);
  const history = useHistory();
  const {params} = props.match;
  const {authStore, gameSessionsStore} = props;
  const isBlankCertificate = !params.gameId || !params.userId;
  const isMasterCertificate = params.gameId === '15';
  const isCurrentUserCertificate = params.userId === authStore.userId.toString();

  const {t, i18n} = useTranslation();
  const isSpanish = i18n.language === 'es';

  const getGameNameFromParams = () => {
    if (params.gameId === '15') return t('Master Certificate');
    const game = props.gamesStore.games.find((g) => g.id.toString() === params.gameId);
    if (!game) return null;
    return isSpanish ? game.name_es : game.name;
  };
  const gameName = getGameNameFromParams();
  let gameSession = null;

  const handleCancel = () => {
    history.goBack();
  };

  if (isBlankCertificate) {
    gameSession = {complete_date: na};
  } else if (!isCurrentUserCertificate) {
    if (gameSessionsStore.certificate.hasCertificate === undefined) {
      gameSessionsStore.hasCertificate(params.userId, params.gameId);
      return <Loader />;
    } else {
      const {hasCertificate, org, completeDate, fullName} = gameSessionsStore.certificate;
      if (hasCertificate) {
        gameSession = {
          game_id: params.gameId,
          game_name: gameName,
          complete_date: completeDate,
          organization_display_name: org,
          full_name: fullName,
        };
      }
    }
  } else {
    gameSession = isMasterCertificate
      ? gameSessionsStore.getMasterCertificateUserSession()
      : filterSession(gameSessionsStore.sessions, params);
  }

  if (!gameSession || !gameSession.complete_date) return <Redirect to='/account' />;

  const dowloadCertificateAsPdf = () => {
    setDownloadButtonDisabled(true);
    const gcc = document.getElementById('game-certificate-container');
    const gc = document.getElementById('game-certificate');
    gc.style.boxShadow = 'none';
    gcc.style.border = '#eee solid 3px';
    html2canvas(gcc).then((canvas) => {
      gcc.style.border = 'none';
      gc.style.boxShadow = '0px 1px 9px 0px rgba(0, 0, 0, 0.31)';
      const dataURL = canvas.toDataURL();
      const name = gameName ? gameName.replace(/\W+/, '-').toLowerCase() : 'blank';
      const filename = `certificate-${params.userId}-${name}.pdf`;
      CertificatePDF(filename, dataURL);
      setTimeout(() => {
        setDownloadButtonDisabled(false);
      }, 2000);
    });
  };

  const cancelText = t('Cancel');
  const playerIdText = `${t('Player ID(certificate)')}: ${isBlankCertificate ? '' : params.userId}`;

  return (
    <div id='certificate-wrapper' className='certificate-wrapper'>
      <div className='margin-bottom-3'>
        <LangaugeSelector {...props} />
      </div>
      {!authStore.isAdmin && !authStore.isOrganization && (
        <>
          <div className='grid-row padding-right-9 margin-bottom-2 hide-print'>
            <i>{t(printNotice)}</i>
          </div>
          <div className='grid-row padding-right-9 margin-bottom-2 hide-print'>
            <b>{playerIdText}</b>
          </div>
        </>
      )}
      <button onClick={() => window.print()} className='usa-button margin-bottom-205 hide-print'>
        {t('Print Certificate')}
      </button>
      <button
        onClick={dowloadCertificateAsPdf}
        className='usa-button margin-bottom-205 hide-print'
        disabled={downloadButtonDisabled}
      >
        {t('Download Certificate as PDF')}
      </button>
      <button type='button' className='hide-print usa-button usa-button--unstyled' onClick={handleCancel}>
        {cancelText}
      </button>
      {renderCertificate(gameSession, gameName, t, isSpanish, isBlankCertificate, isMasterCertificate, playerIdText)}
    </div>
  );
});

export default Certificate;
export {filterSession};
