import {observable, action, decorate} from "mobx";
import Fuse from 'fuse.js';
import RequestService from '../services/RequestService';
import config from '../config';

const fuseOptions = {
  matchAllTokens: true,
  tokenize: true,
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 10,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: [
    "name",
    "details",
  ]
};

class GlossaryItemsStore {
  constructor() {
    this.buildFromSession();
    this.fuseGlossaryItems = new Fuse(this.glossaryItems, fuseOptions);
  }

  glossaryItemsLoading = true;
  glossaryItems = [];
  fuseGlossaryItems = new Fuse([], fuseOptions);

  addGlossaryItem = (name, details) => (
    RequestService.post(`${config.apiGateway.URL}/glossary-items`, {glossary_item: {name: name, details: details}})
  );

  deleteGlossaryItem = (id) => (
    RequestService.delete(`${config.apiGateway.URL}/glossary-items/${id}`, () => {}, null, true)
  );

  getGlossaryItems = () => {
    this.glossaryItems.length === 0 && RequestService.get(`${config.apiGateway.URL}/glossary-items`, (resp) => {
      this.glossaryItems = resp.data;
      this.fuseGlossaryItems = new Fuse(resp.data, fuseOptions);
      this.glossaryItemsLoading = false;
      this.writeToSession();
    });
  };

  resetGlossaryItemsStore = () => {
    this.glossaryItems = [];
    this.glossaryItemsLoading = true;
    this.fuseGlossaryItems = new Fuse([], fuseOptions);
  };

  filterGlossaryItems = (searchField, isSpanish) => {
    if (isSpanish) {
      if (!!searchField) {
        const fuseOptionsEs = {...fuseOptions, keys: ['name_es', 'details_es']};
        const fuseGlossaryItemsEs = new Fuse(this.fuseGlossaryItems.list, fuseOptionsEs);
        this.glossaryItems = fuseGlossaryItemsEs.search(searchField);
      } else {
        this.glossaryItems = this.fuseGlossaryItems.list;
      }
    } else {
      searchField ? 
        this.glossaryItems = this.fuseGlossaryItems.search(searchField) : 
        this.glossaryItems = this.fuseGlossaryItems.list;
    }
  };

  itemBeginsWith = (item, letter, isSpanish) => {
    if (!!isSpanish) {
      return item.name_es[0] === letter || (!isNaN(item.name_es[0]) && letter === '#');
    } else {
      return item.name[0] === letter || (!isNaN(item.name[0]) && letter === '#'); 
    }
  };

  searchFirstLetter = (letter, isSpanish) => {
    return this.fuseGlossaryItems.list.filter(item => this.itemBeginsWith(item, letter, isSpanish));
  };

  clearSession = () => {
    sessionStorage.removeItem('GlossaryItemsStore');
  };

  writeToSession = () => {
    let json = JSON.stringify(this);
    sessionStorage.setItem('GlossaryItemsStore', json);
  };

  buildFromSession = () => {
    let json = JSON.parse(sessionStorage.getItem('GlossaryItemsStore'));
    if(json) {
      this.glossaryItems = json.glossaryItems;
      this.glossaryItemsLoading = json.glossaryItemsLoading;
    }
  };
}

decorate(GlossaryItemsStore, {
  glossaryItems: observable,
  fuseGlossaryItems: observable,
  glossaryItemsLoading: observable,
  getGlossaryItems: action,
  filterGlossaryItems: action,
  resetGlossaryItemsStore: action
});

export default GlossaryItemsStore;