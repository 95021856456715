import React, {Component} from 'react';
import {observer} from 'mobx-react';
import {withTranslation, Trans} from 'react-i18next';
import {Link} from 'react-router-dom';

import GameCategory from '../../components/GameCategory/GameCategory';
import config from '../../config';
import LanguageSelector from '../../components/Buttons/LanguageSelector';

class Games extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
    };
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  addCloseEventListener = () => {
    window.addEventListener('mousedown', this.props.handleMouseDown || this.handleMouseDown);
  };

  handleMouseDown = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.hideMenu();
    }
  };

  toggleMenu = (e) => {
    e.preventDefault();
    this.setState({showMenu: !this.state.showMenu});
  };

  hideMenu = (e) => {
    this.setState({showMenu: false});
  };

  componentDidMount() {
    this.props.gamesStore.getGames();
    this.addCloseEventListener();
    config.setUniqueTitle('Games');
  }

  getGroupedGames = () => {
    let groupedGames = [];
    let games = this.props.gamesStore.games;
    for (let i = 0, j = games.length; i < j; i += 5) {
      let gameArray = games.slice(i, i + 5);
      groupedGames.push(gameArray);
    }
    return groupedGames;
  };

  renderGameDropdown = () => {
    const {i18n} = this.props;
    const isSpanish = i18n.language === 'es';
    return this.getGroupedGames().map((gameGroup, index) => (
      <ul key={index} className={`ls-col-${index}`}>
        {gameGroup.map((game) => {
          const urlGameName = game.name
            .toLowerCase()
            .replace(/\u2014/g, '')
            .replace(/ /g, '-')
            .replace(/-{2,}/g, '-');
          const gameName = isSpanish ? game.name_es : game.name;
          return (
            <li key={game.id}>
              <Link
                className='usa-link game-link'
                to={`/game/${urlGameName}`}
                tabIndex={this.state.showMenu ? '0' : '-1'}
              >
                <i className='material-icons'>keyboard_arrow_right</i> {gameName}
              </Link>
            </li>
          );
        })}
      </ul>
    ));
  };

  render() {
    const {showMenu} = this.state;
    const {gamesStore, t, i18n} = this.props;
    const isSpanish = i18n.language === 'es';
    const linkUrl = isSpanish
      ? 'https://www.fdic.gov/consumers/consumer/moneysmartsp/index.html'
      : 'https://www.fdic.gov/resources/consumers/money-smart/index.html';
    const maxWidth = isSpanish ? '636px' : '548px';
    return (
      <div className='games-wrapper'>
        <div className='game-page games-banner'>
          <div className='grid-col-12 games-banner-container'>
            <h1 className='margin-right-10'>
              <Trans i18nKey='title'>How Money Smart Are You?</Trans>
            </h1>
            <div className='margin-bottom-1 text-center' style={{maxWidth}}>
              <LanguageSelector {...this.props} />
            </div>
            <span className='color-alternate-tan'>
              <Trans i18nKey='games.subHeader'>Learn about everyday financial topics.</Trans>
            </span>
            <div className='games-banner-buttons' ref={this.setWrapperRef}>
              <a
                title={`${t('games.chooseGameButton')} ${showMenu ? t('expanded') : t('collapsed')}`}
                tabIndex={0}
                href='/'
                onClick={this.toggleMenu}
                className='show-games-button'
              >
                <span className='display-inline-block position-relative'>
                  <Trans i18nKey='games.chooseGameButton'>Choose a Game</Trans>
                  <i className='material-icons' style={{right: '-24px'}}>
                    keyboard_arrow_down
                  </i>
                </span>
              </a>

              <ul className={showMenu ? 'games-list animeOpen' : 'games-list animeHide'} id='ls-elements'>
                {this.renderGameDropdown()}
              </ul>

              <Link className='resources-button' to='/resources'>
                <span>
                  <Trans i18nKey='games.jumpToResourcesButton'>Jump to Resources</Trans>
                </span>
              </Link>
            </div>
          </div>
        </div>
        <h2 className='games-title color-alternate-tan'>
          <Trans i18nKey='games.selectAGameButton'>Select one of the 14 games below.</Trans>
        </h2>
        <div className='game-category grid-row'>
          {!gamesStore.gamesLoading &&
            gamesStore.gameCategories.map((cat, index) => (
              <GameCategory key={index} categoryName={t(cat.name)} games={cat.games} />
            ))}
          <div className='grid-row grid-col-12 desktop:grid-col-6 text-center padding-4'>
            <div className='grid-col-12'>
              <h2 className='margin-top-0 desktop:margin-top-4 desktop:margin-bottom-0'>
                <Trans i18nKey='games.exploreOtherSites'>Visit our Money Smart webpage!</Trans>
              </h2>
            </div>
            <div className='grid-col-12 desktop:margin'>
              <a
                href={linkUrl}
                title={t('games.financialEdHomepage')}
                className='text-decoration-none usa-button fdic-button'
              >
                <span>
                  <Trans i18nKey='games.financialEdHomepage'>Money Smart Webpage</Trans>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(observer(Games));
