import React from "react";
import {Formik, Form} from "formik";
import Tables from "../utilities/Tables";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation} from "react-i18next";

const emptyItem = (allowRemoval = false) => ({
  item: {
    value: "My Goals",
    inputType: "textarea",
    columnWidth: 4,
    rows: 5,
    allowRemoval: allowRemoval,
  },
  smart: {value: "", inputType: "checkboxes", options: goalOptions},
});

const goalOptions = [
  "Specific?",
  "Measurable?",
  "Action-oriented?",
  "Reachable?",
  "Time-bound?",
];

const initialValues = {
  goalsSection: {
    "My SMART Goals": [emptyItem()],
  },
};

const MySmartGoals = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  const isSpanish = i18n.language === 'es';
  const tableh1 = t('To ensure your goals are...');
  const tableh2 = t('Ask yourself...');
  return (
    <div>
      <Formik initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <EditableContent
              htmlType="div"
              initialContent={
                isSpanish
                  ? tool.contentsEs || tool.contents
                  : tool.contents
              }
              fieldName={isSpanish ? "contents_es" : "contents"}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            <table className="usa-table" title={`${t('Table')}: ${tableh1}, ${tableh2}`}>
              <thead>
                <tr>
                  <th scope="col">{tableh1}</th>
                  <th scope="col">{tableh2}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <strong>{t('Specific')}</strong>
                  </th>
                  <td>
                    {t('What exactly do I want to accomplish? Why is this important to me? Is this something I really want?')}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <strong>{t('Measurable')}</strong>
                  </th>
                  <td>
                    {t('How much? How many? How will I know when I’ve met my goal?')}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <strong>{t('Action-oriented')}</strong>
                  </th>
                  <td>
                    {t('What specific actions do I need to complete to meet this goal?')}
                  </td>
                </tr>
                <tr>
                  <th scope="row">
                    <strong>{t('Reachable')}</strong>
                  </th>
                  <td>{t('Is this goal something I can actually reach?')}</td>
                </tr>
                <tr>
                  <th scope="row">
                    <strong>{t('Time-bound')}</strong>
                  </th>
                  <td>{t('When will I reach this goal?')}</td>
                </tr>
              </tbody>
            </table>
            <p>
              {t('You can use this table to set SMART goals that involve money. Write your goal and then use the checklist to make sure it is SMART!')}
            </p>
            <p>
              {t('Select the plus sign to add more goals.')}
            </p>
            {Tables.renderSimpleTable(
              [
                t('My Goals'), 
                t('Is My Goal...')
              ],
              initialValues.goalsSection,
              formikProps,
              "goalsSection",
              true,
              () => emptyItem(true),
              undefined,
              false,
              true
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MySmartGoals;
