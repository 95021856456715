import React from 'react';
import {Formik, Form} from 'formik';
import Tables from '../utilities/Tables';
import Inputs, {ExpandableTextarea} from '../utilities/Inputs';
import EditableContent from '../../../components/EditableContent/EditableContent';
import config from '../../../config';
import {useTranslation, Translation} from 'react-i18next';

const ynidkOptions = ['Yes', 'No', 'I don’t know'];
const emptyItem = (item = '', inputType = 'label', label = '', columnName = null) => ({
  item: {value: item, label: label, inputType: inputType, columnWidth: 4, columnName},
  choice: {
    value: '',
    inputType: 'radioButtonGroup',
    columnWidth: 3,
    options: ynidkOptions,
  },
});

const totalRow = () => ({
  item: {
    value: (
      <Translation>
        {(t) => (
          <span className='font-size-16'>
            {t('Total number of “Yes,” “No,” and “I don’t know” answers')}
          </span>
        )}
      </Translation>
    ),
    inputType: 'labelHTML',
    columnWidth: 2,
  },
  choice: {
    inputType: 'calculated',
    calculation: (formikProps) => total(formikProps.values),
    columnWidth: 1,
  },
});

const total = (values, key) => {
  return (
    <Translation>
      {(t) => {
        let total = {yes: 0, no: 0, unsure: 0};
        total.yes = values.questionsSections['Ready to Buy Home'].filter(
          (item) => item.choice.value === ynidkOptions[0]
        ).length;
        total.no = values.questionsSections['Ready to Buy Home'].filter(
          (item) => item.choice.value === ynidkOptions[1]
        ).length;
        total.unsure = values.questionsSections['Ready to Buy Home'].filter(
          (item) => item.choice.value === ynidkOptions[2]
        ).length;
        return (
          <div className='font-size-16' tabIndex='0'>
            <span className='margin-right-2'>
              <b>
                {t('Yes:')} {total.yes}
              </b>{' '}
            </span>
            <span className='margin-right-2'>
              <b>No: {total.no}</b>{' '}
            </span>
            <span>
              <b>
                {t('I don’t know:')} {total.unsure}
              </b>{' '}
            </span>
          </div>
        );
      }}
    </Translation>
  );
};

const initialValues = {
  readyToBuy: 'I don’t know',
  questionsSections: {
    'Ready to Buy Home': [
      emptyItem(
        '',
        'textareaWithQuestionLabelTrans',
        <Translation>
          {(t) => (
            <span>
              <b>{t('Do I know why I want to buy a home?')}</b>
              <br />
              {t('You can write your reasons below.')}
            </span>
          )}
        </Translation>,
        'Do I know why I want to buy a home?'
      ),
      emptyItem('Do I have reliable sources of income?'),
      emptyItem(
        'Have I received this income on a regular basis for at least the last two or three years?'
      ),
      emptyItem('Do I have a credit history?'),
      emptyItem('Have I reviewed my credit reports recently?'),
      emptyItem('Is the information in my credit reports accurate?'),
      emptyItem(
        'If there is inaccurate information in my credit reports, have I filed a dispute with the credit reporting agency or agencies?'
      ),
      emptyItem('Do I have a good record of paying my bills on time?'),
      emptyItem(
        'If I currently pay rent, have I been paying it regularly and on time?'
      ),
      emptyItem(
        'If applicable, have I been paying utilities regularly and on time?'
      ),
      emptyItem(
        'Could I comfortably afford to make a monthly mortgage payment that would likely be at least as much as a rent payment?'
      ),
      emptyItem(
        <Translation>
          {(t) => (
            <span>
              <b>
                {t(
                  'Will I be able to make my mortgage payment every month in addition to the other costs of owning a home?'
                )}
              </b>
              <br />
              {t(
                'Note: That includes possibly higher utility payments, taxes, insurance, routine maintenance, and repairs.'
              )}
            </span>
          )}
        </Translation>,
        'labelNoBoldHTML'
      ),
      emptyItem(
        'Am I likely to stay in a home I buy for at least the next few years?'
      ),
      emptyItem(
        <Translation>
          {(t) => (
            <span>
              <b>{t('Do I have enough money saved for a down payment?')}</b>
              <br />
              {t(
                'Note: The amount of money you need for a down payment depends on each loan program.'
              )}
            </span>
          )}
        </Translation>,
        'labelNoBoldHTML'
      ),
      emptyItem('Do I have enough money saved for closing costs?'),
      emptyItem(
        'Have I looked into homebuyer assistance programs in my area that may help me afford a down payment and closing costs?'
      ),
      emptyItem(
        'Do I have money saved for emergencies, unexpected expenses, home maintenance, and home repairs?'
      ),
      emptyItem(
        <Translation>
          {(t) => (
            <span>
              <b>
                {t('Have I looked into ')}
                <a href='https://www.hud.gov/i_want_to/talk_to_a_housing_counselor'>
                  {t('homeowner classes and counseling')}
                </a>
                {t(' (hud.gov) from a HUD-approved housing counseling agency?')}
              </b>
            </span>
          )}
        </Translation>,
        'labelNoBoldHTML'
      ),
      totalRow(),
    ],
  },
};

const DecideIfReadyToBuyHome = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  return (
    <div>
      <Formik initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <EditableContent
              htmlType='div'
              initialContent={
                i18n.language === 'es'
                  ? tool.contentsEs || tool.contents
                  : tool.contents
              }
              fieldName={i18n.language === 'es' ? 'contents_es' : 'contents'}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            <div className='grid-col-12'>
              {Tables.renderSimpleTable(
                [t('Question'), t('Answer')],
                initialValues.questionsSections,
                formikProps,
                'questionsSections',
                true,
                emptyItem,
                undefined,
                false,
                true
              )}
            </div>

            <p className='page-break-before-always'>
              {t('Now, review the totals for your answers.')}
            </p>
            <p>
              {t(
                'The more “Yes” answers you have, the more ready you may be to buy a home. If you have “No” or “I don’t know” answers, think about what you can do to turn those into “Yes” answers. Or maybe turn them into goals if they are going to take some time to address.'
              )}
            </p>
            <div className='tool-text-area'>
              <div className='label-row margin-bottom-2'>
                {t('Based on your answers, are you ready to buy?')}
              </div>
              {Inputs.radioButtonGroup({
                id: 'readyToBuy',
                value: formikProps.values.readyToBuy,
                error: formikProps.errors.readyToBuy,
                touched: formikProps.touched.readyToBuy,
                options: ['Yes', 'No', 'Maybe'],
              })}
            </div>
            <p></p>
            <ExpandableTextarea
              name='workOn'
              question={t(
                'If you answered No or Maybe, what are some things you can work on to get ready?'
              )}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default DecideIfReadyToBuyHome;
