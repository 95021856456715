import React from "react";
import {Trans, useTranslation} from "react-i18next";

const PlayGameBanner = (props) => {
  const {t} = useTranslation();
  return (
    <div className="grid-row play-game-banner hide-print margin-bottom-2">
      <div className="grid-col-2 hide-desktop-lg"></div>
      <div className="grid-col-9 desktop-lg:grid-col-7 mobile-full-width">
        <div className="grid-row">
          <h3 className="no-margin">
            <Trans i18nKey="playGame.banner1">To find out more, play the</Trans>
          </h3>
        </div>
        <div className="grid-row margin-bottom-105">
          <h3 className="no-margin">
            <Trans i18nKey="playGame.banner2">
              <i>How Money Smart Are You? </i>
              game:
            </Trans>
          </h3>
        </div>
        <div className="grid-row">
          <p className="no-margin">
            <a href={`/game/${props.tool.gameId}`} className="alternate-link" title={t(props.tool.gameName)}>
              {t(props.tool.gameName)}
            </a>
          </p>
        </div>
      </div>
      <div className="grid-col-3 padding-top-4 padding-left-6 mobile-full-width">
        <a
          href={`/game/${props.tool.gameId}?openGame=true`}
          className="fdic-button tan-button tall-button"
          title={t('playGame.playButton')}
        >
          <Trans i18nKey="playGame.playButton">Play game</Trans>
        </a>
      </div>
    </div>
  );
};

export default PlayGameBanner;
