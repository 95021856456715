import React from "react";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import StrategyCard from "../utilities/StrategyCard";
import {useTranslation} from "react-i18next";

const headerIndices = [0, 3, 6];

const UseSpendingSavingPlan = ({strategy, canEdit}) => {
  const {i18n} = useTranslation();
  return (
    <div className="strategy">
      {strategy.toolContents.map((toolContent, index) => {
        if (headerIndices.includes(index)) {
          return (
            <EditableContent
              key={toolContent.id}
              htmlType="h3"
              fieldName={i18n.language === "es" ? "details_es" : "details"}
              initialContent={
                i18n.language === "es"
                  ? toolContent.detailsEs || toolContent.details
                  : toolContent.details
              }
              updateEndpoint={`${config.apiGateway.URL}/tool-contents/${strategy.id}/${toolContent.id}`}
              canEdit={canEdit}
            />
          );
        } else {
          return (
            <StrategyCard key={toolContent.id}>
              <EditableContent
                htmlType="div"
                fieldName={i18n.language === "es" ? "details_es" : "details"}
                initialContent={
                  i18n.language === "es"
                    ? toolContent.detailsEs || toolContent.details
                    : toolContent.details
                }
                updateEndpoint={`${config.apiGateway.URL}/tool-contents/${strategy.id}/${toolContent.id}`}
                canEdit={canEdit}
                richText
              />
            </StrategyCard>
          );
        }
      })}
    </div>
  );
};

export default UseSpendingSavingPlan;
