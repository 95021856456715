import React from 'react';
import {useTranslation} from 'react-i18next';

import GlossaryPDF from './GlossaryPDF';

const GlossaryToPdfButton = ({glossaryItems, topMargin = 0}) => {
  const {t, i18n} = useTranslation();
  const mt = `margin-top-${topMargin}`;
  const buttonText = t('Download Glossary');
  return (
    <button
      className={`usa-button ${mt} margin-bottom-2`}
      onClick={() => GlossaryPDF(i18n.language, t, glossaryItems)}
      title={buttonText}
    >
      {buttonText}
    </button>
  );
};

export default GlossaryToPdfButton;
