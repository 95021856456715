import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';

const EyeIconButton = ({onClickToggle, show, style}) => {
  const {t} = useTranslation();
  const styleToUse = style
    ? style
    : {
      position: 'absolute',
      top: 0,
      left: '230px', // login form input is hard set to 260px width
      transform: 'translateY(0.55rem)',
      cursor: 'pointer',
      background: 'transparent',
    };
  return (
    <button onClick={onClickToggle} type="button" style={styleToUse}>
      <i
        aria-label={t('toggle button to display or mask password text for input field')}
      >
        {show ? (
          <FontAwesomeIcon icon={faEyeSlash} />
        ) : (
          <FontAwesomeIcon icon={faEye} />
        )}
      </i>
    </button>
  );
};

export default EyeIconButton;
