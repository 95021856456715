import React from "react";
import {Formik, Form} from "formik";
import Tables from "../utilities/Tables";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation} from "react-i18next";

const emptyItem = (
  item = "",
  inputType = "questionlabel",
  label = "",
  allowRemoval = false
) => ({
  item: {
    value: item,
    inputType: inputType,
    label: label,
    allowRemoval: allowRemoval,
  },
  details: {value: "", inputType: "textarea", columnWidth: 6},
});

const initialValues = {
  itemsSection: {
    Items: [
      emptyItem("Rent"),
      emptyItem("Childcare, eldercare, spousal, child support payments"),
      emptyItem("Cell phone bill"),
      emptyItem("Electric bill"),
      emptyItem("Gas bill"),
      emptyItem("Water, sewage, garbage bills"),
      emptyItem("Cable, satellite, TV viewing, internet service bill"),
      emptyItem("Insurance payments"),
      emptyItem("Loan from friend or family member"),
      emptyItem("Savings"),
      emptyItem("", "textWithLabel", "Other:"),
    ],
  },
};

const CreateAlternativeCreditHistory = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  return (
    <div>
      <Formik initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <EditableContent
              htmlType="div"
              initialContent={
                i18n.language === "es"
                  ? tool.contentsEs || tool.contents
                  : tool.contents
              }
              fieldName={i18n.language === "es" ? "contents_es" : "contents"}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            {Tables.renderSimpleTable(
              [
                t('Payment or Item'),
                t('Details (How frequently do you make the payment or deposit? How long have you done so? What documentation can you provide? How much are the payments?)'),
              ],
              initialValues.itemsSection,
              formikProps,
              "itemsSection",
              true,
              () => emptyItem("", "textWithLabel", "Other:", true),
              undefined,
              false,
              true,
              "",
              "Table"
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateAlternativeCreditHistory;
