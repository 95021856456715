import React, {useState} from 'react';
import {Formik, Field} from 'formik';
import InputError from '../../../components/Formik/InputError';
import {hasAlert} from '../../../components/Formik/FormInput';
import * as Yup from 'yup';
import Filter from 'bad-words';
import RequestService from '../../../services/RequestService';
import config from '../../../config';

const filter = new Filter();

const addAdditionalUserSchema = Yup.object().shape({
  email: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .email('Must enter a valid email address.')
    .min(2, 'Email must be at least two characters.')
    .max(50, 'Email must be at most 50 characters.')
    .required('Email is required.'),
  emailConfirmation: Yup.string()
    .oneOf([Yup.ref('email'), null], 'Emails must match.')
    .required('You must confirm your email.'),
  userId: Yup.string().required('Player ID is required.')
});

const editUserSchema = Yup.object().shape({
  email: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .email('Must enter a valid email address.')
    .min(2, 'Email must be at least two characters.')
    .max(50, 'Email must be at most 50 characters.')
    .required('Email is required.'),
  firstName: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .min(2, 'First name must be at least two characters.')
    .max(50, 'First name must be at most 50 characters.')
    .required('First name is required.'),
  lastName: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .min(2, 'Last name must be at least two characters.')
    .max(50, 'Last name must be at most 50 characters.')
    .required('Last name is required.')
});

const initialValues = {email: '', emailConfirmation: '', userId: ''};

const AddAdditionalUserForm = ({onSubmit, onCancel, editingUser}) => {
  const [userError, setUserError] = useState(null);

  const checkForExistingUser = (email, userId, onSuccess) => {
    RequestService.post(`${config.apiGateway.URL}/organizations/check-user-can-upgrade`, {email: email, userId: userId}, (response) => {
      setUserError(null);
      onSuccess();
    }, (error) => {
      setUserError(error.response.data);
    });
  };

  const handleSubmit = (values) => {
    checkForExistingUser(values.email, values.userId, () => onSubmit(values));
  };

  return(
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={editingUser ? editUserSchema : addAdditionalUserSchema}
    >
      {formikProps =>
        <div className="registration-wrapper">
          <form className="reg-form">
            <fieldset>
              <div className="grid-row">
                <h4>{editingUser ? "Edit" : "Add"} Additional Staff</h4>
              </div>
              <div className="grid-row margin-bottom-2">
                <div className="grid-col-6 padding-right-2">
                  <label htmlFor="email">Email: *</label>
                  <Field id="email" name="email" type="email" className={`${hasAlert(formikProps, 'email') && 'alert'}`} required/>
                  <InputError formikProps={formikProps} fieldName="email" />
                </div>
                {!editingUser && <div className="grid-col-6 padding-right-2">
                  <label htmlFor="emailConfirmation">Re-enter email: *</label>
                  <Field id="emailConfirmation" name="emailConfirmation" type="email" className={`${hasAlert(formikProps, 'emailConfirmation') && 'alert'}`} required/>
                  <InputError formikProps={formikProps} fieldName="emailConfirmation" />
                </div>}
              </div>
              {!editingUser && <div className="grid-row margin-bottom-2">
                <div className="grid-col-6 padding-right-2">
                  <label htmlFor="userId">Player ID: *</label>
                  <Field id="userId" name="userId" type="userId" className={`${hasAlert(formikProps, 'userId') && 'alert'}`} required/>
                  <InputError formikProps={formikProps} fieldName="userId" />
                </div>
              </div>}
              <button type="submit" className="usa-button" onClick={formikProps.handleSubmit}>{editingUser ? "Save" : "Add This Staff Member"}</button>
              <button type="button" className="usa-button usa-button--unstyled" onClick={onCancel}>Cancel</button>
              {userError && <div tabIndex={0} className="usa-alert usa-alert--error margin-top-3">
                <div className="usa-alert__body">
                  <p className="usa-alert__text">
                    {userError}
                  </p>
                </div>
              </div>}
            </fieldset>
          </form>
        </div>
      }
    </Formik>
  );
};

export default AddAdditionalUserForm;