import React, {Component} from 'react';
import Loader from '../../components/Loader/Loader';

import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import RequestService from '../../services/RequestService';
import config from '../../config';
import SpendSaveSharePlan from './pages/SpendSaveSharePlan';
import SpendingSavingPlan from './pages/SpendingSavingPlan';
import TrackMonthlyExpenses from './pages/TrackMonthlyExpenses';
import IdentifyMyValues from './pages/IdentifyMyValues';
import MySmartGoals from './pages/MySmartGoals';
import IdentifyFinancialProducts from './pages/IdentifyFinancialProducts';
import LearnPartsOfCheck from './pages/LearnPartsOfCheck';
import MyBankingChecklist from './pages/MyBankingChecklist';
import ReadPayStatement from './pages/ReadPayStatement';
import NetMonthlyIncomeLog from './pages/NetMonthlyIncomeLog';
import PrioritizeExpenses from './pages/PrioritizeExpenses';
import EstimateChangesInMyIncome from './pages/EstimateChangesInMyIncome';
import SaveMoneyForGoals from './pages/SaveMoneyForGoals';
import CreateAlternativeCreditHistory from './pages/CreateAlternativeCreditHistory';
import ReviewMyCreditReports from './pages/ReviewMyCreditReports';
import GetReadyToBorrowMoney from './pages/GetReadyToBorrowMoney';
import UnderstandingMyDebt from './pages/UnderstandingMyDebt';
import ReadCreditCardStatement from './pages/ReadCreditCardStatement';
import CompareCreditCards from './pages/CompareCreditCards';
import PlanToBuildMoreAssets from './pages/PlanToBuildMoreAssets';
import CalculateNetWorth from './pages/CalculateNetWorth';
import NeedAndWantInHome from './pages/NeedAndWantInHome';
import EstimateAffordableHousingPayment from './pages/EstimateAffordableHousingPayment';
import ReviewLease from './pages/ReviewLease';
import DecideIfReadyToBuyHome from './pages/DecideIfReadyToBuyHome';
import CompareLoanEstimates from './pages/CompareLoanEstimates';
import ChoosingHomeBuyingTeam from './pages/ChoosingHomeBuyingTeam';
import GetHelpPayingMortgage from './pages/GetHelpPayingMortgage';
import ReviewInsuranceCoverage from './pages/ReviewInsuranceCoverage';
import EmergencySavingsFundPlan from './pages/EmergencySavingsFundPlan';

import StayFocusedOnGoals from './strategies/StayFocusedOnGoals';
import ManageSavingsAccount from './strategies/ManageSavingsAccount';
import ManageCheckingAccount from './strategies/ManageCheckingAccount';
import PayBills from './strategies/PayBills';
import UseSpendingSavingPlan from './strategies/UseSpendingSavingPlan';
import FindMoneyToSave from './strategies/FindMoneyToSave';
import BuildCredit from './strategies/BuildCredit';
import MaintainProductiveCreditHistory from './strategies/MaintainProductiveCreditHistory';
import ProtectCreditHistory from './strategies/ProtectCreditHistory';
import RepairImproveCredit from './strategies/RepairImproveCredit';
import ConsiderSomeoneElseHelpManageMoney from './strategies/ConsiderSomeoneElseHelpManageMoney';
import IdentifyAlternativesToDebt from './strategies/IdentifyAlternativesToDebt';
import GetHelpWithDebt from './strategies/GetHelpWithDebt';
import ManageCreditCardEffectively from './strategies/ManageCreditCardEffectively';
import IncreaseNetWorth from './strategies/IncreaseNetWorth';
import ShopWiselyForInsurance from './strategies/ShopWiselyForInsurance';
import ManageInsuranceCoverage from './strategies/ManageInsuranceCoverage';
import DealWithIdentityTheft from './strategies/DealWithIdentityTheft';
import EstimateWhatCanAffordWithHousing from './strategies/EstimateWhatCanAffordWithHousing';
import BeginRenting from './strategies/BeginRenting';
import BuyingAHome from './strategies/BuyingAHome';
import AvoidScamsFollowingDisaster from './strategies/AvoidScamsFollowingDisaster';
import HelpRecoverAfterDisaster from './strategies/HelpRecoverAfterDisaster';
import PrepareForDisaster from './strategies/PrepareForDisaster';
import FileADispute from './strategies/FileADispute';
import DealWithMyDebts from './strategies/DealWithMyDebts';
import TakeActionOnMedicalDebt from './strategies/TakeActionOnMedicalDebt';
import CompareCostsAndBenefitsOfTraining from './strategies/CompareCostsAndBenefitsOfTraining';
import ReduceRiskOfIdentityTheft from './strategies/ReduceRiskOfIdentityTheft';
import HowToRequestBankingHistory from './strategies/HowToRequestBankingHistory';
import ToolIndex from './pages/ToolIndex';
import './utilities/Numbers';
import PlayGameBanner from './PlayGameBanner';
import PrintButton from '../../components/Buttons/PrintButton';
import {Translation, Trans} from 'react-i18next';
import LanguageSelector from '../../components/Buttons/LanguageSelector';

const toolMapper = (tool, canEdit) => ({
  1: <IdentifyMyValues tool={tool} canEdit={canEdit} />,
  2: <MySmartGoals tool={tool} canEdit={canEdit} />,
  3: <IdentifyFinancialProducts tool={tool} canEdit={canEdit} />,
  4: <LearnPartsOfCheck tool={tool} canEdit={canEdit} />,
  5: <MyBankingChecklist tool={tool} canEdit={canEdit} />,
  6: <ReadPayStatement tool={tool} canEdit={canEdit} />,
  7: <NetMonthlyIncomeLog tool={tool} canEdit={canEdit} />,
  8: <SpendSaveSharePlan tool={tool} canEdit={canEdit} />,
  9: <TrackMonthlyExpenses tool={tool} canEdit={canEdit} />,
  10: <PrioritizeExpenses tool={tool} canEdit={canEdit} />,
  11: <SpendingSavingPlan tool={tool} canEdit={canEdit} />,
  12: <EmergencySavingsFundPlan tool={tool} canEdit={canEdit} />,
  13: <EstimateChangesInMyIncome tool={tool} canEdit={canEdit} />,
  14: <SaveMoneyForGoals tool={tool} canEdit={canEdit} />,
  15: <CreateAlternativeCreditHistory tool={tool} canEdit={canEdit} />,
  16: <ReviewMyCreditReports tool={tool} canEdit={canEdit} />,
  17: <GetReadyToBorrowMoney tool={tool} canEdit={canEdit} />,
  18: <UnderstandingMyDebt tool={tool} canEdit={canEdit} />,
  19: <ReadCreditCardStatement tool={tool} canEdit={canEdit} />,
  20: <CompareCreditCards tool={tool} canEdit={canEdit} />,
  21: <PlanToBuildMoreAssets tool={tool} canEdit={canEdit} />,
  22: <CalculateNetWorth tool={tool} canEdit={canEdit} />,
  23: <NeedAndWantInHome tool={tool} canEdit={canEdit} />,
  24: <EstimateAffordableHousingPayment tool={tool} canEdit={canEdit} />,
  25: <ReviewLease tool={tool} canEdit={canEdit} />,
  26: <DecideIfReadyToBuyHome tool={tool} canEdit={canEdit} />,
  27: <CompareLoanEstimates tool={tool} canEdit={canEdit} />,
  28: <ChoosingHomeBuyingTeam tool={tool} canEdit={canEdit} />,
  29: <GetHelpPayingMortgage tool={tool} canEdit={canEdit} />,
  30: <ReviewInsuranceCoverage tool={tool} canEdit={canEdit} />,
  101: <StayFocusedOnGoals strategy={tool} canEdit={canEdit} />,
  102: <ManageSavingsAccount strategy={tool} canEdit={canEdit} />,
  103: <ManageCheckingAccount strategy={tool} canEdit={canEdit} />,
  104: <PayBills strategy={tool} canEdit={canEdit} />,
  105: <UseSpendingSavingPlan strategy={tool} canEdit={canEdit} />,
  106: <FindMoneyToSave strategy={tool} canEdit={canEdit} />,
  107: <BuildCredit strategy={tool} canEdit={canEdit} />,
  108: <MaintainProductiveCreditHistory strategy={tool} canEdit={canEdit} />,
  109: <ProtectCreditHistory strategy={tool} canEdit={canEdit} />,
  110: <RepairImproveCredit strategy={tool} canEdit={canEdit} />,
  111: <ConsiderSomeoneElseHelpManageMoney strategy={tool} canEdit={canEdit} />,
  112: <IdentifyAlternativesToDebt strategy={tool} canEdit={canEdit} />,
  113: <GetHelpWithDebt strategy={tool} canEdit={canEdit} />,
  114: <ManageCreditCardEffectively strategy={tool} canEdit={canEdit} />,
  115: <IncreaseNetWorth strategy={tool} canEdit={canEdit} />,
  116: <ShopWiselyForInsurance strategy={tool} canEdit={canEdit} />,
  117: <ManageInsuranceCoverage strategy={tool} canEdit={canEdit} />,
  118: <DealWithIdentityTheft strategy={tool} canEdit={canEdit} />,
  120: <EstimateWhatCanAffordWithHousing strategy={tool} canEdit={canEdit} />,
  121: <BeginRenting strategy={tool} canEdit={canEdit} />,
  122: <BuyingAHome strategy={tool} canEdit={canEdit} />,
  123: <AvoidScamsFollowingDisaster strategy={tool} canEdit={canEdit} />,
  124: <HelpRecoverAfterDisaster strategy={tool} canEdit={canEdit} />,
  125: <PrepareForDisaster strategy={tool} canEdit={canEdit} />,
  126: <FileADispute strategy={tool} canEdit={canEdit} />,
  127: <DealWithMyDebts strategy={tool} canEdit={canEdit} />,
  128: <TakeActionOnMedicalDebt strategy={tool} canEdit={canEdit} />,
  129: <CompareCostsAndBenefitsOfTraining strategy={tool} canEdit={canEdit} />,
  130: <ReduceRiskOfIdentityTheft strategy={tool} canEdit={canEdit} />,
  131: <HowToRequestBankingHistory strategy={tool} canEdit={canEdit} />,
  212: <ToolIndex />,
});

const interactiveTools = [
  '1',
  '2',
  '3',
  '5',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '20',
  '21',
  '22',
  '23',
  '24',
  '26',
  '27',
  '28',
  '29',
  '30',
];

export default class Tools extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toolLoading: props.toolLoading || true,
      tool: props.tool || null,
    };
  }

  componentDidMount() {
    const {match, history} = this.props;
    const {toolId} = match.params;
    if (toolId === '212') {
      this.setState({
        tool: {toolId: 212, gameId: 1, gameName: 'Test'},
        toolLoading: false,
      });
    } else if (!this.state.tool) {
      RequestService.get(
        `${config.apiGateway.URL}/tools/${toolId}`,
        (resp) => {
          this.setState({
            toolLoading: false,
            tool: resp.data,
          });
          config.setUniqueTitle(resp.data.name);
        },
        () => {
          history.push('/games');
        }
      );
    }
  }

  render() {
    const {toolLoading, tool} = this.state;
    if (toolLoading) return <Loader />;
    return (
      <Translation>
        {(t) => (
          <div className='tools'>
            <BreadCrumb
              includeHome
              historyLinks={[
                {
                  link: '/games',
                  display: 'Games',
                },
                {
                  link: `/game/${tool.gameId}`,
                  display: tool.gameName,
                },
                {
                  link: `/resources/`,
                  display: 'Resources',
                },
                {
                  link: `/resources/?tab=Tools`,
                  display: 'Tools',
                },
                {
                  link: '#',
                  display: tool.name,
                },
              ]}
            />
            <h1 className=''>{t(tool.name)}</h1>
            <LanguageSelector {...this.props} />
            <p className='hide-print'>
              <PrintButton />
              <i>
                {' '}
                <Trans i18nKey='tool.print1a'>You can </Trans>
                <a
                  className='hide-print'
                  href='/'
                  onClick={(e) => e.preventDefault() || window.print()}
                  title={t('tool.print1b')}
                >
                  <Trans i18nKey='tool.print1b'>print</Trans>
                </a>{' '}
                <Trans i18nKey='tool.print1c'>a copy of this tool at any time.</Trans>
                {interactiveTools.includes(this.props.match.params.toolId) && (
                  <Trans i18nKey='tool.print2'>
                    However, once you close this browser tab or window, anything you typed into this tool will not be
                    saved.
                  </Trans>
                )}
              </i>
            </p>

            {toolMapper(tool, this.props.authStore.isEditor)[tool.toolId]}
            <PlayGameBanner tool={tool} />
          </div>
        )}
      </Translation>
    );
  }
}

export {toolMapper};
