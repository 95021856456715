import React, {useState, useRef} from 'react';
import {Redirect, useHistory} from 'react-router-dom';
import {Formik, Field, FieldArray} from 'formik';
import {observer} from 'mobx-react';
import MaskedInput from "react-text-mask";
import {faQuestionCircle} from '@fortawesome/free-regular-svg-icons';
import Filter from 'bad-words';
import * as Yup from 'yup';
import Modal from 'react-responsive-modal';
import InputError from '../../../components/Formik/InputError';
import {hasAlert} from '../../../components/Formik/FormInput';
import {Loader} from '../../../components/Loader';
import {phoneNumberMask, organizationNameInfo, organizationDisplayNameInfo, phoneNumberRegex} from '../../register/OrganizationApplication';
import {Tooltip, withStyles} from '@material-ui/core';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import AddressForm, {addressSchemaObject} from '../../../components/Formik/AddressForm';
import AddAdditionalUserForm from './AddAdditionalUserForm';

const additionalUserInfo = "You can add additional staff to help manage this Organization Account. Do NOT add individual player information here.";

const FormChangesMessage = () => {
  return (
    <div className="usa-alert usa-alert--error margin-3">
      <div className="usa-alert__body">
        <p className="usa-alert__text">
          You have unsaved changes to your Organization account. Select “Save” before leaving this page or else your changes will be lost.
        </p>
      </div>
    </div>
  );
};

const mapStoreToFormFields = (organization) => (
  {
    phoneNumber: organization.phone_number,
    name: organization.name,
    displayName: organization.display_name,
    addressLineOne: organization.address_line_one,
    addressLineTwo: organization.address_line_two,
    city: organization.city,
    state: organization.state,
    zipCode: organization.zip_code,
    users: organization.account_owners.map((user) => ({
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
      id: user.id,
      remove: user.remove,
      primary: user.primary
    }))
  }
);

const AccountToolTip = withStyles({
  tooltip: {
    fontSize: '1em'
  }
})(Tooltip);

const filter = new Filter();

let editOrganizationSchemaObject = {
  phoneNumber: Yup.string()
    .matches(phoneNumberRegex, 'Phone number must be valid.')
    .required('Phone number is required.'),
  name: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .min(2, 'Organization name must be at least two characters.')
    .max(50, 'Organization name must be at most 50 characters.')
    .required('Organization name is required.'),
  displayName: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .min(2, 'Organization display name must be at least two characters.')
    .max(50, 'Organization display name must be at most 50 characters.')
    .required('Organization display name is required.'),
};

editOrganizationSchemaObject = Object.assign(editOrganizationSchemaObject, addressSchemaObject);

const editOrganizationSchema = Yup.object().shape(editOrganizationSchemaObject);

const EditOrganizationAccount = observer(({organizationStore, authStore, match}) => {
  const history = useHistory();
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailureAlert, setShowFailureAlert] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const successAlertRef = useRef(null);
  const failureAlertRef = useRef(null);
  organizationStore.getOrganization(match.params.id || authStore.organizationId);
  organizationStore.getUserHistory(match.params.id || authStore.organizationId);
  if(organizationStore.organizationLoading) return <Loader />;
  if(organizationStore.organizationError) return <Redirect to="/account" />;
  
  const showSuccess = () => {
    setShowFailureAlert(false);
    setShowSuccessAlert(true);
    successAlertRef.current.focus();
  };

  const showFailure = () => {
    setShowSuccessAlert(false);
    setShowFailureAlert(true);
    failureAlertRef.current.focus();
  };

  const disableInputs = !authStore.isOrganization && !authStore.isAdmin;
  const disableOrgUsersInputs = !authStore.primaryOrganizationAccount && !authStore.isAdmin;

  return(
    <div className="edit-organization registration-wrapper margin-left-auto margin-right-auto margin-top-5">
      <Formik
        initialValues={
          mapStoreToFormFields(organizationStore.organization)
        }
        validationSchema={editOrganizationSchema}
        onSubmit={(values, {resetForm}) => {
          organizationStore.updateOrganization(organizationStore.organization.id, values, showSuccess, showFailure);
        }}
        enableReinitialize={true}
      >
        {formikProps => (
          <form className="reg-form">
            <fieldset>
              {showSuccessAlert && <div ref={successAlertRef}  tabIndex={0} className="usa-alert usa-alert--success margin-top-3">
                <div className="usa-alert__body">
                  <p className="usa-alert__text">
                  You have successfully updated {authStore.isAdmin ? 'this' : 'your'} account! <br/>
                    <a href={authStore.isAdmin ? `/account/organizations/${organizationStore.organization.id}` : '/account'}>Return to {authStore.isAdmin ? 'organization' : 'account'}</a>
                  </p>
                </div>
              </div> }
              {showFailureAlert && <div ref={failureAlertRef}  tabIndex={0} className="usa-alert usa-alert--error margin-top-3">
                <div className="usa-alert__body">
                  <p className="usa-alert__text">
                    We have encountered an unexpected error. Please try again later. <br/>
                    <a href={authStore.isAdmin ? `/account/organizations/${organizationStore.organization.id}` : '/account'}>Return to {authStore.isAdmin ? 'organization' : 'account'}</a>
                  </p>
                </div>
              </div> }
              <h2 className="margin-bottom-1">{!disableInputs ? "Edit" : ""} Organization Details</h2>
              <p className="margin-top-0 margin-bottom-3">All fields marked with * are required.</p>
              <div className="grid-row margin-bottom-2">
                <div className="grid-col-9 padding-right-2">
                  <label htmlFor="phoneNumber">
                    <span htmlFor="phoneNumber">Phone number: *</span>
                  </label>
                  <Field 
                    id="phoneNumber" 
                    name="phoneNumber"
                    render={({field}) => (
                      <MaskedInput
                        {...field}
                        mask={phoneNumberMask}
                        id="phoneNumber"
                        type="text"
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        className={
                          formikProps.errors.phoneNumber && formikProps.touched.phoneNumber
                            ? "alert"
                            : ""
                        }
                        disabled={disableInputs}
                      />
                    )}/>
                  <InputError formikProps={formikProps} fieldName="phoneNumber" />
                </div>
              </div>
              <div className="grid-row margin-bottom-2">
                <div className="grid-col-6 padding-right-2">
                  <label htmlFor="name">
                    <AccountToolTip title={organizationNameInfo} placement="top">
                      <span htmlFor="name">Organization name: * <FontAwesomeIcon icon={faQuestionCircle} /></span>
                    </AccountToolTip>
                  </label>
                  <Field 
                    id="name" 
                    name="name" 
                    type="text" 
                    className={`${hasAlert(formikProps, 'name') && 'alert'}`} 
                    required 
                    disabled={disableInputs}/>
                  <InputError formikProps={formikProps} fieldName="name" />
                </div>    
                <div>
                  <label htmlFor="displayName">
                    <AccountToolTip title={organizationDisplayNameInfo} placement="top">
                      <span htmlFor="displayName">Organization display name: * <FontAwesomeIcon icon={faQuestionCircle} /></span>
                    </AccountToolTip>
                  </label>
                  <Field 
                    id="displayName" 
                    name="displayName" 
                    type="text" 
                    className={`${hasAlert(formikProps, 'displayName') && 'alert'}`} 
                    required
                    disabled={disableInputs}
                  />
                  <InputError formikProps={formikProps} fieldName="displayName" />
                </div>
              </div>
              <AddressForm formikProps={formikProps} disabled={disableInputs}/>
              <div className="grid-row">
                <div className="grid-col-12 padding-right-2">
                  <label htmlFor="additionalUsers">
                    <AccountToolTip title={additionalUserInfo} placement="top">
                      <span><b>Staff accounts: <FontAwesomeIcon icon={faQuestionCircle} /></b></span>
                    </AccountToolTip>
                  </label>
                  <FieldArray
                    name="users"
                    render={
                      arrayHelpers =>
                        <div className="additional-emails">
                          <table className="usa-table">
                            <thead>
                              <tr>
                                <td>Email/Name</td>
                                <td>Player ID</td>
                                <td>Role</td>
                              </tr>
                            </thead>
                            <tbody>
                              {formikProps.values.users.map((user, index) => (
                                <tr key={index} className={`${user.remove ? "removed" : ""} ${user.id ? "" : "new"}`}>
                                  <td>{user.email}<br /><i>{user.firstName} {user.lastName}</i></td>
                                  <td>{user.id || user.userId}</td>
                                  <td>
                                    {/* {user.primary ?
                                      <i>Primary Account</i> : <>
                                      {authStore.userId !== user.id && <>
                                        <button 
                                          type="button" 
                                          className="usa-button usa-button--unstyled"
                                          onClick={() => {
                                            if(!user.id) {
                                              arrayHelpers.remove(index); 
                                            }
                                            else{
                                              formikProps.setFieldValue(`users.${index}.remove`, !user.remove);
                                            }
                                          }}
                                        >
                                          {user.remove ? 'Cancel' : 'Remove'}
                                        </button>
                                      </>
                                      }
                                      </>
                                    } */}
                                    {user.primary ? <span>Primary Account</span> : <span>Additional Staff</span>}
                                    <br />
                                    {!user.primary && !disableOrgUsersInputs && 
                                      <>
                                        <button 
                                          type="button" 
                                          className="usa-button usa-button--unstyled"
                                          onClick={() => {
                                            if(!user.id) {
                                              arrayHelpers.remove(index); 
                                            }
                                            else{
                                              formikProps.setFieldValue(`users.${index}.remove`, !user.remove);
                                            }
                                          }}
                                        >
                                          {user.remove ? 'Cancel' : 'Remove'}
                                        </button>
                                        {" | "}
                                        <button
                                          type="button" 
                                          className="usa-button usa-button--unstyled"
                                          onClick={() => {
                                            formikProps.values.users.forEach((user, index) => formikProps.setFieldValue(`users.${index}.primary`, false));
                                            formikProps.setFieldValue(`users.${index}.primary`, true);
                                          }}
                                        >
                                          Make Primary
                                        </button>
                                      </>
                                    }
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div className="grid-row margin-bottom-2">
                            <i>
                              Note:{' '}
                              {authStore.primaryOrganizationAccount ?
                                "Before you can add additional staff to this Organization account, you need to know their email address and Player ID. Ask them to create an Individual Player account (if they haven’t already) and give you that information." :
                                "Primary Accounts can add/remove additional staff members and change which account is Primary. Additional Staff cannot."
                              }
                            </i>
                          </div>
                          {!disableOrgUsersInputs && <button type="button" className="fdic-button square-button tan-button add-user-button" onClick={() => setShowAddUserModal(true)}>
                            Add additional staff
                          </button>}
                          <Modal
                            open={showAddUserModal}
                            closeOnOverlayClick={false}
                            closeOnEsc={false}
                            showCloseIcon={false}
                            onClose={() => setShowAddUserModal(false)}
                          >
                            <div tabIndex={0}>
                              <AddAdditionalUserForm 
                                editingUser={editingUser}
                                onSubmit={(values) => {
                                  if(values.id) {
                                    arrayHelpers.replace(formikProps.values.users.findIndex((user) => user.id === values.id), values);
                                  }
                                  else {
                                    arrayHelpers.insert(formikProps.values.users.length, values);
                                  }
                                  setEditingUser(null);
                                  setShowAddUserModal(false);
                                }}
                                onCancel={() => setShowAddUserModal(false)} 
                              />
                            </div>
                          </Modal>
                        </div>
                    }
                  />
                </div>
              </div>
            </fieldset>
            {formikProps.dirty && <FormChangesMessage />}
            <div className="margin-top-3 margin-bottom-4">
              {!disableInputs && <div className="grid-row">
                <button id="save" className="usa-button fdic-button square-button centered" onClick={formikProps.handleSubmit}>Save</button>
              </div>}
              <div className="grid-row">
                <button 
                  id="cancel" 
                  className="usa-button usa-button--unstyled margin-top-2 centered" 
                  onClick={() => history.push(authStore.isAdmin ? `/account/organizations/${organizationStore.organization.id}` : '/account')}
                >{!disableInputs ? "Cancel" : "Back to Account"}</button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
});

export default EditOrganizationAccount;