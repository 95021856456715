import React, {useEffect} from 'react';
import {Formik, Form, Field} from 'formik';
import {Typeahead} from 'react-bootstrap-typeahead';
import * as Yup from 'yup';
import moment from 'moment';
import queryString from 'query-string';
import {useHistory} from 'react-router-dom';
import RequestService from '../../../services/RequestService';
import config from '../../../config';
import {getOrganizationOptions} from './OrganizationReport';
import {Loader} from '../../../components/Loader';
import {observer} from 'mobx-react';
import {getOrganizationFromOptions} from '../../../services/OrganizationUtilities';

const filterSchema = Yup.object().shape({
  endDate: Yup.date().when(
    'startDate',
    (startDate, schema) => startDate && schema.min(startDate, 'Start date must be before end date.'),
  ),
});

const GameUsageReport = ({gamesStore, authStore, organizationStore}) => {
  const history = useHistory();

  let startDate = queryString.parse(history.location.search).startDate;
  if (!startDate) startDate = "2021-09-30";
  let endDate = queryString.parse(history.location.search).endDate;
  if (!endDate) endDate = moment().format('YYYY-MM-DD');
  const organizationId = queryString.parse(history.location.search).organizationId;

  organizationStore.getOrganizations();
  const organizationOptions = getOrganizationOptions(organizationStore);

  const fetchReport = () => {
    RequestService.get(`${config.apiGateway.URL}/v2/admin-reports/game-usage-report`, (response) => {
      console.log(response);
    });
  };

  useEffect(() => {
    fetchReport();
    gamesStore.getGames();
  }, [gamesStore]);

  if (organizationStore.organizationsLoading) {
    return <Loader />;
  }

  return (
    <div className='organization mobile-full-width game-usage-report'>
      <h1>Game Reports V2</h1>

      <div className='grid-row background-color-light-grey padding-left-3 padding-top-2 padding-right-3 padding-bottom-3 margin-bottom-4 margin-top-2'>
        <div className='grid-col-12'>
          <Formik
            validationSchema={filterSchema}
            initialValues={{
              startDate: startDate,
              endDate: endDate,
              organizationId: organizationId,
            }}
          >
            {(formikProps) => (
              <Form>
                {authStore.isAdmin && (
                  <div>
                    <div className='grid-row'>
                      <h4 className='no-margin'>Filter by Organization</h4>
                    </div>
                    <div className='grid-row'>
                      <label htmlFor='organization-list-choice' className='color-primary font-size-14'>
                        Organization:
                      </label>
                    </div>
                    <div id='org-choic-container' className='grid-row margin-bottom-2 mobile-full-width'>
                      <Typeahead
                        id='organization-list-choices'
                        selected={
                          formikProps.values.organizationId &&
                          getOrganizationFromOptions(organizationOptions, formikProps.values.organizationId)
                        }
                        multiple={false}
                        options={organizationOptions}
                        onChange={(values) => values[0] && formikProps.setFieldValue('organizationId', values[0].id)}
                      />
                    </div>
                  </div>
                )}
                <div className='grid-row'>
                  <div className='grid-col-12'>
                    <h4 className='no-margin'>Filter by Date</h4>
                  </div>
                  <div className='grid-col-4 mobile-full-width'>
                    <div className='grid-row'>
                      <label htmlFor='startDate' className='color-primary font-size-14'>
                        Start date:
                      </label>
                    </div>
                    <div className='grid-row'>
                      <Field
                        title='Enter start date'
                        id='startDate'
                        name='startDate'
                        type='date'
                        className={`${
                          formikProps.touched.endDate && formikProps.errors.endDate && 'alert'
                        } fdic-input mobile-full-width`}
                      />
                    </div>
                  </div>
                  <div className='grid-col-4 mobile-full-width'>
                    <div className='grid-row'>
                      <label htmlFor='endDate' className='color-primary font-size-14'>
                        End date:
                      </label>
                    </div>
                    <div className='grid-row'>
                      <Field
                        title='Enter end date'
                        id='endDate'
                        name='endDate'
                        type='date'
                        className={`${
                          formikProps.touched.endDate && formikProps.errors.endDate && 'alert'
                        } fdic-input mobile-full-width`}
                        placeholder='test'
                      />
                    </div>
                  </div>
                  <div className='grid-col-4 mobile-full-width padding-top-3'>
                    <button className='usa-button full-width' onClick={formikProps.handleSubmit}>
                      Apply Filter{authStore.isAdmin ? 's' : ''}
                    </button>
                  </div>
                </div>
                {formikProps.touched.endDate && formikProps.errors.endDate && (
                  <div className='grid-row alert full-width margin-top-105'>{formikProps.errors.endDate}</div>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>

      <div>
        <p className='hide-print'>Select a game to view the data. The current date filter will be applied until it is changed or cleared.</p>
        <div>
          {gamesStore.games.map((game) => (
            <div className="game-row" onClick={() => history.push(`/account/v2/game-usage-report/${game.id}`)}>
              <div className="accordion-title">
                {game.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default observer(GameUsageReport);