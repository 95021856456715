import React from 'react';
import {observer} from 'mobx-react';
import {Redirect} from 'react-router-dom';
import AdminAccount from './admin/AdminAccount';
import IndividualAccount from './individual/IndividualAccount';
import OrganizationAccount from './organization/OrganizationAccount';
import config from '../../config';

const Account = observer((props) => {
  config.setUniqueTitle('Account');
  props.gameSessionsStore.getSessions(props.authStore.userId);
  props.gamesStore.getGames();
  return( 
    props.authStore.isAdmin ? <AdminAccount {...props} /> : 
      props.authStore.isEditor ? <Redirect to="/games" /> :
        props.authStore.isOrganization ? <OrganizationAccount {...props} /> : 
          <IndividualAccount {...props} />
  );
});

export default Account;