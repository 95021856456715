import React from "react";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import StrategyCard from "../utilities/StrategyCard";
import {useTranslation} from "react-i18next";

const DealWithMyDebts = ({strategy, canEdit}) => {
  const {i18n} = useTranslation();
  return (
    <div className="strategy">
      <EditableContent
        htmlType="div"
        initialContent={
          i18n.language === "es"
            ? strategy.contentsEs || strategy.contents
            : strategy.contents
        }
        fieldName={i18n.language === "es" ? "contents_es" : "contents"}
        updateEndpoint={`${config.apiGateway.URL}/topic-items/${strategy.id}`}
        canEdit={canEdit}
        richText
      />
      {strategy.toolContents.map((toolContent) => (
        <StrategyCard key={toolContent.id}>
          <EditableContent
            htmlType="div"
            fieldName={i18n.language === "es" ? "details_es" : "details"}
            initialContent={
              i18n.language === "es"
                ? toolContent.detailsEs || toolContent.details
                : toolContent.details
            }
            updateEndpoint={`${config.apiGateway.URL}/tool-contents/${strategy.id}/${toolContent.id}`}
            canEdit={canEdit}
            richText
          />
        </StrategyCard>
      ))}
    </div>
  );
};

export default DealWithMyDebts;
