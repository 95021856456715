import React from "react";
import {Formik, Form} from "formik";
import {ExpandableTextarea} from "../utilities/Inputs";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation} from "react-i18next";

const initialValues = {
  matterToMe: "",
  values: "",
};

const IdentifyMyValues = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  return (
    <div>
      <Formik initialValues={initialValues}>
        {(_formikProps) => (
          <Form>
            <EditableContent
              htmlType="div"
              initialContent={
                i18n.language === "es"
                  ? tool.contentsEs || tool.contents
                  : tool.contents
              }
              fieldName={i18n.language === "es" ? "contents_es" : "contents"}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            <div className="grid-col-8 mobile-full-width">
              <ExpandableTextarea
                name="matterToMe"
                question={
                  <b>
                    {t('What are the people, things, and places that matter to me?')}
                  </b>
                }
                title={t('What are the people, things, and places that matter to me?')}
              />
              <ExpandableTextarea
                name="values"
                question={<b>{t('What values do my answers represent?')}</b>}
                title={t('What values do my answers represent?')}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default IdentifyMyValues;
