import React, {Component} from 'react';
import {Trans, withTranslation} from 'react-i18next';
import RequestService from '../../services/RequestService';
import config from '../../config';
import ResourceList from '../resourceList/ResourceList';
import Loader from '../../components/Loader/Loader';
import ExternalLinkWarning from './ExternalLinkWarning';
import LanguageSelector from '../../components/Buttons/LanguageSelector';

import chloe2 from '../../assets/images/chloe2.png';

class Resources extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topicItemsLoading: props.topicItemsLoading || true,
      topicItems: props.topicItems || [],
      gameCategories: props.gameCategories || [],
    };
  }

  componentDidMount() {
    config.setUniqueTitle('Resources');
    this.state.topicItems.length === 0 &&
      RequestService.get(`${config.apiGateway.URL}/topic-items`, (resp) => {
        this.setState({
          topicItems: resp.data,
          topicItemsLoading: false,
        });
      });
    this.state.gameCategories.length === 0 &&
      RequestService.get(`${config.apiGateway.URL}/game-categories`, (resp) => {
        this.setState({
          gameCategories: resp.data,
          games: this.getGames(resp.data),
        });
      });
  }

  getGames = (gameCategories) => {
    let games = [];
    gameCategories.forEach((gameCategory) => {
      games = games.concat(gameCategory.games);
    });
    return games.sort((a, b) => {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    });
  };

  render() {
    if (!this.state.topicItems) return null;
    const {t} = this.props;
    return (
      <div className='grid-container resources-wrapper margin-top-3'>
        <div className='grid-row resource-search-section'>
          <div className='grid-col-8 mobile-full-width'>
            <h1 className='no-margin'>
              <Trans i18nKey='resource.title'>Resources</Trans>
            </h1>
            <LanguageSelector {...this.props} />
            <div className='grid-row margin-top-4 margin-bottom-4 resources-description'>
              <p>
                <Trans i18nKey='resource.details'>
                  Browse the tabs below to find what you are looking for. You can also explore the
                  <a href='/glossary' title={t('Glossary')}>Glossary</a> for definitions of terms used in <i>How Money Smart Are You?</i>.
                </Trans>
              </p>
              <p>
                <Trans i18nKey='resource.note'>
                  <b>Note:</b> You don’t need an account to use these Resources. Information you enter in the Tools will
                  not be saved to an account.
                </Trans>
              </p>
            </div>
          </div>
          <div className='grid-col-4 chloe-container'>
            <img alt='Money Smart character' height='250' src={chloe2} />
          </div>
        </div>
        {this.state.gameCategories && this.state.games && !this.state.topicItemsLoading ? (
          <div className='grid-row resources-page'>
            <ResourceList
              topics={this.state.topicItems}
              gameCategories={this.state.gameCategories}
              games={this.state.games}
              displayAll
              canEdit={this.props.authStore.isEditor}
              history={this.props.history}
              authStore={this.props.authStore}
            />
          </div>
        ) : (
          <Loader />
        )}
        <div className='grid-row'>
          <p>
            <ExternalLinkWarning />
          </p>
        </div>
      </div>
    );
  }
}

export default withTranslation()(Resources);
