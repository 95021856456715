import {observable, action, decorate} from 'mobx';
import {snakeCase, mapKeys} from 'lodash';
import RequestService from '../services/RequestService';
import config from '../config';
import moment from 'moment';

export const refStartDateTime = '2000-01-01T00:00:00.001Z';

class OrganizationStore {
  constructor() {
    this.buildFromSession();
  }

  organization = null;
  organizationLoading = true;
  organizationError = false;
  userHistory = null;
  userHistoryLoading = true;
  userHistoryError = false;
  organizations = [];
  organizationsLoading = true;
  applications = [];
  approvedOrganizations = [];
  suspendedOrganizations = [];
  deniedOrganizations = [];
  sessions = [];
  sessionsLoading = true;

  alphabatizeOrganizations = (organizations) =>
    organizations.sort((organizationA, organizationB) => {
      if (organizationA.name < organizationB.name) return -1;
      if (organizationA.name > organizationB.name) return 1;
      return 0;
    });

  getOrganizations = () => {
    this.organizationsLoading &&
      RequestService.get(
        `${config.apiGateway.URL}/organizations`,
        (response) => {
          this.organizations = response.data;
          this.applications = response.data.filter((organization) => !organization.approved && !organization.denied);
          this.approvedOrganizations = this.alphabatizeOrganizations(
            response.data.filter(
              (organization) => organization.approved && !organization.suspended && !organization.denied,
            ),
          );
          this.suspendedOrganizations = this.alphabatizeOrganizations(
            response.data.filter(
              (organization) => organization.approved && organization.suspended && !organization.denied,
            ),
          );
          this.deniedOrganizations = this.alphabatizeOrganizations(
            response.data.filter((organization) => organization.denied),
          );
          this.organizationsLoading = false;
        },
        (error) => console.log('error'),
      );
  };

  getOrganization = (organizationId) => {
    this.organizationLoading &&
      RequestService.get(
        `${config.apiGateway.URL}/organizations/${organizationId}`,
        (response) => {
          this.organization = response.data;
          this.organizationLoading = false;
        },
        () => {
          this.organizationError = true;
          this.organizationLoading = false;
        },
      );
  };

  updateOrganization = (organizationId, organization, callback, failureCallback) => {
    let organizationParams = {organization: mapKeys(organization, (_value, key) => snakeCase(key))};
    organizationParams.organization.users = organizationParams.organization.users.map((user) =>
      mapKeys(user, (_value, key) => snakeCase(key)),
    );
    RequestService.post(
      `${config.apiGateway.URL}/organization/${organizationId}`,
      organizationParams,
      (response) => {
        this.organization = response.data;
        callback && callback();
      },
      (error) => {
        failureCallback && failureCallback();
      },
    );
  };

  getUserHistory = (organizationId) => {
    this.userHistoryLoading &&
      RequestService.get(
        `${config.apiGateway.URL}/organizations/${organizationId}/user-history`,
        (response) => {
          this.userHistory = response.data;
          this.userHistoryLoading = false;
        },
        () => {
          this.userHistoryError = true;
          this.userHistoryLoading = false;
        },
      );
  };

  addNote = (organizationId, note, onSuccess) => {
    this.postAndAction(organizationId, 'notes', onSuccess, {note: note});
  };

  approveOrganization = (organizationId, onSuccess) => {
    this.postAndAction(organizationId, 'approve', onSuccess);
  };

  suspendOrganization = (organizationId, onSuccess) => {
    this.postAndAction(organizationId, 'suspend', onSuccess);
  };

  unsuspendOrganization = (organizationId, onSuccess) => {
    this.postAndAction(organizationId, 'unsuspend', onSuccess);
  };

  denyOrganization = (organizationId, onSuccess) => {
    this.postAndAction(organizationId, 'deny', onSuccess);
  };

  postAndAction = (organizationId, endpoint, onSuccess, body = null) => {
    RequestService.post(`${config.apiGateway.URL}/organizations/${organizationId}/${endpoint}`, body, (response) => {
      onSuccess();
    });
  };

  deleteOrganization = (organizationId, onSuccess) => {
    RequestService.delete(
      `${config.apiGateway.URL}/organizations/${organizationId}`,
      (response) => {
        onSuccess();
      },
      (error) => console.log(error),
      true,
    );
  };

  getSessions = ({startDate, endDate, organizationId, gameId}, forceUpdate = false) => {
    (this.sessionsLoading || forceUpdate) &&
      RequestService.get(
        `${config.apiGateway.URL}/organization/game-sessions${this.queryString(startDate, endDate, organizationId, gameId)}`,
        (response) => {
          this.sessions = response.data;
          this.sessionsLoading = false;
          this.writeToSession();
        },
        (error) => console.log(error),
      );
  };

  queryString = (startDate, endDate, organizationId, gameId) => {
    let queryString = '?';
    if (startDate && endDate) queryString += `start_date=${startDate}&end_date=${endDate}&`;
    if (organizationId) queryString += `organization_id=${organizationId}`;
    if (gameId) queryString += `game_id=${gameId}`;
    return queryString;
  };

  getGameCounts = (gameId) => {
    let gameSessions = this.sessions[gameId];
    if (!gameSessions) return {complete: 0, inProgress: 0};
    const validGameSessions = gameSessions.filter(
      (gs) => gs.organization_name === null || (gs.organization_name !== null && gs.organization_approved),
    );
    let complete = validGameSessions.filter((session) => session.complete_date);
    return {complete: complete.length, inProgress: validGameSessions.length - complete.length};
  };

  getSortedSessions = (gameId) => {
    if (gameId > 14) return [];
    const gameSessions = this.sessions[gameId];
    if (!gameSessions) return [];
    const validGameSessions = gameSessions.filter((gs) => this.isValidGameSession(gs));
    return validGameSessions.slice().sort((a, b) => this.sortGameSessionsByUserId(a, b));
  };

  getMasterCertificatesEarnedByUserId = () => {
    const gameCompleteCountsByUser = Object.keys(this.sessions).reduce((prev, gameId) => {
      const gameSessions = this.sessions[gameId];
      gameSessions.forEach((gs) => {
        if (!this.isValidGameSession(gs)) return;
        if (!prev[gs.user_id]) prev[gs.user_id] = {count: 0, complete_date: refStartDateTime};
        if (gs.complete_date !== null) {
          prev[gs.user_id].count++;
          if (moment(prev[gs.user_id].complete_date) < moment(gs.complete_date)) {
            prev[gs.user_id].complete_date = gs.complete_date;
          }
        }
      });
      return prev;
    }, {});
    return Object.keys(gameCompleteCountsByUser).reduce((prev, userId) => {
      if (gameCompleteCountsByUser[userId].count === 14) {
        prev.push({
          user_id: userId,
          game_id: 15,
          start_date: null,
          complete_date: gameCompleteCountsByUser[userId].complete_date,
        });
      }
      return prev;
    }, []);
  };

  gameSessionForMasterCertificateEarnedByUserId = (userId) => {
    const userIdString = userId.toString();
    let mostCurrentSession = {complete_date: refStartDateTime};
    let earnedCertificates = 0;
    Object.keys(this.sessions).forEach((gameId) => {
      const gameSessions = this.sessions[gameId];
      const gameSession = gameSessions.filter(
        (gs) => gs.user_id.toString() === userIdString && gs.complete_date !== null,
      );
      if (gameSession.length > 0) {
        earnedCertificates++;
        if (moment(mostCurrentSession.complete_date) < moment(gameSession[0].complete_date)) {
          mostCurrentSession = gameSession[0];
        }
      }
    });
    if (earnedCertificates < 14) return null;
    return {...mostCurrentSession, game_id: 15, game_name: 'Master Certificate'};
  };

  getOrganizationDisplayNameByName = (orgName) => {
    console.log(orgName);
    this.approvedOrganizations.forEach((org) => {
      console.log(org.name);
      return org.name === orgName;
    });
    // console.log(t.display_name);
    return '';
  };

  isValidGameSession = (gameSession) => {
    if (!gameSession.user_id) return false;
    if (gameSession.organization_name === null) return true;
    if (gameSession.organization_approved) return true;
    return false;
  };

  sortGameSessionsByUserId = (a, b) => {
    return a.user_id < b.user_id ? -1 : 1;
  };

  removeUserFromUserHistory = (userId) => {
    let hitKey = '';
    let hitJ = -1;
    const keys = Object.keys(this.userHistory);
    for (let i = 0; i < keys.length; i++) {
      const users = this.userHistory[keys[i]];
      for (let j = 0; j < users.length; j++) {
        if (users[j].user_id === userId) {
          hitKey = keys[i];
          hitJ = j;
          break;
        }
      }
      if (hitJ > -1) break;
    }
    if (hitJ === -1) return false;
    this.userHistory[hitKey] = [
      ...this.userHistory[hitKey].slice(0, hitJ),
      ...this.userHistory[hitKey].slice(hitJ + 1),
    ];
    this.organization.user_count--;
    return true;
  };

  removeAllUsersFromUserHistory = () => {
    this.userHistory = {};
    this.organization.user_count = 0;
  };

  writeToSession = () => {
    let json = JSON.stringify(this);
    sessionStorage.setItem('OrganizationStore', json);
  };

  buildFromSession = () => {
    let json = JSON.parse(sessionStorage.getItem('OrganizationStore'));
    if (json) {
      this.sessions = json.sessions;
    }
  };
}

decorate(OrganizationStore, {
  organization: observable,
  organizationLoading: observable,
  organizationError: observable,
  userHistory: observable,
  userHistoryError: observable,
  userHistoryLoading: observable,
  organizations: observable,
  organizationsLoading: observable,
  sessions: observable,
  sessionsLoading: observable,
  getOrganizations: action,
  getUserHistory: action,
  getSessions: action,
  removeUserFromUserHistory: action,
  removeAllUsersFromUserHistory: action,
});

export default OrganizationStore;
