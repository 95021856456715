import React from 'react';
import {observer} from 'mobx-react';
import Modal from 'react-responsive-modal';
import moment from 'moment';
import {withTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {Formik} from 'formik';
import * as Yup from 'yup';

import FormInput from '../../../components/Formik/FormInput';
import Loader from '../../../components/Loader/Loader';
import AccountSubHeader from '../AccountSubHeader';
import config from '../../../config';
import RequestService from '../../../services/RequestService';
import LanguageSelector from '../../../components/Buttons/LanguageSelector';
import IndividualGamePlayInfo from './IndividualGamePlayInfo';
import {organizationDisclosure} from '../../register/Register';

const deleteAccountSchema = Yup.object().shape({
  password: Yup.string()
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/, 'Password is required.')
    .required('Password is required.'),
});

class IndividualAccount extends React.Component {
  constructor(props) {
    super(props);
    this.closeModal = this.closeModal.bind(this);
    this.handleUnlinkOrganization = this.handleUnlinkOrganization.bind(this);
    this.handleDeleteAccountButtonClick = this.handleDeleteAccountButtonClick.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleDeleteResponse = this.handleDeleteResponse.bind(this);
    this.state = {
      showOrganizationInput: false,
      organizationId: props.authStore.organizationId,
      deleteModalOpen: false,
      unlinkOrgModalOpen: false,
    };
  }

  getApprovedOrgDisplayName = () => {
    const {authStore, organizationStore} = this.props;
    const orgId = authStore.organizationId;
    const approvedOrgs = orgId
      ? organizationStore.approvedOrganizations.filter((o) => o.id.toString() === orgId.toString())
      : [];
    const displayName = approvedOrgs.length > 0 ? approvedOrgs[0].display_name : '';
    return displayName;
  };

  organizationForm = (displayName, t) => {
    return (
      <div className='grid-row margin-bottom-2'>
        {displayName !== '' && (
          <h3 className='no-margin'>
            {t('My Organization:')} {displayName}
          </h3>
        )}
      </div>
    );
  };

  closeModal = () => {
    this.setState({deleteModalOpen: false, unlinkOrgModalOpen: false});
  };

  handleUnlinkOrganization = () => {
    const {authStore} = this.props;
    RequestService.post(
      `${config.apiGateway.URL}/update-organization/unlink`,
      {
        user_id: authStore.userId,
      },
      (response) => {
        authStore.organizationId = response.data.organization_id;
        authStore.writeToSession();
        this.props.history.push('/account');
        this.closeModal();
      },
    );
  };

  handleDeleteAccountButtonClick = () => {
    this.setState({deleteModalOpen: true}, () => {
      // not 508'y, but set focus on cancel button to not activate
      //  form validation immediately...annoying for the cancel button click
      document.getElementById('cancel-delete-user-button').focus();
    });
  };

  handleDelete = (e, values) => {
    e.preventDefault();
    RequestService.post(
      `${config.apiGateway.URL}/delete-account`,
      {user: {password: values.password}},
      () => this.handleDeleteResponse(true),
      () => this.handleDeleteResponse(false),
    );
  };

  handleDeleteResponse = (success) => {
    const item = success ? 'success' : 'failed';
    sessionStorage.clear();
    sessionStorage.setItem('accountDeleted', item);
    this.closeModal();
    if (success) {
      this.props.history.push('/login');
    }
  };

  render() {
    const {organizationStore, authStore, gamesStore, gameSessionsStore, temporaryOrganizationAccount, t} = this.props;
    organizationStore.getOrganizations();
    if (authStore.isOrganization) organizationStore.getOrganization(authStore.organizationId);
    const displayName = this.getApprovedOrgDisplayName();
    const hasOrg = displayName !== '';
    const orgLink = hasOrg ? t('Change organization') : t('Link to organization');
    const changePasswordText = t('Change password');
    const deleteAccountText = t('Delete Account');

    const {deleteModalOpen, unlinkOrgModalOpen} = this.state;
    const deleteAccountWarningText =
      'You will lose all certificates and game progress. If you are linked to an organization, they will no longer be able to view your certificates.';
    const unlinkOrgWarningText = 'Your account will no longer be associated with an organization.';

    return (
      <div>
        <h1 className='margin-bottom-105'>{t('My Account')}</h1>
        <LanguageSelector {...this.props} />
        {temporaryOrganizationAccount && (
          <div className='grid-col-10'>
            <p>
              Date Application Submitted:{' '}
              <b>{moment(organizationStore.organization.application_date).parseZone().format('LL')}</b>
            </p>
            <p>
              If you have not heard back from us within 5 business days after submitting your information, please let us
              know by emailing <a href='mailto: communityaffairs@fdic.gov'>communityaffairs@fdic.gov</a>.
            </p>
          </div>
        )}
        <AccountSubHeader h3Text={`${t('Player ID')}: ${authStore.userId}`} pText={authStore.email} />
        {organizationStore.organizationsLoading ? (
          <Loader />
        ) : (
          <div>
            {this.organizationForm(displayName, t)}
            {hasOrg && (
              <div>
                <p>
                  <i>{t(organizationDisclosure)}</i>
                </p>
              </div>
            )}            
            <Link to='/account/change-password'>{changePasswordText}</Link>
            <span> | </span>
            <Link to='/account/change-organization'>{orgLink}</Link>
            {hasOrg && (
              <>
                <span> | </span>
                <button
                  className='usa-button usa-button--unstyled fdic-button--link'
                  onClick={() => this.setState({unlinkOrgModalOpen: true})}
                >
                  {t('Unlink from this organization')}
                </button>
              </>
            )}
            <button
              className='usa-button usa-button--unstyled fdic-button--link float-right'
              onClick={this.handleDeleteAccountButtonClick}
            >
              {deleteAccountText}
            </button>
            <IndividualGamePlayInfo
              authStore={authStore}
              gamesStore={gamesStore}
              gameSessionsStore={gameSessionsStore}
            />
          </div>
        )}
        <Modal open={deleteModalOpen || unlinkOrgModalOpen} onClose={this.closeModal}>
          <div className='suspend-warning-modal'>
            <h4>{t(`Are you sure you want to ${deleteModalOpen ? 'delete' : 'unlink'} your account?`)}</h4>
            <p className='margin-bottom-3'>{deleteModalOpen ? t(deleteAccountWarningText) : t(unlinkOrgWarningText)}</p>
            {deleteModalOpen && (
              <div className='registration-wrapper auto-width'>
                <Formik initialValues={{password: ''}} validationSchema={deleteAccountSchema} onSubmit={undefined}>
                  {(formikProps) => (
                    <form id='delete-account-form' className='display-flex flex-column'>
                      <p>Enter your valid password to delete your account.</p>
                      <div className='reg-form padding-bottom-2'>
                        <fieldset>
                          <FormInput
                            formikProps={formikProps}
                            type='password'
                            id='password'
                            label={'Password'}
                            required
                          />
                        </fieldset>
                      </div>
                      <div className='padding-bottom-2'>
                        <button
                          type='button'
                          onClick={(e) => this.handleDelete(e, formikProps.values)}
                          className='confirm-suspend-button usa-button usa-button--secondary margin-right-4'
                          disabled={!formikProps.isValid || formikProps.isSubmitting || !formikProps.dirty}
                        >
                          {t('Delete my account')}
                        </button>
                        <button
                          id='cancel-delete-user-button'
                          onClick={this.closeModal}
                          className='usa-button usa-button--outline'
                        >
                          {t('Cancel')}
                        </button>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            )}
            {unlinkOrgModalOpen && (
              <>
                <button
                  onClick={this.handleUnlinkOrganization}
                  className='confirm-suspend-button usa-button usa-button--secondary margin-right-4'
                >
                  {t('Unlink my account')}
                </button>
                <button onClick={this.closeModal} className='usa-button usa-button--outline'>
                  {t('Cancel')}
                </button>
              </>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(observer(IndividualAccount));
