import React, {useState} from 'react';
import {Formik} from 'formik';
import {observer} from 'mobx-react';
import {Redirect} from 'react-router-dom';
import * as Yup from 'yup';
import Filter from 'bad-words';
import {Trans, useTranslation} from 'react-i18next';
import moment from 'moment';
import {Link} from 'react-router-dom';

import RequestService from '../../services/RequestService';
import config from '../../config';
import {passwordRequirements, registerSuccessString} from '../register/Register';
import {orgApplySuccess} from '../register/OrganizationApplication';
import FormInput from '../../components/Formik/FormInput';
import {ACCOUNT_LOCKED_PERIOD_MINUTES} from '../../stores/AuthStore';
import LanguageSelector from '../../components/Buttons/LanguageSelector';
import MandatoryDisclosure from '../MandatoryDisclosure';

const filter = new Filter();

const loginSchema = Yup.object().shape({
  loginEmail: Yup.string()
    .test(
      "Vulgarity",
      "This is not a valid value.",
      (value) => !filter.isProfane(value)
    )
    .email("Must enter a valid email address.")
    .min(2, "Email must be at least two characters.")
    .max(50, "Email must be at most 50 characters.")
    .required("Email is required."),
  password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/,
      passwordRequirements
    )
    .required("Password is required."),
});

const accountLocked = (authStore) => {
  if (!authStore.accountLocked) return false;
  if (moment(authStore.accountLocked).diff(moment(), 'hours') >= ACCOUNT_LOCKED_PERIOD_MINUTES / 60) {
    authStore.resetAccountLocked();
    return false;
  }
  return true;
};

const login = (email, password, authStore, history, afterLogin, actions) => {
  !accountLocked(authStore) &&
    RequestService.post(
      `${config.apiGateway.URL}/login`,
      {user: {email: email, password: password}},
      (response) => {
        if (response.data && response.data.id) {
          authStore.storeAuth(
            response.data.email,
            response.data.first_name,
            response.data.last_name,
            response.data.id,
            response.data.account_type,
            response.data.organization_id,
            response.data.primary_organization_account,
            response.headers.authorization,
          );
          afterLogin ? afterLogin() : history.push('/account');
        } else {
          authStore.loginFailure();
          authStore.passwordFailure = true;
          authStore.emailFailure = false;
          actions.setSubmitting(false);
        }
      },
      (error) => {
        authStore.loginFailure();
        authStore.passwordFailure = true;
        authStore.emailFailure = false;
        actions.setSubmitting(false);
      },
    );
};

const passwordFailure = () => (
  <div className='alert full-width text-align-center'>
    <p>
      <Trans i18nKey='login.failure'>The email and/or password you entered is incorrect.</Trans>
    </p>
  </div>
);

const displayAlert = (message = 'message', i18Key = 'login.inactivity', type = 'warning') => (
  <div className={`usa-alert usa-alert--${type} margin-left-2 margin-right-2`}>
    <div className='usa-alert__body'>
      <p className='usa-alert__text'>
        <Trans i18nKey={i18Key}>{message}</Trans>
      </p>
    </div>
  </div>
);

const inactivityLogoutWarning = () => {
  const message = 'You have been logged out due to inactivity. You can log back in below.';
  return displayAlert(message, 'login.inactivity', 'warning');
};

const registrationWasSuccessful = () => {
  const message = 'You successfully created your account. Please login below.';
  return displayAlert(message, 'login.registrationSuccess', 'success');
};

const orgApplyWasSuccessful = () => {
  const message =
    'Success! Thank you for submitting your request! You should hear back within 5 business days. Remember, no one can link their account to yours until we approve your request. Check your email.';
  return displayAlert(message, 'login.orgApplySuccess', 'success');
};

const accountLockedWarning = () => {
  const message = 'Your account has been locked for 2 hours due to 5 unsuccessful login attempts.';
  return displayAlert(message, 'login.accountLocked', 'warning');
};

const accountDeleteSuccess = () => {
  const message = 'Your account was successfully deleted.';
  return displayAlert(message, 'login.accountDeleteSuccess', 'success');
};

const accountDeleteFail = () => {
  const message = 'Unable to delete your account. Login and try again.';
  return displayAlert(message, 'login.accountDeleteFail', 'error');
};

const LoginForm = observer(({formikProps, ...props}) => {
  const [showMandatoryStatements, setShowMandatoryStatements] = useState(false);
  const {t} = useTranslation();

  const showInactivityLogoutWarning = sessionStorage.getItem('inactivityLogout');
  const accountDeleted = sessionStorage.getItem('accountDeleted');

  const seen = accountDeleted !== null && sessionStorage.getItem('lsSeen');
  const showAccountDeleteSuccess = !seen && accountDeleted === 'success';
  const showAccountDeleteFail = !seen && accountDeleted === 'failed';

  if (accountDeleted !== null) {
    sessionStorage.setItem('lsSeen', true);
  }

  if (seen) {
    sessionStorage.removeItem('accountDeleted');
    sessionStorage.removeItem('lsSeen');
  }

  return (
    <form className='reg-form padding-bottom-4'>
      {showInactivityLogoutWarning && inactivityLogoutWarning()}
      {props.authStore.accountLocked && accountLockedWarning()}
      {document.location.search === registerSuccessString && registrationWasSuccessful()}
      {document.location.search === orgApplySuccess && orgApplyWasSuccessful()}
      {showAccountDeleteSuccess && accountDeleteSuccess()}
      {showAccountDeleteFail && accountDeleteFail()}
      <fieldset>
        <div className='padding-left-5 padding-right-5 text-align-left'>
          {props.loginMessage || <h1 className='margin-bottom-105 margin-top-0'>{t(`Login`)}</h1>}
          <div className='text-center'>
            <LanguageSelector {...props} />
          </div>
          <div>
            <p>
              <Trans i18nKey='login.message1'>You can play and learn without an account.</Trans>
            </p>
            <p>
              <Trans i18nKey='login.message2'>
                However, <b>to earn certificates or save your progress</b>, you do need an account. And, always login to
                play the Games.
              </Trans>
            </p>
            <p>
              <Trans i18nKey='login.message2a'>To use the Resources, you don’t need an account or to login.</Trans>
            </p>
          </div>
          <div>
            <p className='font-size-15'>
              <Trans i18nKey='login.new'>
                New to <i style={{paddingRight: '.5rem'}}>How Money Smart are You?</i>
              </Trans>
              <Link className='link-btn font-size-15 text-align-left' to='/register'>
                <Trans i18nKey='login.create'>Create an account</Trans>
              </Link>
            </p>
          </div>
          <div>
            <p className='font-size-15'>
              <Trans i18nKey='login.account'>Already have an account? Login below.</Trans>
            </p>
            <div className='text-align-center'>
              <p>
                <Trans i18nKey='login.message3'>
                  Be sure to enter the same information you used to create your{' '}
                  <i className='text-nowrap'>How Money Smart Are You?</i> account.
                </Trans>
              </p>
              <div className='grid-row text-align-left'>
                <div className='grid-col'>
                  <FormInput formikProps={formikProps} type='email' id='loginEmail' label={'Email'} />
                </div>
                <div className='grid-col'>
                  <FormInput formikProps={formikProps} type='password' id='password' label={'Password'} />
                </div>
              </div>
              <ul>{props.authStore.passwordFailure && passwordFailure()}</ul>
            </div>
          </div>
          <div className='grid-row'>
            <div className='grid-col-6 text-align-right'>
              <button
                id='login'
                onClick={() => setShowMandatoryStatements(true)}
                className='usa-button fdic-button tan-button square-button'
                type='button'
                title={t('Login')}
                disabled={!formikProps.isValid || formikProps.isSubmitting}
              >
                {t('Login')}
              </button>
            </div>
            <div className='grid-col-6 text-align-left padding-left-2 padding-top-05'>
              <Link className='link-btn font-size-15' to='/password-reset'>
                <Trans i18nKey='login.forgot'>Forgot Password?</Trans>
              </Link>
            </div>
          </div>
          <div className='grid-row margin-bottom-2 text-center margin-top-3'>
            <span className='font-size-14 centered'>
              <Link className='link-btn font-size-15 centered' to='/help-center/6'>
                <Trans i18nKey='login.trouble'>Trouble logging in?</Trans>
              </Link>
            </span>
          </div>
          <MandatoryDisclosure
            {...props}
            submit={() => formikProps.handleSubmit()}
            show={showMandatoryStatements}
            setShow={setShowMandatoryStatements}
          />
        </div>
      </fieldset>
    </form>
  );
});

const Login = observer((props) => {
  config.setUniqueTitle('Login');

  if (props.authStore.loggedIn && !props.preventRedirect) {
    return <Redirect to='/account' />;
  }

  return (
    <div className='registration-wrapper centered'>
      <Formik
        validationSchema={loginSchema}
        initialValues={{loginEmail: '', password: ''}}
        onSubmit={(values, actions) =>
          login(values.loginEmail, values.password, props.authStore, props.history, props.afterLogin, actions)
        }
      >
        {(formikProps) => <LoginForm formikProps={formikProps} {...props} />}
      </Formik>
    </div>
  );
});

export default Login;
