import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {Loader} from '../../../components/Loader';
import Modal from 'react-responsive-modal';
import config from '../../../config';
import RequestService from '../../../services/RequestService';

const ForceCertficate = observer(({gamesStore, organizationStore}) => {
  const [navHref, setNavHref] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [modalBody, setModalBody] = useState('');
  const [modalCertificateButton, setModalCertificateButton] = useState('');

  const onModalOpen = () => setModalOpen(true);
  const onModalClose = () => setModalOpen(false);

  // if a new cert is added, we need to wait until the org store finishes its getsessions call
  //  before we try to render the certificate
  if (navHref !== '' && !organizationStore.sessionsLoading) window.location.assign(navHref);
  if (gamesStore.gamesLoading || showLoader) return <Loader />;

  const gameSelectValues = gamesStore.games.map((game) => ({id: game.id, name: game.name}));

  const handleClickCertificateButton = async (href, data) => {
    setModalOpen(false);
    setShowLoader(true);
    if (data.success) {
      await organizationStore.getSessions({});
      setNavHref(href);
    } else {
      window.location.assign(href);
    }
  };

  const handleResponse = (response, setSubmitting, resetForm) => {
    const headerUnsuccessful = <span className='text-red'>Update Unsuccessful</span>;
    const headerSuccessful = <span className='text-green'>Successful Update!</span>;
    const bodySuccessful = (
      <>
        <p>Successfully added the certificate.</p>
        <p>The Player can now view this certificate in their My Account tab when logged in.</p>
      </>
    );
    const bodyUnsuccessful = <p>This game is already complete:</p>;
    const {data} = response;
    if (data.success || data.gameId) {
      const localDateTime = new Date(data.completeDate).toLocaleString();
      const gameObject = gameSelectValues.filter((game) => game.id === data.gameId)[0];
      const header = data.success ? headerSuccessful : headerUnsuccessful;
      const body = data.success ? bodySuccessful : bodyUnsuccessful;
      const href = `/certificate/${data.gameId}/${data.userId}`;
      setModalHeader(header);
      setModalBody(
        <>
          <div>{body}</div>
          <ul>
            <li>Player ID: {data.userId}</li>
            <li>Player Email: {data.userEmail}</li>
            <li>Game Name: {gameObject.name}</li>
            <li>Complete Date: {localDateTime}</li>
          </ul>
        </>,
      );
      setModalCertificateButton(
        <button className='usa-button' onClick={() => handleClickCertificateButton(href, data)}>
          View Certificate
        </button>,
      );
    } else {
      const failedFor = data.userId ? `ID: ${data.userId}` : `Email: ${data.userEmail}`;
      const failMessage = (
        <>
          <p>Unable to find Player {failedFor}</p>
          <p>Please try again.</p>
        </>
      );
      setModalHeader(headerUnsuccessful);
      setModalBody(failMessage);
      setModalCertificateButton(<></>);
    }
    onModalOpen();
    setSubmitting(false);
    resetForm();
  };

  const handleFailedRequest = (error, setSubmitting, resetForm) => {
    // do not display error - unless debug is needed...
    setModalHeader(<span className='text-red'>Update Failed</span>);
    setModalBody('An unknown error occurred. Contact a system admin to troubleshoot.');
    setSubmitting(false);
    resetForm();
  };

  const sendForceCertificateRequest = async (values, setSubmitting, resetForm) => {
    const requestEndpoint = `${config.apiGateway.URL}/game-sessions-admin/force-complete`;
    const body = {userId: values.playerId, userEmail: values.playerEmail, gameId: values.gameId};
    await RequestService.post(
      requestEndpoint,
      body,
      (response) => handleResponse(response, setSubmitting, resetForm),
      (response) => handleFailedRequest(response, setSubmitting, resetForm),
    );
  };

  const formValidation = (values) => {
    const errors = {};
    const idOrEmailMessage = 'Enter a Player ID or a Player Email.';
    let validId = values.playerId > 0;
    let validEmail = values.playerEmail.length > 6;
    if (!validId && !validEmail) {
      if (!validId) {
        errors.playerId = idOrEmailMessage;
      }
      if (!validEmail) {
        errors.playerEmail = idOrEmailMessage;
      }
    }
    if (values.gameId < 1 || values.gameId > 14) {
      errors.gameId = 'Please select a game.';
    }
    return errors;
  };

  return (
    <div className='force-certificate-component'>
      <div>
        <h1>Manually Award Game Certificate</h1>
        <p>
          Enter a Player ID <strong>or</strong> a Player Email <strong>and</strong> select a Game to manually award a
          game certificate. The player must have an existing account for certificate(s) to be added. Submit the form
          multiple times if you need multiple certifcates added.
        </p>
        <p>
          <strong>NOTE: </strong>
          <em>
            If both Player ID and Player Email fields are filled in, the application will only attempt to match by the
            Player ID.
          </em>
        </p>
      </div>
      <Formik
        initialValues={{playerId: '', playerEmail: '', gameId: ''}}
        validate={formValidation}
        onSubmit={async (values, {setSubmitting, resetForm}) => {
          await sendForceCertificateRequest(values, setSubmitting, resetForm);
        }}
      >
        {({isSubmitting, isValid}) => (
          <Form className='usa-form usa-form--large'>
            <fieldset className='usa-fieldset'>
              <label className='usa-label force-cert-label'>
                Player ID:
                <Field type='number' name='playerId' className='usa-input' />
                <ErrorMessage name='playerId' component='div' className='usa-error-message' />
              </label>
              <div className='text-bold font-size-1-5rem margin-top-1'>OR</div>
              <label className='usa-label force-cert-label'>
                Player Email:
                <Field type='email' name='playerEmail' className='usa-input' />
                <ErrorMessage name='playerEmail' component='div' className='usa-error-message' />
              </label>
              <div className='text-bold font-size-1-5rem margin-top-1'>AND</div>
              <label className='usa-label force-cert-label'>
                Game:
                <Field component='select' name='gameId' className='usa-input'>
                  <option key={0} value=''>
                    -- Select a game --
                  </option>
                  {gameSelectValues.map((game) => (
                    <option key={game.id} value={game.id}>
                      {game.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name='gameId' component='div' className='usa-error-message' />
              </label>
              <button
                type='submit'
                className='usa-button usa-button--force-add-submit display-block'
                disabled={isSubmitting || !isValid}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </fieldset>
          </Form>
        )}
      </Formik>
      <Modal
        open={modalOpen}
        onClose={onModalClose}
        center
        ariaLabelledby='modal-title'
        ariaDescribedby='modal-description'
        closeIconId='modal-close-x-button'
      >
        <div id='force-complete-modal'>
          <h4 id='modal-title'>{modalHeader}</h4>
          <div id='modal-description' className='margin-bottom-6'>
            {modalBody}
          </div>
          <button className='usa-button usa-button--secondary' onClick={onModalClose}>
            Close
          </button>
          {modalCertificateButton}
        </div>
      </Modal>
    </div>
  );
});

export default ForceCertficate;
