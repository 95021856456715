import React, {Component} from 'react';
import {Accordion, AccordionRow, AccordionTitle, AccordionContent} from '../../components/Accordion';
import Loader from '../../components/Loader/Loader';
import {observer} from 'mobx-react';
import EditableContent from '../../components/EditableContent';
import config from '../../config';
import NewGlossaryItem from './NewGlossaryItem';
import {withTranslation, Trans} from 'react-i18next';
import CollapseAllSwitch from '../../components/Accordion/CollapseAllSwitch';

import LanguageSelector from '../../components/Buttons/LanguageSelector';
import GlossaryToPdfButton from './GlossaryToPdfButton';

import glossaryFolks from '../../assets/images/glossary-folks.png';

let letters = [...Array(26)].map((val, i) => String.fromCharCode(i + 65));
letters.unshift('#');

class Glossary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchField: '',
      allOpen: false,
      preventLetterTabbing: false,
      filterApplied: false,
    };
    this.props.authStore.isEditor && this.props.glossaryItemsStore.resetGlossaryItemsStore();
  }

  componentDidMount() {
    this.props.glossaryItemsStore.getGlossaryItems();
    config.setUniqueTitle('Glossary');
    // track ourselves so we can clear the search on lang switching
    this.lang = this.props.i18n.language;
  }

  componentDidUpdate() {
    if (this.lang !== this.props.i18n.language) {
      this.lang = this.props.i18n.language;
      this.clearSearch(true);
    }
  }

  isSpanish = () => {
    const {i18n} = this.props;
    return i18n.language === 'es';
  };

  compareGlossaryItems = (a, b) => {
    if (this.isSpanish()) {
      if (a.name_es < b.name_es) return -1;
      if (a.name_es > b.name_es) return 1;
      return 0;
    }
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  };

  getSortedGlossaryItems = () => {
    const glossaryItems = this.props.glossaryItemsStore.glossaryItems.slice();
    return glossaryItems.sort(this.compareGlossaryItems);
  };

  renderGlossaryItems = () => {
    const sortedGlossaryItems = this.getSortedGlossaryItems();
    return (
      <Accordion>
        {sortedGlossaryItems.map((glossaryItem, index) => (
          <AccordionRow
            key={`glossary-item-${index}`}
            forceOpen={this.state.allOpen || this.props.authStore.isEditor}
            title={`Glossary ${glossaryItem.name} button`}
          >
            <AccordionTitle>
              {this.props.authStore.isEditor ? (
                <div className='grid-row'>
                  <div className='grid-col-6'>
                    <EditableContent
                      initialContent={
                        this.props.i18n.language === 'es'
                          ? glossaryItem.name_es || glossaryItem.name
                          : glossaryItem.name
                      }
                      fieldName={this.props.i18n.language === 'es' ? 'name_es' : 'name'}
                      updateEndpoint={`${config.apiGateway.URL}/glossary-items/${glossaryItem.id}`}
                      canEdit={this.props.authStore.isEditor}
                      htmlType='span'
                      whiteButton
                      canDelete
                      deleteEndpoint={`${config.apiGateway.URL}/glossary-items/${glossaryItem.id}`}
                      deleteCallback={() => window.location.reload()}
                    />
                  </div>
                </div>
              ) : (
                <p className='no-margin'>
                  {this.props.i18n.language === 'es' ? glossaryItem.name_es || glossaryItem.name : glossaryItem.name}
                </p>
              )}
            </AccordionTitle>
            <AccordionContent>
              {this.props.authStore.isEditor ? (
                <EditableContent
                  initialContent={
                    this.props.i18n.language === 'es'
                      ? glossaryItem.details_es || glossaryItem.details
                      : glossaryItem.details
                  }
                  fieldName={this.props.i18n.language === 'es' ? 'details_es' : 'details'}
                  updateEndpoint={`${config.apiGateway.URL}/glossary-items/${glossaryItem.id}`}
                  canEdit={this.props.authStore.isEditor}
                  htmlType='span'
                  richText
                />
              ) : (
                React.createElement('p', {
                  className: 'no-margin glossary-details',
                  dangerouslySetInnerHTML: {
                    __html:
                      this.props.i18n.language === 'es'
                        ? glossaryItem.details_es || glossaryItem.details
                        : glossaryItem.details,
                  },
                })
              )}
            </AccordionContent>
          </AccordionRow>
        ))}
      </Accordion>
    );
  };

  liveSearch = (event) => {
    event.preventDefault();
    this.props.glossaryItemsStore.filterGlossaryItems(this.state.searchField, this.isSpanish());
  };

  clearSearch = (clearInput, event) => {
    !!event && event.preventDefault();
    this.props.glossaryItemsStore.filterGlossaryItems('');
    const searchField = !!clearInput ? '' : this.state.searchField;
    this.setState({preventLetterTabbing: false, filterApplied: false, searchField});
  };

  buttonSearch = (letter) => {
    const firstLetterResults = this.props.glossaryItemsStore.searchFirstLetter(letter, this.isSpanish());
    return (
      <a
        tabIndex={!this.state.preventLetterTabbing && firstLetterResults.length > 0 ? '0' : '-1'}
        href='/'
        className={`letter-search ${firstLetterResults.length > 0 ? 'active' : ''} ${letter}`}
        onClick={(e) => {
          e.preventDefault();
          firstLetterResults.length > 0 && (this.props.glossaryItemsStore.glossaryItems = firstLetterResults);
          this.setState({preventLetterTabbing: true, filterApplied: letter});
        }}
        onBlur={() => this.setState({preventLetterTabbing: false})}
      >
        {letter}
      </a>
    );
  };

  handleSearchInputChange = (e) => {
    if (e.target.value === '') {
      this.clearSearch(true, e);
    }
    this.setState({searchField: e.target.value});
  };

  render() {
    const {t} = this.props;
    return (
      <div className='glossary-wrapper margin-bottom-2'>
        <h1 className='margin-bottom-0'>
          <Trans i18nKey='glossary.title'>Glossary</Trans>
        </h1>
        <LanguageSelector {...this.props} />
        <div className='glossary-content'>
          <div className='search-info full-width'>
            <p>
              <Trans i18nKey='glossary.details'>
                We explain terms as we use them in the <i>How Money Smart Are You?</i> games and resources. Our
                explanations do not take the place of lengthier, more complete definitions you may find in a financial
                dictionary, by searching for the term at
                <a href='https://www.usa.gov/' title='USA.gov'>
                  USA.gov
                </a>
                , or from another source. Clicking on external links will take you outside of
                <i>How Money Smart Are You?</i> and the
                <a title='FDIC.gov' href='https://www.fdic.gov/'>
                  FDIC.gov
                </a>
                website.
              </Trans>
            </p>
            <GlossaryToPdfButton glossaryItems={this.getSortedGlossaryItems()} topMargin={0} />
            {!this.props.authStore.isEditor && (
              <div>
                <div className='grid-col-12'>
                  <p className='no-margin'>
                    <Trans i18nKey='glossary.search'>Search the glossary</Trans>
                  </p>
                </div>
                <form className='usa-search usa-search--small '>
                  <div role='search'>
                    <label className='usa-sr-only' htmlFor='extended-search-field-small'>
                      {t('glossary.searchButton')}
                    </label>
                    <input
                      onChange={this.handleSearchInputChange}
                      value={this.state.searchField}
                      className='usa-input search-input'
                      id='extended-search-field-small'
                      type='search'
                      name='search'
                      placeholder=''
                    />
                    <button className='usa-button search-button' onClick={this.liveSearch} type='submit' title={t('glossary.searchButton')}>
                      <span className='usa-search'>
                        <Trans i18nKey='glossary.searchButton'>Search</Trans>
                      </span>
                    </button>
                  </div>
                </form>
                <div className='grid-row'>
                  <button
                    onClick={(e) => this.clearSearch(true, e)}
                    className='usa-button usa-button--unstyled fdic-button--link margin-top-1'
                    title={t('glossary.clearSearch')}
                  >
                    <Trans i18nKey='glossary.clearSearch'>Clear search</Trans>
                  </button>
                </div>
                {this.props.glossaryItemsStore.glossaryItems.length > 0 && (
                  <div>
                    <CollapseAllSwitch
                      checked={this.state.allOpen}
                      onChange={() => this.setState({allOpen: !this.state.allOpen})}
                    />
                    <div className='grid-row'>
                      <div className='text-center'>
                        <span className='search-title'>
                          <Trans i18nKey='glossary.filterAlpha'>Select a letter to filter alphabetically.</Trans>
                        </span>
                        <ul>
                          {letters.map((letter) => (
                            <li key={letter}>{this.buttonSearch(letter)}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                    {this.state.filterApplied && (
                      <div className='grid-row'>
                        <button
                          onClick={(e) => this.clearSearch(false, e)}
                          className='usa-button usa-button--unstyled fdic-button--link margin-bottom-1'
                        >
                          {`${t('Clear filter:')} “${t('Begins with')} ${this.state.filterApplied}”`}
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            {this.props.authStore.isEditor && <NewGlossaryItem glossaryItemsStore={this.props.glossaryItemsStore} />}
          </div>
          <div className='cartoon'>
            <img
              className={`${this.props.glossaryItemsStore.glossaryItems.length === 0 && 'empty-display'}`}
              src={glossaryFolks}
              alt='Money Smart characters'
            />
          </div>
        </div>
        {this.props.glossaryItemsStore.glossaryItemsLoading ? (
          <Loader loadingText='Loading Glossary Items' />
        ) : this.props.glossaryItemsStore.glossaryItems.length > 0 ? (
          this.renderGlossaryItems()
        ) : (
          <h3 className='no-margin'>
            <Trans i18nKey='glossary.searchNoMatch'>Your search did not match any glossary items.</Trans>
          </h3>
        )}
      </div>
    );
  }
}

export default withTranslation()(observer(Glossary));
