import React from 'react';
import {
  BarChart,
  Bar,
  ReferenceLine,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Text,
} from 'recharts';
import moment from 'moment';
import {Formik, Form, Field} from 'formik';
import * as Yup from 'yup';

import {reorderDomForRechartAccessibility} from '../../util';

const formatMonth = (date) => moment(date).format('MMMM YYYY');

const defaultStartDate = moment("202109").format('YYYY-MM');

let defaultEndDate = moment().format('YYYY-MM');

defaultEndDate = defaultEndDate < defaultStartDate ? defaultStartDate : defaultEndDate;

const createEmptyHistory = (start) => {
  let empty = {};
  let month = moment(start);
  while (month < moment()) {
    empty[formatMonth(month)] = 0;
    month.add(1, 'M');
  }
  return empty;
};

const mapUserHistory = (userHistory) => {
  const emptyHistory = createEmptyHistory(Object.keys(userHistory)[0]);
  Object.keys(userHistory).forEach(
    (month) => (emptyHistory[formatMonth(month)] = userHistory[month].length)
  );
  let history = Object.keys(emptyHistory).map((month) => ({
    name: month,
    'New Players': emptyHistory[month],
  }));
  return history;
};

const getMax = (userHistory) => {
  let max = 0;
  Object.keys(userHistory).forEach((key) => {
    if (userHistory[key].length > max) max = userHistory[key].length;
  });
  return max;
};

const displayChartTitle = (startDate, endDate) => {
  let title = `Number of new players per month ${
    startDate && endDate && 'from'
  }`;
  let dateRange = `${moment(startDate).format('MMMM YYYY')} to ${moment(
    endDate
  ).format('MMMM YYYY')}`;
  return (
    <h4 className='margin-bottom-2'>
      {title}
      <br />
      {startDate && endDate && dateRange}
    </h4>
  );
};
class CustomizedAxisTick extends React.PureComponent {
  render() {
    const {x, y, payload} = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor='end'
          fill='#666'
          transform='rotate(-45)'
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

const filterSchema = Yup.object().shape({
  endDate: Yup.date().when(
    'startDate',
    (startDate, schema) =>
      startDate && schema.min(startDate, 'Start date must be before end date.')
  ),
});

export default class Example extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userHistory: props.userHistory,
      appliedEndDate: defaultEndDate,
      appliedStartDate: defaultStartDate,
    };
  }

  componentDidMount() {
    this.applyFilter(this.state.appliedStartDate, this.state.appliedEndDate);
    reorderDomForRechartAccessibility();
  }

  componentDidUpdate() {
    reorderDomForRechartAccessibility();
  }

  applyFilter = (startDate, endDate) => {
    const filtered =
      startDate && endDate
        ? Object.keys(this.props.userHistory)
          .filter(
            (key) =>
              moment.parseZone(key) <=
                  moment.parseZone(endDate).add(1, 'month') &&
                moment.parseZone(key) > moment.parseZone(startDate)
          )
          .reduce((obj, key) => {
            obj[key] = this.props.userHistory[key];
            return obj;
          }, {})
        : this.props.userHistory;
    this.setState({
      userHistory: filtered,
      appliedStartDate: startDate,
      appliedEndDate: endDate,
    });
  };

  render() {
    let max = getMax(this.state.userHistory);
    return (
      <div>
        <div className='padding-left-9'>
          {displayChartTitle(
            this.state.appliedStartDate,
            this.state.appliedEndDate
          )}
        </div>
        <div className='grid-row margin-bottom-2 hide-print'>
          <Formik
            validationSchema={filterSchema}
            initialValues={{
              startDate: this.state.appliedStartDate,
              endDate: this.state.appliedEndDate,
            }}
            onSubmit={(values) =>
              this.applyFilter(values.startDate, values.endDate)
            }
          >
            {(formikProps) => (
              <Form className='full-width grid-row text-align-left margin-bottom-2 padding-left-9'>
                <div className='grid-row full-width margin-bottom-2'>
                  <div className='grid-col-4 padding-right-2'>
                    <div className='grid-row'>
                      <label
                        htmlFor='startDate'
                        className='color-primary font-size-14'
                      >
                        Start month:
                      </label>
                    </div>
                    <div className='grid-row'>
                      <Field
                        title='Enter start date'
                        name='startDate'
                        type='month'
                        className={`${
                          formikProps.touched.endDate &&
                          formikProps.errors.endDate &&
                          'alert'
                        } fdic-input mobile-full-width start-date-input`}
                      />
                    </div>
                  </div>
                  <div className='grid-col-6'>
                    <div className='grid-row'>
                      <label
                        htmlFor='endDate'
                        className='color-primary font-size-14'
                      >
                        End month:
                      </label>
                    </div>
                    <div className='grid-row'>
                      <Field
                        title='Enter end date'
                        name='endDate'
                        type='month'
                        className={`${
                          formikProps.touched.endDate &&
                          formikProps.errors.endDate &&
                          'alert'
                        } fdic-input mobile-full-width start-date-input`}
                      />
                    </div>
                  </div>
                </div>
                <div className='grid-col-3'>
                  <button
                    className='usa-button full-width apply-filter'
                    onClick={formikProps.handleSubmit}
                  >
                    Apply Filter
                  </button>
                </div>
                {formikProps.touched.endDate && formikProps.errors.endDate && (
                  <div className='grid-row alert full-width margin-top-105'>
                    {formikProps.errors.endDate}
                  </div>
                )}
              </Form>
            )}
          </Formik>
        </div>
        {max > 0 ? (
          <div className='barchart-container'>
            <BarChart
              aria-hidden="false"
              width={600}
              height={550}
              data={mapUserHistory(this.state.userHistory)}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 0,
              }}
            >
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis
                dataKey='name'
                tick={<CustomizedAxisTick />}
                height={120}
              />
              <YAxis
                interval={max > 4 ? 0 : 3}
                label={
                  <Text x={0} y={0} dx={40} dy={300} offset={0} angle={-90}>
                    Number of New Players
                  </Text>
                }
              />
              <Tooltip />
              <Legend
                verticalAlign='top'
                wrapperStyle={{lineHeight: '40px'}}
              />
              <ReferenceLine y={0} stroke='#000' />
              <Bar
                dataKey='New Players'
                fill='#82ca9d'
                label={{position: 'top'}}
              />
            </BarChart>
            <div
              className='barchart-data'
              style={{height: 0, overflow: 'hidden'}}
            >
              <ul>
                {mapUserHistory(this.state.userHistory).map((d, i) => (
                  <li key={i}>
                    New Players: {d['New Players']}, Date: {d.name}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          <div className='padding-left-9'>
            <h4 className='no-data-warning'>
              No new players within this date range
            </h4>
          </div>
        )}
      </div>
    );
  }
}

export {CustomizedAxisTick};
