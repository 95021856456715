import React from 'react';
import moment from 'moment';

const TemporaryOrganizationAccount = (props) => {
  return(
    <div className="temporary-organization-account">
      <h2>{props.organizationStore.organization.name}</h2>
      <div className="grid-col-10">
        <p>Date Submitted: <b>{moment(props.organizationStore.organization.application_date).parseZone().format('LL')}</b></p>
        <p>If you have not heard back from us within 5 business days after submitting your information, please let us know by emailing <a href="mailto: communityaffairs@fdic.gov">communityaffairs@fdic.gov</a>.</p>
      </div>
    </div>
  );
};

export default TemporaryOrganizationAccount;