async function OrganizationPDF(orgStore, moment) {
  const {default: pdfMake} = await import('pdfmake/build/pdfmake');
  const {default: pdfFonts} = await import('pdfmake/build/vfs_fonts');

  const {applications, approvedOrganizations, suspendedOrganizations, deniedOrganizations} = orgStore;
  const orgCategories = [applications, approvedOrganizations, suspendedOrganizations, deniedOrganizations];
  const orgCategoryTitles = ['Requests for Accounts', 'Approved Accounts', 'Suspended Accounts', 'Denied Accounts'];
  const colors = ['#7F7141', '#FFF', '#FFF', '#FFF'];
  const fillColors = ['#DDDDDD', '#003256', '#F36946', '#BA0C2F'];

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const getFormattedDate = (date) => {
    return moment(date).parseZone().format('LL');
  };

  const labelAndValue = (label, value) => {
    return [{text: `${label}: `, bold: true}, value];
  };

  const buildOrgCategory = (section, pageBreak = false) => {
    const orgCat = {
      text: section,
      bold: true,
      italics: true,
      fontSize: 20,
      color: '#333',
      alignment: 'left',
      margin: [0, 0, 0, 10],
    };
    if (pageBreak) {
      orgCat.pageBreak = 'before';
    }
    return orgCat;
  };

  const buildOrgData = (org, color = '#000', fillColor = '#FFF') => {
    const left = [];
    const right = [];

    left.push({text: labelAndValue('Display name', org.name)});
    left.push({text: labelAndValue('Applied on', getFormattedDate(org.application_date))});
    const primaryIndex = org.account_owners.findIndex((ao) => ao.primary === true);
    if (primaryIndex !== -1) {
      const primary = org.account_owners[primaryIndex];
      const displayName = `${primary.first_name} ${primary.last_name}`;
      left.push({text: labelAndValue('Primary contact name', displayName)});
      left.push({text: labelAndValue('Primary contact email', primary.email)});
    }
    if (org.account_owners.lenght > 1) {
      let firstAdditionalPushed = false;
      for (let i = 0; i < org.account_owners.length; i++) {
        if (i !== primaryIndex) {
          const additional = org.account_owners[i];
          const displayName = `${additional.first_name} ${additional.last_name}`;
          if (!firstAdditionalPushed) {
            left.push({text: labelAndValue('Additional contacts', displayName)});
          } else {
            left.push({text: displayName, margin: [110, 0, 0, 0]});
          }
        }
      }
    } else {
      left.push({text: labelAndValue('Additional contacts', 'N/A')});
    }
    left.push({text: labelAndValue('Phone number', org.phone_number)});
    left.push({text: labelAndValue('Address', org.address_line_one)});
    left.push({text: org.address_line_two, margin: [51, 0, 0, 0]});
    left.push({text: `${org.city}, ${org.state} ${org.zip_code}`, margin: [51, 0, 0, 12]});

    right.push({text: labelAndValue('FDIC notes', '')});
    for (let i = 0; i < org.notes.length; i++) {
      const note = org.notes[i];
      right.push({
        text: ['• ', note.details, {text: getFormattedDate(note.date), italics: true}],
        margin: [10, 0, 0, 0],
      });
    }

    const content = [
      {
        table: {
          widths: [280, 220],
          headerRows: 1,
          body: [
            [
              {
                text: org.name,
                fontSize: 16,
                bold: true,
                margin: [8, 5, 0, 2],
                color,
                fillColor,
                colSpan: 2,
              },
              {},
            ],
            [{stack: left}, {stack: right}],
          ],
        },
        layout: 'lightHorizontalLines',
      },
    ];

    return content;
  };

  const dd = {
    info: {
      title: 'money-smart-organization-report',
      author: 'FDIC',
      subject: 'Money Smart Organization Report',
      creator: 'FDIC',
      producer: 'FDIC',
    },
    pageSize: 'LETTER',
    pageMargins: 50,
    footer: function (currentPage, pageCount) {
      return {
        text: `Page ${currentPage.toString()} of ${pageCount}`,
        margin: [0, 10, 50, 0],
        alignment: 'right',
        color: '#999999',
        fontSize: 12,
      };
    },
    defaultStyle: {
      lineHeight: 1.15,
    },
  };

  dd.content = [];
  dd.content.push({
    text: ['FDIC’s ', {text: 'How Money Smart Are You?', italics: true}, ' ', 'Organization Report'],
    bold: true,
    fontSize: 20,
    alignment: 'center',
    margin: [0, -20, 0, 10],
    color: '#999999',
  });

  for (let i = 0; i < orgCategories.length; i++) {
    const orgs = orgCategories[i];
    const title = orgCategoryTitles[i];
    const color = colors[i];
    const fillColor = fillColors[i];
    const pageBreak = i !== 0;
    dd.content.push(buildOrgCategory(`${title} (${orgs.length})`, pageBreak));
    if (orgs && orgs.length > 0) {
      orgs.forEach((org) => {
        dd.content.push({
          layout: 'noBorders',
          table: {
            headerRows: 0,
            body: [buildOrgData(org, color, fillColor)],
            dontBreakRows: true,
          },
        });
      });
    }
  }

  pdfMake.tableLayouts = {
    exampleLayout: {
      hLineWidth: function (i, node) {
        return 0;
      },
      vLineWidth: function (i) {
        return 0;
      },
      hLineColor: function (i) {
        return i === 1 ? 'black' : '#aaa';
      },
      paddingLeft: function (i) {
        return 0;
      },
      paddingRight: function (i, node) {
        return 0;
      },
    },
  };

  return pdfMake.createPdf(dd).download('money-smart-organization-report.pdf');
}

export default OrganizationPDF;
