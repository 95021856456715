import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {Redirect, useHistory} from 'react-router-dom';
import moment from 'moment';
import Modal from 'react-responsive-modal';
import {Link} from 'react-router-dom';

import {Loader} from '../../../components/Loader';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import UserHistoryChart from './UserHistoryChart';
import PrintButton from '../../../components/Buttons/PrintButton';
import AddressShowUpdate from './AddressShowUpdate';
import AccountSubHeader from '../AccountSubHeader';
import LinkButton from '../../../components/Buttons/LinkButton';
import IndividualGamePlayInfo from '../individual/IndividualGamePlayInfo';
import RequestService from '../../../services/RequestService';
import config from '../../../config';
import {downloadCsvAsFile} from '../../../containers/util';

const positiveEvents = ['approved', 'unsuspended'];

const mapAccountOwners = (users) => {
  return users.map(
    (user, index) => `${user.email}${user.primary ? ' (primary)' : ''}${index < users.length - 1 ? ', ' : ''}`,
  );
};

const convertResponseDataToCsv = (playerStats) => {
  const csvRows = [`Number of Linked Players:,${Object.keys(playerStats).length}`];
  const headerRow = [
    'Player ID',
    'Date Linked',
    'First Name',
    'Last Name',
    'Email Address',
    'Won all 14',
    'Borrowing',
    'Building',
    'Buying',
    'Credit',
    'Disasters',
    'Housing',
    'Debt',
    'Protecting',
    'CCards',
    'Banking',
    'Income',
    'Values',
    'Saving',
    'Spending',
  ];
  csvRows.push(headerRow.join(','));

  const numberOfGames = 14;
  Object.keys(playerStats).forEach((id) => {
    const row = [];
    const playerData = playerStats[id];
    const dateLinked = moment(playerData.date_linked).format('L');
    row.push(id, dateLinked, playerData.first_name, playerData.last_name, playerData.email);
    const convertedCompleteValues = [];
    for (let i = 0; i < numberOfGames; i++) {
      // converted: 1 = not started; 2 = started; 3 = complete
      let convertedStatus = 1;
      const gameStatus = playerData[i + 1];
      if (gameStatus !== undefined) {
        convertedStatus = gameStatus === 1 ? 3 : 2;
      }
      convertedCompleteValues.push(convertedStatus);
    }
    const all14Complete = convertedCompleteValues.reduce((p, v) => p + v, 0) === numberOfGames * 3 ? 'yes' : 'no';
    row.push(all14Complete, ...convertedCompleteValues);
    csvRows.push(row.join(','));
  });

  const csvString = csvRows.join('\n');
  return csvString;
};

const OrganizationDetails = observer((props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [mode, setMode] = useState('');
  const [successfulUnlinkMessage, setSuccessfulUnlinkMessage] = useState('');
  const [unsuccessfulUnlinkMessage, setUnsuccessfulUnlinkMessage] = useState(false);
  const [disableCsvButton, setDisableCsvButton] = useState(false);
  const [disableUnlinkButton, setDisableUnlinkButton] = useState(false);
  const [disableUnlinkAllButton, setDisableUnlinkAllButton] = useState(false);
  const [showChart, setShowChart] = useState(true);
  const [playerIdToUnlink, setPlayerIdToUnlink] = useState(-1);
  const history = useHistory();

  const {organizationStore, match, authStore, gamesStore, gameSessionsStore} = props;
  const orgId = match.params.id || authStore.organizationId;
  organizationStore.getSessions(moment('2021-08-30 00:00:00'), moment(Date.now()), orgId);
  organizationStore.getOrganization(orgId);
  organizationStore.getUserHistory(orgId);
  const {organizationLoading, organizationError, organization} = organizationStore;

  if (organizationLoading) return <Loader />;
  if (organizationError) return <Redirect to='/account' />;

  const currentUserEmail = authStore.email;
  let {
    name,
    display_name,
    id,
    events,
    application_date,
    certificate_count,
    user_count,
    last_user_activity,
    account_owners,
    phone_number,
    address_line_one,
    address_line_two,
    city,
    zip_code,
    state,
  } = organization;
  let primaryAccount = account_owners.filter((user) => user.primary)[0] || account_owners[0];

  const displayCsvLink = authStore.isAdmin || (authStore.isOrganization && authStore.primaryOrganizationAccount);

  const getCsvFile = (playerStats) => {
    const csvData = convertResponseDataToCsv(playerStats);
    const filename = organizationStore.organization.name.toLowerCase().replace(/\W+/g, '-');
    downloadCsvAsFile(csvData, filename);
  };

  const handleCsvClick = () => {
    setDisableCsvButton(true);
    const orgId = authStore.isOrganization ? authStore.organizationId : match.params.id;
    const endpoint = `${config.apiGateway.URL}/organization/${orgId}/player-stats`;
    RequestService.get(
      endpoint,
      (res) => {
        if (res.data.success) {
          getCsvFile(res.data.orgPlayerStats);
        }
        setDisableCsvButton(false);
      },
      (err) => {
        console.error(err);
        setDisableCsvButton(false);
      },
    );
  };

  const callUnlink = () => {
    setModalOpen(false);
    if (unlinkMode) {
      callUnlinkPlayer();
    } else {
      callUnlinkAllPlayers();
    }
  };

  const callUnlinkPlayer = () => {
    setDisableUnlinkButton(true);
    const endpoint = `${config.apiGateway.URL}/update-organization/unlink-player/${playerIdToUnlink}`;
    RequestService.post(
      endpoint,
      {},
      (res) => {
        if (res.data.success) {
          setShowChart(false);
          organizationStore.removeUserFromUserHistory(parseInt(playerIdToUnlink));
          setSuccessfulUnlinkMessage(`Player ID: ${playerIdToUnlink}`);
        } else {
          setUnsuccessfulUnlinkMessage(true);
        }
        setDisableUnlinkButton(false);
        setPlayerIdToUnlink(-1);
        setShowChart(true);
      },
      (err) => {
        console.error(err);
        setDisableUnlinkButton(false);
        setPlayerIdToUnlink(-1);
        setUnsuccessfulUnlinkMessage(true);
      },
    );
  };

  const callUnlinkAllPlayers = () => {
    setDisableUnlinkAllButton(true);
    const orgId = authStore.isOrganization ? authStore.organizationId : match.params.id;
    const endpoint = `${config.apiGateway.URL}/update-organization/${orgId}/unlink-all-players`;
    RequestService.post(
      endpoint,
      {},
      (res) => {
        if (res.data.success) {
          organizationStore.removeAllUsersFromUserHistory();
          setSuccessfulUnlinkMessage('all players');
        } else {
          setUnsuccessfulUnlinkMessage(true);
        }
      },
      (err) => {
        console.error(err);
        setUnsuccessfulUnlinkMessage(true);
      },
    );
  };

  const handleDeleteClick = () => {
    setModalOpen(true);
    setMode('delete');
  };

  const handleMoreCsvInfoButton = () => {
    history.push('/help-center/119');
  };

  const handleUnlinkClick = (userId) => {
    setPlayerIdToUnlink(userId);
    setMode('unlink');
    setModalOpen(true);
  };

  const handleUnlinkAllClick = () => {
    setMode('unlinkAll');
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setPlayerIdToUnlink(-1);
  };

  const handlePlayerIdInput = (e) => {
    if (e.key === 'Backspace' || e.key === 'Delete' || e.key === 'Tab') return;
    if (isNaN(parseInt(e.key))) {
      e.preventDefault();
    }
  };

  const idInUserHistory = () => {
    if (organizationStore.userHistoryLoading) return false;
    const keys = Object.keys(organizationStore.userHistory);
    for (let i = 0; i < keys.length; i++) {
      const users = organizationStore.userHistory[keys[i]];
      for (let j = 0; j < users.length; j++) {
        if (users[j].user_id.toString() === playerIdToUnlink) {
          return true;
        }
      }
    }
    return false;
  };

  const isValidIdToUnlink = idInUserHistory();

  const unlinkMode = mode === 'unlink';
  const unlinkAllMode = mode === 'unlinkAll';
  const deleteMode = mode === 'delete';

  return (
    <div className='organization-details'>
      {authStore.isAdmin && (
        <BreadCrumb
          includeHome
          historyLinks={[
            {
              link: '/account',
              display: 'Dashboard',
            },
            {
              link: '/account/organizations',
              display: 'Organizations',
            },
            {
              link: `/account/organizations/${id}`,
              display: name,
            },
          ]}
        />
      )}

      <h1 className='margin-bottom-0'>{name}</h1>
      <AccountSubHeader h3Text={`Organization Display Name: ${display_name}`} pText={currentUserEmail} />
      <div id='top' className='grid-row'>
        {!authStore.isAdmin && (
          <>
            {/* TODO: Uncomment */}
            {/* <Link to='/account/update'>Update Profile</Link>
            <span> | </span> */}
            <Link className='hide-print' to='/account/change-password'>
              Change password
            </Link>
            <span> | </span>
            <button onClick={handleDeleteClick} className='usa-btn-link'>
              Delete Account
            </button>
            <span> | </span>
            <LinkButton
              id={'your-game-play-information'}
              textJsx={
                <span>
                  <i>Your</i> Game Play Information
                </span>
              }
            />
          </>
        )}
      </div>
      <div className='grid-row margin-bottom-2'>
        <PrintButton printText='Print' />
      </div>

      {successfulUnlinkMessage.length > 0 && (
        <div className='usa-alert usa-alert--success usa-alert--slim margin-bottom-2'>
          <div className='usa-alert__body'>
            <p className='usa-alert__text'>Successfully unlinked {successfulUnlinkMessage}.</p>
          </div>
        </div>
      )}

      {unsuccessfulUnlinkMessage && (
        <div className='usa-alert usa-alert--error usa-alert--slim margin-bottom-2'>
          <div className='usa-alert__body'>
            <p className='usa-alert__text'>Unable to unlink player(s). Please try again.</p>
          </div>
        </div>
      )}

      <div className='grid-row margin-bottom-2'>
        <div className='grid-col-6'>
          <h4 className='no-margin'>Organization details</h4>
          <div className='grid-row'>
            <span>
              <b>Primary contact:</b>{' '}
              {primaryAccount ? `${primaryAccount.first_name} ${primaryAccount.last_name}` : 'None assigned'}
            </span>
          </div>
          <div className='grid-row'>
            <span>
              <b className='no-margin'>{`Email${account_owners.length > 1 ? 's' : ''}`}: </b>
              {mapAccountOwners(account_owners)}
            </span>
          </div>
          <div className='grid-row'>
            <span>
              <b className='no-margin'>Phone number: </b>
              <span>{phone_number}</span>
            </span>
          </div>
          <AddressShowUpdate
            addressLineOne={address_line_one}
            addressLineTwo={address_line_two}
            city={city}
            zipCode={zip_code}
            state={state}
            id={id}
            altText={'edit address'}
            allowEditing={false}
          />
          <div className='grid-row margin-top-1'>
            <button
              onClick={() =>
                history.push(authStore.isAdmin ? `/account/organizations/${match.params.id}/edit` : '/account/edit')
              }
              className='usa-button usa-button--outline margin-top-1'
            >
              {(primaryAccount && currentUserEmail === primaryAccount.email) || authStore.isAdmin
                ? 'Edit and Add Additional Staff'
                : 'Edit'}
            </button>
          </div>
          <div className='grid-row'>
            <button
              onClick={() => history.push('/help-center/115')}
              className='usa-button usa-button--outline margin-top-1'
            >
              Read Updates from FDIC
            </button>
          </div>
        </div>

        <div className='grid-col-6'>
          <h4 className='no-margin'>Player information</h4>
          <div className='grid-row'>
            <span>
              <b className='no-margin'>Total players: </b>
              <span className='total-players'>{user_count}</span>
            </span>
          </div>
          <div className='grid-row'>
            <span>
              <b className='no-margin'>Total certificates: </b>
              {certificate_count}
            </span>
          </div>
          <div className='grid-row'>
            <span>
              <b className='no-margin'>Last player activity: </b>
              {last_user_activity ? moment(last_user_activity).parseZone().format('LL') : 'N/A'}
            </span>
          </div>
          {displayCsvLink && (
            <>
              <div className='grid-row margin-top-1'>
                <span>
                  <button
                    className='usa-button usa-button--outline margin-top-1'
                    onClick={handleCsvClick}
                    disabled={disableCsvButton || user_count === 0}
                  >
                    Download List of Linked Players (.csv)
                  </button>
                </span>
              </div>
              <div className='grid-row'>
                <span>
                  <button className='usa-button usa-button--outline margin-top-1' onClick={handleMoreCsvInfoButton}>
                    Read More About the .csv File
                  </button>
                </span>
              </div>
              {user_count > 0 && (
                <div className='grid-row'>
                  <button
                    className='usa-button usa-button--outline margin-top-1'
                    type='button'
                    onClick={handleUnlinkAllClick}
                    disabled={disableUnlinkAllButton}
                  >
                    Unlink All Players
                  </button>
                  <button
                    className='usa-button usa-button--outline margin-top-1'
                    type='button'
                    onClick={handleUnlinkClick}
                    disabled={disableUnlinkButton}
                  >
                    Unlink Single Player
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className='grid-row'>
        {authStore.isAdmin && (
          <div className='grid-col-4'>
            <table className='usa-table'>
              <thead>
                <tr>
                  <th>Event</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {events
                  .slice()
                  .reverse()
                  .map((event, index) => (
                    <tr key={`event-${index}`}>
                      <td
                        className={
                          positiveEvents.includes(event.type)
                            ? 'background-color-faded-primary'
                            : 'background-color-faded-secondary'
                        }
                      >
                        {event.type.replace(/^\w/, (c) => c.toUpperCase())}
                      </td>
                      <td
                        className={
                          positiveEvents.includes(event.type)
                            ? 'background-color-faded-primary'
                            : 'background-color-faded-secondary'
                        }
                      >
                        {moment(event.date).parseZone().format('LL')}
                      </td>
                    </tr>
                  ))}
                <tr>
                  <td className='background-color-faded-primary'>Applied</td>
                  <td className='background-color-faded-primary'>
                    {moment(application_date).parseZone().format('LL')}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {showChart &&
          !organizationStore.userHistoryLoading &&
          Object.keys(organizationStore.userHistory).length > 0 && (
          <div className='grid-col-8 page-break-inside-avoid'>
            <UserHistoryChart userHistory={organizationStore.userHistory} />
          </div>
        )}
      </div>

      {authStore.isOrganization && (
        <IndividualGamePlayInfo
          authStore={authStore}
          gamesStore={gamesStore}
          gameSessionsStore={gameSessionsStore}
          showBackToTop={user_count > 0}
        />
      )}

      <Modal open={modalOpen} onClose={() => setModalOpen(false)} blockScroll={false}>
        <div className='suspend-warning-modal'>
          <h4>
            {unlinkMode && 'Enter a valid Player ID that is linked to your Organization'}
            {!unlinkMode && 'Are you sure you want to '}
            {unlinkAllMode && 'unlink all players?'}
            {deleteMode && 'delete this account?'}
          </h4>
          {!deleteMode && (
            <div className='margin-bottom-6'>
              {unlinkMode && (
                <label className='usa-label'>
                  Player ID:
                  <input
                    className='usa-input width-15'
                    type='text'
                    onKeyDown={(e) => handlePlayerIdInput(e)}
                    onChange={(e) => setPlayerIdToUnlink(e.target.value)}
                    maxLength={10}
                  />
                </label>
              )}
              <p className='margin-bottom-1'>
                This action will unlink <strong>{unlinkMode ? 'this' : 'all'}</strong> player{unlinkMode ? ' ' : 's '}
                currently linked to your Organization account. This action will not delete{' '}
                {unlinkMode ? 'the player’s' : 'players’'} account and their game play history.
              </p>
              <p className='margin-bottom-1'>
                If they are currently logged in when you take this action, they will remain linked until the next time
                they log in.
              </p>
              <p>
                <strong>You</strong> cannot re-link {unlinkMode ? 'the account' : 'any accounts'}. However,{' '}
                {unlinkMode ? 'the ' : ''}
                <strong>player{unlinkMode ? '' : 's'}</strong> can re-link their account to your Organization account if
                they choose to do so.
              </p>
              {unlinkMode && (
                <p>You can select “Unlink player” only if you entered a valid Player ID linked to your Organization.</p>
              )}
            </div>
          )}
          {deleteMode && (
            <p className='margin-bottom-6'>
              Access to game progress and certificates of completion for players linked to this organization account
              will be lost. Players linked to this account will be unlinked immediately. No one will be able to link to
              this organization account once it is deleted.
            </p>
          )}
          <button
            onClick={() => {
              unlinkMode || unlinkAllMode
                ? callUnlink()
                : organizationStore.deleteOrganization(id, (window.location = '/login'));
            }}
            className='confirm-suspend-button usa-button usa-button--secondary margin-right-4'
            disabled={unlinkMode && !isValidIdToUnlink}
          >
            {unlinkMode ? 'Unlink player' : unlinkAllMode ? 'Unlink all players' : 'Delete this account'}
          </button>
          <button onClick={handleCloseModal} className='usa-button usa-button--outline'>
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
});

export default OrganizationDetails;
