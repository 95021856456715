import React from 'react';
import EditableContent from '../../../components/EditableContent/EditableContent';
import config from '../../../config';
import {useTranslation} from 'react-i18next';

const ProtectCreditHistory = ({strategy, canEdit}) => {
  const {i18n, t} = useTranslation();
  const headers = [t('Strategy'), t('When to Use It'), t('Requirements'), t('Details')];
  const title = `${t('Table')}: ${headers.join(', ')}`;
  return (
    <div className='strategy'>
      <EditableContent
        htmlType='div'
        initialContent={i18n.language === 'es' ? strategy.contentsEs || strategy.contents : strategy.contents}
        fieldName={i18n.language === 'es' ? 'contents_es' : 'contents'}
        updateEndpoint={`${config.apiGateway.URL}/topic-items/${strategy.id}`}
        canEdit={canEdit}
        richText
      />
      <div>
        <table className='usa-table strategy-table' title={title}>
          <thead>
            <tr>
              {headers.map((h, i) => (
                <th key={i} scope='col'>
                  {h}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className='col-2' scope='row'>
                <p>{t('Initial Fraud Alert')}</p>
              </th>
              <td>
                <p>{t('If you are concerned you may become the victim of identity theft or fraud.')}</p>
              </td>
              <td>
                <p>{t('Must provide proof of identity.')}</p>
              </td>
              <td>
                <ul>
                  <li>
                    {t(
                      'Requires creditors and other businesses to take steps to verify your identity before granting credit in your name.'
                    )}
                  </li>
                  <li>
                    {t(
                      'The credit reporting agency you place the initial fraud alert with must notify the other two nationwide credit reporting agencies to place an alert.'
                    )}
                  </li>
                  <li>{t('The alert is free.')}</li>
                  <li>{t('The alert stays in place for one year.')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th scope='row'>
                <p>{t('Active Duty Military Alert')}</p>
              </th>
              <td>
                <p>{t('If you are concerned you may become the victim of identity theft or fraud.')}</p>
              </td>
              <td>
                <p>{t('Must be an active duty member of the military on deployment.')}</p>
              </td>
              <td>
                <ul>
                  <li>
                    {t('Requires creditors to take steps to verify your identity before granting credit in your name.')}
                  </li>
                  <li>
                    {t(
                      'The credit reporting agency you place the active duty military alert with must notify the other two nationwide credit reporting agencies to place an alert.'
                    )}
                  </li>
                  <li>
                    {t(
                      'The alert is free and stays in place for 12 months but can be renewed for the length of your deployment.'
                    )}
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th scope='row'>
                <p>{t('Extended Fraud Alert')}</p>
              </th>
              <td>
                <p>{t('If your identity has been stolen.')}</p>
              </td>
              <td>
                <ul>
                  <li>
                    {t('Must provide proof of identity theft ')}
                    <b>{t('and')}</b>
                    {t(' a copy of an identity theft report.')}
                  </li>
                  <li>
                    {t('You can create an identity theft report at ')}{' '}
                    <a
                      href={
                        i18n.language === 'es' ? 'http://www.robodeidentidad.gov/' : 'http://www.identitytheft.gov/'
                      }
                    >
                      {t('IdentityTheft.gov.')}
                    </a>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>{t('The same as an initial fraud alert except it stays in place for seven years.')}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <th scope='row'>
                <p>{t('Credit Freeze')}</p>
              </th>
              <td>
                <p>{t('If you want more protection than a fraud alert.')}</p>
              </td>
              <td>
                <p>{t('Contact each of the credit reporting agencies where you want to place a credit freeze.')}</p>
              </td>
              <td>
                <ul>
                  <li>{t('You have a legal right to freeze your credit reports.')}</li>
                  <li>{t('A credit freeze prevents access to your credit reports.')}</li>
                  <li>
                    {t(
                      'A credit freeze prevents new accounts for credit from being opened and in some states, stops new accounts for other services such as insurance and utilities.'
                    )}
                  </li>
                  <li>
                    {t(
                      'May take 24 to 48 hours to lift a freeze. If you want to apply for credit, a job, or something else that requires access to your credit reports, you must plan ahead.'
                    )}
                  </li>
                  <li>
                    {t(
                      'There is no cost to place or lift a freeze. To be effective, you must put a freeze at each of the three nationwide credit reporting agencies.'
                    )}
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th scope='row'>
                <p>{t('Credit Lock')}</p>
              </th>
              <td>
                <p>{t('If you want more protection than a fraud alert.')}</p>
              </td>
              <td>
                <p>{t('Contact each of the credit reporting agencies where you want to place a credit lock.')}</p>
              </td>
              <td>
                <ul>
                  <li>
                    {t(
                      'Similar to the credit freeze except this is a credit reporting agency product, not a right provided by law.'
                    )}
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ProtectCreditHistory;
