import React from 'react';
import ResourceFilter from './ResourceFilter';

class ResourceFilterContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryFilterExpanded: false,
      gameFilterExpanded: false,
    };
  }

  componentDidMount() {
    this.addCloseEventListener();
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  addCloseEventListener = () => {
    window.addEventListener('mousedown', this.props.handleMouseDown || this.handleMouseDown);
  };

  handleMouseDown = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.closeFilters();
    }
  };

  toggleExpanded = (filterType) => {
    let state = {};
    state[`${filterType}FilterExpanded`] = !this.state[`${filterType}FilterExpanded`];
    this.setState(state);
    !this.state[`${filterType}FilterExpanded`] && this.closeOtherFilter(filterType);
  };

  closeOtherFilter = (openFilterType) => {
    openFilterType === 'category'
      ? this.setState({gameFilterExpanded: false})
      : this.setState({categoryFilterExpanded: false});
  };

  closeFilters = () => {
    this.setState({
      categoryFilterExpanded: false,
      gameFilterExpanded: false,
    });
  };

  render() {
    return (
      <div className='resource-filter-container grid-row margin-top-2' ref={this.setWrapperRef}>
        <div id='test-hide' onClick={this.closeFilters}></div>
        {this.props.gameCategories && (
          <div className='margin-right-2'>
            <ResourceFilter
              key='category-filter'
              filter={(selected) => this.props.filterCategory(selected)}
              buttonText={'Filter by Category'}
              options={this.props.gameCategories.map((cat) => cat.name)}
              topicName={this.props.topicName}
              selectedOptions={[]}
              expanded={this.state.categoryFilterExpanded}
              toggleExpanded={() => this.toggleExpanded('category')}
            />
          </div>
        )}
        {this.props.games && (
          <ResourceFilter
            key='game-filter'
            filter={(selected) => this.props.filterGame(selected)}
            buttonText={'Filter by Game'}
            options={this.props.gameOptions}
            topicName={this.props.topicName}
            selectedOptions={this.props.getSelectedGames()}
            dynamicallyUpdateSelected
            expanded={this.state.gameFilterExpanded}
            toggleExpanded={() => this.toggleExpanded('game')}
          />
        )}
      </div>
    );
  }
}

export default ResourceFilterContainer;
