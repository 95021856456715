import React from "react";
import {Formik, Form, FieldArray, Field} from "formik";
import Tables from "../utilities/Tables";
import Inputs from "../utilities/Inputs";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation, Translation} from "react-i18next";

const emptyExpense = {expense: "", amount: "", nwo: ""};

const initialValues = {
  week: "",
  sections: {
    Sunday: [emptyExpense],
    Monday: [emptyExpense],
    Tuesday: [emptyExpense],
    Wednesday: [emptyExpense],
    Thursday: [emptyExpense],
    Friday: [emptyExpense],
    Saturday: [emptyExpense],
  },
};

const removeRowButton = (onClick) => (
  <button
    title="Remove Expense"
    type="button"
    className="remove-row-button usa-button float-left"
    onClick={onClick}
  >
    -
  </button>
);

const addRowButton = (onClick) => (
  <button
    title="Add Expense"
    type="button"
    className="add-row-button usa-button float-left"
    onClick={onClick}
  >
    +
  </button>
);

const renderTableSection = (formikProps, key) => (
  <FieldArray
    key={`sections.${key}`}
    name={`sections.${key}`}
    render={(arrayHelpers) => (
      <Translation>
        {(t, {i18n}) => (
          <tbody>
            {formikProps.values.sections[key].map((expense, index) => (
              <tr key={`row-${index}`}>
                <th scope="row">
                  {index === 0 && <b className="margin-right-2">{t(key)}</b>}
                </th>
                <td className="col-5">
                  <div className="grid-row">
                    <div className="grid-col-11">
                      <Field
                        title={`${t('Expense')}: ${t(key)} #${index + 1}`}
                        name={`sections.${key}.${index}.expense`}
                      />
                    </div>
                  </div>
                </td>
                <td className="col-2">
                  <span className="money-input">
                    <Field
                      placeholder="0"
                      step=".01"
                      type="number"
                      name={`sections.${key}.${index}.amount`}
                      title={`${t('Amount')}: ${t(key)} #${index + 1}`}
                    />
                  </span>
                </td>
                <td>
                  <div className="grid-row">
                    <div className="grid-col-11">
                      {Inputs.radioButtonGroup({
                        id: `sections.${key}.${index}.nwo`,
                        value: formikProps.values.sections[key][index].nwo,
                        options: [
                          {value: "N", title: "Need", label: "N"},
                          {value: "W", title: "Want", label: i18n.language === 'es' ? "D" : "W"},
                          {value: "O", title: "Obligation", label: "O"},
                        ],
                      })}
                    </div>
                    <div className="grid-col-1 mobile-full-width">
                      {index > 0 &&
                    removeRowButton(() => arrayHelpers.remove(index))}
                      {index === 0 &&
                    addRowButton(() =>
                      arrayHelpers.insert(
                        formikProps.values.sections[key].length,
                        emptyExpense
                      )
                    )}
                    </div>
                  </div>
                </td>
              </tr>
            ))}

          </tbody>
        )}
      </Translation>
    )}
  />
);

const total = (formikProps) =>
  Object.keys(formikProps.values.sections).reduce(
    (sum, key) =>
      sum +
      formikProps.values.sections[key].reduce(
        (daySum, expense) =>
          !isNaN(expense.amount) && expense.amount !== ""
            ? daySum + expense.amount
            : daySum + 0,
        0
      ),
    0
  );

const renderTotals = (formikProps) => (
  <tbody>
    <tr>
      <th>
        <b tabIndex="0" className="subtotal-row">
          Total
        </b>
      </th>
      <td></td>
      <td>
        <b tabIndex="0" className="subtotal-row">
          ${total(formikProps).toFixed(2).withCommas()}
        </b>
      </td>
      <td></td>
    </tr>
  </tbody>
);

const SpendSaveSharePlan = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  const tableHeaders = [
    t('Day'),
    t('Expense'),
    t('Amount'),
    t('Need (N), Want (W), or Obligation (O)')
  ];
  const tableTitle = `${t('Table')}: ${tableHeaders.join(', ')}`;
  return (
    <Formik initialValues={initialValues}>
      {(formikProps) => (
        <Form>
          <EditableContent
            htmlType="div"
            initialContent={
              i18n.language === "es"
                ? tool.contentsEs || tool.contents
                : tool.contents
            }
            fieldName={i18n.language === "es" ? "contents_es" : "contents"}
            updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
            canEdit={canEdit}
            richText
          />
          <div className="grid-row">
            <span className="margin-right-4">
              <h3>
                {t('My Saving, Sharing, and Spending for the Week of:')}{" "}
                <strong>
                  <Field type="date" name="week" title={t('Select date')}></Field>
                </strong>
              </h3>
            </span>
          </div>
          <table className="usa-table" title={tableTitle}>
            {Tables.renderTableHeader(tableHeaders)}
            {Tables.renderTableSections(
              initialValues.sections,
              formikProps,
              renderTableSection
            )}
            {renderTotals(formikProps)}
          </table>
        </Form>
      )}
    </Formik>
  );
};

export default SpendSaveSharePlan;
