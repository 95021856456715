import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useTranslation} from 'react-i18next';
import Modal from 'react-responsive-modal';
import LanguageSelector from '../components/Buttons/LanguageSelector';

// This component is meant to be used inside a form, where the
//  submit button behaves accordingly
const MandatoryDisclosure = observer((props) => {
  const [acknowledged, setAcknowledged] = useState(false);
  const {t} = useTranslation();

  const handleOnCloseModal = () => {
    return;
  };

  const closeModal = () => {
    setAcknowledged(false);
    props.setShow(false);
  };

  const handleAcknowledgeCheckboxChange = () => {
    setAcknowledged(!acknowledged);
  };

  const handleSubmitButtonClick = () => {
    setAcknowledged(!acknowledged);
    props.setShow(false);
    if (props.submit) {
      props.submit();
    }
  };

  const h1Classes = 'text-center margin-bottom-0';
  const pClasses = 'margin-top-05';

  const privacyActStatementHref = 'https://www.fdic.gov/policies/privacy/sorns.html';

  return (
    <Modal
      open={props.show}
      onClose={handleOnCloseModal}
      showCloseIcon={false}
      closeOnEsc={false}
      closeOnOverlayClick={false}
    >
      <div className='text-center'>
        <LanguageSelector {...props} />
      </div>
      <div className='mandatory-disclosure-component'>
        <div className='monitorying-statement'>
          <h1 className={h1Classes}>{t('MONITORING STATEMENT')}</h1>
          <p className={pClasses}>{t('MONITORING STATEMENT text')}</p>
        </div>
        <div className='privacy-act-statement'>
          <h1 className={h1Classes}>{t('PRIVACY ACT STATEMENT')}</h1>
          <p className={pClasses}>
            <span>{t('PRIVACY ACT STATEMENT text 1')}</span>
            <a href={privacyActStatementHref} referrerPolicy='same-origin' rel='noopener noreferrer' target='_blank'>
              <span>{t('PRIVACY ACT STATEMENT text link')}</span>
            </a>
            <span>{t('PRIVACY ACT STATEMENT text 2')}</span>
          </p>
        </div>
        <div className='grid-row acknowledge-statements padding-3 text-center bg-primary-lighter margin-bottom-3'>
          <div className='grid-col-fill'></div>
          <div className='grid-col-12 mobile-lg:grid-col-8 usa-checkbox bg-transparent'>
            <input
              id='acknowledge-statements-checkbox'
              className='usa-checkbox__input'
              type='checkbox'
              name='acknowledged'
              checked={acknowledged}
              onChange={handleAcknowledgeCheckboxChange}
            />
            <label className='usa-checkbox__label margin-0' htmlFor='acknowledge-statements-checkbox'>
              {t('I have read and acknowledge both the Monitoring Statement and the Privacy Act Statement.')}
            </label>
          </div>
          <div className='grid-col-fill'></div>
        </div>
        <div className='acknowledge-button-container text-center margin-bottom-2'>
          <button className='fdic-button tan-button margin-right-1' type='button' onClick={closeModal}>
            {t('Cancel')}
          </button>
          <button className='fdic-button' type='submit' onClick={handleSubmitButtonClick} disabled={!acknowledged}>
            {t('Submit')}
          </button>
        </div>
      </div>
    </Modal>
  );
});

export default MandatoryDisclosure;
