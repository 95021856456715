import React from "react";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const BreadCrumb = ({historyLinks, includeHome}) => {
  const {t} = useTranslation();
  return (
    <div className="grid-row hide-print">
      <div className="breadcrumb grid-col-11">
        {includeHome && <FontAwesomeIcon icon={faHome} />}
        {historyLinks.map((link, index) => (
          <span key={index}>
            {(index > 0 || historyLinks.length === 1) && (
              <FontAwesomeIcon icon={faChevronRight} size="xs" />
            )}
            <Link
              to={link.link}
              className={`margin-right-1 ${
                index === historyLinks.length - 1 ? "last" : ""
              }`}
              disabled={index === historyLinks.length - 1}
            >
              {t(link.display)}
            </Link>
          </span>
        ))}
      </div>
    </div>
  );
};

BreadCrumb.propTypes = {
  historyLinks: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      display: PropTypes.string.isRequired,
    })
  ),
  includeHome: PropTypes.bool,
};

BreadCrumb.defaultProps = {
  includeHome: false,
};

export default BreadCrumb;
