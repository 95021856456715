import React, {useEffect, useState} from 'react';
import BreadCrumb from '../../../components/BreadCrumb/BreadCrumb';
import RequestService from '../../../services/RequestService';
import config from '../../../config';
import SessionsTable from './SessionsTable';
import {Loader} from '../../../components/Loader';
import SessionsChart from './SessionsChart';
// import {Trans} from 'react-i18next';

const ViewGame = ({gamesStore, match, organizationStore}) => {
  const [gameSessions, setGameSessions] = useState(null);
  const gameId = match.params.gameId;

  const game = gamesStore.getGame(gameId);

  useEffect(() => {
    const fetchGameUsage = () => {
      RequestService.get(`${config.apiGateway.URL}/v2/admin-reports/game-usage-report/${gameId}`, (response) => {
        setGameSessions(response.data[gameId]);
      });
    };
    fetchGameUsage();
  }, [gameId]);

  if(!gameSessions || gameSessions.length === 0) return <Loader />;

  const sortGameSessionsByUserId = (a, b) => {
    return a.user_id < b.user_id ? -1 : 1;
  };

  let validGameSessions = gameSessions ? gameSessions.filter(
    (gs) => gs.organization_name === null || (gs.organization_name !== null && gs.organization_approved),
  ) : [];
  validGameSessions = validGameSessions.slice().sort(sortGameSessionsByUserId);
  let complete = validGameSessions.filter((session) => session.complete_date);
  let stat = {complete: complete.length, inProgress: validGameSessions.length - complete.length, id: gameId};

  return (
    <div className='organization mobile-full-width'>
      <BreadCrumb
        includeHome
        historyLinks={[
          {
            link: '/account/v2/game-usage-report',
            display: 'Game Reports',
          },
          {
            link: `/account/v2/game-usage-report/${gameId}`,
            display: game.name,
          }
        ]}
      />
      <h1>{game.name}</h1>
      <div>
        {(stat.complete > 0 || stat.inProgress > 0) && (
          <div className='mobile-full-width print-full-width sessions-chart-container'>
            <SessionsChart gameCounts={stat} />
          </div>
        )}
      </div>
      <div>
        <SessionsTable
          gameStat={stat}
          sessions={validGameSessions}
          organizationStore={organizationStore}
          showOrganizationDetails={() => {}}
        />
      </div>
    </div>
  );
};

export default ViewGame;