import React, {Fragment} from 'react';
import {Field} from 'formik';
import moment from 'moment';
import autosize from 'autosize';
import {Translation} from 'react-i18next';

class ExpandableTextarea extends React.Component {
  componentDidMount() {
    autosize(this.textarea);
  }

  textArea = () => {
    return (
      <textarea
        title={this.props.title || this.props.question}
        ref={(c) => (this.textarea = c)}
        rows={this.props.rows || 5}
        defaultValue=''
        disabled={this.props.disabled}
        placeholder={this.props.disabled ? this.props.disabledText : ''}
      />
    );
  };

  render() {
    return this.props.inputOnly ? (
      <Field
        title={this.props.title || this.props.name}
        id={this.props.id || this.props.name}
        name={this.props.name}
        component={this.textArea}
        rows={this.props.rows}
        width='100%'
        disabled={this.props.disabled}
        placeholder={this.props.disabled ? this.props.disabledText : ''}
      />
    ) : (
      <div className='tool-text-area'>
        <div className='label-row grid-row'>
          <label htmlFor={this.props.name}>
            <p className='no-margin'>{this.props.question}</p>
          </label>
        </div>
        <div className='grid-row margin-bottom-2'>
          <Field
            title={this.props.name}
            id={this.props.name}
            name={this.props.name}
            component={this.textArea}
            rows={this.props.rows}
            width='100%'
            disabled={this.props.disabled}
            placeholder={this.props.disabled ? this.props.disabledText : ''}
          />
        </div>
      </div>
    );
  }
}

class Inputs {
  static textArea = (name, question, example, rows = 5) => (
    <Translation>
      {(t) => {
        const q = t(question);
        const e = t(example);
        const title = `${q} ${e}`;
        return (
          <div className='tool-text-area'>
            <div className='label-row grid-row'>
              <label htmlFor={name}>
                <p className='no-margin'>
                  <b>{q}</b>
                  <br />
                  {e}
                </p>
              </label>
            </div>
            <div className='grid-row margin-bottom-2'>
              <Field title={title} id={name} name={name} component='textarea' rows={rows} width='100%'></Field>
            </div>
          </div>
        );
      }}
    </Translation>
  );
  static input = (name, question, type = 'text', fullWidth = false) => (
    <Translation>
      {(t) => {
        const transQuestion = t(question);
        return (
          <div className='tool-text-area'>
            <div className='label-row grid-row'>
              <label htmlFor={name}>
                <p className='no-margin'>{transQuestion}</p>
              </label>
            </div>
            <div className='grid-row margin-bottom-2'>
              <Field
                className={`usa-input ${fullWidth ? 'full-width' : ''} no-margin`}
                title={transQuestion}
                type={type}
                id={name}
                name={name}
                width='100%'
              ></Field>
            </div>
          </div>
        );
      }}
    </Translation>
  );
  static monthSelect = (formikProps, label, title) => (
    <Translation>
      {(t) => (
        <span className='margin-right-4'>
          <p>
            {label}
            <select
              title={t(title) || t('Select month')}
              className='margin-left-1'
              name='month'
              value={formikProps.values.month}
              onChange={formikProps.handleChange}
            >
              {moment.months().map((month) => (
                <option key={month} value={month}>
                  {t(month)}
                </option>
              ))}
            </select>{' '}
          </p>
        </span>
      )}
    </Translation>
  );
  static radioButtonGroup = ({
    customOption,
    id,
    labelPrefix = '',
    label,
    value,
    error,
    touched,
    options,
    stackbuttons,
    includeOther = false,
    money = false,
    disabled,
    largeLabel,
  }) => {
    const updatedOther = includeOther && customOption ? customOption : 'Other';
    const updatedOptions = includeOther ? [...options, updatedOther] : options;
    return (
      <Fragment>
        <RadioButtonGroup
          id={id}
          label={label}
          value={value}
          error={error}
          touched={touched}
          stackbuttons={stackbuttons}
          options={updatedOptions}
          largeLabel={largeLabel}
        >
          {updatedOptions.map((option, i) => (
            <Translation key={i}>
              {(t) => {
                return (
                  <Field
                    key={`${label}-${option}`}
                    component={RadioButton}
                    name={id} // do not translate here!
                    id={option.value ? option.value : option} // do not translate here!
                    label={`${labelPrefix}${option.label ? t(option.label) : t(option)}`}
                    stackbuttons={stackbuttons}
                    options={updatedOptions}
                    disabled={disabled}
                    title={option.title ? t(option.title) : t(option)}
                  />
                );
              }}
            </Translation>
          ))}
        </RadioButtonGroup>
        {includeOther && (
          <Translation>
            {(t) => {
              return (
                <div className={`margin-top-105${money ? ' money-input' : ''}`}>
                  <Field
                    name={`${id}Other`} // do not translate here!
                    id={`${id}Other`} // do not translate here!
                    label={t('Other Explain')}
                    disabled={value !== updatedOther} // do not translate here!
                    type={money ? 'number' : 'text'}
                    title={t('Other')}
                  />
                </div>
              );
            }}
          </Translation>
        )}
      </Fragment>
    );
  };
}

const RadioButton = ({stackbuttons, options, ...props}) => {
  return stackbuttons ? <div>{radioButtonHtml(props)}</div> : radioButtonHtml({stackbuttons, options, ...props});
};

const radioButtonHtml = ({field: {name, value, onChange, onBlur}, id, label, className, ...props}) => (
  <label className='no-wrap' htmlFor={`${name}-${id}`}>
    <input
      name={name}
      id={`${name}-${id}`}
      type='radio'
      value={id}
      checked={id.toString() === value.toString()}
      onChange={onChange}
      onBlur={onBlur}
      className={'usa-radio margin-right-105'}
      {...props}
    />
    <span className={`${!props.stackbuttons && 'margin-right-105'}`}>{label.withCommas()}</span>
  </label>
);

const RadioButtonGroup = ({
  customOption,
  value,
  error,
  touched,
  id,
  label,
  className,
  stackbuttons,
  children,
  largeLabel,
}) => {
  return (
    <div className={className}>
      <fieldset className='no-border no-padding'>
        {largeLabel ? <h4 className='margin-bottom-0'>{label}</h4> : <legend>{label}</legend>}
        {children}
        {touched && <InputFeedback error={error} />}
      </fieldset>
    </div>
  );
};

const InputFeedback = ({error}) => (error ? <div className={'alert centered'}>{error}</div> : null);

export default Inputs;
export {ExpandableTextarea};
