import {observable, action, decorate, computed} from "mobx";
import RequestService from "../services/RequestService";
import config from "../config";

class AdminReportsStore {
  userReport = null;
  userReportLoading = true;
  userReportCalled = false;
  certificateReport = null;
  certificateReportLoading = true;
  certificateReportCalled = false;
  gameSessionsReport = null;
  gameSessionsReportLoading = true;
  gameSessionsReportCalled = false;
  gameUsageReport = null;
  gameUsageReportLoading = true;
  gameUsageReportCalled = false;

  hydrateStore = (startDate, endDate, forceUpdate = false) => {
    this.getUserReport(startDate, endDate, false);
    this.getGameSessionsReport(startDate, endDate, false);
    this.getGameUsageReport(startDate, endDate, false);
    this.getCertificateReport(startDate, endDate);
  };

  getUserReport = (startDate, endDate, forceUpdate = false, callback) => {
    ((!this.userReportCalled && this.userReportLoading) || forceUpdate) && RequestService.get(`${config.apiGateway.URL}/admin/report${this.dateFilterQueryString(startDate, endDate)}`, response => {
      this.userReport = response.data; 
      this.userReportLoading = false;
      callback && callback();
    });
    this.userReportCalled = true;
  };

  getGameUsageReport = (startDate, endDate, forceUpdate = false, callback) => {
    ((!this.gameUsageReportCalled && this.gameUsageReportLoading) || forceUpdate) && RequestService.get(`${config.apiGateway.URL}/admin/game-usage-report${this.dateFilterQueryString(startDate, endDate)}`, response => {
      this.gameUsageReport = response.data; 
      this.gameUsageReportLoading = false;
      callback && callback();
    });
    this.gameUsageReportCalled = true;
  };

  getCertificateReport = (startDate, endDate, forceUpdate = false, callback) => {
    ((!this.certificateReportCalled && this.certificateReportLoading) || forceUpdate) && RequestService.get(`${config.apiGateway.URL}/admin/game-usage-report${this.dateFilterQueryString(startDate, endDate)}only_complete=true`, response => {
      this.certificateReport = response.data; 
      this.certificateReportLoading = false;
      callback && callback();
    });
    this.certificateReportCalled = true;
  };

  getGameSessionsReport = (startDate, endDate, forceUpdate = false, callback) => {
    ((!this.gameSessionsReportCalled && this.gameSessionsReportLoading) || forceUpdate) && RequestService.get(`${config.apiGateway.URL}/admin/game-sessions-report${this.dateFilterQueryString(startDate, endDate)}`, response => {
      this.gameSessionsReport = response.data; 
      this.gameSessionsReportLoading = false;
      callback && callback();
    });
    this.gameSessionsReportCalled = true;
  };

  get sortedGameSessionReportByCertificates() {
    const gamesList = this.gameSessionsReport.map((game) => {
      return {gameId: game.game_id, gameSessions: game.completed_count_all};
    });
    return this.sortedGameSessionReport(gamesList);
  }

  get sortedGameSessionReportByTotal() {
    const gamesList = this.gameSessionsReport.map((game) => {
      return {gameId: game.game_id, gameSessions: game.played_count_all};
    });
    return this.sortedGameSessionReport(gamesList);
  }

  sortedGameSessionReport = (gamesList) => {
    return gamesList.sort((gameA, gameB) => {
      if (gameA.gameSessions > gameB.gameSessions) return -1;
      if (gameB.gameSessions > gameA.gameSessions) return 1;
      return 0;
    });
  };

  dateFilterQueryString = (startDate, endDate) => {
    if(!startDate || !endDate) return '?';
    return `?start_date=${startDate}&end_date=${endDate}&`;
  };

  gameUsageReportStatistics = (onlyComplete = false) => {
    if(this.gameUsageReportLoading || this.certificateReportLoading) return 'N/A';
    let totalUserGamesPlayed = 0;
    let users = 0;
    let report = onlyComplete ? this.certificateReport : this.gameUsageReport;
    Object.keys(report).forEach((gamesPlayed) => {
      if(report[gamesPlayed] > 0) {
        users += report[gamesPlayed];
        totalUserGamesPlayed += parseFloat(gamesPlayed) * report[gamesPlayed];
      }
    });
    return {
      averageGamesPlayed: Math.round(totalUserGamesPlayed / users),
      totalUsers: users,
      totalUserGamesPlayed: totalUserGamesPlayed
    };
  };
}

decorate(AdminReportsStore, {
  userReport: observable,
  userReportLoading: observable,
  userReportCalled: observable,
  certificateReport: observable, 
  certificateReportLoading: observable,
  certificateReportCalled: observable,
  gameSessionsReport: observable,
  gameSessionsReportLoading: observable,
  gameSessionsReportCalled: observable,
  gameUsageReport: observable, 
  gameUsageReportLoading: observable,
  gameUsageReportCalled: observable,
  getUserReport: action,
  getGameSessionsReport: action,
  getGameUsageReport: action,
  getCertificateReport: action,
  gameUsageReportAverage: action,
  sortedGameSessionReportByTotal: computed,
  sortedGameSessionReportByCertificates: computed
});

export default AdminReportsStore;