import React from 'react';
import PropTypes from 'prop-types';

// only allow one or the other (h3Text or rightJsx) to be passed in
//  in order to render
const AccountSubHeader = ({h3Text, rightJsx, pText}) => {
  return (
    <div className='grid-row'>
      <div className='grid-col-8'>
        {h3Text && !rightJsx && <h3 className='no-margin'>{h3Text}</h3>}
        {rightJsx && !h3Text && rightJsx}
      </div>
      <div className='grid-col-4' style={{alignSelf: 'center'}}>
        <p className='no-margin text-align-right'>{pText}</p>
      </div>
    </div>
  );
};

AccountSubHeader.propTypes = {
  h3Text: PropTypes.string,
  rightJsx: PropTypes.element,
  pText: PropTypes.string.isRequired,
};

export default AccountSubHeader;
