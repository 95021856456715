import React from "react";
import {Formik, Form, Field} from "formik";
import Tables from "../utilities/Tables";
import Inputs from "../utilities/Inputs";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation} from "react-i18next";

const emptyItem = (
  item = "",
  inputType = "questionlabel",
  rowInputType = "yesNo",
  label = "",
  allowRemoval = false
) => ({
  item: {
    value: item,
    inputType: inputType,
    label: label,
    allowRemoval: allowRemoval,
  },
  financial1: {
    value: "",
    inputType: rowInputType,
    options: ["Yes", "No"],
    columnName: "Financial Institution #1",
  },
  financial2: {
    value: "",
    inputType: rowInputType,
    options: ["Yes", "No"],
    columnName: "Financial Institution #2",
  },
  financial3: {
    value: "",
    inputType: rowInputType,
    options: ["Yes", "No"],
    columnName: "Financial Institution #3",
  },
});

const emptyItemFillable = (
  item = "",
  type = "textarea",
  inputType = "questionlabel",
  label = "",
  allowRemoval = false
) => ({
  item: {
    value: item,
    inputType: inputType,
    label: label,
    allowRemoval: allowRemoval,
  },
  financial1: {value: "", inputType: type},
  financial2: {value: "", inputType: type},
  financial3: {value: "", inputType: type},
});

const initialValues = {
  month: "January",
  financial1: "",
  financial2: "",
  financial3: "",
  bestOption: "",
  myNeedsAndAccess: {
    needs: [
      emptyItem(
        "Is the institution insured by the FDIC (for banks and savings associations) or NCUA (for credit unions)?"
      ),
      emptyItem(
        "If the institution is a credit union, am I eligible to join?"
      ),
      emptyItem("Do I feel welcome and valued as a potential customer?"),
      emptyItem(
        "Does the institution offer products and services I might need? (mobile app, personal loans, vehicle loans, mortgages, credit cards, savings products, other)"
      ),
      emptyItem(
        "Are the products and services described in terms and language I can understand?"
      ),
      emptyItem(
        "Is staff available to answer my questions at times that work for my schedule? (in person, by phone, through online chat, secure email, other)"
      ),
      emptyItem(
        "Can I access my account information how and when I need it? (phone, paper, online, mobile, other)"
      ),
      emptyItem(
        "Can I access my money how and when I need it? (convenient ATM, branches, other)"
      ),
      emptyItem(
        "Are there special accounts for students, older adults, or other groups I’m part of? What benefits are there to these accounts?",
        "questionlabel",
        "yesNoExplain"
      ),
      emptyItemFillable("", "textarea", "textAreaWithLabel", "Other:"),
    ],
  },
  accounts: {
    accounts: [
      emptyItemFillable("Minimum opening deposit?", "money"),
      emptyItemFillable("Minimum monthly balance?", "money"),
      emptyItem(
        "Will my money earn interest? If yes, what is the Annual Percentage Yield (APY)? What balance do I have to maintain to earn interest?",
        "questionlabel",
        "yesNoExplain"
      ),
      emptyItemFillable(
        "How can I deposit money? (branch, ATM, online, mobile app, other)"
      ),
      emptyItemFillable(
        "If I plan to deposit checks, how soon will the funds generally be available to me?"
      ),
      emptyItem(
        "Is there an online or mobile bill payment feature? Make sure I understand how it works."
      ),
      emptyItem(
        "Can I set up alerts, such as for low balances? If yes, can I choose how they are sent? (email, text, phone, other)",
        "questionlabel",
        "yesNoExplain"
      ),
      emptyItemFillable(
        "What fees might I have to pay every month? What other fees are there? (Ask for a fee schedule.)"
      ),
      emptyItemFillable("", "textarea", "textAreaWithLabel", "Other:"),
    ],
  },
  savingsAccounts: {
    savingsAccounts: [
      emptyItemFillable("Minimum opening deposit?", "money"),
      emptyItemFillable("Minimum monthly balance?", "money"),
      emptyItemFillable(
        "What is the Annual Percentage Yield (APY)? What balance do I have to maintain to earn interest?"
      ),
      emptyItemFillable(
        "What fees might I have to pay every month? What other fees are there? (Ask for a fee schedule.)"
      ),
      emptyItemFillable(
        "Is there a limit on monthly withdrawals? What is it? What happens if I exceed the limit?"
      ),
      emptyItemFillable("", "textarea", "textAreaWithLabel", "Other:"),
    ],
  },
  debitCards: {
    debitCards: [
      emptyItem(
        "Will I get a debit card or an ATM card? When?",
        "questionlabel",
        "yesNoExplain"
      ),
      emptyItem(
        "Are there rebates, bonuses, or other rewards for using a debit card?"
      ),
      emptyItem(
        "Can I set up alerts, such as for when the card is used? If yes, can I choose how to receive them? (email, text, phone, other)",
        "questionlabel",
        "yesNoExplain"
      ),
      emptyItemFillable(
        "What fees might the card have? (Ask for a fee schedule.)"
      ),
      emptyItemFillable("", "textarea", "textAreaWithLabel", "Other:"),
    ],
  },
  overdrafts: {
    overdrafts: [
      emptyItemFillable(
        "What are the overdraft practices that come with the account?"
      ),
      emptyItem(
        "Will the institution authorize and pay overdrafts caused by checks, other transactions using my account number, and automatic bill payments?"
      ),
      emptyItem(
        "If I opt-in to an overdraft program, will the institution authorize and pay overdrafts caused by using ATMs and making everyday debit card transactions?"
      ),
      emptyItemFillable(
        "What fees does the institution charge related to overdrafts? (Ask for the overdraft disclosure and fee schedule.)"
      ),
      emptyItem(
        "Can the institution link my savings account to my checking account to avoid an overdraft? If yes, are there fees?",
        "questionlabel",
        "yesNoExplain"
      ),
      emptyItemFillable("", "textarea", "textAreaWithLabel", "Other:"),
    ],
  },
  otherServices: {
    otherServices: [
      emptyItem(
        "Person-to-Person (P2P) Payments: Can I transfer money directly to another person? Make sure you understand how the service works, and how quickly the recipient would receive your payment. (Ask for a fee schedule.)"
      ),
      emptyItem(
        "Money Orders: Can I purchase money orders? (Ask for a fee schedule.)"
      ),
      emptyItem(
        "Remittance Transfers: Can I send money to a person or business in another country? (Ask about a specific country if one is important to you, and ask for a fee schedule.)"
      ),
      emptyItem(
        "Safe Deposit Boxes: Can I rent a safe deposit box? (Ask for a fee schedule.)"
      ),
      emptyItemFillable(
        "Third Party Access: If someone helps manage my banking relationship, what information can they receive on my behalf? How would I authorize them to receive this information?"
      ),
      emptyItemFillable("", "textarea", "textAreaWithLabel", "Other:"),
    ],
  },
};

const renderFinancialInstituteTable = (formikProps, t) => {
  const tableH = t('5.header');
  const tableTitle = `${t('Table')}: ${tableH}`;
  const titles = [
    t('Financial Institution #1'),
    t('Financial Institution #2'),
    t('Financial Institution #3'),
  ];
  return (
    <div className="grid-col-6">
      <table className="usa-table" title={tableTitle}>
        <thead>
          <tr>
            <th></th>
            <th>{tableH}</th>
          </tr>
        </thead>
        <tbody>
          {titles.map((t, i) => {
            return (
              <tr key={i}>
                <th>{i + 1}. </th>
                <td className="col-8">
                  <Field
                    name={`financial${i + 1}`}
                    title={t}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const MyBankingChecklist = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  return (
    <div>
      <Formik initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <EditableContent
              htmlType="div"
              initialContent={
                i18n.language === "es"
                  ? tool.contentsEs || tool.contents
                  : tool.contents
              }
              fieldName={i18n.language === "es" ? "contents_es" : "contents"}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            {renderFinancialInstituteTable(formikProps, t)}
            {Tables.renderSimpleTable(
              [
                t('My Needs and Access'),
                formikProps.values.financial1,
                formikProps.values.financial2,
                formikProps.values.financial3,
              ],
              initialValues.myNeedsAndAccess,
              formikProps,
              "myNeedsAndAccess",
              true,
              () =>
                emptyItemFillable(
                  "",
                  "textarea",
                  "textAreaWithLabel",
                  "Other:",
                  true
                ),
              undefined,
              false,
              true,
            )}
            <div className="page-break-inside-avoid">
              {Tables.renderSimpleTable(
                [
                  t('Checking Accounts, Reloadable Prepaid Cards, and other Transactional Accounts'),
                  formikProps.values.financial1,
                  formikProps.values.financial2,
                  formikProps.values.financial3,
                ],
                initialValues.accounts,
                formikProps,
                "accounts",
                true,
                () =>
                  emptyItemFillable(
                    "",
                    "textarea",
                    "textAreaWithLabel",
                    "Other:",
                    true
                  ),
                undefined,
                false,
                true
              )}
            </div>
            {Tables.renderSimpleTable(
              [
                t('Savings Accounts'),
                formikProps.values.financial1,
                formikProps.values.financial2,
                formikProps.values.financial3,
              ],
              initialValues.savingsAccounts,
              formikProps,
              "savingsAccounts",
              true,
              () =>
                emptyItemFillable(
                  "",
                  "textarea",
                  "textAreaWithLabel",
                  "Other:",
                  true
                ),
              undefined,
              false,
              true
            )}
            {Tables.renderSimpleTable(
              [
                t('Debit Cards and ATM (Automated Teller Machine) Cards'),
                formikProps.values.financial1,
                formikProps.values.financial2,
                formikProps.values.financial3,
              ],
              initialValues.debitCards,
              formikProps,
              "debitCards",
              true,
              () =>
                emptyItemFillable(
                  "",
                  "textarea",
                  "textAreaWithLabel",
                  "Other:",
                  true
                ),
              undefined,
              false,
              true
            )}
            {Tables.renderSimpleTable(
              [
                t('Overdrafts and Overdraft Fees'),
                formikProps.values.financial1,
                formikProps.values.financial2,
                formikProps.values.financial3,
              ],
              initialValues.overdrafts,
              formikProps,
              "overdrafts",
              true,
              () =>
                emptyItemFillable(
                  "",
                  "textarea",
                  "textAreaWithLabel",
                  "Other:",
                  true
                ),
              undefined,
              false,
              true
            )}
            <div className="page-break-inside-avoid">
              {Tables.renderSimpleTable(
                [
                  t('Other Available Services'),
                  formikProps.values.financial1,
                  formikProps.values.financial2,
                  formikProps.values.financial3,
                ],
                initialValues.otherServices,
                formikProps,
                "otherServices",
                true,
                () =>
                  emptyItemFillable(
                    "",
                    "textarea",
                    "textAreaWithLabel",
                    "Other:",
                    true
                  ),
                undefined,
                false,
                true
              )}
            </div>
            <p>
              <b>{t('Based on the above information, which one do you think will work better for you?')}
                
              </b>
            </p>
            <div className="margin-bottom-4">
              {Inputs.radioButtonGroup({
                id: "bestOption",
                value: formikProps.values.bestOption,
                error: formikProps.errors.bestOption,
                touched: formikProps.touched.bestOption,
                options: [
                  formikProps.values.financial1 || t('Financial Institution #1'),
                  formikProps.values.financial2 || t('Financial Institution #2'),
                  formikProps.values.financial3 || t('Financial Institution #3'),
                ],
              })}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MyBankingChecklist;
export {emptyItem, emptyItemFillable};
