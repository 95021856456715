import {observable, decorate} from "mobx";
import RequestService from '../services/RequestService';
import config from '../config';

class AuditLogStore {
  constructor() {
    this.buildFromSession();
  }

  auditLogs = [];
  pages = 0;
  auditLogsLoading = true;

  getAuditLogs = (startDate, endDate, forceUpdate = false, page = 1) => {
    (this.auditLogsLoading || forceUpdate) && RequestService.get(`${config.apiGateway.URL}/admin/audit-report${this.queryString(startDate, endDate, page)}`, (resp) => {
      this.auditLogs = resp.data.audit_logs;
      this.pages = resp.data.pages;
      this.auditLogsLoading = false;
      this.writeToSession();
    });
  };

  queryString = (startDate, endDate, page) => {
    let queryString = '?';
    if(startDate && endDate) queryString += `start_date=${startDate}&end_date=${endDate}`;
    if(page) queryString += `&page=${page}`;
    return queryString;
  };

  getAuditLogsById = (auditLogId) => {
    return this.auditLogs.filter((auditLog) => parseInt(auditLog.id) === parseInt(auditLogId))[0];
  };

  buildFromSession = () => {
    let json = JSON.parse(sessionStorage.getItem('AuditLogStore'));
    if(json) {
      this.auditLogs = json.auditLogs;
      this.pages = json.pages;
      this.auditLogsLoading = json.auditLogsLoading;
    }
  };

  writeToSession = () => {
    let json = JSON.stringify(this);
    sessionStorage.setItem('AuditLogStore', json);
  };
}

decorate(AuditLogStore, {
  auditLogs: observable,
  pages: observable,
  auditLogsLoading: observable,
});

export default AuditLogStore;