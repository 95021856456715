import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';

class ResourceFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: props.options,
      selectedOptions: props.selectedOptions || [],
      filter: props.filter,
      expanded: false,
    };
  }

  UNSAFE_componentWillReceiveProps = (props) => {
    props.dynamicallyUpdateSelected &&
      this.setState({
        selectedOptions: props.selectedOptions,
      });
  };

  filter = (option, add) => {
    let currentOptions = this.state.selectedOptions;
    add ? currentOptions.push(option) : currentOptions.splice(currentOptions.indexOf(option), 1);
    this.setState(
      {
        selectedOptions: currentOptions,
      },
      this.props.filter(this.state.selectedOptions)
    );
  };

  groupOptions = () => {
    let groupedOptions = [];
    let options = this.props.options;
    for (let i = 0, j = options.length; i < j; i += 7) {
      let optionArray = options.slice(i, i + 7);
      groupedOptions.push(optionArray);
    }
    return groupedOptions;
  };

  renderOptions = () => {
    const {t} = this.props;
    let groupedOptions = this.groupOptions();
    return groupedOptions.map((optionGroup, index) => (
      <ul key={`option-group-${index}`} className='filter-option-group margin-right-2'>
        {optionGroup.map((option) => (
          <li key={option} className='usa-nav__submenu-item'>
            <div className='usa-checkbox'>
              <input
                className='usa-checkbox__input'
                id={option}
                type='checkbox'
                name={option}
                value={option}
                onChange={(e) => {
                  this.filter(option, e.target.checked);
                }}
                checked={this.state.selectedOptions.includes(option)}
              />
              <label className='usa-checkbox__label' htmlFor={option}>
                {t(option)}
              </label>
            </div>
          </li>
        ))}
      </ul>
    ));
  };

  render() {
    const {buttonText, toggleExpanded, expanded, t} = this.props;
    const {selectedOptions} = this.state;
    const tButtonText = t(buttonText);
    return (
      <div>
        <ul className='usa-nav__primary usa-accordion'>
          <li key={buttonText} className='filter-dropdown'>
            <button
              onClick={toggleExpanded}
              className='usa-accordion__button usa-current resource-filter'
              aria-expanded={expanded}
            >
              <span className='filter-button-text'>
                {tButtonText} {selectedOptions.length > 0 && `(${selectedOptions.length})`}
              </span>
            </button>
            <ul
              id='dropdown-options-section'
              className={`usa-nav__submenu filter-options ${expanded ? 'expanded' : ''}`}
            >
              {this.renderOptions()}
            </ul>
          </li>
        </ul>
      </div>
    );
  }
}

ResourceFilter.propTypes = {
  buttonText: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  filter: PropTypes.func.isRequired,
  selectedOptions: PropTypes.array,
};

ResourceFilter.defaultProps = {
  selectedOptions: [],
};

export default withTranslation()(ResourceFilter);
