import axios from 'axios';

const config = () => {
  return {
    "headers": {
      "Authorization": sessionStorage.getItem('Token')
    },
    maxContentLength: 100000000,
    maxBodyLength: 1000000000,
    timeout: 1000000000,
  };
};

const checkForUnauthorizedResponse = (error) => {
  if(sessionStorage.getItem('Token') && error.response && error.response.status === 401) {
    sessionStorage.clear();
    window.location = '/login';
  }
};

const RequestService = {
  get: async (url, callback, failureCallback) => {
    axios.get(url, config()).then((response) => {
      callback(response);
    }).catch((err) => {
      checkForUnauthorizedResponse(err);
      failureCallback && failureCallback(err);
    });
  },
  post: async (url, body, callback, failureCallback) => {
    axios.post(url, body, config()).then((response) => {
      callback(response);
    }).catch((err) => {
      checkForUnauthorizedResponse(err);
      failureCallback && failureCallback(err);
    });
  },
  delete: async (url, callback, failureCallback, includeAuth = true) => {
    axios.delete(url, includeAuth && config()).then((response) => {
      callback(response);
    }).catch((err) => {
      checkForUnauthorizedResponse(err);
      failureCallback && failureCallback(err);
    });
  }
};

export default RequestService;