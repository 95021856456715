import React from "react";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation, Trans} from "react-i18next";

const buildTitle = (t, header) => `${t('Table')}: ${header}`;

const FileADispute = ({strategy, canEdit}) => {
  const {t, i18n} = useTranslation();
  const header1 = t('126.step1.step');
  const header2 = t('126.step2.step');
  const header3 = t('126.step3.step');
  const title1 = buildTitle(t, header1);
  const title2 = buildTitle(t, header2);
  const title3 = buildTitle(t, header3);
  return (
    <div className="strategy">
      <EditableContent
        htmlType="div"
        initialContent={
          i18n.language === "es"
            ? strategy.contentsEs || strategy.contents
            : strategy.contents
        }
        fieldName={i18n.language === "es" ? "contents_es" : "contents"}
        updateEndpoint={`${config.apiGateway.URL}/topic-items/${strategy.id}`}
        canEdit={canEdit}
        richText
      />
      <table
        className="usa-table"
        title={title1}
      >
        <thead>
          <tr>
            <th colSpan="2" scope="col">
              <p>{header1}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              <p>
                <strong>
                  ChexSystems<sup>&reg;</sup>
                </strong>
              </p>
              <p><Trans i18nKey="126.step1.do">Do one of the following:</Trans></p>
              <ul>
                <li>
                  <p>
                    <Trans i18nKey="126.step1.fileOnline">
                      <a href="https://www.chexsystems.com/web/chexsystems/consumerdebit/page/dispute/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8ziDRxdHA1Ngg183AP83QwcXX39LIJDfYwMnM30w1EV-HuEGAEVuPq4Gxt5G7oHmuhHkaQfTYGBOZH6cQBHA8rsByqIwm98uH4UqhVYQgCvG0BeJGRJQW5oaGiEQaano6IiAPs5WGA!/dz/d5/L2dBISEvZ0FBIS9nQSEh/">
                        File a dispute online
                      </a></Trans>
                    {" "}
                    (chexsystems.com).
                  </p>
                </li>
                <li>
                  <p><Trans i18nKey="126.step1.fileMail">
                    File a dispute using certified mail with return receipt
                    requested, so you have proof they received it. Download the 
                    <a href="https://files.consumerfinance.gov/f/201602_cfpb_checking-account-consumer-report-dispute-sample-letter.pdf">
                        Consumer Financial Protection Bureau&rsquo;s Sample
                        Letter
                    </a></Trans>
                    (consumerfinance.gov). <br />
                  <Trans i18nKey="126.step1.send">Then send it to:</Trans>&nbsp; <br /> <br /> ChexSystems, Inc.{" "}
                  <br /> Attn: Consumer Relations
                  <br /> 7805 Hudson Road, Suite 100 <br /> Woodbury, MN 55125
                  </p>
                </li>
              </ul>
            </th>
            <td>
              <p>
                <strong>
                  Early Warning<sup>&reg;</sup>
                </strong>
              </p>
              <p><Trans i18nKey="126.step1.do">Do one of the following:</Trans></p>
              <ul>
                <li>
                  <p><Trans i18nKey="126.step1.fileOnline">
                    <a href="https://consumerservices.earlywarning.com/">
                    File a dispute online
                    </a></Trans>{" "}
                    (earlywarning.com). <Trans i18nKey="126.step1.register">You will need to register first.</Trans>
                  </p>
                </li>
                <li>
                  <p><Trans i18nKey="126.step1.fileMail">
                    File a dispute using certified mail with return receipt
                    requested, so you have proof they received it. Download the 
                    <a href="https://files.consumerfinance.gov/f/201602_cfpb_checking-account-consumer-report-dispute-sample-letter.pdf">
                      Consumer Financial Protection Bureau&rsquo;s Sample Letter
                    </a></Trans>
                    (consumerfinance.gov). <br />
                  <Trans i18nKey="126.step1.send">Then send it to: </Trans> <br /> <br /> Early Warning <br />{" "}
                    Attn: Consumer Services Department
                  <br /> 16552 N. 90th Street <br /> Scottsdale, AZ 85260
                  </p>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
      <table
        className="usa-table page-break-before-always"
        title={title2}
      >
        <thead>
          <tr>
            <th colSpan="2" scope="col">
              <p>{header2}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">
              <ul>
                <li>
                  <p>
                    <Trans i18nKey="126.step2.bullet1">Download the 
                      <a href="https://files.consumerfinance.gov/f/201602_cfpb_sample-letter-to-your-bank-or-credit-union-to-dispute-information-it-provided-about-your-checking-account-history.pdf">
                          Consumer Financial Protection Bureau&rsquo;s Sample
                          Letter 
                      </a>
                      
                    (consumerfinance.gov). Adjust the letter to be
                    specific to your situation.</Trans>
                  </p>
                </li>
                <li>
                  <p>
                    <Trans i18nKey="126.step2.bullet2">Send your completed letter by certified mail to the
                    financial institution, return receipt requested, so you have
                    proof the financial institution received it.</Trans>
                  </p>
                </li>
              </ul>
            </th>
          </tr>
        </tbody>
      </table>
      <table
        className="usa-table no-border"
        title={title3}
      >
        <thead>
          <tr>
            <th colSpan="2" scope="col">
              <p>{header3}</p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colSpan="2" scope="row">
              <p>
                <Trans i18nKey="126.step3.intro">Use the following information to request your banking history
                report. When you receive it, review it to make sure the error(s)
                you disputed have been corrected. You will likely not have a
                banking history report if there is no negative information on
                file.</Trans>
              </p>
              <p>
                <strong>
                  <Trans i18nKey="126.step3.getChex">Get Your ChexSystems<sup>&reg;</sup> Report</Trans>
                </strong>
              </p>
            </th>
          </tr>
          <tr>
            <th scope="row">
              <p><Trans i18nKey="126.step3.online">Online:</Trans></p>
            </th>
            <td>
              <p>
                <Trans i18nKey="126.step3.visit">Visit </Trans>
                <a href="https://www.chexsystems.com/web/chexsystems/consumerdebit/otherpage/FACTAFreeReport/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zizQIsPN2NLAx8DDyc3AwcXYPMnM28_YwMjA30w8EKDBxdHA1Ngg18_J3dzYEKXCycHf2cjAwMDPSjSNLvHuAPUuDrZxEc6gPUb06kfhzAkUj78SiIwm98uH4URAmeEEBVgMWLhCwpyA0NDY0wyPR0VFQEAFZE7O0!/dz/d5/L2dBISEvZ0FBIS9nQSEh/">
                    chexsystems.com
                </a>
              </p>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <p><Trans i18nKey="126.step3.phone">Phone:</Trans></p>
            </th>
            <td>
              <p>1-800-428-9623</p>
            </td>
          </tr>
          <tr>
            <th className="vertical-align-top" scope="row">
              <p><Trans i18nKey="126.step3.mail">Mail:</Trans></p>
            </th>
            <td>
              <p>
                ChexSystems
                <br />
                Attn: Consumer Relations
                <br />
                7805 Hudson Road, Suite 100
                <br />
                Woodbury, MN 55125
              </p>
            </td>
          </tr>
          <tr>
            <th colSpan="2" scope="row">
              <p>
                <strong>
                  <Trans i18nKey="126.step3.getEarly">Get Your Early Warning Services<sup>&reg;</sup> Report</Trans>{" "}
                </strong>
              </p>
              <p>
                <Trans i18nKey="126.step3.complete">Download and complete the 
                  <a href="https://www.earlywarning.com/sites/default/files/2019-01/CIC%20Form-170215-0811-SAMPLE.pdf">
                    form
                  </a>
                and mail or fax it to Early Warning.</Trans>
              </p>
            </th>
          </tr>
          <tr>
            <th className="vertical-align-top" width="90px" scope="row">
              <p><Trans i18nKey="126.step3.mail">Mail:</Trans></p>
            </th>
            <td>
              <p>
                Early Warning
                <br />
                Attn: Consumer Services Department
                <br />
                16552 N. 90th Street
                <br />
                Scottsdale, AZ 85260
              </p>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <p>Fax:</p>
            </th>
            <td>
              <p>1-480-656-6850</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default FileADispute;
