import React from 'react';
import {Field} from 'formik';
import * as Yup from 'yup';
import Filter from 'bad-words';
import InputError from './InputError';
import {hasAlert} from './FormInput';

const UsaStates = require('usa-states').UsaStates;
const usStates = new UsaStates({includeTerritories: true});

const filter = new Filter();

const addressSchemaObject = {
  addressLineOne: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .min(2, 'Address line one must be at least two characters.')
    .max(50, 'Address line one must be at most 50 characters.')
    .required('Address line one is required.'),
  addressLineTwo: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .min(2, 'Address line two must be at least two characters.')
    .max(50, 'Address line two must be at most 50 characters.'),
  city: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .min(2, 'City must be at least two characters.')
    .max(50, 'City must be at most 50 characters.')
    .required('City is required.'),
  state: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .test('Select State', 'Select a state.', (value) => value !== 'Please select')
    .min(2, 'State must be at least two characters.')
    .max(50, 'State must be at most 50 characters.')
    .required('State is required.'),
  zipCode: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .min(2, 'Zip code must be at least two characters.')
    .max(50, 'Zip code must be at most 50 characters.')
    .required('Zip code is required.'),
};

const AddressForm = ({formikProps, disabled}) => {
  return (
    <div className='address-form'>
      <div className='grid-row margin-bottom-2'>
        <div className='grid-col-12 padding-right-2'>
          <label htmlFor='addressLineOne'>
            <span htmlFor='addressLineOne'>Address Line 1: *</span>
          </label>
          <Field
            id='addressLineOne'
            name='addressLineOne'
            type='text'
            className={`${hasAlert(formikProps, 'addressLineOne') && 'alert'} full-width`}
            required
            disabled={disabled}
          />
          <InputError formikProps={formikProps} fieldName='addressLineOne' />
        </div>
      </div>
      <div className='grid-row margin-bottom-2'>
        <div className='grid-col-12 padding-right-2'>
          <label htmlFor='addressLineTwo'>
            <span htmlFor='addressLineTwo'>Address Line 2:</span>
          </label>
          <Field
            id='addressLineTwo'
            name='addressLineTwo'
            type='text'
            className={`${hasAlert(formikProps, 'addressLineTwo') && 'alert'} full-width`}
            required
            disabled={disabled}
          />
          <InputError formikProps={formikProps} fieldName='addressLineTwo' />
        </div>
      </div>
      <div className='grid-row margin-bottom-2'>
        <div className='grid-col-8 padding-right-2'>
          <label htmlFor='city'>
            <span htmlFor='city'>City: *</span>
          </label>
          <Field
            id='city'
            name='city'
            type='text'
            className={`${hasAlert(formikProps, 'city') && 'alert'} full-width`}
            required
            disabled={disabled}
          />
          <InputError formikProps={formikProps} fieldName='city' />
        </div>
        <div className='grid-col-4 padding-right-2'>
          <label htmlFor='state'>
            <span htmlFor='state'>State: *</span>
          </label>
          <Field
            component='select'
            name='state'
            className={`${hasAlert(formikProps, 'city') && 'alert'}`}
            disabled={disabled}
          >
            <option value='Please select' disabled>
              Please select
            </option>
            {usStates.states.map((state, i) => (
              <option key={i} value={state.abbreviation}>
                {state.name}
              </option>
            ))}
          </Field>
          <InputError formikProps={formikProps} fieldName='state' />
        </div>
      </div>
      <div className='grid-row margin-bottom-6'>
        <div className='grid-col-4 padding-right-2'>
          <label htmlFor='zipCode'>
            <span htmlFor='zipCode'>Zip Code: *</span>
          </label>
          <Field
            id='zipCode'
            name='zipCode'
            type='text'
            className={`${hasAlert(formikProps, 'zipCode') && 'alert'}`}
            required
            disabled={disabled}
          />
          <InputError formikProps={formikProps} fieldName='zipCode' />
        </div>
      </div>
    </div>
  );
};

export default AddressForm;
export {addressSchemaObject};
