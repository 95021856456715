import React from "react";
import {Formik, Form} from "formik";
import Tables from "../utilities/Tables";
import Inputs from "../utilities/Inputs";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation, Trans, Translation} from "react-i18next";

const emptyExpense = (
  item = "",
  label = "",
  inputType = "labelNoBold",
  allowRemoval = false
) => ({
  item: {
    value: item,
    label: label,
    inputType: inputType,
    allowRemoval: allowRemoval,
  },
  amount: {value: "", inputType: "money"},
  frequency: {
    value: "Every month",
    inputType: "select",
    options: Object.keys(Tables.frequencies),
  },
  monthlyAmount: {
    value: "",
    inputType: "calculatedMoney",
    calculation: calculateMonthlyAmount,
    columnWidth: 1,
  },
  notes: {value: "", inputType: "textarea", columnWidth: 3},
});

const calculateMonthlyAmount = (formikProps, sectionIdentifier, key, index) => {
  return monthlyAmount(
    formikProps.values.sections[key][index].amount.value,
    Tables.frequencies[formikProps.values.sections[key][index].frequency.value]
  )
    .toFixed(2)
    .withCommas();
};

const initialValues = {
  month: "January",
  sections: {
    Saving: [
      emptyExpense("Saving toward: An emergency fund"),
      emptyExpense("", "Saving toward:", "textWithLabel"),
    ],
    Sharing: [
      emptyExpense("Sharing with family and friends"),
      emptyExpense("Charitable contributions"),
      emptyExpense("", "Other:", "textWithLabel"),
    ],
    Spending: [
      emptyExpense("Rent, mortgage payment"),
      emptyExpense("Property taxes, insurance"),
      emptyExpense("Water"),
      emptyExpense("Electric"),
      emptyExpense("Gas, oil"),
      emptyExpense("Trash collection"),
      emptyExpense("Telephone (cell and land-line)"),
      emptyExpense("Internet"),
      emptyExpense("Cable, satellite, TV viewing services"),
      emptyExpense("Car, truck payment"),
      emptyExpense("Car, truck insurance"),
      emptyExpense("Car, truck maintenance and repair"),
      emptyExpense("Car, truck fuel"),
      emptyExpense("Public transportation"),
      emptyExpense(
        "Health insurance (part not covered by employer or taken out of gross pay)"
      ),
      emptyExpense("Other healthcare expenses"),
      emptyExpense("Student loan payments"),
      emptyExpense("Credit card debt payments"),
      emptyExpense("Other debt payments"),
      emptyExpense("Personal care attendant"),
      emptyExpense("Eldercare"),
      emptyExpense("Childcare, child support payments"),
      emptyExpense("Groceries and household supplies"),
      emptyExpense("Eating out, take-out"),
      emptyExpense("Service animal expenses"),
      emptyExpense("Pet care"),
      emptyExpense("Personal expenses"),
      emptyExpense("Entertainment"),
      emptyExpense("", "Other:", "textWithLabel"),
    ],
  },
};

const monthlyAmount = (input, calculation) =>
  Math.round(calculation(input) * 100) / 100;

const calculateSectionTotal = (section) => {
  let total = 0;
  section.forEach(
    (expense) =>
      (total += monthlyAmount(
        expense.amount.value,
        Tables.frequencies[expense.frequency.value]
      ))
  );
  return total;
};

const calculateGrandTotal = (sections) => {
  let total = 0;
  sections.forEach((section) => (total += calculateSectionTotal(section)));
  return total;
};

const renderSummaryTable = (formikProps) => (
  <Translation>
    {(t) => {
      const headers = [t('Category'), t('Monthly Amount for')];
      const title = `${'Summary table'}: ${headers.join(', ')}`;
      return (
        <table className="usa-table summary-table" title={title}>
          <thead>
            <tr>
              {headers.map((h, i) => <th key={i} scope='col'>{h}</th>)}
            </tr>
          </thead>
          <tbody>
            {Object.keys(initialValues.sections).map((section, index) => (
              <tr key={`row-${index}`}>
                {section === "Sharing" ?
                  <th className="padding-left-2 padding-top-1 padding-bottom-1">
                    {t('My_singular')} {t(section)}
                  </th>
                  :
                  <th className="padding-left-2 padding-top-1 padding-bottom-1">
                    {t('My')} {t(section)}
                  </th>}
                <td className="padding-left-2">
              $
                  {calculateSectionTotal(formikProps.values.sections[section])
                    .toFixed(2)
                    .withCommas()}
                </td>
              </tr>
            ))}
            <tr>
              <th className="padding-left-2 padding-top-1 padding-bottom-1">
                <b tabIndex="0">{t('Total Monthly Expenses')}</b>
              </th>
              <td className="padding-left-2">
                <b tabIndex="0">
              $
                  {calculateGrandTotal(
                    Object.keys(formikProps.values.sections).map(
                      (key) => formikProps.values.sections[key]
                    )
                  )
                    .toFixed(2)
                    .withCommas()}
                </b>
              </td>
            </tr>
          </tbody>
        </table>
      );
    }}
  </Translation>
);

const TrackMonthlyExpenses = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  return (
    <div>
      <Formik initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <EditableContent
              htmlType="div"
              initialContent={
                i18n.language === "es"
                  ? tool.contentsEs || tool.contents
                  : tool.contents
              }
              fieldName={i18n.language === "es" ? "contents_es" : "contents"}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            {Inputs.monthSelect(formikProps, t('My Expenses for the month of:'))}
            <div className="page-break-inside-avoid">
              <h3><Trans i18nKey="My Saving">My Saving</Trans></h3>
              {Tables.renderSimpleTable(
                [
                  t('Item'),
                  t('Saving Amount'),
                  t('How often do you save this amount?'),
                  t('Monthly Amount'),
                  t('Notes'),
                ],
                {Saving: initialValues.sections.Saving},
                formikProps,
                "sections",
                true,
                (value, label) =>
                  emptyExpense(value, label, "textWithLabel", true),
                undefined,
                true,
                true
              )}
            </div>
            <h3><Trans i18nKey="My Sharing">My Sharing</Trans></h3>
            {Tables.renderSimpleTable(
              [
                t('Item'),
                t('Sharing Amount'),
                t('How often do you share this amount?'),
                t('Monthly Amount'),
                t('Notes'),
              ],
              {Sharing: initialValues.sections.Sharing},
              formikProps,
              "sections",
              true,
              (value, label) =>
                emptyExpense(value, label, "textWithLabel", true),
              undefined,
              true,
              true
            )}
            <h3><Trans i18nKey="My Spending">My Spending</Trans></h3>
            {Tables.renderSimpleTable(
              [
                t('Item'),
                t('Spending Amount'),
                t('How often do you pay this expense?'),
                t('Monthly Amount'),
                t('Notes'),
              ],
              {Spending: initialValues.sections.Spending},
              formikProps,
              "sections",
              true,
              (value, label) =>
                emptyExpense(value, label, "textWithLabel", true),
              undefined,
              true,
              true
            )}
            <div className="grid-col-8 mobile-full-width">
              {renderSummaryTable(formikProps)}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default TrackMonthlyExpenses;
