import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';

class AccordionRow extends Component {
  constructor(props) {
    super(props);
    this.state = {active: props.forceOpen};
  }

  static propTypes = {
    children: PropTypes.node,
  };

  toggleClass = (e) => {
    this.setState({active: !this.state.active});
  };

  componentDidUpdate(prevProps) {
    if(prevProps.forceOpen === true && this.props.forceOpen === false) {
      this.setState({
        active: false
      });
    }
  }

  render() {
    let {children} = this.props;
    let classes = cc(['accordion-row', this.state.active || this.props.forceOpen ? 'active' : '', this.props.forceOpen ? 'hide-dash' : '']);
    return (
      <div title={`${this.props.title || 'Expandable item'} ${this.state.active ? 'expanded' : 'collapsed'}`} ref={this.props.focusRef} id={this.props.id} tabIndex={0} className={classes} onClick={!this.props.forceOpen ? this.toggleClass : undefined}>
        {children}
      </div>
    );
  }
}

export default AccordionRow;