import React, {useEffect, useState} from 'react';
import SessionsChart from './SessionsChart';
import SessionsTable from './SessionsTable';
import RequestService from '../../../services/RequestService';
import config from '../../../config';
import Loader from '../../../components/Loader/Loader';
import AuthStore from '../../../stores/AuthStore';

const GameRow = ({startDate, endDate, organizationId, gameStat, organizationStore, showOrganizationDetails}) => {
  const [gameSessions, setGameSessions] = useState(null);
  const [loading, setLoading]= useState(true);
  const queryString = () => {
    let queryString = '?';
    if (startDate && endDate) queryString += `start_date=${startDate}&end_date=${endDate}&`;
    if (organizationId) queryString += `organization_id=${organizationId}&`;
    if (gameStat.id) queryString += `game_id=${gameStat.id}`;
    return queryString;
  };

  const authStore = new AuthStore();

  const fetchGameStat = () => {
    RequestService.get(
      `${config.apiGateway.URL}/organization/game-sessions${queryString()}`,
      (response) => {
        setGameSessions(response.data[gameStat.id]);
        setLoading(false);
      },
      (error) => console.log(error),
    );
  };
  useEffect(() => {
    fetchGameStat();
    // eslint-disable-next-line
  }, []);

  if(loading) return <Loader />;

  const sortGameSessionsByUserId = (a, b) => {
    return a.user_id < b.user_id ? -1 : 1;
  };

  let validGameSessions = gameSessions ? gameSessions.filter(
    (gs) => gs.organization_name === null || (gs.organization_name !== null && gs.organization_approved),
  ) : [];
  validGameSessions = validGameSessions.slice().sort((a, b) => sortGameSessionsByUserId(a, b));
  let complete = validGameSessions.filter((session) => session.complete_date);
  let stat = {complete: complete.length, inProgress: validGameSessions.length - complete.length, id: gameStat.id};
  return (
    <div className='grid-row'>
      {(stat.complete > 0 || stat.inProgress > 0) && (
        <div className='grid-col-5 mobile-full-width print-full-width sessions-chart-container'>
          <SessionsChart gameCounts={stat} />
        </div>
      )}
      {(stat.complete > 0 || stat.inProgress > 0) ? (
        <div className='grid-col-7 print-full-width centered'>
          <h5 className='no-margin centered text-center'>
            Total players with accounts: {stat.complete + stat.inProgress}
          </h5>
          <SessionsTable
            gameStat={stat}
            sessions={validGameSessions}
            organizationStore={organizationStore}
            showOrganizationDetails={showOrganizationDetails}
          />
        </div>
      ) : (
        <div className='grid-col-10'>
          <h3>
            {gameStat.id !== 15
              ? (!authStore.isAdmin && 'No one has played this game yet while logged into their account.')
              : (!authStore.isAdmin && 'No one has earned a Master Certficate yet.')}
          </h3>
        </div>
      )}
    </div>
  );
};

export default GameRow;