import React from "react";
import {Formik, Form} from "formik";
import Tables from "../utilities/Tables";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation} from "react-i18next";

const emptyItem = (item = "", inputType = "text") => ({
  item: {value: item, inputType: "label", columnWidth: 2},
  creditCard1: {
    value: "",
    inputType: inputType,
    maxLength: inputType === "text" ? 30 : null,
    rows: 3,
    columnWidth: 2,
    columnName: 'Credit Card 1',
  },
  creditCard2: {
    value: "",
    inputType: inputType,
    maxLength: inputType === "text" ? 30 : null,
    rows: 3,
    columnWidth: 2,
    columnName: 'Credit Card 2',
  },
  creditCard3: {
    value: "",
    inputType: inputType,
    maxLength: inputType === "text" ? 30 : null,
    rows: 3,
    columnWidth: 2,
    columnName: 'Credit Card 3',
  },
});

const labelItem = (item = "", label = "", allowRemoval = false) => ({
  item: {
    value: item,
    inputType: "textAreaWithLabelHTML",
    label: label,
    columnWidth: 2,
    allowRemoval: allowRemoval,
    title: label,
    bold: true
  },
  creditCard1: {
    value: "",
    inputType: "textareaAlignBottom",
    maxLength: 30,
    columnWidth: 2,
    columnName: 'Credit Card 1',
  },
  creditCard2: {
    value: "",
    inputType: "textareaAlignBottom",
    maxLength: 30,
    columnWidth: 2,
    columnName: 'Credit Card 2',
  },
  creditCard3: {
    value: "",
    inputType: "textareaAlignBottom",
    maxLength: 30,
    columnWidth: 2,
    columnName: 'Credit Card 3',
  },
});

const initialValues = {

  creditCardFeeSection: {
    "Credit card fees": [
      emptyItem("Name of creditor/card"),
      emptyItem(
        "Introductory Annual Percentage Rate (APR) and how long it lasts"
      ),
      emptyItem("APR after introductory period"),
      emptyItem("APR for purchases"),
      emptyItem("APR for cash advances"),
      emptyItem("APR for balance transfers"),
      emptyItem("Penalty APR"),
      emptyItem("Annual fee"),
      emptyItem("Late fee"),
      emptyItem("Over-the-limit fee"),
      emptyItem("Cash advance fee"),
      labelItem(
        "",
        "Other credit card fee:",
        "textarea"
      
      ),
    ],
    "Credit card details": [
      emptyItem("Is there a grace period? How long does it last?", "textarea"),
      emptyItem(
        "Are there rewards? Make sure you understand what you have to do to earn, use, and keep the rewards.",
        "textarea"
      ),
      emptyItem("What will your credit limit be, if known?", "textarea"),
      emptyItem("Is it a secured card? What are the requirements?", "textarea"),
      labelItem(
        "",
        "Other credit card detail:",
        "textarea"
      ),
    ],
  },

};

const CompareCreditCards = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  return (
    <div>
      <Formik initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <EditableContent
              htmlType="div"
              initialContent={
                i18n.language === "es"
                  ? tool.contentsEs || tool.contents
                  : tool.contents
              }
              fieldName={i18n.language === "es" ? "contents_es" : "contents"}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            {Tables.renderSimpleTable(
              [
                t('Comparison Item'),
                t('Credit Card 1'),
                t('Credit Card 2'),
                t('Credit Card 3'),
              ],
              initialValues.creditCardFeeSection,
              formikProps,
              "creditCardFeeSection",
              true,
              (value, label) => labelItem(value, label, true),
              undefined,
              false,
              true,
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompareCreditCards;
