import React from 'react';
import {observer} from 'mobx-react';
import OrganizationDetails from '../admin/OrganizationDetails';
import IndividualAccount from '../individual/IndividualAccount';
import Loader from '../../../components/Loader/Loader';

const OrganizationError = () => {
  return(
    <div className="organization-deleted-message margin-top-15">
      <p>Your organization has been deleted. If you believe this is a mistake, please contact <a href="mailto: communityaffairs@fdic.gov">communityaffairs@fdic.gov</a>.</p>
    </div>
  );
};

const OrganizationAccount = observer((props) => {
  props.organizationStore.getOrganization(props.authStore.organizationId);

  if(props.organizationStore.organizationLoading) return <Loader />;
  if(props.organizationStore.organizationError) return <OrganizationError />;
  return(
    props.organizationStore.organization.approved ?
      <OrganizationDetails {...props} /> :
      <IndividualAccount {...props} temporaryOrganizationAccount={true}/> 
  );
});

export default OrganizationAccount;