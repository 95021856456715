import {observable, decorate} from "mobx";

class FeatureToggleStore {
  constructor() {
    this.buildFromSession();
  }

  translations = true;

  toggleTranslations = () => {
    this.translations = !this.translations;
    this.writeToSession();
  };

  buildFromSession = () => {
    let json = JSON.parse(sessionStorage.getItem('FeatureToggleStore'));
    if(json) {
      this.translations = json.translations;
    }
  };

  writeToSession = () => {
    let json = JSON.stringify(this);
    sessionStorage.setItem('FeatureToggleStore', json);
  };
}

decorate(FeatureToggleStore, {
  translations: observable
});

export default FeatureToggleStore;