import React from "react";
import {Formik, Form} from "formik";
import Tables from "../utilities/Tables";
import config from "../../../config";
import EditableContent from "../../../components/EditableContent/EditableContent";
import {useTranslation} from "react-i18next";

const emptyItem = (
  item = "",
  label = null,
  whatTheyDo = "",
  allowRemoval = false
) => ({
  item: {
    value: label,
    inputType: "labelWithAddTrans",
    label: label,
    columnWidth: 2,
    allowRemoval: allowRemoval,
  },
  whatTheyDo: {
    value: whatTheyDo,
    inputType: "questionlabel",
    columnName: "Value",
  },
  contact: {value: "", inputType: "textarea", rows: 5, label: "", columnName: "Possible Team Members Contact Information"},
  notes: {value: "", inputType: "textarea", rows: 5},
});

const initialValues = {
  myAssetsSection: {
    "real estate agent": [
      emptyItem(
        "",
        "Real estate agent",
        "Professional who can help you find an affordable home that meets your needs. The real estate agent can also help guide you through the process of buying a home."
      ),
    ],
    appraiser: [
      emptyItem(
        "",
        "Appraiser",
        "Professional who provides an estimate of a home’s value."
      ),
    ],
    lender: [
      emptyItem(
        "",
        "Lender",
        "Professional who qualifies you for a loan. Also lends you the money to help you buy the home."
      ),
    ],
    "housing inspector": [
      emptyItem(
        "",
        "Housing inspector",
        "Professional who identifies key problems with the home before you buy it."
      ),
    ],
    "housing counselor": [
      emptyItem(
        "",
        "Housing counselor",
        "Professional who provides education and counseling on the home buying process."
      ),
    ],
    attorney: [
      emptyItem(
        "",
        "Attorney",
        "Professional licensed to practice law. In some real estate transactions, an attorney may perform functions such as writing the real estate contract, searching the title, and conducting the closing."
      ),
    ],
    "title insurance agent": [
      emptyItem(
        "",
        "Title insurance agent",
        "Professional who researches the title of the property to make sure the seller has the right to sell it. The professional also checks to see what claims lenders or other parties may have to the property."
      ),
    ],
    "insurance agent": [
      emptyItem(
        "",
        "Insurance agent",
        "Professional who recommends how much homeowners insurance you need and how much it will cost."
      ),
    ],
  },
};

const ChoosingHomeBuyingTeam = ({tool, canEdit}) => {
  const {t, i18n} = useTranslation();
  return (
    <div>
      <Formik initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <EditableContent
              htmlType="div"
              initialContent={
                i18n.language === "es"
                  ? tool.contentsEs || tool.contents
                  : tool.contents
              }
              fieldName={i18n.language === "es" ? "contents_es" : "contents"}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            {Tables.renderSimpleTable(
              [
                t('Team Member'),
                t('What They Do'),
                t('Possible Team Members Contact Information'),
                t('Notes'),
              ],
              initialValues.myAssetsSection,
              formikProps,
              "myAssetsSection",
              true,
              (value, label) => emptyItem(value, label, "", true),
              undefined,
              false,
              true
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ChoosingHomeBuyingTeam;
