import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useTranslation} from 'react-i18next';

const para = (text = '', bold = false, i = 0) => {
  if (text === '') return <div key={i} className='padding-top-1'></div>;
  return (
    <p key={i} className={`margin-0${bold ? ' text-bold' : ''}`}>
      {text}
    </p>
  );
};

const handleText = (text, isHeader = false) => {
  if (Array.isArray(text)) return text.map((t, i) => para(t, isHeader, i));
  else return para(text, isHeader);
};

const displayAlert = (header = null, message = 'message', type = 'info') => (
  <div className={`usa-alert usa-alert--${type}`}>
    <div className='usa-alert__body'>
      <div className='usa-alert__text padding-right-6'>
        {header !== null && handleText(header, true)}
        {handleText(message)}
      </div>
    </div>
  </div>
);

const TempMessage = () => {
  const [show, setShow] = useState(false);
  const [header, setHeader] = useState(['']);
  const [headerEs, setHeaderEs] = useState(['']);
  const [message, setMessage] = useState(['']);
  const [messageEs, setMessageEs] = useState(['']);
  const [level, setLevel] = useState('info');

  const {i18n} = useTranslation();

  useEffect(() => {
    axios
      .get('/message.json')
      .then((res) => {
        const {data} = res;
        setShow(data.show);
        setHeader(data.header);
        setHeaderEs(data.headerEs);
        setMessage(data.message);
        setMessageEs(data.messageEs);
        setLevel(data.level);
      })
      .catch();
  }, []);

  if (!show) return null;
  const isSpanish = i18n.language === 'es';
  const h = isSpanish ? headerEs : header;
  const m = isSpanish ? messageEs : message;
  return displayAlert(h, m, level);
};

export default TempMessage;
