import React, {useState} from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import RequestService from '../../../services/RequestService';
import config from '../../../config';
import Alert from './Alert';

const PlayerAdmin = (props) => {
  const [alertMode, setAlertMode] = useState('none');
  const [alertMessage, setAlertMessage] = useState('');

  const formValidation = (values) => {
    const errors = {};
    const validId = values.playerId > 0;
    const validEmail = values.playerEmail.length > 6;
    const iDontKnow = values.iDontKnow;
    const validAccountType = values.accountType !== '';
    if (!validId && !iDontKnow) {
      errors.playerId = 'Enter a valid Player ID';
    }
    if (!validEmail && iDontKnow) {
      errors.playerEmail = 'Enter player’s email address';
    }
    if (values.action === 'change-account-type' && !validAccountType) {
      errors.accountType = 'Select an account type';
    }
    return errors;
  };

  const submitForm = (values, setSubmitting, resetForm) => {
    const body = {};
    values.iDontKnow ? (body.email = values.playerEmail) : (body.user_id = parseInt(values.playerId));
    if (values.accountType !== '') {
      body.account_type = values.accountType;
    }
    if (values.action === 'delete-player') {
      const endpoint = `${config.apiGateway.URL}/admin/delete-user`;
      RequestService.post(
        endpoint,
        body,
        (response) => handleResponse(response, setSubmitting, resetForm, values),
        (response) => handleFailedRequest(response, setSubmitting, resetForm),
      );
    } else {
      const endpoint = `${config.apiGateway.URL}/admin/change-user-account-type`;
      RequestService.post(
        endpoint,
        body,
        (response) => handleResponse(response, setSubmitting, resetForm, values),
        (response) => handleFailedRequest(response, setSubmitting, resetForm),
      );
    }
  };

  const handleResponse = (response, setSubmitting, resetForm, values) => {
    setAlertMode(response.data.success ? 'success' : 'error');
    const id = values.iDontKnow ? values.playerEmail : `ID: ${values.playerId}`;
    let message;
    if (response.data.success) {
      message =
        values.accountType === '' ? (
          <span>Successfully deleted Player {id}.</span>
        ) : (
          <span>
            Successfully updated the account type to {values.accountType} for Player {id}.
          </span>
        );
    } else {
      message = <span>Unable to find Player {id}. Ensure you enter a valid Player ID or email.</span>;
    }
    setAlertMessage(message);
    cleanUpAfterSubmit(setSubmitting, resetForm);
  };

  const handleFailedRequest = (response, setSubmitting, resetForm) => {
    console.error(response);
    setAlertMode('error');
    setAlertMessage(
      <span>
        Something went wrong with the network request. Please try again and if you experience continued issues, contact
        a system admin.
      </span>,
    );
    cleanUpAfterSubmit(setSubmitting, resetForm);
  };

  const cleanUpAfterSubmit = (setSubmitting, resetForm) => {
    setSubmitting(false);
    resetForm();
    document.querySelectorAll('.usa-radio__input').forEach((node) => {
      node.checked = false;
    });
    document.querySelectorAll('.usa-checkbox__input').forEach((node) => {
      node.checked = false;
    });
  };

  const handlePlayerIdInput = (e) => {
    if (e.key === 'Backspace' || e.key === 'Delete' || e.key === 'Tab') return;
    if (isNaN(parseInt(e.key))) {
      e.preventDefault();
    }
  };

  const accountTypes = ['individual', 'admin'];
  const displayForAccountTypes = ['Individual Player', 'FDIC Administrator'];
  if (props.authStore.isEditor) {
    accountTypes.push('editor');
    displayForAccountTypes.push('FDIC Editor');
  }

  return (
    <div className='player-admin-component'>
      <h1>Player Administration</h1>
      <p>
        You can permanently delete player accounts. Game play history and certificates for deleted accounts will be lost
        and not included in statistics. The player can create a new account with the same or different email address if
        they wish. You can manually add back certificates, but not game play history.
      </p>
      <p>
        You can also change player account types. Changing a player's account type does not affect game history or
        certificates.
      </p>
      <p>You cannot delete Organization accounts here. You do that through the Organization tab.</p>

      <Alert message={alertMessage} mode={alertMode} />

      <Formik
        initialValues={{playerId: '', playerEmail: '', accountType: '', action: '', iDontKnow: false}}
        validate={formValidation}
        onSubmit={async (values, {setSubmitting, resetForm}) => {
          await submitForm(values, setSubmitting, resetForm);
        }}
      >
        {({isSubmitting, isValid, values}) => (
          <Form className='usa-form usa-form--large'>
            <fieldset className='usa-fieldset'>
              <legend className='usa-legend margin-bottom-2'>What would you like to do?</legend>

              <div role='group'>
                <div className='usa-radio'>
                  <Field
                    type='radio'
                    id='change-account-type-radio'
                    className='usa-radio__input'
                    name='action'
                    value='change-account-type'
                  />
                  <label className='usa-radio__label' htmlFor='change-account-type-radio'>
                    Change Player Account Type
                  </label>
                </div>
                <div className='usa-radio'>
                  <Field
                    type='radio'
                    id='delete-player-radio'
                    className='usa-radio__input'
                    name='action'
                    value='delete-player'
                  />
                  <label className='usa-radio__label' htmlFor='delete-player-radio'>
                    Delete a Player Account
                  </label>
                </div>
              </div>

              <label className='usa-label display-inline-block margin-right-4 margin-top-2'>
                Player ID:
                <Field
                  type='text'
                  name='playerId'
                  className='usa-input width-15'
                  maxLength={10}
                  onKeyDown={(e) => handlePlayerIdInput(e)}
                  disabled={values.action === '' || values.iDontKnow}
                />
              </label>
              <div className='usa-checkbox display-inline-block'>
                <Field
                  id='delete-checkbox'
                  type='checkbox'
                  name='iDontKnow'
                  className='usa-checkbox__input'
                  disabled={values.action === ''}
                />
                <label className='usa-checkbox__label' htmlFor='delete-checkbox'>
                  Use Player's email instead
                </label>
              </div>
              {!values.iDontKnow && <ErrorMessage name='playerId' component='div' className='usa-error-message' />}

              {values.iDontKnow && (
                <label className='usa-label force-cert-label'>
                  Player Email:
                  <Field type='email' name='playerEmail' className='usa-input' />
                  <ErrorMessage name='playerEmail' component='div' className='usa-error-message' />
                </label>
              )}

              {values.action === 'change-account-type' && (
                <label className='usa-label force-cert-label'>
                  Change to this Account Type:
                  <Field component='select' name='accountType' className='usa-input' disabled={values.delete}>
                    <option key={0} value=''>
                      -- Select an account type --
                    </option>
                    {accountTypes.map((type, i) => (
                      <option key={type} value={type}>
                        {displayForAccountTypes[i]}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name='accountType' component='div' className='usa-error-message' />
                </label>
              )}
              <button
                type='submit'
                className='usa-button usa-button--force-add-submit display-block'
                disabled={isSubmitting || !isValid}
              >
                {isSubmitting ? 'Submitting...' : 'Submit'}
              </button>
            </fieldset>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PlayerAdmin;
