import React from 'react';
import {PropTypes} from 'prop-types';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

const GameSessionRow = ({game, gameSession, gameIcon, gameIconAltText, from}) => {
  const {t, i18n} = useTranslation();
  const isSpanish = i18n.language === 'es';
  const gameName = isSpanish ? game.name_es : game.name;
  return (
    <tr key={game.id}>
      <th scope='row'>
        <img alt={gameIconAltText} src={gameIcon} />
      </th>
      <td className='display-flex vertical-align flex-column'>
        <div>
          {game.id !== 15 ? (
            <Link to={`/game/${game.id}?openGame=true`}>
              <h3>
                <u>{gameName}</u>
              </h3>
            </Link>
          ) : (
            <h3>{gameName}</h3>
          )}
        </div>
        {/* <RenderManagingDebtTempMessage gameName={game.name} isSpanish={isSpanish} /> */}
      </td>
      <td>
        <RenderCertLink game={game} gameSession={gameSession} from={from} t={t} />
      </td>
    </tr>
  );
};

GameSessionRow.propTypes = {
  game: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }),
  gameSession: PropTypes.shape({
    complete_date: PropTypes.string,
  }),
  gameIcon: PropTypes.any,
};

export default GameSessionRow;

const RenderCertLink = ({game, gameSession, from, t}) => {
  if (gameSession && gameSession.complete_date) {
    let href = `/certificate/${game.id}/${gameSession.user_id}`;
    // the from is to support admin + org accounts accessing their own certificates
    //  from their own dashboards (and still support them accessing certificates
    //  from game reports)
    if (from) {
      href += `?from=${from}`;
    }
    const viewCertText = t('View certificate');
    return (
      <h3 className='margin-left-2'>
        <Link className='text-decoration-none font-family-merriweather' to={href}>
          {viewCertText}
        </Link>
      </h3>
    );
  }
  return <></>;
};

/////////////////////////////////////////////////
// TEMP COMPONENT UNTIL Managing Debt IS UPDATED:
/////////////////////////////////////////////////
// const RenderManagingDebtTempMessage = ({gameName, isSpanish}) => {
//   if (gameName !== 'Managing Debt') return null;
//   const message = isSpanish
//     ? '**Cambios importantes sobre las deudas médicas. Vaya a '
//     : '**Important Change to medical debt. View ';
//   const hrefText = isSpanish ? 'Administrar sus deudas' : 'Managing Debt gamepage';
//   const message2 = isSpanish ? ' para conocer estos cambios.' : '.';
//   return (
//     <div className='margin-top-neg-2'>
//       <strong>{message}</strong>
//       <a href={'/game/managing-debt'}>{hrefText}</a>
//       <strong>{message2}</strong>
//     </div>
//   );
// };
