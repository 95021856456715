import React from "react";
import {Formik, Form, FieldArray, Field} from "formik";
import Tables from "../utilities/Tables";
import Inputs from "../utilities/Inputs";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation, Translation} from "react-i18next";

// WARNING: This tool was made as a prototype so much of the code is very sketchy and may be difficult to use.

const emptyItem = (
  item = "",
  isOther = false,
  otherText = "Other:",
  increment = 1,
  showIncrement = false,
  allowRemoval = false
) => ({
  item: item,
  amount: "",
  frequency: "Every month",
  monthlyAmount: "",
  nextMonthAmount: "",
  isOther: isOther,
  otherText: otherText,
  increment: increment,
  showIncrement: showIncrement,
  allowRemoval: allowRemoval,
});

const initialValues = {
  month: "January",
  sections: {
    Saving: [
      emptyItem("Saving toward: An emergency fund"),
      emptyItem("", true, "Other saving item:", false),
    ],
    Sharing: [
      emptyItem("Sharing with family and friends"),
      emptyItem("Charitable contributions"),
      emptyItem("", true, "Other sharing item:", false),
    ],
    Spending: [
      emptyItem("Rent or Mortgage payment"),
      emptyItem("Property taxes, insurance"),
      emptyItem("Water"),
      emptyItem("Electric"),
      emptyItem("Gas, oil"),
      emptyItem("Trash collection"),
      emptyItem("Telephone (cell and land-line)"),
      emptyItem("Internet"),
      emptyItem("Cable, satellite, TV viewing services"),
      emptyItem("Car or truck payment"),
      emptyItem("Car or truck insurance"),
      emptyItem("Car or truck maintenance and repair"),
      emptyItem("Car or truck fuel"),
      emptyItem("Public transportation"),
      emptyItem(
        "Health insurance (part not covered by employer or taken out of gross pay)"
      ),
      emptyItem("Other healthcare expenses"),
      emptyItem("Student loan payments"),
      emptyItem("Credit card debt payments"),
      emptyItem("Other debt payments"),
      emptyItem("Personal care attendant"),
      emptyItem("Eldercare"),
      emptyItem("Childcare, child support payments"),
      emptyItem("Groceries, household supplies"),
      emptyItem("Eating out, take-out"),
      emptyItem("Service animal expenses"),
      emptyItem("Pet care"),
      emptyItem("Personal expenses"),
      emptyItem("Entertainment"),
      emptyItem("", true, "Other spending item:", false),
    ],
  },
  incomeSections: {
    Income: [
      emptyItem("Net (take-home) pay 1", false, "Net (take-home) pay", 1, true),
      emptyItem(
        "Net self-employment income 1",
        false,
        "Net self-employment income",
        1,
        true
      ),
      emptyItem("Public benefit 1", false, "Public benefit", 1, true),
      emptyItem("Interest"),
      emptyItem("Dividends"),
      emptyItem("Child support"),
      emptyItem("Alimony"),
      emptyItem("Gifts"),
      emptyItem("", true, "Other income item:", false),
    ],
  },
};

const addRowButton = (onClick, title) => (
  <button
    title={title || "Add item"}
    type="button"
    className="add-row-button usa-button float-left mobile-full-width"
    onClick={onClick}
  >
    +
  </button>
);

const removeRowButton = (onClick) => (
  <button
    title="Remove item"
    type="button"
    className="remove-row-button usa-button float-left margin-top-105 mobile-full-width"
    onClick={onClick}
  >
    -
  </button>
);

const monthlyAmount = (input, calculation) =>
  Math.round(calculation(input) * 100) / 100;

const calculateSectionTotal = (section) => {
  let total = 0;
  section.forEach(
    (expense) =>
      (total += monthlyAmount(
        expense.amount,
        Tables.frequencies[expense.frequency]
      ))
  );
  return Math.round(total * 100) / 100;
};

const calculateNextMonthTotal = (section) => {
  let total = 0;
  section.forEach((expense) => {
    expense.nextMonthAmount !== ""
      ? (total += monthlyAmount(
        expense.nextMonthAmount,
        Tables.frequencies["Every month"]
      ))
      : (total += monthlyAmount(
        expense.amount,
        Tables.frequencies[expense.frequency]
      ));
  });
  return Math.round(total * 100) / 100;
};

const calculateNextMonthGrandTotal = (sections) => {
  let total = 0;
  sections.forEach((section) => (total += calculateNextMonthTotal(section)));
  return Math.round(total * 100) / 100;
};

const calculateGrandTotal = (sections) => {
  let total = 0;
  sections.forEach((section) => (total += calculateSectionTotal(section)));
  return Math.round(total * 100) / 100;
};

const renderTableSection = (
  formikProps,
  key,
  sectionIdentifier,
  _sections,
  hideSectionLabel
) => {
  const tableRowsArray = formikProps.values[sectionIdentifier][key];
  return (
    <Translation key={key}>
      {(t) => (
        <FieldArray
          name={`${sectionIdentifier}.${key}`}
          render={(arrayHelpers) => (
            <tbody>
              {tableRowsArray.map((item, index) => {
                const tableRowObject = tableRowsArray[index];
                console.log(item);
                return (
              
                  <tr key={`${key}-${index}`}>
                    <th key={`item-${key}-${index}`} className="col-4" scope="row">
                      {index < tableRowsArray.length - 1 ? (
                        [
                          t('Net (take-home) pay 1'),
                          t('Net self-employment income 1'),
                          t('Public benefit 1'),
                        ].includes(tableRowObject.item) ||
                    tableRowObject.isOther ? (
                            <div className="grid-row align-center">
                              <div className="grid-col-11">
                                {!tableRowObject.isOther ? (
                                  <span>{t(tableRowObject.otherText)}</span>
                                ) : (
                                  <div>
                                    <label
                                      htmlFor={`${sectionIdentifier}.${key}.${index}.item`}
                                    >
                                      {t(tableRowObject.otherText)}{" "}
                                      {tableRowObject.showIncrement &&
                                  tableRowObject.increment}
                                    </label>
                                    {!tableRowObject.increment && (
                                      <Field
                                        title={t('Details')}
                                        name={`${sectionIdentifier}.${key}.${index}.item`}
                                      />
                                    )}
                                  </div>
                                )}
                              </div>
                            </div>
                          ) : (
                            <span>{t(tableRowObject.item)}</span>
                          )
                      ) : (
                        <div className="grid-row align-center">
                          <div className="grid-col-12">
                            <label
                              htmlFor={`${sectionIdentifier}.${key}.${index}.item`}
                            >
                              {t(tableRowObject.otherText)}
                            </label>
                            <Field
                              title={t(tableRowObject.otherText)}
                              name={`${sectionIdentifier}.${key}.${index}.item`}
                            />
                          </div>
                        </div>
                      )}
                    </th>
                    <td className="col-2">
                      <span className="money-input">
                        <Field
                          title={t('Amount')}
                          id={`amount-${key}-${index}`}
                          placeholder="0"
                          step=".01"
                          type="number"
                          name={`${sectionIdentifier}.${key}.${index}.amount`}
                        />
                      </span>
                    </td>
                    <td className="col-2">
                      <select
                        name={`${sectionIdentifier}.${key}.${index}.frequency`}
                        value={tableRowObject.frequency}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        title={t('Frequency')}
                      >
                        {Object.keys(Tables.frequencies).map((frequency) => (
                          <option
                            key={`${key}-${index}-${frequency}`}
                            value={frequency}
                          >
                            {t(frequency)}
                          </option>
                        ))}
                      </select>
                    </td>
                    <td>
                      <b>$</b>
                      {monthlyAmount(
                        tableRowObject.amount,
                        Tables.frequencies[tableRowObject.frequency]
                      )
                        .toFixed(2)
                        .withCommas()}
                    </td>
                    <td className="col-2">
                      <div className="grid-row">
                        <div className="grid-col-10">
                          <span className="money-input">
                            <Field
                              title={t('Amount')}
                              id={`amount-${key}-${index}`}
                              step=".01"
                              type="number"
                              placeholder={monthlyAmount(
                                tableRowObject.amount,
                                Tables.frequencies[tableRowObject.frequency]
                              )}
                              name={`${sectionIdentifier}.${key}.${index}.nextMonthAmount`}
                            />
                          </span>
                        </div>
                        <div className="grid-col-2 mobile-full-width">
                          {(index === tableRowsArray.length - 1 ||
                        tableRowObject.showIncrement ||
                        tableRowObject.allowRemoval) &&
                        addRowButton(
                          () =>
                            arrayHelpers.insert(
                              index + 1,
                              emptyItem(
                                "",
                                true,
                                tableRowObject.otherText,
                                tableRowObject.increment
                                  ? tableRowObject.increment + 1
                                  : false,
                                tableRowObject.showIncrement,
                                true
                              )
                            ),
                          t('Add item')
                        )}
                          {tableRowObject.allowRemoval &&
                        removeRowButton(() => arrayHelpers.remove(index))}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
              <tr>
                <th className="subtotal-row">
                  <span tabIndex="0">
                    {key === "Income" ? t('My Total Net Income') : t('Total') + ` ` + t(key)}
                  </span>
                </th>
                <td></td>
                <td></td>
                <td className="subtotal-row">
                  <span tabIndex="0">
                ${calculateSectionTotal(tableRowsArray).toFixed(2).withCommas()}
                  </span>
                </td>
                <td className="subtotal-row">
                  <span tabIndex="0">
                $
                    {calculateNextMonthTotal(tableRowsArray)
                      .toFixed(2)
                      .withCommas()}
                  </span>
                </td>
              </tr>
            </tbody>
          )}
        />
      )}
    </Translation>
  );
};

const renderSummaryTable = (formikProps) => {
  const incomeSectionList = Object.keys(formikProps.values.incomeSections).map(
    (key) => formikProps.values.incomeSections[key]
  );
  const expenseSectionList = Object.keys(formikProps.values.sections).map(
    (key) => formikProps.values.sections[key]
  );
  const netIncome = calculateGrandTotal(incomeSectionList);
  const nextMonthIncome = calculateNextMonthGrandTotal(incomeSectionList);
  const totalExpense = calculateGrandTotal(expenseSectionList);
  const nextMonthExpense = calculateNextMonthGrandTotal(expenseSectionList);
  return (
    <Translation>
      {(t) => {
        const headers = [t('Category'), t('Past Monthly Amount'), t('Planned Monthly Amount')];
        const title = `${t('Summary table')}: ${headers.join(', ')}`;
        return (
          <table
            id="comparisonTable"
            className="margin-top-2 usa-table summary-table"
            title={title}
          >
            <thead>
              <tr>
                {headers.map((h, i) => <th key={i} scope='col'>{h}</th>)}
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className="padding-left-2 padding-top-1 padding-bottom-1 col-6">
                  {t('My Total Net Income')}
                </th>
                <td className="padding-left-2 padding-top-1 padding-bottom-1 col-3">
              ${netIncome.toFixed(2).withCommas()}
                </td>
                <td className="padding-left-2 padding-top-1 padding-bottom-1 col-3">
              ${nextMonthIncome.toFixed(2).withCommas()}
                </td>
              </tr>
              <tr>
                <th className="padding-left-2 padding-top-1 padding-bottom-1">
                  {t('My Total Expenses')}
                </th>
                <td className="padding-left-2 padding-top-1 padding-bottom-1">
              ${totalExpense.toFixed(2).withCommas()}
                </td>
                <td className="padding-left-2 padding-top-1 padding-bottom-1">
              ${nextMonthExpense.toFixed(2).withCommas()}
                </td>
              </tr>
              <tr>
                <th className="padding-left-2 padding-top-1 padding-bottom-1 background-color-white">
                  <b tabIndex="0">{t('Difference')}</b>
                  <br></br>{t('(My Total Net Income minus My Total Expenses)')}
                </th>
                <td className="padding-left-2 padding-top-1 padding-bottom-1 vertical-align-top background-color-white">
                  <b tabIndex="0">{renderDifference(netIncome, totalExpense)}</b>
                </td>
                <td className="padding-left-2 padding-top-1 padding-bottom-1 vertical-align-top background-color-white">
                  <b tabIndex="0">
                    {renderDifference(nextMonthIncome, nextMonthExpense)}
                  </b>
                </td>
              </tr>
            </tbody>
          </table>
        );
      }}
    </Translation>
  );
  
};

const renderExpensesTable = (formikProps) => {
  const saving = calculateSectionTotal(
    formikProps.values["sections"]["Saving"]
  );
  const sharing = calculateSectionTotal(
    formikProps.values["sections"]["Sharing"]
  );
  const spending = calculateSectionTotal(
    formikProps.values["sections"]["Spending"]
  );
  const nextSaving = calculateNextMonthTotal(
    formikProps.values["sections"]["Saving"]
  );
  const nextSharing = calculateNextMonthTotal(
    formikProps.values["sections"]["Sharing"]
  );
  const nextSpending = calculateNextMonthTotal(
    formikProps.values["sections"]["Spending"]
  );
  const totalThisMonth = saving + sharing + spending;
  const totalNextMonth = nextSaving + nextSharing + nextSpending;
  return (
    <Translation>
      {(t) => {
        const tableH1 = t('Category');
        const tableH2 = t('Past Monthly Amount');
        const tableTitle = `${t('Summary table')}: ${tableH1}, ${tableH2}`;
        return (
          <table
            className="margin-top-2 usa-table summary-table"
            title={tableTitle}
          >
            <thead>
              <tr>
                <th
                  className="padding-left-2 padding-top-1 padding-bottom-1"
                  scope="col"
                >
                  {tableH1}
                </th>
                <th
                  className="padding-left-2 padding-top-1 padding-bottom-1"
                  scope="col"
                >
                  {tableH2}
                </th>
                <th scope="col">{t('Planned Monthly Amount')}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="col-6">{t('My Total Saving')}</td>
                <td className="col-3">${saving.toFixed(2).withCommas()}</td>
                <td className="col-3">${nextSaving.toFixed(2).withCommas()}</td>
              </tr>
              <tr>
                <td>{t('My Total Sharing')}</td>
                <td>${sharing.toFixed(2).withCommas()}</td>
                <td>${nextSharing.toFixed(2).withCommas()}</td>
              </tr>
              <tr>
                <td>{t('My Total Spending')}</td>
                <td>${spending.toFixed(2).withCommas()}</td>
                <td>${nextSpending.toFixed(2).withCommas()}</td>
              </tr>
              <tr>
                <td>
                  <b tabIndex="0">{t('My Total Expenses')}</b>
                </td>
                <td>
                  <b tabIndex="0">${totalThisMonth.toFixed(2).withCommas()}</b>
                </td>
                <td>
                  <b tabIndex="0">${totalNextMonth.toFixed(2).withCommas()}</b>
                </td>
              </tr>
            </tbody>
          </table>
        );
      }}
    </Translation>
  );
};

const renderDescriptionTable = () => (
  <Translation>
    {(t) => {
      const headers = [t('If the Difference is:'), t('That means:'), t('And so:')];
      const title = buildTableTitle(t, headers);
      return (
        <table className="usa-table" title={title}>
          <thead>
            <tr>
              {headers.map((h, i) => <th key={i}>{h}</th>)}
            </tr>
          </thead>
          <tbody>
            <tr>
              <th className="col-2">
                <b>{t('Zero (0)')}</b>
              </th>
              <td>{t('Your income is the same as your expenses.')}</td>
              <td>{t('You should have just enough income to cover your expenses.')}</td>
            </tr>
            <tr>
              <th>
                <b>{t('A positive number')}</b>
              </th>
              <td>{t('You have more income than expenses.')}</td>
              <td>{t('You should have enough income to cover your expenses.')}</td>
            </tr>
            <tr>
              <th>
                <b>{t('A negative number')}</b>
              </th>
              <td>{t('You have more expenses than income.')}</td>
              <td>
                {t('You would likely not have enough income to cover your expenses. You will need to increase your income, decrease your expenses, or incur debt to make it possible to pay your expenses.')}
              </td>
            </tr>
          </tbody>
        </table>
      );
    }}
  </Translation>
);

const renderDifference = (income, expense) => (
  <span
    className={`${
      income - expense !== 0
        ? income - expense < 0
          ? "red-text"
          : "green-text"
        : ""
    }`}
  >
    ${(Math.round((income - expense) * 100) / 100).toFixed(2).withCommas()}
  </span>
);

const buildTableTitle = (t, headers) => {
  return `${t('Table')}: ${headers.join(', ')}`;
};

const SpendingSavingPlan = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  const tableHeadersIncome = [
    t('Item'),
    t('Income Amount'),
    t('How often do you receive this income?'),
    t('Past Monthly Amount'),
    t('Planned Monthly Amount'),
  ];
  const tableTitleIncome = buildTableTitle(t, tableHeadersIncome);
  const tableHeadersSavings = [
    t('Item'),
    t('Saving Amount'),
    t('How often do you save this amount?'),
    t('Past Monthly Amount'),
    t('Planned Monthly Amount'),
  ];
  const tableTitleSavings = buildTableTitle(t, tableHeadersSavings);
  const tableHeadersSharings = [
    t('Item'),
    t('Sharing Amount'),
    t('How often do you share this amount?'),
    t('Past Monthly Amount'),
    t('Planned Monthly Amount'),
  ];
  const tableTitleSharings = buildTableTitle(t, tableHeadersSharings);
  const tableHeadersSpendings = [
    t('Item'),
    t('Spending Amount'),
    t('How often do you pay this expense?'),
    t('Past Monthly Amount'),
    t('Planned Monthly Amount'),
  ];
  const tableTitleSpendings = buildTableTitle(t, tableHeadersSpendings);
  return (
    <div>
      <Formik initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <EditableContent
              htmlType="div"
              initialContent={
                i18n.language === "es"
                  ? tool.contentsEs || tool.contents
                  : tool.contents
              }
              fieldName={i18n.language === "es" ? "contents_es" : "contents"}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            <div className="grid-row page-break-before-always">
              {Inputs.monthSelect(
                formikProps,
                t('My Spending and Saving Plan for the Month of: ')
              )}
            </div>
            <h2 tabIndex="0" id="incomes" className="margin-top-3">
              {t('My Net Income')}
            </h2>
            <a href="#expenses">
              <b>{t('Jump to My Expenses')}</b>
            </a>{" "}
            <br></br>
            <a href="#summary">
              <b>{t('Jump to Comparison')}</b>
            </a>
            <div className="overflow-x-scroll">
              <table className="usa-table" title={tableTitleIncome}>
                {Tables.renderTableHeader(tableHeadersIncome)}
                {Tables.renderTableSections(
                  initialValues.incomeSections,
                  formikProps,
                  renderTableSection,
                  "incomeSections",
                  true
                )}
              </table>
            </div>
            <h2
              tabIndex="0"
              id="expenses"
              className="margin-top-6 page-break-before-always"
            >
              {t('My Expenses')}
            </h2>
            <a href="#incomes">
              <b>{t('Jump to My Net Income')}</b>
            </a>{" "}
            <br></br>
            <a href="#summary">
              <b>{t('Jump to Comparison')}</b>
            </a>
            <h3 className="margin-bottom-0">{t('My Saving')}</h3>
            <div className="overflow-x-scroll">
              <table className="usa-table" title={tableTitleSavings}>
                {Tables.renderTableHeader(tableHeadersSavings)}
                {Tables.renderTableSections(
                  {Saving: initialValues.sections.Saving},
                  formikProps,
                  renderTableSection,
                  "sections"
                )}
              </table>
              <h3 className="margin-bottom-0">{t('My Sharing')}</h3>
              <table className="usa-table" title={tableTitleSharings}>
                {Tables.renderTableHeader(tableHeadersSharings)}
                {Tables.renderTableSections(
                  {Sharing: initialValues.sections.Sharing},
                  formikProps,
                  renderTableSection,
                  "sections"
                )}
              </table>
              <h3 className="margin-bottom-0 page-break-before-always">
                {t('My Spending')}
              </h3>
              <table className="usa-table" title={tableTitleSpendings}>
                {Tables.renderTableHeader(tableHeadersSpendings)}
                {Tables.renderTableSections(
                  {Spending: initialValues.sections.Spending},
                  formikProps,
                  renderTableSection,
                  "sections"
                )}
              </table>
            </div>
            <h3 className="page-break-before-always" tabIndex="0">
              {t('My Total Expenses for')} {t(formikProps.values.month)}
            </h3>
            <div className="grid-col-8 print-full-width">
              <a href="#incomes">
                <b>{t('Jump to My Net Income')}</b>
              </a>{" "}
              <br></br>
              <a href="#expenses">
                <b>{t('Jump to My Expenses')}</b>
              </a>
            </div>
            <div className="grid-col-10 mobile-full-width">
              {renderExpensesTable(formikProps)}
            </div>
            <h3 className="page-break-before-always" tabIndex="0" id="summary">
              {t('Comparison of My Total Net Income and My Total Expenses for')}{" "}
              {t(formikProps.values.month)}
            </h3>
            <div className="grid-col-8 print-full-width">
              <a href="#incomes">
                <b>{t('Jump to My Net Income')}</b>
              </a>{" "}
              <br></br>
              <a href="#expenses">
                <b>{t('Jump to My Expenses')}</b>
              </a>
            </div>
            <div className="grid-col-10 mobile-full-width">
              {renderSummaryTable(formikProps)}
            </div>
            <p>
              {t('When you compare your income and expenses, you will have three possible outcomes.')}
            </p>
            <div className="grid-col-10 mobile-full-width">
              {renderDescriptionTable()}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default SpendingSavingPlan;
