import React from 'react';
import {observer} from 'mobx-react';
import queryString from 'query-string';
import Fuse from 'fuse.js';
import {withTranslation} from 'react-i18next';

import SearchField from './components/SearchField';
import {Loader} from '../../components/Loader';
import HelpTopicsList from './components/HelpTopicsList';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import LanguageSelector from '../../components/Buttons/LanguageSelector';

const fuseOptions = {
  matchAllTokens: true,
  tokenize: true,
  shouldSort: true,
  threshold: 0.2,
  location: 0,
  distance: 10,
  maxPatternLength: 32,
  minMatchCharLength: 3,
  keys: [
    {
      name: 'name',
      weight: 0.85,
    },
    {
      name: 'details',
      weight: 0.1,
    },
  ],
};

class SearchHelpTopics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search: queryString.parse(props.history.location.search).search,
    };
  }
  componentDidMount() {
    this.props.helpCenterStore.getHelpTopics();
  }

  searchHelpTopics = (isSpanish) => {
    let opts = fuseOptions;
    if (isSpanish) {
      opts = {
        ...fuseOptions,
        keys: [
          {name: 'name_es', weight: 0.85},
          {name: 'details_es', weight: 0.1},
        ],
      };
    }
    let fuseHelpTopics = new Fuse(this.props.helpCenterStore.helpTopics, opts);
    return fuseHelpTopics.search(this.state.search);
  };

  renderHelpTopicsResults = (t, searchedHelpTopics) => {
    const {helpCenterStore} = this.props;
    return (
      <div>
        {!helpCenterStore.helpTopicsLoading && (
          <h3 className='color-alternate-tan margin-bottom-5'>
            {`${t('Showing')} ${searchedHelpTopics.length} ${t('search result(s) for')} ‘${this.state.search}’`}
          </h3>
        )}
        <div className='condensed-topic-list'>
          {helpCenterStore.helpTopicsLoading ? <Loader /> : <HelpTopicsList helpTopics={searchedHelpTopics} />}
        </div>
      </div>
    );
  };

  render() {
    const {t, i18n} = this.props;
    const isSpanish = i18n.language === 'es';
    let searchedHelpTopics = this.searchHelpTopics(isSpanish);
    return (
      <div className='help-center-search grid-container'>
        <BreadCrumb
          historyLinks={[
            {
              link: '/help-center',
              display: 'Help Center',
            },
            {
              link: '#',
              display: 'glossary.searchButton', // Search
            },
          ]}
        />
        <h1>{t('Help Center')}</h1>
        <LanguageSelector {...this.props} />
        <SearchField handleSubmit={(search) => this.setState({search: search})} />
        {!this.state.search ? (
          <h3>{t('Please enter your search in the search bar above.')}</h3>
        ) : (
          this.renderHelpTopicsResults(t, searchedHelpTopics)
        )}
      </div>
    );
  }
}

export default withTranslation()(observer(SearchHelpTopics));
