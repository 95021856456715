import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import moment from 'moment';
import {observer} from 'mobx-react';
import {Link} from 'react-router-dom';

import {Loader} from '../../../../components/Loader';
import DateFilter from '../../../../components/DateFilter/DateFilter';

const defaultStartDate = moment().subtract(1, 'days').format('YYYY-MM-DD');

const defaultEndDate = moment().format('YYYY-MM-DD');

const renderAuditLogs = (auditLogs) => {
  return (
    <table className='usa-table'>
      <thead>
        <tr>
          <td>
            <b>Action</b>
          </td>
          <td>
            <b>Date</b>
          </td>
          <td>
            <b>User</b>
          </td>
          <td></td>
        </tr>
      </thead>
      <tbody>
        {auditLogs.map((auditLog, i) => (
          <tr key={i}>
            <td>{auditLog.action}</td>
            <td>{moment(auditLog.created_at).format('LLLL')}</td>
            <td>{auditLog.user.email}</td>
            <td>
              <Link to={`/audit-logs/${auditLog.id}`}>View</Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const AuditReport = ({auditLogStore, history}) => {
  auditLogStore.getAuditLogs(defaultStartDate, defaultEndDate, false, 1);
  const [startDate, setStartDate] = React.useState(defaultStartDate);
  const [endDate, setEndDate] = React.useState(defaultEndDate);

  return (
    <div>
      <h1>Audit Report</h1>
      <DateFilter
        startDate={defaultStartDate}
        endDate={defaultEndDate}
        applyFilter={({startDate, endDate}) => {
          setStartDate(startDate);
          setEndDate(endDate);
          auditLogStore.getAuditLogs(
            startDate,
            moment(endDate).add(1, 'days').format('YYYY-MM-DD'),
            true,
            auditLogStore.page,
          );
        }}
        history={history}
      />
      <div>
        {auditLogStore.auditLogsLoading ? (
          <Loader />
        ) : (
          <div className='margin-top-2'>
            <Pagination
              count={auditLogStore.pages}
              onChange={(_e, page) =>
                auditLogStore.getAuditLogs(startDate, moment(endDate).add(1, 'days').format('YYYY-MM-DD'), true, page)
              }
            />
            {renderAuditLogs(auditLogStore.auditLogs)}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(AuditReport);