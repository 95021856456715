import React from 'react';
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, LineChart, Line} from 'recharts';
import {Formik, Field, Form} from 'formik';

import RequestService from '../../../services/RequestService';
import config from '../../../config';
import moment from 'moment';
import PrintButton from '../../../components/Buttons/PrintButton';

import {reorderDomForRechartAccessibility} from '../../util';

// const defaultStartDate = moment("20210930").format('YYYY-MM-DD');
const sd = config.defaultQueryStartDate;
const defaultStartDate = moment(sd, 'YYYY-MM-DD').format('YYYY-MM-DD');

let defaultEndDate = moment().format('YYYY-MM-DD');

defaultEndDate = defaultEndDate < defaultStartDate ? defaultStartDate : defaultEndDate;

const CustomTooltip = ({active, payload, label}) => {
  if (active) {
    return (
      <div className='custom-tooltip'>
        <h4 className='label no-margin'>{`${label}`}</h4>
        <h5 className='label no-margin'>Tool ID: {`${payload[0].payload.tool_id}`}</h5>
        <p className='desc'>{`Uses: ${payload[0] ? payload[0].value : '0'}`}</p>
      </div>
    );
  }

  return null;
};

class CustomizedAxisTick extends React.PureComponent {
  render() {
    const {x, y, payload} = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text className='font-size-12' x={0} y={0} dy={16} textAnchor='end' fill='#666' transform='rotate(-60)'>
          {payload.value}
        </text>
      </g>
    );
  }
}

const mapList = (data) => {
  let mappedData = data.map((statistic) => ({
    mappedDate: moment.parseZone(statistic.created_at).format('LL'),
  }));
  let groupedData = {};
  mappedData.forEach((statistic) => {
    let currentCount = groupedData[statistic.mappedDate];
    if (currentCount) {
      groupedData[statistic.mappedDate] = currentCount + 1;
    } else {
      groupedData[statistic.mappedDate] = 1;
    }
  });
  const final = Object.keys(groupedData).map((date) => ({
    date: date,
    Uses: groupedData[date],
  }));
  // console.log(final);
  return final;
};

const ToolStatisticBarChart = ({data}) => (
  <div className='barchart-container'>
    <BarChart
      aria-hidden='false'
      width={1000}
      height={800}
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 30,
        bottom: 350,
      }}
    >
      <CartesianGrid strokeDasharray='1 1' />
      <XAxis dataKey='tool_name' tick={<CustomizedAxisTick />} textAnchor='end' interval={0} />
      <YAxis />
      <Tooltip content={<CustomTooltip />} />
      <Legend
        verticalAlign='top'
        align='right'
        wrapperStyle={{
          paddingBottom: '15px',
        }}
      />
      <Bar
        onClick={(bar) => (window.location = `/tools/${bar.tool_id}`)}
        dataKey='count'
        stackId='a'
        fill='#124C8F'
        isAnimationActive={false}
      />
    </BarChart>
    <div className='barchart-data' style={{height: 0, overflow: 'hidden'}}>
      <ul>
        {data.map((d) => (
          <li key={d.tool_id}>
            Tool Name: {d.tool_name}, Tool ID: {d.tool_id}, Use Count: {d.count}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const ToolHistoryLineChart = ({data}) => console.log(data) || (
  <div className='linechart-container'>
    <LineChart
      aria-hidden='false'
      width={1000}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 20,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray='3 3' />
      <XAxis dataKey='date' />
      <YAxis />
      <Tooltip />
      <Legend verticalAlign='top' wrapperStyle={{paddingBottom: '15px'}} />
      <Line type='monotone' dataKey='Uses' stroke='#8884d8' activeDot={{r: 8}} />
    </LineChart>
    <div className='linechart-data' style={{height: 0, overflow: 'hidden'}}>
      <ul>
        {data.map((d) => (
          <li key={d.date}>
            Date: {d.date}, Uses: {d.Uses}
          </li>
        ))}
      </ul>
    </div>
  </div>
);

const DateFilter = ({startDate, endDate, applyFilter}) => (
  <Formik
    initialValues={{
      startDate: startDate,
      endDate: endDate,
    }}
    onSubmit={applyFilter}
  >
    {(formikProps) => (
      <Form className='full-width grid-row'>
        <div className='grid-col-6'>
          <div className='grid-row margin-bottom-2'>
            <div className='grid-col-12'>
              <h4 className='no-margin'>Filter by Date</h4>
            </div>
            <div className='grid-col-6 mobile-full-width'>
              <div className='grid-row'>
                <label htmlFor='startDate' className='color-primary font-size-14'>
                  Start date:
                </label>
              </div>
              <div className='grid-row'>
                <Field
                  id='startDate'
                  name='startDate'
                  type='date'
                  className={`${
                    formikProps.touched.endDate && formikProps.errors.endDate && 'alert'
                  } fdic-input mobile-full-width start-date-input`}
                />
              </div>
            </div>
            <div className='grid-col-5 mobile-full-width margin-right-2'>
              <div className='grid-row'>
                <label htmlFor='endDate' className='color-primary font-size-14'>
                  End date:
                </label>
              </div>
              <div className='grid-row'>
                <Field
                  id='endDate'
                  name='endDate'
                  type='date'
                  className={`${
                    formikProps.touched.endDate && formikProps.errors.endDate && 'alert'
                  } fdic-input mobile-full-width end-date-input`}
                  placeholder='test'
                />
              </div>
            </div>
          </div>
          <div className='grid-row'>
            <div className='grid-col-5 mobile-full-width'>
              <button className='usa-button full-width apply-filter' onClick={formikProps.handleSubmit}>
                Apply Filter
              </button>
            </div>
          </div>
          {formikProps.touched.endDate && formikProps.errors.endDate && (
            <div className='grid-row alert full-width margin-top-105'>{formikProps.errors.endDate}</div>
          )}
        </div>
      </Form>
    )}
  </Formik>
);

class ToolStatistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      statisticsReport: null,
      statisticsList: null,
      appliedStartDate: defaultStartDate,
      appliedEndDate: defaultEndDate,
    };
  }
  componentDidMount() {
    this.getData(defaultStartDate, defaultEndDate);
    this.getHistory(defaultStartDate, defaultEndDate);
    reorderDomForRechartAccessibility();
  }
  componentDidUpdate() {
    reorderDomForRechartAccessibility();
  }
  getHistory = (startDate, endDate) => {
    RequestService.get(
      `${config.apiGateway.URL}/admin/list-tool-statistics?start_date=${startDate}&end_date=${endDate}`,
      (response) => {
        this.setState({
          statisticsList: response.data,
        });
      },
    );
  };
  getData = (startDate, endDate) => {
    RequestService.get(
      `${config.apiGateway.URL}/admin/tool-statistics-report?start_date=${startDate}&end_date=${endDate}`,
      (response) => {
        this.setState({
          statisticsReport: response.data,
        });
      },
    );
  };
  applyFilter = (startDate, endDate) => {
    this.getData(startDate, endDate);
    this.getHistory(startDate, endDate);
    this.setState({
      appliedStartDate: startDate,
      appliedEndDate: endDate,
    });
  };
  render() {
    return (
      <div className='admin-account'>
        <h1 className='margin-bottom-0'>Tool Reports</h1>
        <h3 className='no-margin'>Refine Your Report</h3>
        <p>
          Apply this filter to refine your report. Select the date range for the report. When you’re done, select{' '}
          <b>Apply Filter.</b>
        </p>
        <p>
          <b>Notes:</b>
        </p>
        <ul>
          <li>This report include use of the tools by both players with an account and without an account.</li>
          <li>
            A tool "use" is recorded as soon as a player navigates to the page and does not imply any amount of time
            spent on the page.
          </li>
        </ul>

        <div className='grid-col-10 margin-bottom-2'>
          <p>
            <b>REMEMBER:</b> Public launch was September 30, 2021. To remove test data, be sure to{' '}
            <b>FILTER for Start Date 9/30/2021.</b>
          </p>
        </div>
        <DateFilter
          startDate={this.state.appliedStartDate}
          endDate={this.state.appliedEndDate}
          applyFilter={(values) => this.applyFilter(values.startDate, values.endDate)}
        />
        <br />
        <PrintButton printText='Print' />
        {this.state.statisticsReport && (
          <div>
            <h3 className='margin-bottom-0'>
              Interactive tool usage for {moment(this.state.appliedStartDate).format('LL')} to{' '}
              {moment(this.state.appliedEndDate).format('LL')}
            </h3>
            <i>This only includes tools 1-30</i>
            <ToolStatisticBarChart
              data={this.state.statisticsReport
                .filter((stat) => parseInt(stat.tool_id) <= 50)
                .sort((a, b) => (parseInt(a.tool_name) < parseInt(b.tool_name) ? -1 : 1))}
            />
          </div>
        )}
        {this.state.statisticsReport && (
          <div>
            <h3 className='margin-bottom-0 page-break-before-always'>
              Non-interactive tool usage for {moment(this.state.appliedStartDate).format('LL')} to{' '}
              {moment(this.state.appliedEndDate).format('LL')}
            </h3>
            <i>This only includes tools 101-131</i>
            <ToolStatisticBarChart
              data={this.state.statisticsReport
                .filter((stat) => parseInt(stat.tool_id) >= 50)
                .sort((a, b) => (parseInt(a.tool_name) < parseInt(b.tool_name) ? -1 : 1))}
            />
          </div>
        )}
        {this.state.statisticsList && (
          <div>
            <h3 className='margin-bottom-0'>
              Total tool usage history for {moment(this.state.appliedStartDate).format('LL')} to{' '}
              {moment(this.state.appliedEndDate).format('LL')}
            </h3>
            <i>This includes all tools and strategies (1-131)</i>
            <ToolHistoryLineChart data={mapList(this.state.statisticsList)} />
          </div>
        )}
        <br />
      </div>
    );
  }
}

export default ToolStatistics;
