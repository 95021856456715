async function CertificatePDF(filename, dataURL) {
  const {default: pdfMake} = await import('pdfmake/build/pdfmake');
  const {default: pdfFonts} = await import('pdfmake/build/vfs_fonts');

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const dd = {
    info: {
      title: 'money-smart-game-certificate',
      author: 'FDIC',
      subject: 'Money Smart Game Certificate',
      creator: 'FDIC',
      producer: 'FDIC',
    },
    pageSize: 'LETTER',
    pageOrientation: 'landscape',
    pageMargins: 50,
  };

  dd.content = [
    {
      image: dataURL,
      width: 680,
    },
  ];

  return pdfMake.createPdf(dd).download(filename);
}

export default CertificatePDF;
