import React from 'react';
import PropTypes from 'prop-types';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@gabmarini/ckeditor5-build-classic-base64upload';
import Modal from 'react-responsive-modal';
import MaskedInput from 'react-text-mask';
import RequestService from '../../services/RequestService';

class EditableContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initialContent: props.initialContent,
      content: props.initialContent,
      editable: false,
      displaySuccess: false,
      displayFailure: false,
      showDeleteModal: false,
    };
  }

  handleTextSubmit = () => {
    let body = {};
    body[this.props.fieldName] = this.state.content;
    RequestService.post(
      this.props.updateEndpoint,
      body,
      () =>
        this.setState({
          editable: false,
          content: this.state.content,
          displaySuccess: true,
          displayFailure: false,
        }),
      () =>
        this.setState({
          displaySuccess: false,
          displayFailure: true,
        })
    );
  };

  handleDelete = () => {
    RequestService.delete(
      this.props.deleteEndpoint,
      () => {
        this.props.deleteCallback && this.props.deleteCallback();
      },
      (error) => console.log(error),
      true
    );
  };

  cancelEditing = () => {
    this.setState({
      editable: false,
      content: this.state.initialContent,
      displayFailure: false,
      displaySuccess: false,
    });
  };

  renderInput = () => {
    if (this.props.richText) return this.renderRichTextInput();
    if (this.props.maskedInput) return this.renderMaskedInput();
    return this.renderTextInput();
  };

  renderRichTextInput = () => (
    <div>
      <CKEditor
        editor={ClassicEditor}
        data={this.state.content}
        onChange={(event, editor) => {
          this.setState({content: editor.getData()});
        }}
      />
    </div>
  );

  renderTextInput = () => (
    <div className='grid-row'>
      <input
        className={`usa-input margin-bottom-2 ${
          this.props.fullWidth ? 'full-width' : ''
        }`}
        title={this.props.fieldName}
        value={this.state.content}
        onChange={(e) => this.setState({content: e.target.value})}
        autoFocus
      ></input>
    </div>
  );

  renderMaskedInput = () => (
    <MaskedInput
      className='usa-input margin-bottom-2'
      value={this.state.content}
      mask={this.props.mask}
      id={this.props.fieldName}
      type='text'
      onChange={(e) => this.setState({content: e.target.value})}
    />
  );

  renderAlert = () =>
    this.state.displaySuccess ? (
      <div className='edit-success'>Successfully saved!</div>
    ) : (
      this.state.displayFailure && (
        <div className='edit-failure'>
          Failed to save. Please try again later.
        </div>
      )
    );

  render() {
    return this.props.canEdit && this.state.editable ? (
      <div className={`${this.props.fullWidth ? 'grid-col-12' : ''}`}>
        {this.renderInput(this.props.fullWidth)}
        <button
          className='usa-button save-button'
          onClick={this.handleTextSubmit}
        >
          Save
        </button>
        {this.props.canDelete && (
          <button
            className='usa-button delete-button usa-button--secondary'
            onClick={() => this.setState({showDeleteModal: true})}
          >
            Delete
          </button>
        )}
        <button
          className='usa-button  usa-button--unstyled'
          onClick={this.cancelEditing}
        >
          Cancel
        </button>
        <Modal
          open={this.state.showDeleteModal}
          onClose={() => this.setState({showDeleteModal: false})}
        >
          <h4>Are you sure you want to delete this content?</h4>
          <p>
            This content is not saved anywhere and will not be retrievable once
            you select Delete.
          </p>
          <button
            className='usa-button delete-button usa-button--secondary'
            onClick={this.handleDelete}
          >
            Delete
          </button>
          <button
            onClick={() => this.setState({showDeleteModal: false})}
            className='usa-button usa-button--unstyled '
          >
            Cancel
          </button>
        </Modal>
      </div>
    ) : (
      <div className='editable-content'>
        {!this.props.hideAlert && this.renderAlert()}
        {React.createElement(this.props.htmlType, {
          className: 'editable-element',
          dangerouslySetInnerHTML: {__html: this.state.editable ? this.state.content : this.props.initialContent},
        })}
        {this.props.canEdit && (
          <button
            className={`edit-button ${this.props.whiteButton ? 'white' : ''}`}
            onClick={() => this.setState({editable: true})}
          >
            <i
              className='material-icons editable-edit'
              aria-hidden={this.props.altText !== 'edit'}
            >
              edit
            </i>
            {this.props.altText !== 'edit' && (
              <span
                style={{
                  display: 'block',
                  height: '0',
                  width: '0',
                  overflow: 'hidden',
                }}
              >
                {this.props.altText}
              </span>
            )}
          </button>
        )}
      </div>
    );
  }
}

EditableContent.propTypes = {
  updateEndpoint: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  initialContent: PropTypes.string.isRequired,
  htmlType: PropTypes.string,
  richText: PropTypes.bool,
  canEdit: PropTypes.bool,
  fullWidth: PropTypes.bool,
  altText: PropTypes.string,
};

EditableContent.defaultProps = {
  htmlType: 'p',
  richText: false,
  canEdit: false,
  fullWidth: false,
  altText: 'edit',
};

export default EditableContent;
