import React from 'react';
import {observer} from 'mobx-react';
import {withTranslation, Translation} from 'react-i18next';
import {Link} from 'react-router-dom';

import config from '../../config';
import {Loader} from '../../components/Loader';
import SearchField from './components/SearchField';
import LanguageSelector from '../../components/Buttons/LanguageSelector';

const NEED_MORE_HELP_TOPIC = 'Need More Help?';
const BLACKLISTED_HELP_TOPICS = [NEED_MORE_HELP_TOPIC];

const getNeedMoreHelpTopicId = (helpTopics) => {
  let needMoreHelpTopic = helpTopics.filter((topic) => topic.name === NEED_MORE_HELP_TOPIC)[0];
  return needMoreHelpTopic ? needMoreHelpTopic.id : 33;
};

class HelpCenter extends React.Component {
  componentDidMount() {
    config.setUniqueTitle('Help Center');
    this.props.helpCenterStore.getHelpTopics(!this.props.authStore.isEditor);
  }

  render() {
    return this.props.helpCenterStore.helpTopicsLoading ? (
      <Loader />
    ) : (
      <Translation>
        {(t, {i18n}) => {
          const isSpanish = i18n.language === 'es';
          return (
            <div className='help-center grid-container'>
              <div className='grid-row text-align-center margin-bottom-4'>
                <div className='grid-col-12 text-left'>
                  <h1 className='mobile-centered'>{t('Help Center')}</h1>
                </div>
                <div className='grid-col-12 text-left margin-bottom-2 tablet-lg:margin-bottom-0'>
                  <LanguageSelector {...this.props} />
                </div>
                <div className='grid-col-12'>
                  <SearchField />
                </div>
                <div className='grid-col-12'>
                  <h3>{t('You can also browse the topics below.')}</h3>
                </div>
                {this.props.authStore.isEditor && (
                  <div className='grid-col-12'>
                    <div className='grid-row text-center margin-bottom-2'>
                      <button
                        onClick={() => this.props.history.push('/help-center/edit')}
                        className='fdic-button tan-button centered'
                      >
                        Edit Help Center
                      </button>
                    </div>
                    <div className='grid-row text-center'>
                      <span className='centered'>
                        {this.props.helpCenterStore.editorHelpTopics.map((helpTopic, index) => {
                          const helpTopicInSpanishExists = helpTopic.name_es && helpTopic.name_es.length > 0;
                          const showSpanish = isSpanish && helpTopicInSpanishExists;
                          return (
                            <span key={helpTopic.id} className='margin-right-105'>
                              {index > 0 && <span className='margin-right-105'>|</span>}
                              <Link to={`/help-center/${helpTopic.id}`}>
                                {showSpanish ? helpTopic.name_es : helpTopic.name}
                              </Link>
                            </span>
                          );
                        })}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className='background-color-light-grey padding-bottom-3 padding-right-3 padding-left-3'>
                <div className='grid-row'>
                  {Object.keys(this.props.helpCenterStore.activeGroupedHelpTopics).map((helpTopicCategoryId) => {
                    const category = this.props.helpCenterStore.activeGroupedHelpTopics[helpTopicCategoryId][0];
                    const categoryName =
                      isSpanish && category.help_topic_category_name_es
                        ? category.help_topic_category_name_es
                        : category.help_topic_category_name;
                    return (
                      <div key={helpTopicCategoryId} className='grid-col-4 mobile-full-width'>
                        <div className='help-topic-category'>
                          <h4 className='margin-top-2 margin-bottom-2'>{categoryName}</h4>
                          {this.props.helpCenterStore.activeGroupedHelpTopics[helpTopicCategoryId].map((helpTopic) => {
                            const helpTopicName = isSpanish && helpTopic.name_es ? helpTopic.name_es : helpTopic.name;
                            return (
                              !BLACKLISTED_HELP_TOPICS.includes(helpTopic.name) && (
                                <div key={helpTopic.id} className='grid-row margin-bottom-105'>
                                  <Link to={`/help-center/${helpTopic.id}`}>{helpTopicName}</Link>
                                </div>
                              )
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className='grid-row text-right'>
                  <div className='grid-col-9'></div>
                  <div className='grid-col-3 mobile-full-width'>
                    <Link
                      className='usa-button usa-button--outline'
                      to={`/help-center/${getNeedMoreHelpTopicId(this.props.helpCenterStore.helpTopics)}`}
                    >
                      {t(NEED_MORE_HELP_TOPIC)}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Translation>
    );
  }
}

export default withTranslation()(observer(HelpCenter));
export {NEED_MORE_HELP_TOPIC};