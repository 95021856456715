import React from 'react';
import {IconButton} from '@material-ui/core';
import EditableContent from '../../../components/EditableContent/EditableContent';
import config from '../../../config';
import {useTranslation} from 'react-i18next';

import creditCardStatement from '../../../assets/images/creditCardStatement.png';
import creditCardStatementEs from '../../../assets/images/creditCardStatement-es.png';

const aTitle = 'Account Summary';
const bTitle = 'Payment Information';
const cTitle = 'Account Billing Cycle';
const dTitle = 'Credit Counseling Statement';
const eTitle = 'Notice of Changes to Your Interest Rate';
const fTitle = 'Transactions or Account Activity';
const gTitle = 'Interest Charge Calculations';

const aHtml = (t) => {
  return (
    <>
      <h3>{t('A. Account Summary')}</h3>
      <p>
        {t(
          'The account summary section summarizes your transactions for the billing cycle, including your previous balance, new balance, credit limit, and credit available.'
        )}
      </p>
      <ul>
        <li>
          <b>{t('Previous Balance: ')}</b>
          {t('The money you owed as shown on your last bill')}
        </li>
        <li>
          <b>{t('New Balance: ')}</b>
          {t(
            'What you owe now based on your previous balance, plus any purchases, cash advances, and late fees, minus any payments and credits'
          )}
        </li>
        <li>
          <b>{t('Credit Limit: ')}</b>
          {t('The maximum credit you can use')}
        </li>
        <li>
          <b>{t('Credit Available: ')}</b>
          {t('The credit remaining on your account after your new balance is subtracted from your credit limit')}
        </li>
      </ul>
    </>
  );
};

const bHtml = (t) => {
  return (
    <>
      <h3>{t('B. Payment Information')}</h3>
      <p>{t('The payment information section lists your:')}</p>
      <ul>
        <li>
          <b>{t('New Balance: ')}</b>
          {t('What you owe now')}
        </li>
        <li>
          <b>{t('Minimum Payment Due: ')}</b>
          {t(
            'The minimum dollar amount you must pay by the due date on the statement, perhaps three or four percent of your new balance'
          )}
        </li>
        <li>
          <b>{t('Payment Due Date: ')}</b>
          {t(
            'The date your creditor must receive your payment, which is not the same as the date your payment is postmarked'
          )}
          <ul>
            <li>{t('It must be on the same day each month (for example, always on the 5th day of the month)')}</li>
          </ul>
        </li>
        <li>
          <b>{t('Late Payment Warning: ')}</b>
          {t('An explanation of what will happen if you do not make your payment on time')}
        </li>
        <li>
          <b>{t('Minimum Payment Warning: ')}</b>
          {t('A table with mimimum payment information')}
          <ul>
            <li>{t('How long it will take to pay off the new balance if you only pay the minimum due each month')}</li>
            <li>{t('How much you would have to pay each month to pay off the new balance in 36 months (3 years)')}</li>
            <li>{t('The total cost (required payments and interest) of these options')}</li>
          </ul>
        </li>
      </ul>
    </>
  );
};

const cHtml = (t) => {
  return (
    <>
      <h3>{t('C. Account Billing Cycle')}</h3>
      <p>{t('The account billing cycle is the time period covered by the statement.')}</p>
    </>
  );
};

const dHtml = (t) => {
  return (
    <>
      <h3>{t('D. Credit Counseling Statement')}</h3>
      <p>{t('Here the creditor notes how to obtain information about credit counseling services.')}</p>
    </>
  );
};

const eHtml = (t) => {
  return (
    <>
      <h3>{t('E. Notice of Changes to Your Interest Rate')}</h3>
      <p>{t('This section notes any changes in the interest rate (if any).')}</p>
    </>
  );
};

const fHtml = (t) => {
  return (
    <>
      <h3>{t('F. Transactions or Account Activity')}</h3>
      <p>{t('The transactions or account activity section notes:')}</p>
      <ul>
        <li>
          <b>{t('Transactions')}</b>
          {t(' made with the card')}
        </li>
        <li>
          <b>{t('Finance Charges: ')}</b>
          {t('Interest, service charges, and transaction fees for the account billing cycle')}
        </li>
        <li>
          <b>{t('Annual Fee and Interest Totals: ')}</b>
          {t('The amount you have paid in fees and interest during the calendar year')}
        </li>
      </ul>
    </>
  );
};

const gHtml = (t) => {
  return (
    <>
      <h3>{t('G. Interest Charge Calculations')}</h3>
      <p>
        {t(
          'The interest charge calculation shows you how the interest charge was calculated. An annual percentage rate (APR) is the cost of credit expressed as a yearly rate. It includes interest and other charges. APRs for purchases, balance transfers, and cash advances can be different.'
        )}
      </p>
      <p>{t('Interest rates can be fixed or variable.')}</p>
      <p>
        <i>{t('Fixed rate')}</i>
        {t(' means the interest rate will not change until the creditor tells you in writing that it will change.')}
      </p>
      <p>
        <i>{t('Variable rate')}</i>
        {t(
          ' means the interest rate varies, usually based on an index. A variable rate will likely change more frequently than a fixed rate.'
        )}
      </p>
    </>
  );
};

const getCredit1Buttons = (t) => {
  return [
    {className: 'a', text: aHtml(t), title: aTitle},
    {className: 'b', text: bHtml(t), title: bTitle},
    {className: 'c', text: cHtml(t), title: cTitle},
    {className: 'd', text: dHtml(t), title: dTitle},
    {className: 'e', text: eHtml(t), title: eTitle},
  ];
};

const getCredit2Buttons = (t) => {
  return [
    {className: 'f', text: fHtml(t), title: fTitle},
    {className: 'g', text: gHtml(t), title: gTitle},
  ];
};

const ReadCreditCardStatement = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  const isSpanish = i18n.language === 'es';
  const credit1Buttons = getCredit1Buttons(t);
  const credit2Buttons = getCredit2Buttons(t);
  return (
    <div id='credit-card-statement' className='read-credit-card-statement'>
      <EditableContent
        htmlType='div'
        initialContent={isSpanish ? tool.contentsEs || tool.contents : tool.contents}
        fieldName={isSpanish ? 'contents_es' : 'contents'}
        updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
        canEdit={canEdit}
        richText
      />
      <div className='container'>
        <img src={isSpanish ? creditCardStatementEs : creditCardStatement} alt='credit card statement' />
        {credit1Buttons.map(({className, text, title}) => (
          <CheckButton
            id={className}
            key={`cc1-${className}`}
            className={isSpanish ? `${className} lang-es` : className}
            text={text}
            title={t(title)}
          />
        ))}
        {credit2Buttons.map(({className, text, title}) => (
          <CheckButton
            id={className}
            key={`cc2-${className}`}
            className={isSpanish ? `${className} lang-es` : className}
            text={text}
            title={t(title)}
          />
        ))}
      </div>
      <div>
        {credit1Buttons.concat(credit2Buttons).map((section, index) => (
          <div key={`sections-${section.className}`}>
            <div
              id={`${section.className}-definition`}
              tabIndex={0}
              className='margin-top-6 summary-section page-break-inside-avoid'
            >
              {section.text}
            </div>
            <span className='hide-print'>
              <a
                href={`#${
                  index === credit1Buttons.concat(credit2Buttons).length - 1
                    ? 'credit-card-statement'
                    : credit1Buttons.concat(credit2Buttons)[index + 1].className
                }`}
              >
                {t('Back to top')}
              </a>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};
const CheckButton = ({id, className, title}) => (
  <IconButton id={id} className={`btn ${className}`} href={`#${id}-definition`} title={title}>
    <b>{className.toUpperCase()}</b>
  </IconButton>
);

export default ReadCreditCardStatement;
