import React from "react";
import {Formik, Form} from "formik";
import {ExpandableTextarea} from "../utilities/Inputs";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation} from "react-i18next";

const PlanToBuildMoreAssets = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  return (
    <div>
      <Formik>
        {(_formikProps) => (
          <Form>
            <EditableContent
              htmlType="div"
              initialContent={
                i18n.language === "es"
                  ? tool.contentsEs || tool.contents
                  : tool.contents
              }
              fieldName={i18n.language === "es" ? "contents_es" : "contents"}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            <div className="grid-col-12">
              <ExpandableTextarea
                name="goal"
                question={t('What is one financial goal you have for the future?')}
              />
              <p>
                {t('What additional assets – financial, physical, and productive – would help you meet this goal?')}
              </p>
              <ExpandableTextarea
                name="financial"
                question={
                  <span>
                    <b>{t('Financial:')} </b> {t('For example, do you need money to get started or pay for other assets? Will money help you create a buffer for times when you have lower income or higher expenses?')}
                  </span>
                }
                title={t('For example, do you need money to get started or pay for other assets? Will money help you create a buffer for times when you have lower income or higher expenses?')}
              />
              <ExpandableTextarea
                name="physical"
                question={
                  <span>
                    <b>{t('Physical:')} </b> {t('For example, do you need equipment, tools, or a vehicle to make this goal possible?')}{" "}
                  </span>
                }
                title={t('For example, do you need equipment, tools, or a vehicle to make this goal possible?')}
              />
              <ExpandableTextarea
                name="productive"
                question={
                  <span>
                    <b>{t('Productive:')} </b> {t('For example, do you need good credit to get a loan? Do you need a college degree?')}
                  </span>
                }
                title={t('For example, do you need good credit to get a loan? Do you need a college degree?')}
              />
              <p>{t('Now, ask yourself these questions:')}</p>
              <ExpandableTextarea
                name="resources"
                question={t('What resources do I need to get those assets?')}
              />
              <ExpandableTextarea
                name="steps"
                question={t('What specific steps could I take to get those resources?')}
              />
              <ExpandableTextarea
                name="obstacles"
                question={t('What are some obstacles that may get in the way as I build assets?')}
              />
              <ExpandableTextarea
                name="manage"
                question={t('How will I manage those obstacles?')}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PlanToBuildMoreAssets;
