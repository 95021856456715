import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';
import Loader from '../../../../components/Loader/Loader';
import RequestService from '../../../../services/RequestService';
import config from '../../../../config';
import {withStyles} from '@material-ui/styles';
import {Tooltip} from '@material-ui/core';
import {Field} from 'formik';

const OrganizationInfoToolTip = withStyles({
  tooltip: {
    fontSize: '1em'
  }
})(Tooltip);

const deleteInformation = (organization) => (
  organization.suspended ? ` Delete is permanent. Deleted organizations won’t be in any tab when you refresh this page.`
    :
    organization.approved ? ` Delete is permanent. Deleted organizations won’t be in any tab when you refresh this page.`
      :
      ` Delete is permanent. Deleted applications won’t be in any tab when you refresh this page.`
);

const OrganizationRowContent = ({
  index, formikProps, organizationId, setApproveModalOpen, setDenyModalOpen, 
  setSuspendModalOpen, setReinstateModalOpen, approveInformation, denyInformation, suspendInformation, reinstateInformation,
  approvedState, deniedState, suspendedState, successMessageState, handleDelete
}) => {
  const [organization, setOrganization] = useState({});
  const [notes, setNotes] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchOrganization = () => {
    RequestService.get(
      `${config.apiGateway.URL}/organizations/${organizationId}`,
      (response) => {
        setOrganization(response.data);
        setNotes(response.data.notes);
        setLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchOrganization();
    // eslint-disable-next-line
  }, []);

  const getEventDate = (organization, type) => {
    const events = organization && organization.events && organization.events.filter((event) => event.type === type);
    if(!events || !events[events.length - 1]) return moment();
    return events[events.length - 1].date;
  };

  const mapAdditionalOwners = (users) => {
    const additionalOwners = [];
    for (let i = 0; i < users.length; i++) {
      if (i > 0) {
        const user = users[i];
        additionalOwners.push(`${user.email}${i < users.length - 1 ? ', ' : ''}`);
      }
    }
    return additionalOwners;
  };

  const displayAddress = ({address_line_one, address_line_two, city, state, zip_code}) => (
    <div className="grid-col-12 long-info-container">
      <div className="grid-row">
        <span>{address_line_one}</span>
      </div>
      <div className="grid-row">
        <span>{address_line_two}</span>
      </div>
      <div className="grid-row">
        <span>{city}{city && state && ','} {state} {zip_code}</span>
      </div>
    </div>
  );

  const renderActions = () => {
    return(
      <div>
        <div className="grid-row margin-bottom-2">
          <h3 className="margin-bottom-2 no-margin">Actions</h3>
        </div>
        <div className="grid-row margin-bottom-2 grid-col-12">
          {!organization.approved && !approvedState && 
          <OrganizationInfoToolTip title={approveInformation} placement="bottom">
            <button onClick={setApproveModalOpen} className="usa-button usa-button--outline approve-button">Approve</button>
          </OrganizationInfoToolTip>
          }
          {!organization.approved && !approvedState && !deniedState && 
          <OrganizationInfoToolTip title={denyInformation} placement="bottom">
            <button onClick={setDenyModalOpen} className="usa-button usa-button--outline deny-button">Deny</button>
          </OrganizationInfoToolTip>
          }

          {organization.approved && !organization.suspended && !suspendedState &&
          <OrganizationInfoToolTip title={suspendInformation} placement="bottom">
            <button onClick={setSuspendModalOpen} className="usa-button usa-button--outline suspend-button">Suspend</button>
          </OrganizationInfoToolTip> 
          }
          {organization.approved && organization.suspended && suspendedState &&
          <OrganizationInfoToolTip title={reinstateInformation} placement="bottom">
            <button onClick={setReinstateModalOpen} className="usa-button usa-button--outline unsuspend-button">Reinstate</button>
          </OrganizationInfoToolTip> 
          }
          <OrganizationInfoToolTip title={deleteInformation(organization)} placement="bottom">
            <button onClick={handleDelete} className="usa-button usa-button--secondary delete-button">Delete</button>
          </OrganizationInfoToolTip>
          {organization.approved &&
          <Link className="usa-button usa-button--unstyled padding-top-105 margin-right-1" to={`/account/reports?organizationId=${organization.id}`}>Reports</Link>
          }
          <Link className="usa-button usa-button--unstyled padding-top-105" to={`/account/organizations/${organization.id}`}>Details</Link>
        </div>
        {
          successMessageState &&
          <div className="grid-row success-message margin-right-4">
            <span>{successMessageState}</span>
          </div>
        }
      </div>
    );
  };

  const renderNotes = (formikProps) => {
    const id = `fdic-notes-${index}`;
    return(
      <div className="grid-row">
        <div className="grid-col-12 margin-bottom-2">
          <label className="usa-label" id={id}><b>FDIC Notes:</b></label>
        </div>
        <div className="grid-col-12 margin-bottom-2 padding-right-4">
          {notes.map((note, index) => (
            <div key={`note-${index}`} className="grid-row note-row">
              <li><span>{note.details} <i>{moment(note.date).parseZone().format('MM/DD/YY')}</i></span></li> 
            </div>
          ))}
        </div>
        <div className="grid-col-12 padding-right-4">
          <Field aria-labelledby={id} name="note" onChange={formikProps.handleChange} rows={3} maxLength="255" className="full-width margin-bottom-105 note-input" component="textarea" />
          <button className="usa-button add-note-button" 
            onClick={() => {
              formikProps.handleSubmit(); 
              setNotes(notes.concat({details: formikProps.values.note}));
            }} 
            disabled={!formikProps.values.note}
          >Add note</button>
        </div>
      </div>
    );
  };

  if(loading) return <Loader />;

  return (
    <div className="grid-row">
      <div className="grid-col-6 padding-right-2">
        <div className="grid-row">
          <span><strong>Display name: </strong> {organization.display_name}</span>
        </div>
        <div className="grid-row">
          <span><strong>Applied on: </strong> {moment(organization.application_date).parseZone().format('LL')}</span>
        </div>
        {organization.denied && <div className="grid-row">
          <span><strong>Denied on: </strong> {moment(getEventDate(organization, 'denied')).parseZone().format('LL')}</span>
        </div>}
        {organization.approved && <div className="grid-row">
          <span><strong>Approved on: </strong> {moment(getEventDate(organization, 'approved')).parseZone().format('LL')}</span>
        </div>}
        <div className="grid-row">
          <span><strong>Primary contact name: </strong> 
            {(organization.account_owners && organization.account_owners.length > 0) ? `${organization.account_owners[0].first_name} ${organization.account_owners[0].last_name}` : 'N/A'}
          </span>
        </div>
        <div className="grid-row">
          <span><strong>Primary contact email: </strong> {(organization.account_owners && organization.account_owners.length > 0) ? organization.account_owners[0].email : 'N/A'}</span>
        </div>
        {organization.account_owners && organization.account_owners.length > 1 && <div className="grid-row">
          <span><strong>Additional contacts: </strong> {mapAdditionalOwners(organization.account_owners)}</span>
        </div>}
        <div className="grid-row">
          <span><strong>Phone number: </strong> {organization.phone_number ? organization.phone_number : 'N/A'}</span>
        </div>
        {organization.approved && <div className="grid-row">
          <span><strong>Total linked players: </strong> {organization.user_count}</span>
        </div>}
        {organization.approved && <div className="grid-row">
          <span><strong>Total certificates for linked players: </strong> {organization.certificate_count}</span>
        </div>}
        {organization.approved && <div className="grid-row">
          <span><strong>Last linked player activity: </strong> {organization.last_user_activity && organization.user_count !== 0 ? moment(organization.last_user_activity).parseZone().format('LL') : 'N/A'}</span>
        </div>}
        <div className="grid-row">
          <span><strong>Address:</strong></span>
        </div>
        <div className="grid-row">
          {displayAddress(organization)}
        </div>
        {/* <div className="grid-row">
          <span><strong> How the organization will be using <i>How Money Smart Are You?</i> :</strong></span>
        </div>
        <div className="grid-row long-info-container">
          <span>{organization.details}</span>
        </div>
        */}
      </div>
      <div className="grid-col-6">
        {renderActions()}
        {renderNotes(formikProps)}
      </div>
    </div>
  );
};

export default OrganizationRowContent;