import React, {useState} from 'react';
import {Formik} from 'formik';
import {Trans} from 'react-i18next';
import {Link} from 'react-router-dom';
import * as Yup from 'yup';
import Filter from 'bad-words';

import config from '../../config';
import RequestService from '../../services/RequestService';
import FormInput from '../../components/Formik/FormInput';
import LanguageSelector from '../../components/Buttons/LanguageSelector';

const filter = new Filter();
const resetSchema = Yup.object().shape({
  email: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .email('Must enter a valid email address.')
    .min(2, 'Email must be at least two characters.')
    .max(50, 'Email must be at most 50 characters.')
    .required('Email is required.'),
});

const PasswordReset = (props) => {
  const [resetSuccess, setResetSuccess] = useState(false);

  const handlePasswordReset = ({email}) => {
    RequestService.post(
      `${config.apiGateway.URL}/reset-password`,
      {email: email},
      () => {
        setResetSuccess(true);
      },
      () => {
        setResetSuccess(true);
      },
    );
  };

  return (
    <div className='registration-wrapper centered'>
      <Formik
        validationSchema={resetSchema}
        initialValues={{
          email: '',
        }}
        onSubmit={handlePasswordReset}
      >
        {(formikProps) => (
          <form className='pwreset-form'>
            <h2>
              <Trans i18nKey='login.reset'>Reset Password</Trans>
            </h2>
            <div className='text-center'>
              <LanguageSelector {...props} />
            </div>
            <p>
              <label htmlFor='email'>
                <Trans i18nKey='login.enterEmail'>
                  <b>
                    Please enter the email address you used to create your <i>How Money Smart Are You?</i> account.
                  </b>
                  A new password will be sent to that email.
                </Trans>
              </label>
            </p>
            <FormInput formikProps={formikProps} type='email' id='email' />
            <div className='reg-form-btn margin-top-4'>
              <button
                className='usa-button'
                onClick={formikProps.handleSubmit}
                type='submit'
                disabled={!formikProps.isValid || formikProps.isSubmitting}
              >
                <Trans i18nKey='login.reset'>Reset Password</Trans>
              </button>
              <Link className='link-btn' to='/login'>
                <Trans i18nKey='login.back'>Go Back</Trans>
              </Link>
            </div>
            {resetSuccess && (
              <div className='grid-row centered alert info-box margin-top-4'>
                <span className='centered'>
                  <Trans i18nKey='login.passSent'>A new password has been sent to your email.</Trans>
                </span>
              </div>
            )}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PasswordReset;
