import {observable, decorate} from "mobx";
import RequestService from '../services/RequestService';
import config from '../config';

class EmailsStore {
  constructor() {
    this.buildFromSession();
  }

  emailsLoading = true;
  emails = [];

  getEmails = () => {
    // this.emails.length === 0 && RequestService.get(`${config.apiGateway.URL}/custom-emails`, this.getEmailsSuccessResponse);
    RequestService.get(`${config.apiGateway.URL}/custom-emails`, this.getEmailsSuccessResponse);
  };

  getEmailsSuccessResponse = (resp) => {
    this.emails = resp.data;
    this.emailsLoading = false;
    this.writeToSession();
  };

  updateEmail = (emailId, subject, body) => {
    RequestService.post(`${config.apiGateway.URL}/custom-emails/${emailId}`, {
      subject: subject, body: body
    });
  };

  sendTestEmail = (emailId, to) => {
    RequestService.post(`${config.apiGateway.URL}/custom-emails/test_email/${emailId}`, {to: to});
  };

  buildFromSession = () => {
    let json = JSON.parse(sessionStorage.getItem('EmailsStore'));
    if(json) {
      this.emails = json.emails;
      this.emailsLoading = json.emailsLoading;
    }
  };

  writeToSession = () => {
    let json = JSON.stringify(this);
    sessionStorage.setItem('EmailsStore', json);
  };
}

decorate(EmailsStore, {
  emails: observable,
  emailsLoading: observable,
});

export default EmailsStore;