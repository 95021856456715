import React from 'react';
import {Trans} from 'react-i18next';

const ExternalLinkWarning = () => {
  return (
    <span>
      <Trans i18nKey='const.externalLink'>
        Clicking on external links will take you outside of <i>How Money Smart Are You?</i> and the <a title='FDIC.gov' href='https://www.fdic.gov/'>FDIC.gov</a> website.
      </Trans>
    </span>
  );
};

export default ExternalLinkWarning;
