import React from 'react';
import {observer} from 'mobx-react';
import {Formik, Form, Field} from 'formik';
import Switch from '@material-ui/core/Switch';
import {Link} from 'react-router-dom';

import PrintButton from '../../../components/Buttons/PrintButton';
import Loader from '../../../components/Loader/Loader';
import SessionsBarChart from './dashboard/SessionsBarChart';
import UserPieChart from './dashboard/UserPieChart';
import UsageBarChart from './dashboard/UsageBarChart';
import AccountSubHeader from '../AccountSubHeader';
import LinkButton from '../../../components/Buttons/LinkButton';
import BackToTop from '../../../components/Buttons/BackToTop';
import IndividualGamePlayInfo from '../individual/IndividualGamePlayInfo';
import config from '../../../config';
import RequestService from '../../../services/RequestService';
import {downloadCsvAsFile, dateFormatter} from '../../util';

const defaultStartDate = config.defaultQueryStartDate;
let defaultEndDate = new Date().toLocaleDateString("fr-CA");
defaultEndDate = defaultEndDate < defaultStartDate ? defaultStartDate : defaultEndDate;

const totalAccountsMessage = 'This data only includes players who have created accounts.';

const onlyAccountsMessage =
  'This data only includes players who have created accounts and were logged into their accounts while playing the games.';

const everybodyMessage = 'This data includes players with and without accounts.';

const displayDateRange = (startDate, endDate) => {
  if (!startDate || !endDate) return 'All dates';
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  };
  const formattedStartDate = dateFormatter(startDate, undefined, options);
  const formattedEndDate = dateFormatter(endDate, undefined, options);
  return `${formattedStartDate} to ${formattedEndDate}`;
};

class AdminAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCompletedOnly: false,
      showAccountsOnly: false,
      showAnonymousOnly: false,
      appliedStartDate: props.defaultStartDate || defaultStartDate,
      appliedEndDate: props.defaultEndDate || defaultEndDate,
      approvedOrgDownloadButtonDisabled: false,
    };
  }

  renderFilterOptions = () => (
    <ul key={`option-group-1`} className='filter-option-group margin-right-2'>
      <li key={'Completed'} className='usa-nav__submenu-item'>
        <div className='usa-checkbox'>
          <input
            className='usa-checkbox__input'
            id={'Completed'}
            type='checkbox'
            name={'Completed'}
            value={'Completed'}
            onChange={(e) => this.setState({showCompletedOnly: e.target.checked})}
            checked={this.state.showCompletedOnly}
          />
          <label className='usa-checkbox__label' htmlFor={'Completed'}>
            {'Show only games where players won the game'}
          </label>
        </div>
      </li>
      <li key={'Accounts'} className='usa-nav__submenu-item'>
        <div className='usa-checkbox'>
          <input
            className='usa-checkbox__input'
            id={'Accounts'}
            type='checkbox'
            name={'Accounts'}
            value={'Accounts'}
            onChange={(e) => this.setState({showAccountsOnly: e.target.checked, showAnonymousOnly: false})}
            checked={this.state.showAccountsOnly}
          />
          <label className='usa-checkbox__label' htmlFor={'Accounts'}>
            {'Show only games where players were logged into their account while playing the games'}
          </label>
        </div>
      </li>
      <li key={'Anonymous'} className='usa-nav__submenu-item'>
        <div className='usa-checkbox'>
          <input
            className='usa-checkbox__input'
            id={'Anonymous'}
            type='checkbox'
            name={'Anonymous'}
            value={'Anonymous'}
            onChange={(e) => this.setState({showAnonymousOnly: e.target.checked, showAccountsOnly: false})}
            checked={this.state.showAnonymousOnly}
          />
          <label className='usa-checkbox__label' htmlFor={'Anonymous'}>
            {'Show only anonymous games (no account or not logged into their account)'}
          </label>
        </div>
      </li>
    </ul>
  );

  mapUsageData = (usageReport) =>
    Object.keys(usageReport).map((count) => ({
      count: count,
      'Number of players': usageReport[count],
    }));

  applyFilter = (startDate, endDate) => {
    const {adminReportsStore} = this.props;
    adminReportsStore.getGameUsageReport(startDate, endDate, true);
    adminReportsStore.getGameSessionsReport(startDate, endDate, true);
    adminReportsStore.getCertificateReport(startDate, endDate, true);
    adminReportsStore.getUserReport(startDate, endDate, true);
    this.setState({appliedStartDate: startDate, appliedEndDate: endDate});
  };

  totalInProgress = ({organizationGamesInProgress, nonOrganizationGamesInProgress, anonymousGamesInProgress}) =>
    (organizationGamesInProgress + nonOrganizationGamesInProgress + anonymousGamesInProgress).withCommas();

  getCsv = (approvedOrgs) => {
    if (!approvedOrgs) return;
    const csvRows = [`Total Organizations with Accounts: ${approvedOrgs.length} (Count includes FDIC Test Account)`];
    const csvHeaders = [
      'Date Approved',
      'Organization Name',
      'Organization Display Name',
      'Street Address',
      'City',
      'State',
      'Zip',
      'FirstName',
      'LastName',
      '"Primary Contact\'s Email Address"',
      'Phone',
      'Players',
      'Certificates',
      'Last Activity',
    ];
    csvRows.push(csvHeaders.join(','));

    approvedOrgs.forEach((org) => {
      const row = [];
      row.push(org.approved_date ? dateFormatter(org.approved_date, 'en-US', {timeZone: 'UTC'}) : '');
      row.push(`"${org.name}"`);
      row.push(`"${org.display_name}"`);
      const address = org.address_line_one + (org.address_line_two ? ` ${org.address_line_two}` : '');
      row.push(`"${address}"`);
      row.push(org.city);
      row.push(org.state);
      row.push(org.zip_code);
      if (org.primary_account_owner) {
        row.push(org.primary_account_owner.first_name);
        row.push(org.primary_account_owner.last_name);
        row.push(org.primary_account_owner.email);
      } else {
        row.push('', '', '');
      }
      row.push(org.phone_number);
      row.push(org.user_count);
      row.push(org.certificate_count);
      row.push(org.last_user_activity ? dateFormatter(org.last_user_activity, 'en-US', {timeZone: 'UTC'}) : '');
      csvRows.push(row.join(','));
    });

    const csvData = csvRows.join('\n');
    const filename = 'approved-organizations';
    downloadCsvAsFile(csvData, filename, false);
  };

  handleApprovedOrgsClick = () => {
    // this.props.history.push('/approved-organizations');
    this.setState({approvedOrgDownloadButtonDisabled: true});
    const endpoint = `${config.apiGateway.URL}/organizations/approved-list`;
    RequestService.get(
      endpoint,
      (res) => {
        if (res.data.success) {
          this.getCsv(res.data.approvedOrgs);
        }
        this.setState({approvedOrgDownloadButtonDisabled: false});
      },
      (err) => {
        console.error(err);
        this.setState({approvedOrgDownloadButtonDisabled: false});
      },
    );
  };

  renderAdminLoader = () => {
    return (
      <div className='margin-top-9 margin-bottom-9'>
        <Loader />
      </div>
    );
  };

  renderTransToggle = () => {
    const {featureToggleStore, authStore} = this.props;
    if (featureToggleStore.translations) return null;
    return (
      <div>
        {authStore.isEditor && (
          <span>
            <label className='margin-top-1' htmlFor='toggleOpen'>
              <b>Disable Translations</b>
            </label>
            <Switch
              id='toggleOpen'
              checked={featureToggleStore.translations}
              onChange={featureToggleStore.toggleTranslations}
              inputProps={{'aria-label': 'Expand All'}}
              color='primary'
            />
            <label className='margin-top-1' htmlFor='toggleOpen'>
              <b>Enable Translations</b> (WARNING: This is under development.)
            </label>
          </span>
        )}
      </div>
    );
  };

  renderTopGameSection = (header) => {
    const {adminReportsStore, gamesStore} = this.props;
    const certificateOnly = header !== 'Most played games';
    const sourceReport = certificateOnly
      ? adminReportsStore.sortedGameSessionReportByCertificates
      : adminReportsStore.sortedGameSessionReportByTotal;

    const bodyJsx = sourceReport.slice(0, 3).map((gs) => {
      const game = gamesStore.getGame(gs.gameId);
      return <li key={gs.gameId}>{game.name}</li>;
    });

    return (
      <div className='grid-col-6'>
        <h3 className='no-margin'>{header}</h3>
        <i>{everybodyMessage}</i>
        <ol className='margin-top-105'>{bodyJsx}</ol>
      </div>
    );
  };

  renderTopGames = () => {
    const {adminReportsStore, gamesStore} = this.props;
    const loading = adminReportsStore.gameSessionsReportLoading || gamesStore.gamesLoading;
    const loaderJsx = <div className='grid-col-6'>{this.renderAdminLoader()}</div>;
    return (
      <div className='grid-row margin-bottom-4 padding-left-4 margin-top-2'>
        {loading ? loaderJsx : this.renderTopGameSection('Most played games')}
        {loading ? loaderJsx : this.renderTopGameSection('Games with the most wins')}
      </div>
    );
  };

  renderSessionsBarChart = () => {
    const {adminReportsStore, gamesStore} = this.props;
    const loading = adminReportsStore.gameSessionsReportLoading || gamesStore.gamesLoading;
    if (loading) {
      return this.renderAdminLoader();
    }
    const {showAccountsOnly, showAnonymousOnly, showCompletedOnly} = this.state;
    const sessionData = adminReportsStore.gameSessionsReport.map((gs) => {
      const game = gamesStore.getGame(gs.game_id);
      let complete = gs.completed_count_all;
      let inProgress = gs.played_count_all - gs.completed_count_all;
      if (showAccountsOnly) {
        complete = gs.completed_count_user;
        inProgress = gs.played_count_user - gs.completed_count_user;
      }
      if (showAnonymousOnly) {
        complete = gs.completed_count_anonymous;
        inProgress = gs.played_count_anonymous - gs.completed_count_anonymous;
      }
      const barData = {name: game.name, Won: complete};
      if (showCompletedOnly) {
        return barData;
      }
      return {...barData, 'In progress': inProgress};
    });
    return <SessionsBarChart sessionsData={sessionData} />;
  };

  renderDatesToDisplayRow = (datesToDisplay) => {
    return (
      <div className='grid-row'>
        <h4 className='no-margin date-range-display'>{datesToDisplay}</h4>
      </div>
    );
  };

  componentDidMount() {
    const {gamesStore} = this.props;
    gamesStore.getGames();
    // adminReportsStore.hydrateStore(appliedStartDate, appliedEndDate, true);
  }

  render() {
    const {adminReportsStore, gamesStore, gameSessionsStore, authStore, testing} = this.props;
    const {appliedStartDate, appliedEndDate} = this.state;
    adminReportsStore.hydrateStore(appliedStartDate, appliedEndDate, false);
    let gameUsageReportStatistics = adminReportsStore.gameUsageReportStatistics();
    let gameCertificateReportStatistics = adminReportsStore.gameUsageReportStatistics(true);

    const datesToDisplay = displayDateRange(appliedStartDate, appliedEndDate);
    const datesToDisplayRowJsx = this.renderDatesToDisplayRow(datesToDisplay);

    return (
      <div className='padding-bottom-6 admin-account'>
        <h1 id='dashboard' className='margin-bottom-0'>
          Dashboard
        </h1>
        <div className='grid-row'>
          {/* TODO: Uncomment */}
          {/* <Link to='/account/update'>Update Profile</Link>
          <span> | </span> */}
          <Link to='/account/change-password'>Change Password</Link>
          <span> | </span>
          <Link className='hide-print' to='/certificate'>
            Blank Certificate
          </Link>
          {authStore.isEditor && (
            <>
              <span> | </span>
              <Link className='hide-print' to='/help-center/115'>
                Edit Updates Topic
              </Link>
            </>
          )}
          <span> | </span>
          <button
            className='usa-button usa-button--unstyled usa-btn-link'
            onClick={this.handleApprovedOrgsClick}
            disabled={this.state.approvedOrgDownloadButtonDisabled}
            type='button'
          >
            Approved Organizations Download
          </button>
        </div>
        <div className='grid-row'>
          {authStore.isEditor && (
            <>
              <Link className='hide-print' to='/emails'>
                Edit Email Communications
              </Link>
              <span> | </span>
            </>
          )}
          <Link className='hide-print' to='/editor/force-certificate'>
            Manually Award Game Certificate
          </Link>
          <span> | </span>
          <Link className='hide-print' to='/editor/player-admin'>
            Player Admin
          </Link>
        </div>
        <AccountSubHeader rightJsx={this.renderTransToggle()} pText={authStore.email} />
        <div className='grid-col-10 margin-top-2'>
          <h3 className='no-margin'>Refine Your Statistics</h3>
          <p>
            Apply this filter to refine your statistics. Select the date range for the statistics. When you’re done,
            select <b>Apply Filter.</b>
          </p>
          <p>
            <b>REMEMBER:</b> Public launch was September 30, 2021. To remove test data, be sure to{' '}
            <b>FILTER for Start Date 9/30/2021.</b>
          </p>
        </div>
        <div className='grid-row margin-bottom-3 margin-top-2'>
          <Formik
            initialValues={{
              startDate: appliedStartDate,
              endDate: appliedEndDate,
            }}
            onSubmit={(values) => this.applyFilter(values.startDate, values.endDate)}
          >
            {(formikProps) => (
              <Form className='full-width grid-row'>
                <div className='grid-col-6'>
                  <div className='grid-row margin-bottom-2'>
                    <div className='grid-col-12'>
                      <h4 className='no-margin'>Filter by Date</h4>
                    </div>
                    <div className='grid-col-6 mobile-full-width'>
                      <div className='grid-row'>
                        <label htmlFor='startDate' className='color-primary font-size-14'>
                          Start date:
                        </label>
                      </div>
                      <div className='grid-row'>
                        <Field
                          name='startDate'
                          type='date'
                          className={`${
                            formikProps.touched.endDate && formikProps.errors.endDate && 'alert'
                          } fdic-input mobile-full-width start-date-input`}
                        />
                      </div>
                    </div>
                    <div className='grid-col-5 mobile-full-width margin-right-2'>
                      <div className='grid-row'>
                        <label htmlFor='endDate' className='color-primary font-size-14'>
                          End date:
                        </label>
                      </div>
                      <div className='grid-row'>
                        <Field
                          name='endDate'
                          type='date'
                          className={`${
                            formikProps.touched.endDate && formikProps.errors.endDate && 'alert'
                          } fdic-input mobile-full-width end-date-input`}
                          placeholder='test'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='grid-row'>
                    <div className='grid-col-5 mobile-full-width'>
                      <button className='usa-button full-width apply-filter' onClick={formikProps.handleSubmit}>
                        Apply Filter
                      </button>
                    </div>
                  </div>
                  {formikProps.touched.endDate && formikProps.errors.endDate && (
                    <div className='grid-row alert full-width margin-top-105'>{formikProps.errors.endDate}</div>
                  )}
                </div>
                <div className='grid-col-6'>
                  <div className='grid-col-12'>
                    <h4 className='no-margin'>Jump to</h4>
                  </div>
                  <div className='grid-col-12'>
                    <ul>
                      <li>
                        <LinkButton id={'overall-statistics'} textJsx={<span>Overall Statistics</span>} />
                      </li>
                      <li>
                        <LinkButton id={'games-summary'} textJsx={<span>Games Summary</span>} />
                      </li>
                      <li>
                        <LinkButton
                          id={'game-usage'}
                          textJsx={<span>Number of players vs. number of games played</span>}
                        />
                      </li>
                      <li>
                        <LinkButton
                          id={'certificates'}
                          textJsx={<span>Number of players vs. number of certificates earned</span>}
                        />
                      </li>
                      <li style={{paddingTop: '.5rem'}}>
                        <LinkButton
                          id={'your-game-play-information'}
                          textJsx={
                            <span>
                              <i>Your</i> Game Play Information
                            </span>
                          }
                        />
                      </li>
                    </ul>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
        <div className='grid-row margin-bottom-2'>
          <PrintButton printText='Print' />
        </div>
        <div
          id='overall-statistics'
          className='page-break-inside-avoid background-color-light-grey padding-left-3 padding-top-2 padding-right-3 padding-bottom-2 margin-bottom-8 grid-col-12'
        >
          <div className='grid-row margin-top-3'>
            <div className='grid-col-6'>
              <h2 tabIndex='0' className='no-margin'>
                Overall Statistics
              </h2>
            </div>
            <div className='grid-col-6 text-align-right'>
              <BackToTop />
            </div>
          </div>
          {datesToDisplayRowJsx}
          <div>
            {this.renderTopGames()}
            {adminReportsStore.userReportLoading ? (
              this.renderAdminLoader()
            ) : (
              <div className='grid-row padding-left-4'>
                <div className='grid-col-6 mobile-full-width'>
                  <div className='grid-row'>
                    <h3 className='margin-right-2 individual-user-count margin-bottom-0'>
                      Total players with accounts: {adminReportsStore.userReport.individualUserCount.withCommas()}
                    </h3>
                    <i>{totalAccountsMessage}</i>
                  </div>
                  <div className='grid-row text-align-left'>
                    <UserPieChart
                      showLines={false}
                      extraSpaceForChart={true}
                      data={[
                        {
                          name: 'Accounts not linked to an organization',
                          value: adminReportsStore.userReport.usersWithoutOrganization,
                        },
                        {
                          name: 'Accounts linked to an organization',
                          value: adminReportsStore.userReport.usersWithOrganization,
                        },
                      ]}
                      testing={testing}
                    />
                  </div>
                </div>
                <div className='grid-col-6 mobile-full-width'>
                  <div className='grid-row'>
                    <h3 className='margin-right-2 margin-bottom-0'>
                      Total games opened and not won (yet): {this.totalInProgress(adminReportsStore.userReport)}
                    </h3>
                    <i>{everybodyMessage}</i>
                  </div>
                  <div className='grid-row'>
                    <UserPieChart
                      showLines={false}
                      data={[
                        {
                          name: 'For accounts not linked',
                          value: adminReportsStore.userReport.nonOrganizationGamesInProgress,
                        },
                        {
                          name: 'For accounts linked to an organization',
                          value: adminReportsStore.userReport.organizationGamesInProgress,
                        },
                        {
                          name: 'For anonymous (no account) players',
                          value: adminReportsStore.userReport.anonymousGamesInProgress,
                        },
                      ]}
                      testing={testing}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          id='games-summary'
          className='page-break-inside-avoid grid-col-12 margin-bottom-8 padding-left-3 padding-bottom-2'
        >
          <div className='grid-row'>
            <div className='grid-col-6'>
              <h2 tabIndex='0' className='no-margin'>
                Games Summary
              </h2>
            </div>
            <div className='grid-col-6 text-align-right'>
              <BackToTop />
            </div>
          </div>
          {datesToDisplayRowJsx}
          <div className='grid-row'>{this.renderFilterOptions()}</div>
          {this.renderSessionsBarChart()}
        </div>
        <div
          id='game-usage'
          className='page-break-inside-avoid background-color-light-grey padding-left-3 padding-top-2 padding-right-3 padding-bottom-3 margin-bottom-8 grid-col-12'
        >
          <div className='grid-row margin-top-3'>
            <div className='grid-col-10'>
              <h2 tabIndex='0' className='no-margin'>
                Number of players vs. number of games played
              </h2>
            </div>
            <div className='grid-col-2 text-align-right'>
              <BackToTop />
            </div>
          </div>
          <div>
            {datesToDisplayRowJsx}
            <div className='grid-row'>
              <h5 className='no-margin'>
                Average number of games played by a player: {gameUsageReportStatistics.averageGamesPlayed}
              </h5>
            </div>
            <div className='grid-row'>
              <h5 className='no-margin'>Total players included in chart: {gameUsageReportStatistics.totalUsers}</h5>
            </div>
            <div className='grid-row'>
              <h5 className='no-margin'>Total games played: {gameUsageReportStatistics.totalUserGamesPlayed}</h5>
            </div>
            {!adminReportsStore.gameUsageReportLoading && (
              <div className='grid-row'>
                <ul className='margin-top-0'>
                  <li>
                    <i>
                      The average includes the {adminReportsStore.gameUsageReport[0]} players who have created an
                      account but haven’t played any games yet.
                    </i>
                  </li>
                  <li>
                    <i>“Number of games played” includes games in progress and games won.</i>
                  </li>
                  <li>
                    <i>{onlyAccountsMessage}</i>
                  </li>
                </ul>
              </div>
            )}
            {adminReportsStore.gameUsageReportLoading ? (
              this.renderAdminLoader()
            ) : (
              <UsageBarChart sessionsData={this.mapUsageData(adminReportsStore.gameUsageReport)} units='games played' />
            )}
          </div>
        </div>
        <div
          id='certificates'
          className='page-break-inside-avoid padding-left-3 padding-top-2 padding-right-3 padding-bottom-3 margin-bottom-9 grid-col-12'
        >
          <div className='grid-row margin-top-3'>
            <div className='grid-col-11'>
              <h2 tabIndex='0' className='no-margin'>
                Number of players vs. number of certificates earned
              </h2>
            </div>
            <div className='grid-col-1 text-align-right'>
              <BackToTop />
            </div>
          </div>
          <div>
            {datesToDisplayRowJsx}
            <div className='grid-row'>
              <h5 className='no-margin'>
                Average number of certificates earned by a player: {gameCertificateReportStatistics.averageGamesPlayed}
              </h5>
            </div>
            <div className='grid-row'>
              <h5 className='no-margin'>
                Total players included in chart: {gameCertificateReportStatistics.totalUsers}
              </h5>
            </div>
            <div className='grid-row'>
              <h5 className='no-margin'>
                Total certificates earned: {gameCertificateReportStatistics.totalUserGamesPlayed}
              </h5>
            </div>
            {!adminReportsStore.certificateReportLoading && (
              <div className='grid-row margin-bottom-9'>
                <ul>
                  <li>
                    <i>
                      The average includes the {adminReportsStore.certificateReport[0].length} players who have created
                      an account but haven’t earned any certificates yet.
                    </i>
                  </li>
                  <li>
                    <i>{onlyAccountsMessage}</i>
                  </li>
                </ul>
              </div>
            )}
            {adminReportsStore.certificateReportLoading ? (
              this.renderAdminLoader()
            ) : (
              <UsageBarChart
                sessionsData={this.mapUsageData(adminReportsStore.certificateReport)}
                units='certificates'
              />
            )}
          </div>
        </div>
        <IndividualGamePlayInfo authStore={authStore} gamesStore={gamesStore} gameSessionsStore={gameSessionsStore} />
      </div>
    );
  }
}

export {defaultStartDate, defaultEndDate, displayDateRange};
export default observer(AdminAccount);
