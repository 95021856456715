import React from 'react';
import {useTranslation} from 'react-i18next';

const GameStatusInformation = ({
  notStartedIcon,
  inProgressIcon,
  completeIcon,
  notStartedAltText,
  inProgressAltText,
  completeAltText,
}) => {
  const {t} = useTranslation();
  return (
    <div className='grid-row background-color-light-grey padding-left-2'>
      <div className='grid-col-12'>
        <p>{t('The following symbols indicate your progress on each game:')}</p>
      </div>
      <div className='grid-row grid-col-12'>
        <div className='grid-col-6 mobile-full-width'>
          <div className='grid-row'>
            <div className='grid-col-2 padding-top-2 padding-left-2'>
              <img alt={notStartedAltText} src={notStartedIcon} />
            </div>
            <div className='grid-col-10'>
              <p>{t('Means you haven’t started the game.')}</p>
            </div>
          </div>

          <div className='grid-row'>
            <div className='grid-col-2 padding-top-2 padding-left-2'>
              <img alt={inProgressAltText} src={inProgressIcon} />
            </div>
            <div className='grid-col-10'>
              <p>{t('Means you started, but did not yet win every round.')}</p>
            </div>
          </div>
        </div>

        <div className='grid-col-6 mobile-full-width'>
          <div className='grid-row'>
            <div className='grid-col-2 padding-top-2 padding-left-2'>
              <img alt={completeAltText} src={completeIcon} />
            </div>
            <div className='grid-col-10'>
              <p>{t('Means you won every round in the game and earned a certificate.')}</p>
            </div>
          </div>
        </div>
      </div>
      <div className='grid-row'>
        <p>
          {t('You may play each game as many times as you want.')}
          <br />
          {t(
            'Once you earn a certificate of completion, it’s yours to keep! You won’t lose it, even if you play the same game again and don’t win.'
          )}
        </p>
        <p>
          <b>{t('Click on a Game Title to start or continue playing the game.')}</b>
        </p>
      </div>
    </div>
  );
};

export default GameStatusInformation;
