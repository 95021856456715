import React from "react";
import {Formik, Form} from "formik";
import Tables from "../utilities/Tables";
import {ExpandableTextarea} from "../utilities/Inputs";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation, Translation} from "react-i18next";

const emptyItem = (
  item = "",
  inputType = "labelNoBold",
  label = "",
  allowRemoval = false
) => ({
  item: {
    value: item,
    inputType: inputType,
    label: label,
    allowRemoval: allowRemoval,
  },
  timeframe: {value: "", inputType: "text"},
  amount: {value: "", inputType: "money"},
});

const totalRow = (label, section) => ({
  item: {
    value: (
      <Translation>
        {(t) => <span className="subtotal-row">{t(label)}</span>}
      </Translation>
    ),
    inputType: "labelHTML",
  },
  timeframe: {inputType: "calculated", calculation: () => ""},
  amount: {
    inputType: "calculated",
    calculation: (formikProps) => displayTotal(formikProps.values, section),
  },
});

const displayTotal = (values, section) => (
  <span className="subtotal-row">
		${total(values, section).toFixed(2).withCommas()}
  </span>
);

const total = (values, section) => {
  let total = 0;
  Object.keys(values[section]).forEach((subSection) =>
    values[section][subSection].forEach((row) => {
      if (row.amount.value) total += parseFloat(row.amount.value);
    })
  );
  return Math.round(total * 100) / 100;
};

const initialValues = {
  increasesSection: {
    "Increases in Income": [
      emptyItem("Tax refund"),
      emptyItem("Increase in my hourly wages"),
      emptyItem("Increase in hours, overtime pay"),
      emptyItem("Bonus from my job"),
      emptyItem("Gift"),
      emptyItem("Second job"),
      emptyItem("", "textWithLabel", "Other increase:"),
    ],
    total: [totalRow("Total Increases in Income", "increasesSection")],
  },
  decreasesSection: {
    "Decreases in Income": [
      emptyItem("Cut in my hours or pay at work"),
      emptyItem("Loss of overtime hours"),
      emptyItem("No income (for example, seasonal worker, loss of job)"),
      emptyItem("Life event that may decrease income (for example, divorce)"),
      emptyItem("", "textWithLabel", "Other decrease:"),
    ],

    total: [totalRow("Total Decreases in Income", "decreasesSection")],
  },
  specialsSection: {
    "Periodic and Special Expenses": [
      emptyItem("Self-employment taxes"),
      emptyItem("Property taxes"),
      emptyItem("Insurance payments"),
      emptyItem("Back to school shopping"),
      emptyItem(
        "Special events (for example, holidays, cultural celebrations, weddings, birthdays)"
      ),
      emptyItem(
        "Emergencies (for example, flat tire, broken assistive technology or mobility device, car repairs, broken bone)"
      ),
      emptyItem("", "textWithLabel", "Other:"),
    ],

    total: [totalRow("Total Periodic and Special Expenses", "specialsSection")],
  },
};

const SpendingSavingPlan = ({tool, canEdit}) => {
  const {t, i18n} = useTranslation();
  return (
    <div>
      <Formik initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <EditableContent
              htmlType="div"
              initialContent={
                i18n.language === "es"
                  ? tool.contentsEs || tool.contents
                  : tool.contents
              }
              fieldName={i18n.language === "es" ? "contents_es" : "contents"}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            <h3>{t("Increases in Income")}</h3>
            {Tables.renderSimpleTable(
              [
                t("Item"),
                t("Timeframe (If you expect it, when will it likely happen?)"),
                t("Estimated Amount of Money"),
              ],
              initialValues.increasesSection,
              formikProps,
              "increasesSection",
              true,
              () => emptyItem("", "textWithLabel", "Other increase:", true),
              undefined,
              false,
              true,
              "fixedWidth-table",
              t("Increases in income")
            )}
            <ExpandableTextarea
              name="incomeSavings"
              question={
                <b>
                  {t(
                    "How much money from expected increases in your income can you set aside in your savings?"
                  )}
                </b>
              }
              title={t(
                "How much money from expected increases in your income can you set aside in your savings?"
              )}
            />
            <div className="margin-bottom-4"></div>
            <h3>{t("Decreases in Income")}</h3>
            {Tables.renderSimpleTable(
              [
                t("Item"),
                t("Timeframe (If you expect it, when will it likely happen?)"),
                t("Estimated Amount of Money"),
              ],
              initialValues.decreasesSection,
              formikProps,
              "decreasesSection",
              true,
              () => emptyItem("", "textWithLabel", "Other decrease:", true),
              undefined,
              false,
              true,
              "fixedWidth-table",
              t("Decreases in income")
            )}
            <ExpandableTextarea
              name="savings"
              question={
                <span>
                  <b>
                    {t(
                      "What strategies can you use to make sure you can cover your expenses if your income decreases? "
                    )}
                  </b>{" "}
                  {t(
                    "These strategies might be financial goals that focus on saving money now and setting it aside to cover decreases in your income in the future."
                  )}
                </span>
              }
              title={t(
                "What strategies can you use to make sure you can cover your expenses if your income decreases?"
              )}
            />
            <div className="margin-bottom-4"></div>
            <h3>{t("Periodic and Special Expenses")}</h3>
            {Tables.renderSimpleTable(
              [
                t("Item"),
                t("Timeframe (If you expect it, when will it likely happen?)"),
                t("Estimated Amount of Money"),
              ],
              initialValues.specialsSection,
              formikProps,
              "specialsSection",
              true,
              () => emptyItem("", "textWithLabel", "Other:", true),
              undefined,
              false,
              true,
              "fixedWidth-table",
              t("Periodic and special expenses")
            )}
            <ExpandableTextarea
              name="coverExpenses"
              question={
                <b>
                  {t(
                    "How will you cover these expenses when you need to pay for them?"
                  )}
                </b>
              }
              title={t(
                "How will you cover these expenses when you need to pay for them?"
              )}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SpendingSavingPlan;
