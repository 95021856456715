import React from 'react';
import StrategyCard from '../utilities/StrategyCard';
import EditableContent from '../../../components/EditableContent/EditableContent';
import config from '../../../config';
import {useTranslation} from 'react-i18next';

const StayFocusedOnGoals = ({strategy, canEdit}) => {
  const {i18n} = useTranslation();
  const isSpanish = i18n.language === 'es';
  return (
    <div className='strategy'>
      <EditableContent
        htmlType='div'
        initialContent={isSpanish ? strategy.contentsEs || strategy.contents : strategy.contents}
        fieldName={isSpanish ? 'contents_es' : 'contents'}
        updateEndpoint={`${config.apiGateway.URL}/topic-items/${strategy.id}`}
        canEdit={canEdit}
        richText
      />
      {strategy.toolContents.map((toolContent) => (
        <StrategyCard key={toolContent.id}>
          <EditableContent
            htmlType='div'
            fieldName={isSpanish ? 'details_es' : 'details'}
            initialContent={isSpanish ? toolContent.detailsEs || toolContent.details : toolContent.details}
            updateEndpoint={`${config.apiGateway.URL}/tool-contents/${strategy.id}/${toolContent.id}`}
            canEdit={canEdit}
            richText
          />
        </StrategyCard>
      ))}
    </div>
  );
};

export default StayFocusedOnGoals;
