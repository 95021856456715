import {observable, decorate, action, computed} from "mobx";
import RequestService from "../services/RequestService";
import config from "../config";


class HelpCenterStore {
  constructor() {
    this.buildFromSession();
  }

  helpTopic = null;
  helpTopicLoading = true;
  helpTopics = [];
  helpTopicsLoading = true;
  searchedHelpTopics = [];
  searchedHelpTopicsLoading = true;
  groupedHelpTopics = {};
  groupedHelpTopicsLoading = true;
  activeGroupedHelpTopics = {};
  helpTopicCategories = [];
  helpTopicCategoriesLoading = true;
  deleteHelpTopicCategoryFailure = false;

  sortedBy = null;
  sortOrder = null;

  getHelpTopics = (useSession = false) => {
    (this.helpTopics.length === 0 || !useSession) && RequestService.get(`${config.apiGateway.URL}/help-topics`, (response) => {
      this.groupedHelpTopics = response.data.grouped_help_topics;
      this.activeGroupedHelpTopics = this.buildActiveGroupedHelpTopics(this.groupedHelpTopics);
      this.helpTopics = response.data.help_topics;
      this.sortHelpTopics('name');
      this.groupedHelpTopicsLoading = false;
      this.helpTopicsLoading = false;
      useSession && this.writeToSession();
    });
  };

  getHelpTopic = (id) => (
    this.helpTopic === null && RequestService.get(`${config.apiGateway.URL}/help-topics/${id}`, (response) => {
      this.helpTopic = response.data;
      this.helpTopicLoading = false;
    })
  );

  getHelpTopicCategories = () => (
    this.helpTopicCategories.length === 0 && RequestService.get(`${config.apiGateway.URL}/help-topic-categories`, (response) => {
      this.helpTopicCategories = response.data;
      this.helpTopicCategoriesLoading = false;
    })
  );

  buildActiveGroupedHelpTopics = (allGroupedHelpTopics) => {
    const activeTopics = {};
    Object.keys(allGroupedHelpTopics).forEach(topicGroupId => {
      const activeHelpTopics = allGroupedHelpTopics[topicGroupId].filter(helpTopic => helpTopic.active === true);
      if (activeHelpTopics.length > 0) {
        activeTopics[topicGroupId] = activeHelpTopics;
      }
    });
    return activeTopics;
  };

  deleteHelpTopicCategory = (id) => (
    RequestService.delete(`${config.apiGateway.URL}/help-topic-categories/${id}`, () => {
      let updatedHelpTopicCategories = this.helpTopicCategories;
      updatedHelpTopicCategories.splice(this.helpTopicCategories.findIndex((category) => category.id === id), 1);
      this.helpTopicCategories = updatedHelpTopicCategories;
    }, () => {
      this.deleteHelpTopicCategoryFailure = true;
    }, true)
  );

  createHelpTopicCategory = (values, callback) => {
    RequestService.post(`${config.apiGateway.URL}/help-topic-categories`, {
      help_topic_category: values
    }, 
    (response) => {
      let updatedHelpTopicCategories = this.helpTopicCategories;
      updatedHelpTopicCategories.push(response.data);
      this.helpTopicCategories = updatedHelpTopicCategories;
      callback && callback();
    });
  };

  updateHelpTopicCategory = (id, values, callback) => {
    RequestService.post(`${config.apiGateway.URL}/help-topic-categories/${id}`, {
      help_topic_category: values
    }, (response) => {
      let updatedHelpTopicCategories = this.helpTopicCategories;
      updatedHelpTopicCategories[this.helpTopicCategories.findIndex((category) => category.id === id)] = response.data;
      this.helpTopicCategories = updatedHelpTopicCategories;
      callback && callback();
    });
  };

  updateHelpTopic = (values, callback) => {
    RequestService.post(`${config.apiGateway.URL}/help-topics/${values.id}`, {
      help_topic: values
    }, (response) => {
      this.updateStoreHelpTopic(values.id, response.data);
      callback && callback();
    });
  };

  updateStoreHelpTopic = (id, data) => {
    let updatedHelpTopics = this.helpTopics;
    updatedHelpTopics[this.helpTopics.findIndex((topic) => topic.id === id)] = data;
    this.helpTopics = updatedHelpTopics;
  };

  createHelpTopic = (values, callback) => {
    RequestService.post(`${config.apiGateway.URL}/help-topics`, {
      help_topic: {
        name: values.name,
        details: values.details,
        help_topic_category_id: values.help_topic_category_id,
        account_types: values.account_types
      }
    }, (response) => {
      this.createStoreHelpTopic(response.data);
      callback && callback(response.data);
    });
  };

  createStoreHelpTopic = (data) => {
    let updatedHelpTopics = this.helpTopics;
    updatedHelpTopics.push(data);
    this.helpTopics = updatedHelpTopics;
  };
  
  deleteHelpTopic = (id, callback) => {
    RequestService.delete(`${config.apiGateway.URL}/help-topics/${id}`, () => this.deleteStoreHelpTopic(id, callback), null, true);
  };

  deleteStoreHelpTopic = (id, callback) => {
    let updatedHelpTopics = this.helpTopics;
    updatedHelpTopics.splice(this.helpTopics.findIndex((t) => t.id === id), 1);
    this.helpTopics = updatedHelpTopics;
    callback && callback();
  };

  sortHelpTopics = (field) => {
    if(field === this.sortedBy) {
      this.sortOrder = this.sortOrder === 'asc' ? 'desc' : 'asc';
    }
    else {
      this.sortOrder = 'asc';
    }
    let sortedTopics = this.helpTopics.slice();
    sortedTopics.sort((a, b) => {
      if(a[field] < b[field]) return this.sortOrder === 'asc' ? -1 : 1;
      if(b[field] < a[field]) return this.sortOrder === 'asc' ? 1 : -1;
      return 0;
    });
    this.helpTopics = sortedTopics;
    this.sortedBy = field;
  };

  writeToSession = () => {
    let json = JSON.stringify(this);
    sessionStorage.setItem('HelpCenterStore', json);
  };

  buildFromSession = () => {
    let json = JSON.parse(sessionStorage.getItem('HelpCenterStore'));
    if(json) {
      this.helpTopics = json.helpTopics;
      this.helpTopicsLoading = json.helpTopicsLoading;
      this.groupedHelpTopics = json.groupedHelpTopics;
      this.groupedHelpTopicsLoading = json.groupedHelpTopicsLoading;
      this.activeGroupedHelpTopics = json.activeGroupedHelpTopics;
    }
  };

  get editorHelpTopics() {
    return(
      this.helpTopics.filter((helpTopic) => (
        helpTopic.account_types.includes('editor') && helpTopic.active
      ))
    );
  }
}

decorate(HelpCenterStore, {
  groupedHelpTopics: observable,
  groupedHelpTopicsLoading: observable,
  helpTopic: observable,
  helpTopicLoading: observable,
  helpTopics: observable,
  helpTopicsLoading: observable,
  helpTopicCategories: observable,
  helpTopicCategoriesLoading: observable,
  deleteHelpTopicCategoryFailure: observable,
  searchHelpTopics: observable,
  searchedHelpTopicsLoading: observable,
  getHelpTopicCategories: action,
  getHelpTopics: action,
  sortHelpTopics: action,
  editorHelpTopics: computed,
  sortedBy: observable,
  sortOrder: observable
});

export default HelpCenterStore;