import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cc from 'classcat';
import {AccordionTitle} from '.';
import AccordionContent from './AccordionContent';

class AccordionRowV2 extends Component {
  constructor(props) {
    super(props);
    this.state = {active: props.forceOpen};
  }

  static propTypes = {
    forceOpen: PropTypes.bool,
    onlyOpenOnTitle: PropTypes.bool,
    title: PropTypes.any,
    content: PropTypes.any,
  };

  toggleClass = (e) => {
    this.setState({active: !this.state.active});
  };

  componentDidUpdate(prevProps) {
    if (prevProps.forceOpen && !this.props.forceOpen) {
      this.setState({active: false});
    } else if (!prevProps.forceOpen && this.props.forceOpen) {
      this.setState({active: false});
    }
  }

  render() {
    let tabOpen = this.state.active || this.props.forceOpen;
    let classes = cc([
      'accordion-row',
      tabOpen ? 'active' : '',
      'page-break-inside-avoid',
      this.props.forceOpen ? 'hide-dash' : '',
    ]);

    return (
      <div
        title={
          this.props.htmlTitle
            ? `${this.props.htmlTitle} ${
              this.state.active ? 'expanded' : 'collapsed'
            }`
            : ''
        }
        id={this.props.id}
        tabIndex={0}
        className={classes}
        aria-expanded={this.state.active}
      >
        <AccordionTitle
          toggleOpen={this.toggleClass}
          onClick={
            !(this.props.forceOpen || this.props.onlyOpenOnTitle)
              ? this.toggleClass
              : undefined
          }
        >
          {this.props.title}
        </AccordionTitle>
        <AccordionContent>{tabOpen && this.props.content}</AccordionContent>
      </div>
    );
  }
}

export default AccordionRowV2;
