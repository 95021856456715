import React from 'react';
import {toolMapper} from '../Tools';


const ToolIndex = () => console.log(toolMapper[1].type.prototype.constructor.name) || (
  <div className="tool-index">
    <h1>Tool Index</h1>
    {
      Object.keys(toolMapper).map((toolId) => (
        <div>
          <span className="font-size-14">{toolId} => <a href={`/tools/${toolId}`}>{toolMapper[toolId].type.prototype.constructor.name}</a></span>
        </div>
      ))
    }
  </div>
);

export default ToolIndex;