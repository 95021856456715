import React, {Component} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import queryString from 'query-string';
import 'react-tabs/style/react-tabs.css';
import Switch from '@material-ui/core/Switch';
import {Formik, Form, Field} from 'formik';
import EditableContent from '../../components/EditableContent';
import {Accordion, AccordionRowV2} from '../../components/Accordion';
import config from '../../config';
import ResourcePanel from './ResourcePanel';
import {Loader} from '../../components/Loader';
import ExternalLinkWarning from '../resources/ExternalLinkWarning';
import RequestService from '../../services/RequestService';

import {withTranslation, Trans} from 'react-i18next';

const tools = 'Tools';
const keyTakeaways = 'Key Takeaways';
const faqs = 'Frequently Asked Questions';
const helpfulLinks = 'Helpful Links';

const tabs = [keyTakeaways, tools, faqs, helpfulLinks];

class ResourceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topics: props.topics,
      activeTab: tabs[0],
      faqsOpen: false,
    };
    this.faqToFocus = React.createRef();
  }

  componentDidUpdate() {
    if (this.faqToFocus.current) {
      window.scrollTo(0, this.faqToFocus.current.offsetTop);
      this.faqToFocus.current.focus();
    }
  }

  groupTopicItem = (topicItems) =>
    topicItems.reduce((r, a) => {
      r[a.gameName] = [...(r[a.gameName] || []), a];
      return r;
    }, {});

  renderTools = (tools) => {
    const {authStore, canEdit, t, i18n} = this.props;
    const isSpanish = i18n.language === 'es';
    return tools.map((tool, index) => (
      <div key={`tool-${index}`} className='tool-container'>
        <div className='grid-col-11 margin-bottom-2'>
          <div>
            {authStore.isEditor ? (
              <div>
                <EditableContent
                  htmlType='h4'
                  initialContent={isSpanish ? tool.nameEs || tool.name : tool.name}
                  fieldName={isSpanish ? 'name_es' : 'name'}
                  updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
                  canEdit={canEdit}
                  fullWidth
                />
                <a title={isSpanish ? tool.nameEs || tool.name : tool.name} href={`/tools/${tool.toolId}`}>
                  {` (${t('link to tool')})`}
                </a>
              </div>
            ) : (
              <a title={isSpanish ? tool.nameEs || tool.name : tool.name} href={`/tools/${tool.toolId}`}>
                <h4 className='no-margin'>{isSpanish ? tool.nameEs || tool.name : tool.name}</h4>
              </a>
            )}
          </div>
          <div>
            <EditableContent
              htmlType='p'
              initialContent={isSpanish ? tool.detailsEs || tool.details : tool.details}
              fieldName={isSpanish ? 'details_es' : 'details'}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
          </div>
        </div>
      </div>
    ));
  };

  renderKeyTakeaways = (keyTakeaways) => {
    const {displayAll, canEdit, i18n} = this.props;
    const isSpanish = i18n.language === 'es';
    return keyTakeaways.map((keyTakeaway, index) => (
      <div key={`keytakeaway-${index}`} className={`${!displayAll && 'padding-top-4'}`}>
        <EditableContent
          initialContent={isSpanish ? keyTakeaway.detailsEs || keyTakeaway.details : keyTakeaway.details}
          fieldName={isSpanish ? 'details_es' : 'details'}
          updateEndpoint={`${config.apiGateway.URL}/topic-items/${keyTakeaway.id}`}
          canEdit={canEdit}
          richText
        />
      </div>
    ));
  };

  renderFaqs = (faqs) => {
    let windowFaqId = window.location.hash.replace('#', '');
    const {displayAll, canEdit, i18n} = this.props;
    const isSpanish = i18n.language === 'es';
    return (
      <div className={`faqs ${!displayAll && 'padding-top-4'}`}>
        {canEdit && this.createFaqForm(faqs[0].gameId, faqs[0].gameName, faqs[0].topicId)}
        <Accordion>
          {faqs.map((faq, index) => (
            <AccordionRowV2
              focusRef={windowFaqId === faq.id.toString() && this.faqToFocus}
              id={faq.id}
              key={`faq-${index}`}
              forceOpen={canEdit || windowFaqId === faq.id.toString() || this.state.faqsOpen}
              title={
                <EditableContent
                  initialContent={isSpanish ? faq.nameEs || faq.name : faq.name}
                  fieldName={isSpanish ? 'name_es' : 'name'}
                  updateEndpoint={`${config.apiGateway.URL}/topic-items/${faq.id}`}
                  canEdit={canEdit}
                  whiteButton
                  canDelete
                  deleteEndpoint={`${config.apiGateway.URL}/topic-items/${faq.id}`}
                  deleteCallback={() => window.location.reload()}
                />
              }
              content={
                <React.Fragment>
                  <EditableContent
                    initialContent={isSpanish ? faq.detailsEs || faq.details : faq.details}
                    fieldName={isSpanish ? 'details_es' : 'details'}
                    updateEndpoint={`${config.apiGateway.URL}/topic-items/${faq.id}`}
                    canEdit={canEdit}
                    richText
                  />
                  <p>
                    <Trans i18nKey='resourceList.faqLearnMore'>
                      To find out more, play the <i>How Money Smart Are You?</i> game:{' '}
                    </Trans>
                    <strong>
                      <a href={`/game/${faq.gameId}`}>{this.props.t(faq.gameName)}</a>
                    </strong>
                  </p>
                </React.Fragment>
              }
            />
          ))}
        </Accordion>
      </div>
    );
  };

  renderhelpfulLinks = (helpfulLinks) => {
    const {displayAll, canEdit, i18n} = this.props;
    const isSpanish = i18n.language === 'es';
    return (
      <div className={'helpful-links'}>
        {!displayAll && (
          <div className='grid-row margin-bottom-2'>
            <p className='color-primary external-link-header margin-0'>
              <ExternalLinkWarning />
            </p>
          </div>
        )}
        {helpfulLinks.map((info, index) => (
          <div key={`more-information-${index}`}>
            <EditableContent
              initialContent={isSpanish ? info.detailsEs || info.details : info.details}
              fieldName={isSpanish ? 'details_es' : 'details'}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${info.id}`}
              canEdit={canEdit}
              richText
            />
          </div>
        ))}
      </div>
    );
  };

  createFaq = (values, gameId, topicId) => {
    console.log(values, gameId, topicId);
    RequestService.post(`${config.apiGateway.URL}/topic-items/${topicId}/${gameId}`, values, () => {
      window.location.reload();
    });
  };

  createFaqForm = (gameId, gameName, topicId) => {
    return (
      <Formik 
        initialValues={{
          name: "",
          details: ""
        }}
        onSubmit={(values) => this.createFaq(values, gameId, topicId)}>
        {(formikProps) => (
          <Form>
            <h4 className='margin-bottom-0'>Add a new FAQ for {gameName}</h4>
            <div className='grid-row margin-bottom-2'>
              <div className='grid-col-1'>Title: </div>
              <div className='grid-col-6'>
                <Field name='name' className='full-width' />
              </div>
            </div>
            <div className='grid-row'>
              <div className='grid-col-1'>Details: </div>
              <div className='grid-col-6'>
                <Field component='textarea' name='details' rows='5' className='full-width' />
              </div>
            </div>
            <div className='grid-row margin-bottom-2'>
              <button className='usa-button' onClick={formikProps.handleSubmit}>
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  renderGroupedSection = (topicItems, rendering, topicName) => {
    let unorderedGrouping = this.groupTopicItem(topicItems);
    const orderedGrouping = {};
    Object.keys(unorderedGrouping)
      .sort()
      .forEach((key) => {
        orderedGrouping[key] = unorderedGrouping[key];
      });
    return (
      <div>
        <ResourcePanel
          topicItems={orderedGrouping}
          rendering={rendering}
          gameCategories={this.props.gameCategories}
          games={this.props.games}
          topicName={topicName}
          disclaimer={
            topicName === helpfulLinks ? (
              <p className='color-primary external-link-header margin-0'>
                <ExternalLinkWarning />
              </p>
            ) : (
              ''
            )
          }
        >
          {topicName === 'Frequently Asked Questions' && (
            <div className='margin-top-2'>
              <label className='margin-top-1' htmlFor='toggleOpen'>
                <b>
                  <Trans i18nKey='resourceList.collapseAll'>Collapse all</Trans>
                </b>
              </label>
              <Switch
                id='toggleOpen'
                checked={this.state.faqsOpen}
                onChange={() => this.setState({faqsOpen: !this.state.faqsOpen})}
                inputProps={{'aria-label': 'Expand All'}}
                color='primary'
              />
              <label className='margin-top-1' htmlFor='toggleOpen'>
                <b>
                  <Trans i18nKey='resourceList.expandAll'>Expand all</Trans>
                </b>
              </label>{' '}
            </div>
          )}
        </ResourcePanel>
      </div>
    );
  };

  renderRouter = {
    [keyTakeaways]: this.renderKeyTakeaways,
    [tools]: this.renderTools,
    [faqs]: this.renderFaqs,
    [helpfulLinks]: this.renderhelpfulLinks,
  };

  render() {
    let topics = this.state.filteredTopics || this.props.topics;
    return topics ? (
      <Tabs
        onSelect={(index) => {
          this.setState({activeTab: tabs[index]});
          this.props.history && this.props.history.push({search: `?tab=${tabs[index]}`});
        }}
        defaultIndex={
          this.props.history && this.props.history.location.search
            ? tabs.indexOf(queryString.parse(this.props.history.location.search).tab)
            : 0
        }
      >
        <TabList>
          {tabs.map((topic, index) => (
            <Tab classID={`list-${index}`} key={`tabs-${index}`}>
              <div className='resources-tabs'>
                <h3>{this.props.t(topic.toUpperCase())}</h3>
              </div>
            </Tab>
          ))}
        </TabList>

        <label className='stacked-tabs dropdown'>
          <div className='dd-button'>{this.state.activeTab}</div>

          <input type='checkbox' className='dd-input' id='test' />

          <ul className='dd-menu'>
            {tabs.map((topic, index) => (
              <Tab classID={`list-${index}`} key={`tabs-${index}`}>
                <div className='resources-tabs'>
                  <h3>{topic}</h3>
                </div>
              </Tab>
            ))}
          </ul>
        </label>

        {tabs.map((topic) => (
          <TabPanel key={`panel-${topic}`}>
            {topics[topic] ? (
              this.props.displayAll ? (
                this.renderGroupedSection(topics[topic], this.renderRouter[topic], topic)
              ) : (
                this.renderRouter[topic](topics[topic])
              )
            ) : (
              <h3>No {topic} to display for this game.</h3>
            )}
          </TabPanel>
        ))}
      </Tabs>
    ) : (
      <Loader />
    );
  }
}

export default withTranslation()(ResourceList);
