import {observable, decorate} from "mobx";
import RequestService from "../services/RequestService";
import config from "../config";

const SURVEY_QUESTION_1 = 'I learned something from this game.';
const SURVEY_QUESTION_2 = 'I would recommend this game to others.';

const SURVEY_RESPONSES = ['Strongly Agree', 'Agree', 'Disagree', 'Strongly Disagree'];

class SurveyStore {
  surveyResults = null;
  surveyResultsLoading = true;

  getSurveyResults = (startDate, endDate, forceUpdate = false) => (
    (!this.surveyResults || forceUpdate) && RequestService.get(`${config.apiGateway.URL}/survey-responses${this.queryString(startDate, endDate)}`, (response) => {
      this.surveyResults = response.data;
      this.surveyResultsLoading = false;
    }, (e) => console.log(e))
  );

  queryString = (startDate, endDate) => {
    let queryString = '?';
    if(startDate && endDate) queryString += `start_date=${startDate}&end_date=${endDate}`;
    return queryString;
  };

  submitSurvey = ({question, response, gameId}) => (
    response > -1 && RequestService.post(`${config.apiGateway.URL}/survey-responses`, {
      survey_response: {
        question: question,
        response: response,
        game_id: gameId
      }
    }, () =>{
      console.log('Successfully submitted response.');
    })
  );
}

decorate(SurveyStore, {
  surveyResults: observable,
  surveyResultsLoading: observable
});

export default SurveyStore;
export {SURVEY_QUESTION_1, SURVEY_QUESTION_2, SURVEY_RESPONSES};