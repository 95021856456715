import React from 'react';
import * as Yup from 'yup';
import Filter from 'bad-words';
import {observer} from 'mobx-react';
import Modal from 'react-responsive-modal';
import {Redirect} from 'react-router-dom';
import config from '../../config';
import {Loader} from '../../components/Loader';
import {Formik} from 'formik';
import EditHelpTopicForm from './components/EditHelpTopicForm';
import {withTranslation} from 'react-i18next';
import {RenderHelpTopicBreadcrumb} from './HelpTopic';
import queryString from 'query-string';

function notEqualTo(ref, msg) {
  return this.test({
    name: 'notEqualTo',
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test: (value) => {
      return value !== this.resolve(ref);
    },
  });
}

Yup.addMethod(Yup.string, 'notEqualTo', notEqualTo);

const filter = new Filter();
const nameTest = Yup.string()
  .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
  .min(2, 'Title must be at least two characters.')
  .max(100, 'Title must be at most 100 characters.')
  .required('Title is required.');
const detailsTest = Yup.string().required('Details are required.');
const categoryIdTest = Yup.string().notOneOf(['0'], 'Category is required.').required('Category is required.');

const helpTopicSchema = Yup.object().shape({
  name: nameTest,
  details: detailsTest,
  help_topic_category_id: categoryIdTest,
});

const helpTopicSpanishSchema = Yup.object().shape({
  name_es: nameTest,
  details_es: detailsTest,
  help_topic_category_id: categoryIdTest,
});

class EditHelpTopic extends React.Component {
  constructor() {
    super();
    this.state = {
      editItem: {},
      showDeleteModal: false,
    };
  }

  componentDidMount() {
    config.setUniqueTitle('Editor Help Center');
    this.props.helpCenterStore.getHelpTopic(this.props.match.params.helpTopicId);
    this.props.helpCenterStore.getHelpTopicCategories();
  }

  deleteHelpTopicFromForm = (e, id) => {
    e.target.disabled = true; // prevent extra requests
    this.props.helpCenterStore.deleteHelpTopic(id, () => {
      window.location = '/help-center';
    });
  };

  renderEditHelpTopicForm(helpTopic, helpTopicCategories, viewInSpanish) {
    const params = viewInSpanish ? '?lang=es' : '';
    const initialValues = {
      id: helpTopic.id,
      help_topic_category_id: helpTopic.help_topic_category_id,
      account_types: helpTopic.account_types,
    };
    if (viewInSpanish) {
      initialValues.name_es = helpTopic.name_es || '';
      initialValues.details_es = helpTopic.details_es || '';
    } else {
      initialValues.name = helpTopic.name;
      initialValues.details = helpTopic.details;
    }

    return (
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          this.props.helpCenterStore.updateHelpTopic(
            values,
            () => (window.location = `/help-center/${helpTopic.id}${params}`)
          );
        }}
        validationSchema={viewInSpanish ? helpTopicSpanishSchema : helpTopicSchema}
      >
        {(formikProps) => (
          <EditHelpTopicForm
            formikProps={formikProps}
            helpTopic={helpTopic}
            helpTopicCategories={helpTopicCategories}
            showDelete={true}
            showDetails={true}
            onDelete={() => this.setState({showDeleteModal: true})}
            onCancel={() => (window.location = `/help-center/${helpTopic.id}${params}`)}
            isSpanish={viewInSpanish}
            urlParams={params}
          />
        )}
      </Formik>
    );
  }

  render() {
    if (!this.props.authStore.isEditor) return <Redirect to='/home' />;

    const {helpTopicLoading, helpTopicCategoriesLoading} = this.props.helpCenterStore;
    if (helpTopicLoading || helpTopicCategoriesLoading) return <Loader />;

    const {helpTopic, helpTopicCategories} = this.props.helpCenterStore;
    const isSpanish = this.props.i18n.language === 'es';

    const params = queryString.parse(this.props.location.search);
    const viewInSpanish = params.lang && params.lang === 'es' ? true : false;

    return (
      <div className='edit-help-topic'>
        <RenderHelpTopicBreadcrumb helpTopic={helpTopic} isSpanish={isSpanish} isEdit={true} />
        {this.renderEditHelpTopicForm(helpTopic, helpTopicCategories, viewInSpanish)}
        <Modal open={this.state.showDeleteModal} onClose={() => this.setState({showDeleteModal: false})}>
          <div>
            <h4>Are you sure you want to delete this help topic?</h4>
            <p className='margin-bottom-6'>You cannot undo this action.</p>
            <button
              className='usa-button tan-button fdic-button margin-left-2 margin-right-3'
              onClick={(e) => this.deleteHelpTopicFromForm(e, helpTopic.id)}
            >
              Delete
            </button>
            <button
              onClick={() => this.setState({showDeleteModal: false})}
              className='usa-button usa-button--unstyled '
            >
              Cancel
            </button>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withTranslation()(observer(EditHelpTopic));
