import {observable, action, decorate} from 'mobx';
import RequestService from '../services/RequestService';
import config from '../config';

class GamesStore {
  constructor() {
    this.buildFromSession();
  }

  gamesLoading = true;
  games = [];
  gamesCategories = [];

  getGame = (gameId) => {
    if (isNaN(parseInt(gameId))) {
      const passedName = decodeURIComponent(gameId);
      return this.games.filter((game) => {
        const urlGameName = game.name
          .toLowerCase()
          .replace(/\u2014/g, '')
          .replace(/ /g, '-')
          .replace(/-{2,}/g, '-');
        return urlGameName === passedName;
      })[0];
    }
    return this.games.filter((game) => game.id.toString() === gameId.toString())[0];
  };

  getGameIdFromParam = (gameId) => {
    const game = this.getGame(gameId);
    return game ? game.id : null;
  };

  getGames = () => {
    this.games.length === 0 &&
      RequestService.get(`${config.apiGateway.URL}/game-categories`, (resp) => {
        this.gameCategories = resp.data;
        this.games = this.getGamesFromCategories(resp.data);
        this.gamesLoading = false;
        this.writeToSession();
      });
  };

  getGamesFromCategories = (gameCategories) => {
    let games = [];
    gameCategories.forEach((gameCategory) => {
      games = games.concat(gameCategory.games);
    });
    return games.sort((a, b) => {
      return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
    });
  };

  resetGamesStore = () => {
    this.gameCategories = [];
    this.games = [];
    this.gamesLoading = true;
  };

  clearSession = () => {
    sessionStorage.removeItem('GamesStore');
  };

  writeToSession = () => {
    let json = JSON.stringify(this);
    sessionStorage.setItem('GamesStore', json);
  };

  buildFromSession = () => {
    let json = JSON.parse(sessionStorage.getItem('GamesStore'));
    if (json) {
      this.games = json.games;
      this.gameCategories = json.gameCategories;
      this.gamesLoading = json.gamesLoading;
    }
  };
}

decorate(GamesStore, {
  games: observable,
  gameCategories: observable,
  gamesLoading: observable,
  getGames: action,
  getGame: action,
  resetGamesStore: action,
});

export default GamesStore;
