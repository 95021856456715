import React, {Component} from 'react';
import PropTypes from 'prop-types';

class AccordionTitle extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    let {children} = this.props;

    return (
      <div className="accordion-title" onClick={this.props.toggleOpen}>
        {children}
      </div>
    );
  }
}

export default AccordionTitle;