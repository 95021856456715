import React from "react";
import {Formik, Form} from "formik";
import Tables from "../utilities/Tables";
import {ExpandableTextarea} from "../utilities/Inputs";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation} from "react-i18next";

const emptyItem = ({needOptions, examplesOptions}) => ({
  item: {
    value: "",
    inputType: "checkboxes",
    options: needOptions,
    columnWidth: 3,
    noRowHeader: true,
  },
  products: {value: "", inputType: "checkboxes", options: examplesOptions},
});

const saveMoneyOptions = {
  needOptions: [
    "To save my money for emergencies or my goals in a safe and secure place",
  ],
  examplesOptions: ["Savings account"],
};

const payBillsOptions = {
  needOptions: ["To pay my bills in a safe and secure way"],
  examplesOptions: [
    "Checking account",
    "Second chance checking account",
    "Checkless checking account",
  ],
};

const sendMoneyOptions = {
  needOptions: ["To send money to family or friends electronically"],
  examplesOptions: [
    "Remittance transfer",
    "Person-to-person (P2P) payment app",
  ],
};

const accessOptions = {
  needOptions: ["To get access to cash quickly"],
  examplesOptions: [
    "Checking account with debit card or ATM card",
    "Reloadable insured prepaid card",
  ],
};

const borrowOptions = {
  needOptions: ["To borrow money"],
  examplesOptions: [
    "Credit card",
    "Installment loan",
    "Line of credit",
    "Mortgage",
    "Small-dollar loan ($500 - $3,000)",
  ],
};

const improveCreditOptions = {
  needOptions: ["To improve or build my credit"],
  examplesOptions: ["Credit-building loan", "Secured credit card"],
};

const initialValues = {
  needsOptions: {
    "My needs": [
      emptyItem(saveMoneyOptions),
      emptyItem(payBillsOptions),
      emptyItem(sendMoneyOptions),
      emptyItem(accessOptions),
      emptyItem(borrowOptions),
      emptyItem(improveCreditOptions),
    ],
  },
};

const IdentifyFinancialProducts = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  const isSpanish = i18n.language === 'es';
  return (
    <div>
      <Formik initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <EditableContent
              htmlType="div"
              initialContent={
                isSpanish
                  ? tool.contentsEs || tool.contents
                  : tool.contents
              }
              fieldName={isSpanish ? "contents_es" : "contents"}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            {Tables.renderSimpleTable(
              [
                t('My Money Management Needs'),
                t('Examples of Financial Products or Services to Meet That Need'),
              ],
              initialValues.needsOptions,
              formikProps,
              "needsOptions",
              true,
              emptyItem,
              undefined,
              false,
              true,
              "",
              'Table'
            )}
            <p>{t('Consider the following questions:')}</p>
            <ExpandableTextarea
              name="otherNeeds"
              question={t('What other money management needs do you have?')}
            />
            <ExpandableTextarea
              name="financialProducts"
              question={t('What financial products or services do you think will help you address those needs?')}
            />
            <ExpandableTextarea
              name="whereToGetProducts"
              question={t('Where can you get the financial products or services you need?')}
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default IdentifyFinancialProducts;
