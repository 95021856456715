import React from 'react';

const Alert = ({message, mode = "success"}) => {
  if (mode === "none") return null;
  const classNames = `usa-alert usa-alert--${mode} usa-alert--slim margin-bottom-2`;
  return (
    <div className={classNames}>
      <div className="usa-alert__body">
        <p className="usa-alert__text">{message}</p>
      </div>
    </div>
  );
};


export default Alert;