import React from "react";
import {Formik, Form} from "formik";
import Tables from "../utilities/Tables";
import {ExpandableTextarea} from "../utilities/Inputs";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation, Translation, Trans} from "react-i18next";

const emptyItem = (
  item = "",
  inputType = "questionlabel",
  increment = 1,
  allowRemoval = false
) => ({
  item: {
    value: item,
    label: item,
    inputType: inputType,
    increment: increment,
    allowRemoval: allowRemoval,
  },
  amount: {value: "", inputType: "money"},
  frequency: {
    value: "Every month",
    inputType: "select",
    options: frequencyOptions,
  },
  monthlyAmount: {inputType: "calculated", calculation: displayMonthlyAmount},
});

const otherItem = (item = "", label = "", allowRemoval = false) => ({
  item: {
    value: item,
    inputType: "textWithLabel",
    label: label,
    allowRemoval: allowRemoval,
    title: label,
  },
  amount: {value: "", inputType: "money"},
  frequency: {
    value: "Every month",
    inputType: "select",
    options: frequencyOptions,
  },
  monthlyAmount: {inputType: "calculated", calculation: displayMonthlyAmount},
});

const totalRow = (label = "") => ({
  item: {value: label, inputType: "labelHTML"},
  amount: {value: "", inputType: "label"},
  frequency: {value: "", inputType: "label"},
  monthlyAmount: {
    inputType: "calculated",
    calculation: displayTotalMonthlyAmount,
  },
});

const displayTotalMonthlyAmount = (formikProps, sectionIdentifier) => {
  const subtotal = totalMonthlyAmount(formikProps, sectionIdentifier)
    .toFixed(2)
    .withCommas();
  return (
    <span
      className="subtotal-row"
      tabIndex="0"
      title={`Subtotal ${sectionIdentifier}: ${subtotal}`}
    >
      <b>${subtotal}</b>
    </span>
  );
};

const totalMonthlyAmount = (formikProps, sectionIdentifier) => {
  let total = 0;
  Object.keys(formikProps.values[sectionIdentifier]).forEach((subSection) =>
    formikProps.values[sectionIdentifier][subSection].forEach((row, index) => {
      if (row.amount.value !== "") total += monthlyAmount(row);
    })
  );
  return total;
};

const displayMonthlyAmount = (formikProps, sectionIdentifier, key, index) =>
  `$${monthlyAmount(formikProps.values[sectionIdentifier][key][index])
    .toFixed(2)
    .withCommas()}`;

const monthlyAmount = (item) => {
  const amount = item.amount.value;
  const convertedAmount = Tables.frequencies[item.frequency.value](amount);
  return Math.round(convertedAmount * 100) / 100;
};

const frequencyOptions = [
  "Once per year",
  "Twice per year",
  "Four times per year",
  "Every month",
  "Twice per month",
  "Every two weeks",
  "Every week",
];

const initialValues = {
  month: "January",
  saving: {
    savings: [
      emptyItem("Saving toward: An emergency fund"),
      otherItem("", "Other saving item:"),
    ],
    total: [totalRow(<span className="subtotal-row"><Trans i18nKey="24.totalSaving">Total Saving</Trans></span>)],
  },
  sharing: {
    sharing: [
      emptyItem("Sharing with family and friends"),
      emptyItem("Charitable contributions"),
      otherItem("", "Other sharing item:"),
    ],
    total: [totalRow(<span className="subtotal-row"><Trans i18nKey="24.totalSharing">Total Sharing</Trans></span>)],
  },
  spending: {
    spending: [
      emptyItem("Telephone (cell and land-line)"),
      emptyItem("Internet"),
      emptyItem("Cable, satellite, TV viewing services"),
      emptyItem("Car, truck payment"),
      emptyItem("Car, truck insurance"),
      emptyItem("Car, truck maintenance and repair"),
      emptyItem("Car, truck fuel"),
      emptyItem("Public transportation"),
      emptyItem(
        "Health insurance (part not covered by employer or taken out of gross pay)"
      ),
      emptyItem("Other healthcare expenses"),
      emptyItem("Student loan payments"),
      emptyItem("Credit card debt payments"),
      emptyItem("Other debt payments"),
      emptyItem("Personal care attendant"),
      emptyItem("Eldercare"),
      emptyItem("Childcare, child support payments"),
      emptyItem("Groceries, household supplies"),
      emptyItem("Eating out, take-out"),
      emptyItem("Service animal expenses"),
      emptyItem("Pet care"),
      emptyItem("Personal expenses"),
      emptyItem("Entertainment"),
      otherItem("", "Other spending item:"),
    ],
    total: [totalRow(<span className="subtotal-row"><Trans i18nKey="24.totalSpending">Total Spending</Trans></span>)],
  },
  income: {
    Income: [emptyItem("Net (take-home) pay", "lightIncrementingLabel")],
    SelfEmployment: [
      emptyItem("Net self-employment income", "lightIncrementingLabel"),
    ],
    PublicBenefits: [emptyItem("Public benefit", "lightIncrementingLabel")],
    OtherIncome: [
      emptyItem("Interest"),
      emptyItem("Dividends"),
      emptyItem("Child support"),
      emptyItem("Alimony"),
      emptyItem("Gifts"),
      otherItem("", "Other income item:"),
    ],
    total: [
      totalRow(<span className="subtotal-row"><Trans i18nKey="24.totalNetIncome">My Total Net Income</Trans></span>),
    ],
  },
};

const renderSummaryTables = (formikProps) => {
  const savings = totalMonthlyAmount(formikProps, "saving");
  const sharing = totalMonthlyAmount(formikProps, "sharing");
  const spending = totalMonthlyAmount(formikProps, "spending");
  const total = savings + sharing + spending;
  return (
    <Translation>
      {(t) => (
        <div className="page-break-before-always">
          <h3 tabIndex="0" id="summaryExpenses" className="">
            {t('My Total Non-Housing Expenses')}
          </h3>
          {renderExpensesTable(savings, sharing, spending, total)}
          <h3 tabIndex="0" id="summary" className="">
            {t('What’s Left for Housing Costs?')}
          </h3>
          <a href="#income">
            <b>{t('Jump to My Net Income')}</b>
          </a>{" "}
          <br></br>
          <a href="#expenses">
            <b>{t('Jump to My Non-Housing Expenses')}</b>
          </a>

          {renderSummaryTable(formikProps, total)}
        </div>
      )}
    </Translation>
      
  );
};

const renderSummaryTable = (formikProps, totalExpenses) => {
  let totalIncome = totalMonthlyAmount(formikProps, "income");
  let leftovers = totalIncome - totalExpenses;
  return (
    <Translation>
      {(t) => {
        const headers = [t('Category'), t('Monthly Amount')];
        const title = `${t('Summary table')}: ${headers.join(', ')}`;
        return (
          <div className="grid-col-9 mobile-full-width">
            <table
              className="usa-table summary-table"
              title={title}
            >
              <thead>
                <tr>
                  {headers.map((h, i) => <th key={i}>{h}</th>)}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="col-6">
                    <span tabIndex="0">{t('My Total Net Income')}</span>
                  </td>
                  <td className="col-3">
                    <span tabIndex="0">${totalIncome.toFixed(2).withCommas()}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span tabIndex="0">{t('My Total Non-Housing Expenses')}</span>
                  </td>
                  <td>
                    <span tabIndex="0">${totalExpenses.toFixed(2).withCommas()}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b tabIndex="0">{t('What’s Left for Housing Costs?')}</b>
                    <br></br> {t('(My Total Net Income minus My Total Non-Housing Expenses)')}
                  </td>
                  <td>
                    <span
                      tabIndex="0"
                      className={`${
                        totalIncome - totalExpenses !== 0
                          ? totalIncome - totalExpenses < 0
                            ? "red-text"
                            : "green-text"
                          : ""
                      }`}
                    >
                      <b>${leftovers.toFixed(2).withCommas()}</b>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }}
    </Translation>
  );
};

const renderExpensesTable = (savings, sharing, spending, total) => {
  return (
    <Translation>
      {(t) => {
        const headers = [t('Category'), t('Monthly Amount')];
        const title = `${t('Summary table')}: ${headers.join(', ')}`;
        return (
          <div className="grid-col-9 mobile-full-width">
            <table className="usa-table summary-table" title={title}>
              <thead>
                <tr>
                  {headers.map((h, i) => <th key={i}>{h}</th>)}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="col-6">
                    <span tabIndex="0">{t('My Total Saving')}</span>
                  </td>
                  <td className="col-3">
                    <span tabIndex="0">${savings.toFixed(2).withCommas()}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span tabIndex="0">{t('My Total Sharing')}</span>
                  </td>
                  <td>
                    <span tabIndex="0">${sharing.toFixed(2).withCommas()}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span tabIndex="0">{t('My Total Spending')}</span>
                  </td>
                  <td>
                    <span tabIndex="0">${spending.toFixed(2).withCommas()}</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <b tabIndex="0">{t('My Total Non-Housing Expenses')}</b>
                  </td>
                  <td>
                    <b tabIndex="0">${total.toFixed(2).withCommas()}</b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        );
      }}
    </Translation>
  );
};

const EstimateAffordableHousingPayment = ({tool, canEdit}) => {
  const {i18n} = useTranslation();
  return (
    <Translation>
      {(t) => (
        <div>
          <Formik initialValues={initialValues}>
            {(formikProps) => (
              <Form>
                <EditableContent
                  htmlType="div"
                  initialContent={
                    i18n.language === "es"
                      ? tool.contentsEs || tool.contents
                      : tool.contents
                  }
                  fieldName={i18n.language === "es" ? "contents_es" : "contents"}
                  updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
                  canEdit={canEdit}
                  richText
                />
                <h2 id="income" className="page-break-before-always">{t('My Net Income')}</h2>
                <a href="#expenses">
                  <b>{t('Jump to My Non-Housing Expenses')}</b>
                </a>{" "}
                <br></br>
                <a href="#summary">
                  <b>{t('Jump to What’s Left for Housing Costs?')}</b>
                </a>

                {Tables.renderSimpleTable(
                  [
                    t('Item'),
                    t('Income Amount'),
                    t('How often do you receive this income?'),
                    t('Monthly Amount'),
                  ],
                  initialValues.income,
                  formikProps,
                  "income",
                  true,
                  (value, label, increment) =>
                    increment
                      ? emptyItem(label, "lightIncrementingLabel", increment, true)
                      : otherItem(value, label, true),
                  undefined,
                  false,
                  true
                )}
                <br />
                <h2 id="expenses" className="page-break-before-always">
                  {t('My Non-Housing Expenses')}
                </h2>
                <a href="#income">
                  <b>{t('Jump to My Net Income')}</b>
                </a>{" "}
                <br></br>
                <a href="#summary">
                  <b>{t('Jump to What’s Left for Housing Costs?')}</b>
                </a>

                <h3>{t('My Saving')}</h3>
                {Tables.renderSimpleTable(
                  [
                    t('Item'),
                    t('Saving Amount'),
                    t('How often do you save this amount?'),
                    t('Monthly Amount'),
                  ],
                  initialValues.saving,
                  formikProps,
                  "saving",
                  true,
                  (value, label, increment) => otherItem(value, label, true),
                  undefined,
                  false,
                  true
                )}
                <h3>{t('My Sharing')}</h3>
                {Tables.renderSimpleTable(
                  [
                    t('Item'),
                    t('Sharing Amount'),
                    t('How often do you share this amount?'),
                    t('Monthly Amount'),
                  ],
                  initialValues.sharing,
                  formikProps,
                  "sharing",
                  true,
                  (value, label, increment) => otherItem(value, label, true),
                  undefined,
                  false,
                  true
                )}
                <h3>{t('My Spending')}</h3>
                {Tables.renderSimpleTable(
                  [
                    t('Item'),
                    t('Spending Amount'),
                    t('How often do you pay this expense?'),
                    t('Monthly Amount'),
                  ],
                  initialValues.spending,
                  formikProps,
                  "spending",
                  true,
                  (value, label, increment) => otherItem(value, label, true),
                  undefined,
                  false,
                  true
                )}
                {renderSummaryTables(formikProps)}
                <ExpandableTextarea
                  name="afford"
                  question={
                    <b>{t('How much money can I afford for housing costs each month?')}</b>
                  }
                  title={t('How much money can I afford for housing costs each month?')}
                />
                <ExpandableTextarea
                  name="reductions"
                  question={
                    <b>
                      {t('Can I reduce any expenses or increase any income so I have more money available that can go to housing costs? How?')}
                    </b>
                  }
                  title={t('Can I reduce any expenses or increase any income so I have more money available that can go to housing costs? How?')}
                />
              </Form>
            )}
          </Formik>
        </div>
      )}
    </Translation>
  );
};

export default EstimateAffordableHousingPayment;
