import React, {PureComponent} from 'react';
import {PieChart, Pie, Cell, Legend} from 'recharts';

import {reorderDomForRechartAccessibility} from '../../util';

const COLORS = ['#a8226b', '#124C8F'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({cx, cy, midAngle, innerRadius, outerRadius, percent, index}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill='white' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline='central'>
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const cleanData = (gameCounts) => {
  if (gameCounts.complete === 0) return [{name: 'In progress', value: gameCounts.inProgress}];
  return [
    {name: 'In progress', value: gameCounts.inProgress},
    {name: 'Earned certificate', value: gameCounts.complete},
  ];
};

const displayTitle = (gameCounts) => {
  const total = gameCounts.inProgress + gameCounts.complete;
  const inProgressPercent = Math.round((gameCounts.inProgress / total) * 100);
  const completePercent = Math.round((gameCounts.complete / total) * 100);
  return `Session pie chart; In Progress: ${inProgressPercent}%, Complete: ${completePercent}%`;
};

const coloredLegendText = (value, entry) => {
  const {payload} = entry;
  const percent = (payload.percent * 100).toFixed(0);
  return (
    <span>
      {value}: {payload.value} ({percent}%)
    </span>
  );
};

export default class SessionsChart extends PureComponent {
  componentDidMount() {
    reorderDomForRechartAccessibility();
  }

  componentDidUpdate() {
    reorderDomForRechartAccessibility();
  }

  render() {
    const data = cleanData(this.props.gameCounts);
    return (
      <div className='session-chart' tabIndex='0' title={displayTitle(this.props.gameCounts)}>
        <PieChart width={400} height={400} className='centered'>
          <Legend verticalAlign='top' height={36} formatter={coloredLegendText} />
          <Pie
            data={data}
            cx={180}
            cy={180}
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={140}
            fill='#8884d8'
            dataKey='value'
            legendType={'square'}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </div>
    );
  }
}

export {cleanData};
