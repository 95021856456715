import React from "react";
import {Formik, Form} from "formik";
import Tables from "../utilities/Tables";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation, Translation} from "react-i18next";

const tableHeaders = [
  'Who You Owe Money To',
  'Total Amount You Owe (current balance)',
  'Payment Amount*',
  'Payment Due Date',
  'Interest Rate',
  'Other Important Terms',
];

const emptyItem = (allowRemoval = false) => ({
  item: {
    value: "",
    inputType: "textarea",
    columnWidth: 3,
    allowRemoval: allowRemoval,
    title: 'Item',
    columnName: tableHeaders[0],
  },
  currentBalance: {value: "", inputType: "money", columnWidth: 2, title: 'Amount', columnName: tableHeaders[1]},
  paymentAmount: {value: "", inputType: "money", columnWidth: 2, title: 'Amount', columnName: tableHeaders[2]},
  dueDate: {value: "", inputType: "date", columnWidth: 1, title: 'Date', columnName: tableHeaders[3]},
  interestRate: {value: "", inputType: "percentage", columnWidth: 2, title: 'Rate', columnName: tableHeaders[4]},
  otherInfo: {value: "", inputType: "textarea", columnWidth: 4, title: 'Item', columnName: tableHeaders[5]},
});

const initialValues = {
  debtSection: {
    "My Debt Table": [emptyItem()],
  },
};

const renderQuestions = () => {
  return (
    <Translation>
      {(t) => (
        <div className="page-break-inside-avoid">
          <div className="grid-row">
            <label htmlFor="check-questions" className="margin-bottom-2">
              <strong className="font-size-15">{t('How did you get most of your debt?')}
              </strong>
            </label>
          </div>
          <div className="grid-row">
            <div className="padding-left-2">
              <input
                type="checkbox"
                name="debtSource"
                value={t('Purchased assets. ')}
                className="margin-right-2 margin-bottom-105"
                title={t('Purchased assets. ')}
              />
            </div>
            <div className="grid-col-11 margin-left-neg-1">
              <span className="font-size-14">
                <strong className="font-size-14">{t('Purchased assets. ')}</strong>{t('For example, taking out a loan to buy a car or home.')}
              </span>
            </div>
          </div>
          <div className="grid-row">
            <div className=" padding-left-2">
              <input
                type="checkbox"
                name="debtSource"
                value={t('Paid bills. ')}
                className="margin-right-2 margin-bottom-105"
                title={t('Paid bills. ')}
              />
            </div>
            <div className="grid-col-11 margin-left-neg-1">
              <span className="font-size-14">
                <strong className="font-size-14">{t('Paid bills. ')}</strong>{t('For example, using a credit card to pay a utility bill.')}
              </span>
            </div>
          </div>
          <div className="grid-row">
            <div className=" padding-left-2">
              <input
                type="checkbox"
                name="debtSource"
                value={t('Did not pay bills. ')}
                className="margin-right-2 margin-bottom-105"
                title={t('Did not pay bills. ')}
              />
            </div>
            <div className="grid-col-11 margin-left-neg-1">
              <span className="font-size-14">
                <strong className="font-size-14">{t('Did not pay bills. ')}</strong>{t('For example, not paying bills from a hospital visit, resulting in medical debt.')}
              </span>
            </div>
          </div>
          <div className="grid-row">
            <div className="padding-left-2">
              <input
                type="checkbox"
                name="debtSource"
                value={t('Covered unexpected expenses or emergencies. ')}
                className="margin-right-2 margin-bottom-105"
                title={t('Covered unexpected expenses or emergencies. ')}
              />
            </div>
            <div className="grid-col-11 margin-left-neg-1">
              <span className="font-size-14">
                <strong className="font-size-14">{t('Covered unexpected expenses or emergencies. ')}</strong>{t('For example, borrowing money to pay for a car repair.')}
              </span>
            </div>
          </div>
          <div className="grid-row">
            <div className=" padding-left-2">
              <input
                type="checkbox"
                name="debtSource"
                value={t('Bought things I wanted. ')}
                className="margin-right-2 margin-bottom-105"
                title={t('Bought things I wanted. ')}
              />
            </div>
            <div className="grid-col-11 margin-left-neg-1">
              <span className="font-size-14">
                <strong className="font-size-14">{t('Bought things I wanted. ')}</strong>{t('Think about how you could avoid going into debt for a future item you may want to buy.')}
              </span>
            </div>
          </div>
          <div className="grid-row">
            <div className=" padding-left-2">
              <input
                type="checkbox"
                name="debtSource"
                value={t('Bought things I needed. ')}
                className="margin-right-2 margin-bottom-105"
                title={t('Bought things I needed. ')}
              />
            </div>
            <div className="grid-col-11 margin-left-neg-1">
              <span className="font-size-14">
                <strong> {t('Bought things I needed. ')}</strong>{t('Think about what you bought and whether you could have bought it or something similar that still met your needs without going into debt.')}
              </span>
            </div>
          </div>
        </div>
      )}
    </Translation>
  );
};

const UnderstandingMyDebt = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  return (
    <div>
      <Formik initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <EditableContent
              htmlType="div"
              initialContent={
                i18n.language === "es"
                  ? tool.contentsEs || tool.contents
                  : tool.contents
              }
              fieldName={i18n.language === "es" ? "contents_es" : "contents"}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />

            {Tables.renderSimpleTable(
              tableHeaders.map(th => t(th)),
              initialValues.debtSection,
              formikProps,
              "debtSection",
              true,
              () => emptyItem(true),
              undefined,
              false,
              true,
            )}
            <p>
              {t('*Note: For revolving debt, like credit cards, enter the minimum payment due.')}
            </p>
            {renderQuestions()}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UnderstandingMyDebt;
