import React from 'react';
import {Switch, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import Login from './containers/login/Login';
import Games from './containers/games/Games';
import UnauthenticatedRoute from './components/Routes/UnauthenticatedRoute/UnauthenticatedRoute';
import AuthenticatedRoute from './components/Routes/AuthenticatedRoute/AuthenticatedRoute';
import Game from './containers/game/Game';
import Glossary from './containers/glossary/Glossary';
import Resources from './containers/resources/Resources';
import Tools from './containers/tools/Tools';
import Account from './containers/account/Account';
import Register from './containers/register/Register';
import OrganizationApplication from './containers/register/OrganizationApplication';
import PasswordReset from './containers/passwordReset/PasswordReset';
import Certificate from './containers/account/Certificate';
import OrganizationReport from './containers/account/organization/OrganizationReport';
import OrganizationList from './containers/account/admin/organizationList/OrganizationList';
import OrganizationDetails from './containers/account/admin/OrganizationDetails';
import ChangePassword from './containers/changePassword/ChangePassword';
import ChangeOrganization from './containers/changeOrganization/ChangeOrganization';
import ToolStatistics from './containers/account/admin/ToolStatistics';
import HelpCenter from './containers/helpCenter/HelpCenter';
import EditorHelpCenter from './containers/helpCenter/EditorHelpCenter';
import HelpTopic from './containers/helpCenter/HelpTopic';
import EditHelpTopic from './containers/helpCenter/EditHelpTopic';
import SearchHelpTopics from './containers/helpCenter/SearchHelpTopics';
import CallCenter from './containers/helpCenter/CallCenter';
import HelpTopicCategory from './containers/helpCenter/HelpTopicCategory';
import SurveyDashboard from './containers/account/admin/survey/SurveyDashboard';
import HelpTopicCategories from './containers/helpCenter/helpTopicCategories/HelpTopicCategories';
import Emails from './containers/account/admin/emails/Emails';
import AuditReport from './containers/account/admin/audit/AuditReport';
import AuditLog from './containers/account/admin/audit/AuditLog';
import EditOrganizationAccount from './containers/account/organization/EditOrganizationAccount';
import ForceCertficate from './containers/account/admin/ForceCertificate';
import PlayerAdmin from './containers/account/admin/PlayerAdmin';
import GameUsageReport from './containers/account/organization/GameUsageReport';
import ViewGame from './containers/account/organization/ViewGame';
import EditAccount from './containers/editAccount/EditAccount';

const Routes = ({childProps}) =>
  <Switch>
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <UnauthenticatedRoute path="/register" exact component={Register} props={childProps} />
    <UnauthenticatedRoute path="/organization-apply" exact component={OrganizationApplication} props={childProps} />
    <UnauthenticatedRoute path="/password-reset" exact component={PasswordReset} props={childProps} />
    <UnauthenticatedRoute path="/games" exact component={Games} props={childProps} />
    <UnauthenticatedRoute path="/game/:id" exact component={Game} props={childProps} />
    <UnauthenticatedRoute path="/glossary/" exact component={Glossary} props={childProps} />
    <UnauthenticatedRoute path="/resources/" exact component={Resources} props={childProps} />

    <UnauthenticatedRoute path="/tools/:toolId" exact component={Tools} props={childProps} />

    <UnauthenticatedRoute path="/help-center" exact component={HelpCenter} props={childProps} />
    <UnauthenticatedRoute path="/help-center/search" exact component={SearchHelpTopics} props={childProps} />
    <AuthenticatedRoute path="/help-center/help-topic-categories" exact component={HelpTopicCategories} props={childProps} />
    <UnauthenticatedRoute path="/help-center/help-topic-category/:helpTopicCategoryId" exact component={HelpTopicCategory} props={childProps} />
    <AuthenticatedRoute path="/help-center/edit" exact component={EditorHelpCenter} props={childProps} />
    <AuthenticatedRoute path="/help-center/call-center" exact component={CallCenter} props={childProps} />
    <UnauthenticatedRoute path="/help-center/:helpTopicId" exact component={HelpTopic} props={childProps} />
    <AuthenticatedRoute path="/help-center/:helpTopicId/edit" exact component={EditHelpTopic} props={childProps} />

    <AuthenticatedRoute path="/account" exact component={Account} props={childProps} />
    <AuthenticatedRoute path="/account/edit" exact component={EditOrganizationAccount} props={childProps} />
    <AuthenticatedRoute path="/account/update" exact component={EditAccount} props={childProps} />
    <AuthenticatedRoute path="/account/change-password" exact component={ChangePassword} props={childProps} />
    <AuthenticatedRoute path="/account/change-organization" exact component={ChangeOrganization} props={childProps} />
    <AuthenticatedRoute path="/account/reports" exact component={OrganizationReport} props={childProps} />
    <AuthenticatedRoute path="/account/organizations" exact component={OrganizationList} props={childProps} />
    <AuthenticatedRoute path="/account/organizations/:id" exact component={OrganizationDetails} props={childProps} />
    <AuthenticatedRoute path="/account/organizations/:id/edit" exact component={EditOrganizationAccount} props={childProps} />
    <AuthenticatedRoute path="/account/tool-statistics" exact component={ToolStatistics} props={childProps} />
    <AuthenticatedRoute path="/certificate/:gameId/:userId" exact component={Certificate} props={childProps} />
    <AuthenticatedRoute path="/certificate" exact component={Certificate} props={childProps}/>
    <AuthenticatedRoute path="/survey" onlyAdmin exact component={SurveyDashboard} props={childProps}/>
    <AuthenticatedRoute path="/emails" onlyAdmin exact component={Emails} props={childProps}/>
    <AuthenticatedRoute path="/audit-logs" onlyAdmin exact component={AuditReport} props={childProps} />
    <AuthenticatedRoute path="/audit-logs/:logId" onlyAdmin exact component={AuditLog} props={childProps} />

    <AuthenticatedRoute path="/account/v2/game-usage-report" exact component={GameUsageReport} props={childProps} />
    <AuthenticatedRoute path="/account/v2/game-usage-report/:gameId" exact component={ViewGame} props={childProps} />

    <AuthenticatedRoute path="/editor/force-certificate" onlyAdmin exact component={ForceCertficate} props={childProps} /> 
    <AuthenticatedRoute path="/editor/player-admin" onlyAdmin exact component={PlayerAdmin} props={childProps} /> 

    <Redirect to='/games'/>
  </Switch>;

Routes.propTypes = {
  childProps: PropTypes.object
};
  
export default Routes; 