import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';
import queryString from 'query-string';
import {Link, useHistory} from 'react-router-dom';

import {Loader} from '../../components/Loader';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import config from '../../config';
import LanguageSelector from '../../components/Buttons/LanguageSelector';

const toggleActive = (helpCenterStore) => {
  const {helpTopic} = helpCenterStore;
  let updatedHelpTopic = helpTopic;
  updatedHelpTopic.active = !helpTopic.active;
  helpCenterStore.updateHelpTopic(updatedHelpTopic);
};

const renderActivate = (helpCenterStore) => {
  return helpCenterStore.helpTopic.active ? (
    <div className='margin-bottom-105 margin-top-2'>
      <button className='fdic-button tan-button' onClick={() => toggleActive(helpCenterStore)}>
        Deactivate
      </button>
    </div>
  ) : (
    <div className='margin-bottom-105 margin-top-2'>
      <button className='fdic-button' onClick={() => toggleActive(helpCenterStore)}>
        Activate
      </button>
    </div>
  );
};

const renderNameAndDetails = (helpTopic, isSpanish, props) => {
  const showLanguageSelector = helpTopic.account_types.indexOf('individual') !== -1;
  const isSpanishAndHasName = isSpanish && helpTopic.name_es;
  const isSpanishAndHasDetails = isSpanish && helpTopic.details_es;
  return (
    <>
      <h1>{isSpanishAndHasName ? helpTopic.name_es : helpTopic.name}</h1>
      {showLanguageSelector && <LanguageSelector {...props} />}
      <div dangerouslySetInnerHTML={{__html: isSpanishAndHasDetails ? helpTopic.details_es : helpTopic.details}} />
    </>
  );
};

export const RenderHelpTopicBreadcrumb = ({helpTopic, isEdit}) => {
  const {t, i18n} = useTranslation();
  const isSpanish = i18n.language === 'es';
  const name = isSpanish && helpTopic.name_es ? helpTopic.name_es : helpTopic.name;
  const links = [
    {
      link: '/help-center',
      display: 'Help Center',
    },
    {
      link: `/help-center/help-topic-category/${helpTopic.help_topic_category_id}`,
      display: t(helpTopic.help_topic_category_name),
    },
    {
      link: isEdit ? `/help-center/${helpTopic.id}` : '#',
      display: name,
    },
  ];
  if (isEdit) {
    links.push({
      link: '#',
      display: 'Edit',
    });
  }
  return <BreadCrumb historyLinks={links} />;
};

const HelpTopic = observer((props) => {
  const history = useHistory();
  const {t, i18n} = useTranslation();
  const {helpCenterStore, authStore, match, location} = props;
  helpCenterStore.getHelpTopic(match.params.helpTopicId);
  !helpCenterStore.helpTopicLoading && config.setUniqueTitle(helpCenterStore.helpTopic.name);

  if (helpCenterStore.helpTopicLoading) return <Loader />;

  const params = queryString.parse(location.search);
  const isSpanish = i18n.language === 'es';
  const viewInSpanish = params.lang && params.lang === 'es' ? true : false;
  const langParams = viewInSpanish || isSpanish ? '?lang=es' : '';

  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className='help-center-content'>
      <RenderHelpTopicBreadcrumb helpTopic={helpCenterStore.helpTopic} isEdit={false} />
      {renderNameAndDetails(helpCenterStore.helpTopic, isSpanish || viewInSpanish, props)}
      <div>
        {authStore.isEditor && renderActivate(helpCenterStore)}
        {authStore.isEditor && (
          <>
            <Link className='hide-print' to={`/help-center/${helpCenterStore.helpTopic.id}/edit${langParams}`}>
              Edit
            </Link>
            <span> | </span>
            <Link className='hide-print' to='/help-center/edit'>
              Back
            </Link>
          </>
        )}
        {!authStore.isEditor && (
          <button type='button' className='hide-print usa-button usa-button--unstyled' onClick={handleBack}>
            {t('Back')}
          </button>
        )}
      </div>
    </div>
  );
});

export default HelpTopic;
