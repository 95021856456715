import React from "react";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation, Trans} from "react-i18next";

const ReviewLease = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  const isSpanish = i18n.language === 'es';
  const lawHelpUrl = `https://${isSpanish ? 'espanol' : 'www'}.lawhelp.org/`;
  return (
    <div className="grid-col-12">
      <EditableContent
        htmlType="div"
        initialContent={
          isSpanish
            ? tool.contentsEs || tool.contents
            : tool.contents
        }
        fieldName={isSpanish ? "contents_es" : "contents"}
        updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
        canEdit={canEdit}
        richText
      />
      <div className="grid-row margin-bottom-4">
        <div className="grid-col-1">
          <div className="grid-row">
            <div className="grid-col-4"></div>
            <div className="grid-col-8">
              <input
                type="checkbox"
                title={t('Review names of the parties to the agreement')}
              ></input>
            </div>
          </div>
        </div>
        <div className="grid-col-11">
          <h4 className="no-margin">{t('Names of the parties to the agreement')}</h4>
          <p>
            {t('This will be the property owner or landlord, you, and anyone else who is responsible for the rental, such as roommates. If roommates are not listed, they will not be held responsible for the rental under your lease. They may have their own lease agreements with the landlord.')}</p>
        </div>
      </div>
      <div className="grid-row margin-bottom-4 page-break-inside-avoid">
        <div className="grid-col-1">
          <div className="grid-row">
            <div className="grid-col-4"></div>
            <div className="grid-col-8">
              <input
                type="checkbox"
                title={t('Review location and description of the rental')}
              ></input>
            </div>
          </div>
        </div>
        <div className="grid-col-11">
          <h4 className="no-margin">{t('Location and description of the rental')}</h4>
          <p>{t('This is the address of the rental. The description should also name features included such as: parking, access to a garage, use of a fitness facility, use of storage units, and so on.')}</p>
        </div>
      </div>
      <div className="grid-row margin-bottom-4 page-break-inside-avoid">
        <div className="grid-col-1">
          <div className="grid-row">
            <div className="grid-col-4"></div>
            <div className="grid-col-8">
              <input
                type="checkbox"
                title={t('Review rent amount and due date')}
              ></input>
            </div>
          </div>
        </div>
        <div className="grid-col-11">
          <h4 className="no-margin">{t('Rent amount and due date')}</h4>
          <p>{t('Look for penalties for late or missed payments. Look for when a payment is considered late. For example, rent payments may be due on the 1st of the month and considered late if they are not paid by the 5th of the month.')}
          </p>
        </div>
      </div>
      <div className="grid-row margin-bottom-4 page-break-inside-avoid">
        <div className="grid-col-1">
          <div className="grid-row">
            <div className="grid-col-4"></div>
            <div className="grid-col-8">
              <input
                type="checkbox"
                title={t('Review included/not included in the rent')}
              ></input>
            </div>
          </div>
        </div>
        <div className="grid-col-11">
          <h4 className="no-margin">{t('Included/not included in the rent')}</h4>
          <p>{t('Look for what is included as well as what is not included in the rent. For example, the rent may include electricity and water but not gas or internet. Or perhaps garbage pickup fees are required but extra.')}
          </p>
        </div>
      </div>
      <div className="grid-row margin-bottom-4 page-break-inside-avoid">
        <div className="grid-col-1">
          <div className="grid-row">
            <div className="grid-col-4"></div>
            <div className="grid-col-8">
              <input type="checkbox" title={t('Review term of the lease')}></input>
            </div>
          </div>
        </div>
        <div className="grid-col-11">
          <h4 className="no-margin">{t('Term of the lease')}</h4>
          <p>{t('This is the length of the lease. It could be six months, one year, or another length of time. If no term is specified, it’s a month-to-month agreement. Penalties for moving out before the end of the term should also be explained.')}
          </p>
        </div>
      </div>
      <div className="grid-row margin-bottom-4 page-break-inside-avoid">
        <div className="grid-col-1">
          <div className="grid-row">
            <div className="grid-col-4"></div>
            <div className="grid-col-8">
              <input type="checkbox" title={t('Review options to renew')}></input>
            </div>
          </div>
        </div>
        <div className="grid-col-11">
          <h4 className="no-margin">{t('Options to renew')}</h4>
          <p>{t('This should spell out your options when the lease term ends.')}</p>
          <p>{t('You may need to give the landlord notice that you will not be renewing your lease by a certain time before the end of the lease.')}
          </p>
          <p>{t('If you do not provide notice within that time, your lease may automatically renew. Or your lease may automatically change to a month-to-month agreement at the end of your current lease term. Or you may need to give notice that you intend to renew your lease by a certain time before the current lease ends.')}
          </p>
        </div>
      </div>
      <div className="grid-row margin-bottom-4 page-break-inside-avoid">
        <div className="grid-col-1">
          <div className="grid-row">
            <div className="grid-col-4"></div>
            <div className="grid-col-8">
              <input type="checkbox" title={t('Review increases to rent')}></input>
            </div>
          </div>
        </div>
        <div className="grid-col-11">
          <h4 className="no-margin">{t('Increases to rent')}</h4>
          <p>{t('If you have a month-to-month agreement, the landlord can raise the rent with written notice.')}
          </p>
          <p>{t('If you have a lease term, the landlord can’t raise the rent until the lease term expires unless the rent increase is already built into the lease.')}
          </p>
          <p>{t('State or local laws may govern the rent increases, particularly how far in advance the landlord has to tell you about the increase before it takes effect.')}
          </p>
        </div>
      </div>
      <div className="grid-row margin-bottom-4 page-break-inside-avoid">
        <div className="grid-col-1">
          <div className="grid-row">
            <div className="grid-col-4"></div>
            <div className="grid-col-8">
              <input
                type="checkbox"
                title={t('Review maintenance responsibilities')}
              ></input>
            </div>
          </div>
        </div>
        <div className="grid-col-11">
          <h4 className="no-margin">{t('Maintenance responsibilities')}</h4>
          <p>{t('This should explain what the landlord will and will not fix.')}</p>
          <p>{t('State and local laws outline what landlords must do to keep the space you are renting safe and livable.')}</p>
          <p>{t('If you feel the information in your lease regarding maintenance and repairs is unclear or unfair, visit your city’s/county’s housing department or public housing authority, a Department of Housing and Urban Development (HUD) approved housing counselor, a legal aid attorney, or a pro bono attorney (an attorney who agrees to handle a legal matter without charging the client). They may be able to review your lease with you and explain what your rights are under state law.')}
          </p>
        </div>
      </div>
      <div className="grid-row margin-bottom-4 page-break-inside-avoid">
        <div className="grid-col-1">
          <div className="grid-row">
            <div className="grid-col-4"></div>
            <div className="grid-col-8">
              <input
                type="checkbox"
                title={t('Review limits to modifications')}
              ></input>
            </div>
          </div>
        </div>
        <div className="grid-col-11">
          <h4 className="no-margin">{t('Limits to modifications')}</h4>
          <p>{t('This will describe whether you can paint the apartment or add built-in shelves, for example. The agreement should state who pays for these modifications or how to get permission to make any modifications. It may also include what you cannot do, such as pound nails into the walls.')}
          </p>
        </div>
      </div>
      <div className="grid-row margin-bottom-4 page-break-inside-avoid">
        <div className="grid-col-1">
          <div className="grid-row">
            <div className="grid-col-4"></div>
            <div className="grid-col-8">
              <input
                type="checkbox"
                title={t('Review reasonable modifications')}
              ></input>
            </div>
          </div>
        </div>
        <div className="grid-col-11">
          <h4 className="no-margin">{t('Reasonable modifications')}</h4>
          <p>{t('This will note that federal law permits a resident with a disability to make reasonable modifications at his or her expense to the interior of an apartment and to common/public areas of the building. A reasonable modification is a structural modification that is made to allow persons with disabilities the full enjoyment of the housing and related facilities. Examples include widening a doorway, lowering kitchen cabinets, or replacing flooring to facilitate wheelchair use.')}
          </p>
        </div>
      </div>
      <div className="grid-row margin-bottom-4 page-break-inside-avoid">
        <div className="grid-col-1">
          <div className="grid-row">
            <div className="grid-col-4"></div>
            <div className="grid-col-8">
              <input
                type="checkbox"
                title={t('Review reasonable accomodations')}
              ></input>
            </div>
          </div>
        </div>
        <div className="grid-col-11">
          <h4 className="no-margin">{t('Reasonable accommodations')}</h4>
          <p>{t('This will also note that federal law requires housing providers to make reasonable accommodations for persons with disabilities. A reasonable accommodation is a change in rules, policies, practices, or services so that a person with a disability will have an equal opportunity to use and enjoy a dwelling unit or common space. An example is providing a reserved parking space near a building’s entrance for a tenant with a mobility impairment, even though all parking is unreserved.')}
          </p>
        </div>
      </div>
      <div className="grid-row margin-bottom-4 page-break-inside-avoid">
        <div className="grid-col-1">
          <div className="grid-row">
            <div className="grid-col-4"></div>
            <div className="grid-col-8">
              <input
                type="checkbox"
                title={t('Review when the landlord can enter the property')}
              ></input>
            </div>
          </div>
        </div>
        <div className="grid-col-11">
          <h4 className="no-margin">{t('When the landlord can enter the property')}
          </h4>
          <p>{t('Since the landlord owns the property, they have the right to enter it. But you also have the right to privacy. Make sure this is clearly spelled out in your lease.')}
          </p>
        </div>
      </div>
      <div className="grid-row margin-bottom-4 page-break-inside-avoid">
        <div className="grid-col-1">
          <div className="grid-row">
            <div className="grid-col-4"></div>
            <div className="grid-col-8">
              <input
                type="checkbox"
                title={t('Review notice of termination')}
              ></input>
            </div>
          </div>
        </div>
        <div className="grid-col-11">
          <h4 className="no-margin">{t('Notice of termination')}</h4>
          <p>{t('What is the process if either you or the landlord needs to terminate the lease? This should be spelled out in the lease.')}
          </p>
          <p><Trans i18nKey="25.termination">There are also special rules for terminating the lease if you enter active duty service in the military. This may or may not be included in the lease, but this right exists due to the <a href="https://www.justice.gov/servicemembers/servicemembers-civil-relief-act-scra"> Servicemembers Civil Relief Act </a>(justice.gov).</Trans></p>
        </div>
      </div>
      <div className="grid-row margin-bottom-4 page-break-inside-avoid">
        <div className="grid-col-1">
          <div className="grid-row">
            <div className="grid-col-4"></div>
            <div className="grid-col-8">
              <input type="checkbox" title={t('Review insurance')}></input>
            </div>
          </div>
        </div>
        <div className="grid-col-11">
          <h4 className="no-margin">{t('Insurance')}</h4>
          <p>{t('Renter’s insurance helps you financially recover from losses to certain personal possessions due to a hazard or disaster specifically listed in your insurance policy. The hazards and disasters that the insurance will cover are known as “named perils.” This could include, for example, burglary, fire or smoke, vandalism, theft, explosion, windstorm or hail, lightning, and water damage (not including floods).')}</p>
          <p>{t('Some landlords will require that their tenants purchase renter’s insurance even if they do not want to purchase it. Some landlords may also require you to prove you purchased renter’s insurance before you can move in. The landlord may suggest a particular insurance company, but you generally are free to purchase the insurance from any company. Renter’s insurance can be valuable even if you’re not required to have it.')}
          </p>
        </div>
      </div>
      <div className="grid-row margin-bottom-4 page-break-inside-avoid">
        <div className="grid-col-1">
          <div className="grid-row">
            <div className="grid-col-4"></div>
            <div className="grid-col-8">
              <input
                type="checkbox"
                title={t('Review rule concerning guests')}
              ></input>
            </div>
          </div>
        </div>
        <div className="grid-col-11">
          <h4 className="no-margin">{t('Rule concerning guests')}</h4>
          <p>{t('Some leases will include rules regarding guests. This is to make sure that guests are really guests and not roommates.')}</p>
        </div>
      </div>
      <div className="grid-row margin-bottom-4 page-break-inside-avoid">
        <div className="grid-col-1">
          <div className="grid-row">
            <div className="grid-col-4"></div>
            <div className="grid-col-8">
              <input type="checkbox" title={t('Review rule regarding pets')}></input>
            </div>
          </div>
        </div>
        <div className="grid-col-11">
          <h4 className="no-margin">{t('Rule regarding pets')}</h4>
          <p>{t('This section will describe what kinds of and how many pets you can have. It will also describe any additional fees you may have to pay if pets are allowed and you have one.')}</p>
          <p><Trans i18nKey="25.pets1">Rules regarding service animals may be different depending on where you live. If there is a rule against pets and you have a service animal, ask for a reasonable accommodation. If the landlord still does not permit the animal, consider contacting a state or local agency responsible for handling fair housing complaints, or you can file a complaint with the <a href="https://www.hud.gov/">Department of Housing and Urban Development (HUD)</a> (hud.gov).</Trans></p>
          <p><Trans i18nKey="25.pets2">You may also want to get assistance from legal aid or an attorney. Find a legal aid office by visiting <a href="https://www.lsc.gov/">Legal Services Corporation (LSC)</a>(lsc.gov) or a pro bono attorney by visiting <a href={lawHelpUrl}>LawHelp.org</a>.
          </Trans></p>
        </div>
      </div>
      <div className="grid-row margin-bottom-4 page-break-inside-avoid">
        <div className="grid-col-1">
          <div className="grid-row">
            <div className="grid-col-4"></div>
            <div className="grid-col-8">
              <input type="checkbox" title={t('Review subleasing clause')}></input>
            </div>
          </div>
        </div>
        <div className="grid-col-11">
          <h4 className="no-margin">{t('Subleasing clause')}</h4>
          <p>{t('This section will describe if you can rent to someone else. A person may want to sublet her apartment if she is going to be away for a period of time or she has an extra room. Some leases prohibit subleasing. Some will require a new agreement with the person you want to sublet the rental to. Be sure you read your lease to see if this practice is allowed.')}
          </p>
        </div>
      </div>
      <p>{t('If you don’t understand your lease, get help. Check with a trusted friend, a tenant resource center in your community, your city’s/county’s housing department or public housing authority, a legal aid attorney, a pro bono attorney, or a counselor at a housing counseling agency approved by HUD.')}
      </p>
    </div>
  );
};

export default ReviewLease;
