import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

const getHelpTopicName = (helpTopic, isSpanish) => {
  return isSpanish && helpTopic.name_es ? helpTopic.name_es : helpTopic.name;
};

const getHelpTopicDetails = (helpTopic, isSpanish) => {
  return isSpanish && helpTopic.details_es ? helpTopic.details_es : helpTopic.details;
};

const HelpTopicsList = ({helpTopics, isSpanishExternal}) => {
  const {t, i18n} = useTranslation();
  const isSpanish = i18n.language === 'es' || isSpanishExternal;
  return helpTopics.map((helpTopic) => {
    const name = getHelpTopicName(helpTopic, isSpanish);
    const details = getHelpTopicDetails(helpTopic, isSpanish);
    return (
      <div key={helpTopic.id} className='help-topic-in-list margin-bottom-5'>
        <a href={`/help-center/${helpTopic.id}`} title={`${t('View help topic')}: ${name}`}>
          <h3 className='margin-bottom-0'>{name}</h3>
        </a>
        <div className='help-topic-details' dangerouslySetInnerHTML={{__html: details}} />
      </div>
    );
  });
};

export default observer(HelpTopicsList);
