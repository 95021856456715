import React from "react";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation, Trans} from "react-i18next";

const HowToRequestBankingHistory = ({strategy, canEdit}) => {
  const {t, i18n} = useTranslation();
  const title1 = `${t('Table')}: ${t('126.step3.getChexNoMarkup')}`;
  const title2 = `${t('Table')}: ${t('126.step3.getEarlyNoMarkup')}`;
  return (
    <div className="strategy">
      <EditableContent
        htmlType="div"
        initialContent={
          i18n.language === "es"
            ? strategy.contentsEs || strategy.contents
            : strategy.contents
        }
        fieldName={i18n.language === "es" ? "contents_es" : "contents"}
        updateEndpoint={`${config.apiGateway.URL}/topic-items/${strategy.id}`}
        canEdit={canEdit}
        richText
      />
      <table
        className="usa-table no-border"
        title={title1}
      >
        <thead>
          <tr>
            <th colSpan="2" scope="col">
              <p>
                <strong><Trans i18nKey="126.step3.getChex">
                  Get Your ChexSystems<sup>&reg;</sup> Report
                </Trans>
                </strong>
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colSpan="2" scope="row">
              <p><Trans i18nKey="chexAccess">
                You can access your ChexSystems<sup>&reg;</sup> Report one of
                three ways:
              </Trans>
              </p>
            </th>
          </tr>
          <tr>
            <th className="vertical-align-top" width="90px" scope="row">
              <p><Trans i18nKey="126.step3.online">Online:</Trans></p>
            </th>
            <td>
              <p><Trans i18nKey="126.step3.visit">
                Visit </Trans>
              <a href="https://www.chexsystems.com/web/chexsystems/consumerdebit/otherpage/FACTAFreeReport/!ut/p/z1/04_Sj9CPykssy0xPLMnMz0vMAfIjo8zizQIsPN2NLAx8DDyc3AwcXYPMnM28_YwMjA30w8EKDBxdHA1Ngg18_J3dzYEKXCycHf2cjAwMDPSjSNLvHuAPUuDrZxEc6gPUb06kfhzAkUj78SiIwm98uH4URAmeEEBVgMWLhCwpyA0NDY0wyPR0VFQEAFZE7O0!/dz/d5/L2dBISEvZ0FBIS9nQSEh/">
                  chexsystems.com
              </a>
              </p>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <p><Trans i18nKey="126.step3.phone">Phone:</Trans></p>
            </th>
            <td>
              <p>1-800-428-9623</p>
            </td>
          </tr>
          <tr>
            <th className="vertical-align-top" width="90px" scope="row">
              <p><Trans i18nKey="126.step3.mail">Mail:</Trans></p>
            </th>
            <td>
              <p>
                ChexSystems
                <br />
                Attn: Consumer Relations
                <br />
                7805 Hudson Road, Suite 100
                <br />
                Woodbury, MN 55125
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <table
        className="usa-table no-border"
        title={title2}
      >
        <thead>
          <tr>
            <th colSpan="2" scope="col">
              <p>
                <strong><Trans i18nKey="126.step3.getEarly">
                  Get Your Early Warning Services<sup>&reg;</sup> Report
                </Trans>
                </strong>
              </p>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th colSpan="2" scope="row">
              <p><Trans i18nKey="126.step3.complete">
                Download and complete the 
                <a href="https://www.earlywarning.com/sites/default/files/2019-01/CIC%20Form-170215-0811-SAMPLE.pdf">
                  form 
                </a>
                (earlywarning.com) and mail or fax it to Early Warning
                <sup>&reg;</sup>.
              </Trans>
              </p>
            </th>
          </tr>
          <tr>
            <th className="vertical-align-top" width="90px" scope="row">
              <p><Trans i18nKey="126.step3.online">Online:</Trans></p>
            </th>
            <td>
              <p><Trans i18nKey="126.step3.visit">
                Visit </Trans>
              <strong>
                <a href="https://www.earlywarning.com/consumer-information/">
                    earlywarning.com
                </a>                
              </strong>
              
              </p>
            </td>
          </tr>
          <tr>
            <th className="vertical-align-top" width="90px" scope="row">
              <p><Trans i18nKey="126.step3.mail">Mail:</Trans></p>
            </th>
            <td>
              <p>
                Early Warning
                <br />
                Attn: Consumer Services Department
                <br />
                16552 N. 90th Street
                <br />
                Scottsdale, AZ 85260
              </p>
            </td>
          </tr>
          <tr>
            <th scope="row">
              <p>Fax:</p>
            </th>
            <td>
              <p>1-480-656-6850</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default HowToRequestBankingHistory;
