import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import RequestService from '../../services/RequestService';
import config from '../../config';
import {Trans, useTranslation} from 'react-i18next';
import {observer} from 'mobx-react';

import howMoneySmartAreYou from '../../assets/images/howMoneySmartAreYou.png';
import howMoneySmartAreYouEs from '../../assets/images/howMoneySmartAreYou-es.png';
import compass from '../../assets/images/compass.png';

const gamesLink = {url: '/games', display: 'Games'};
const resourcesLink = {url: '/resources', display: 'Resources'};
const glossaryLink = {url: '/glossary', display: 'Glossary'};
const loginLink = {url: '/login', display: 'Login'};
const myAccountLink = {url: '/account', display: 'My Account'};
const adminAccountLink = {url: '/account', display: 'Dashboard'};
const gameReportsLink = {url: '/account/reports', display: 'Game Reports'};
const organizationsLink = {url: '/account/organizations', display: 'Organizations'};
const toolReportsLink = {url: '/account/tool-statistics', display: 'Tool Reports'};
const surveyLink = {url: '/survey', display: 'Survey Reports'};
const helpCenterLink = {url: '/help-center', display: 'Help Center'};

const publicSiteLinks = [gamesLink, glossaryLink, resourcesLink, loginLink];
const privateSiteLinks = [myAccountLink, gamesLink, glossaryLink, resourcesLink];
const organizationSiteLinks = [myAccountLink, gameReportsLink, gamesLink, glossaryLink, resourcesLink];
const adminSiteLinks = [
  adminAccountLink,
  organizationsLink,
  gameReportsLink,
  toolReportsLink,
  surveyLink,
  gamesLink,
  glossaryLink,
  resourcesLink,
];

export const linkActive = (linkTitle) => window.location.pathname === linkTitle;

export const getLinks = (loggedIn, isAdmin, isOrganization, organization, includeHelpCenter) => {
  let links = [];
  if (!loggedIn) links = publicSiteLinks;
  else if (isAdmin) links = adminSiteLinks;
  else if (isOrganization && organization && organization.approved) links = organizationSiteLinks;
  else links = privateSiteLinks;
  return !!includeHelpCenter ? [...links, helpCenterLink] : links;
};

export const logout = (authStore, history) => {
  RequestService.delete(
    `${config.apiGateway.URL}/logout`,
    () => {
      authStore.clearSession();
      history.push('/login');
    },
    () => {},
  );
};

const pageIncludeCheck = ['/games'];

const moneySmartExtraText = (t) => (
  <span>
    <Trans i18nKey='sideNav.extraText'>
      <span>To play the Games, you need to </span>
      <Link to='/register'>{t('login.create')}</Link>
      <span> only if you want to earn certificates or have your progress saved. To use the </span>
      <Link to='/resources'>{t('Resources')}</Link>, you don’t need an account.
    </Trans>
  </span>
);

const SideNavigator = (props) => {
  const {t, i18n} = useTranslation();
  const history = useHistory();
  const {authStore, organizationStore} = props;
  if (authStore.isOrganization) organizationStore.getOrganization(authStore.organizationId);
  const org = !!organizationStore && !!organizationStore.organization ? organizationStore.organization : null;
  const helpCenterText = t('Help Center');
  return (
    <div className='side-navigator margin-right-0 desktop:margin-right-2 margin-bottom-2'>
      <div className='money-smart-logo hide-mobile'>
        <Link to='/games'>
          <img src={i18n.language === 'es' ? howMoneySmartAreYouEs : howMoneySmartAreYou} alt={t('Money smart logo')} />
        </Link>
      </div>
      <ul className='usa-sidenav hide-mobile'>
        {getLinks(authStore.loggedIn, authStore.isAdmin, authStore.isOrganization, org).map((link, index) => {
          const linkText = t(link.display);
          return (
            <li className='usa-sidenav__item' key={index}>
              <Link
                title={linkText}
                to={link.url}
                className={`navigator-item ${linkActive(link.url) && 'usa-current'}`}
              >
                <span>{linkText}</span>
              </Link>
            </li>
          );
        })}
        {authStore.loggedIn && (
          <li className='usa-sidenav__item'>
            <a
              id='logout'
              href='/login'
              title={t('Log Out')}
              className='navigator-item'
              onClick={(e) => e.preventDefault() || logout(authStore, history)}
            >
              {t('Log Out')}
            </a>
          </li>
        )}
      </ul>
      <div className='help-explanation text-center'>
        <div className='grid-row text-center compass-icon-row hide-mobile'>
          <img width='50' src={compass} alt={t`Compass icon`} className='centered' />
        </div>
        <b>
          <i className='font-size-15px'>{t('How Money Smart Are You?')}</i>
        </b>
        <p className='font-size-15px margin-top-0'>
          {t(
            'is a suite of 14 games and related resources about everyday financial topics. It’s based on FDIC’s award-winning Money Smart program.',
          )}
        </p>
        <div className='grid-row text-center help-center-button-row'>
          <Link className='usa-button fdic-button tan-button centered hide-mobile' to='/help-center'>
            {helpCenterText}
          </Link>
        </div>
        {pageIncludeCheck.includes(window.location.pathname) && (
          <p className='font-size-15px margin-top-0'>{moneySmartExtraText(t)}</p>
        )}
      </div>
    </div>
  );
};

export default observer(SideNavigator);
