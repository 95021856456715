import React, {Component} from 'react';
import {Formik, Form} from 'formik';
import {withTranslation} from 'react-i18next';

import Inputs from '../tools/utilities/Inputs';
import {SURVEY_QUESTION_1, SURVEY_QUESTION_2, SURVEY_RESPONSES} from '../../stores/SurveyStore';

import chloe1 from '../../assets/images/chloe1.png';

const FDIC_FORM_NUMBER = 'survey.fdicFormNumber';
const OMB_NUMBER = 'survey.ombControl';
const OMB_EXPIRATION = 'survey.ombExpDate';
const PRA_TITLE = 'survey.praTitle';
const PRA_BODY = 'survey.praBody';
const PRA_BODY_2 = 'survey.praBody2';

class GameSurvey extends Component {
  constructor() {
    super();
    this.state = {
      showSurvey: false,
    };
  }

  surveyResponseIndex = (value) => SURVEY_RESPONSES.indexOf(value);

  submitSurvey = (values) => {
    this.props.surveyStore.submitSurvey({
      question: SURVEY_QUESTION_1,
      response: this.surveyResponseIndex(values.question1),
      gameId: this.props.game.id,
    });
    this.props.surveyStore.submitSurvey({
      question: SURVEY_QUESTION_2,
      response: this.surveyResponseIndex(values.question2),
      gameId: this.props.game.id,
    });
    this.props.closeSurvey();
  };

  renderActionText = ({question1, question2}) => {
    const {t} = this.props;
    return question1 !== '' && question2 !== ''
      ? t('Thank you! Please select “Continue” to return to the Games page.')
      : t('Please answer both questions.');
  };

  surveyForm = (q1, q2, surveyResponses) => (
    <Formik
      initialValues={{
        question1: '',
        question2: '',
      }}
      onSubmit={this.submitSurvey}
    >
      {(formikProps) => (
        <Form>
          {Inputs.radioButtonGroup({
            id: 'question1',
            label: <b>{q1}</b>,
            value: formikProps.values.question1,
            error: formikProps.errors.question1,
            touched: formikProps.touched.question1,
            options: surveyResponses,
            largeLabel: true,
          })}
          <br />
          {Inputs.radioButtonGroup({
            id: 'question2',
            label: <b>{q2}</b>,
            value: formikProps.values.question2,
            error: formikProps.errors.question2,
            touched: formikProps.touched.question2,
            options: surveyResponses,
            largeLabel: true,
          })}
          <br />
          <p>{this.renderActionText(formikProps.values)}</p>
          <button type='submit' className='usa-button' onClick={formikProps.handleSubmit}>
            {this.props.t('Continue')}
          </button>
        </Form>
      )}
    </Formik>
  );

  render() {
    const {game, closeSurvey, t, i18n} = this.props;
    const isSpanish = i18n.language === 'es';

    const gameName = isSpanish ? game.name_es : game.name;

    const fdicFormNumber = t(FDIC_FORM_NUMBER);
    const ombNumber = t(OMB_NUMBER);
    const ombExpDate = t(OMB_EXPIRATION);
    const praTitle = t(PRA_TITLE);
    const praBody = t(PRA_BODY);
    const praBody2 = t(PRA_BODY_2);

    const q1 = t(SURVEY_QUESTION_1);
    const q2 = t(SURVEY_QUESTION_2);
    const surveyResponses = SURVEY_RESPONSES.map((sr) => t(sr));

    const moneySmartCharAltText = t('Money Smart character');

    return this.state.showSurvey ? (
      <div className='grid-container padding-bottom-5'>
        <div className='omb-information margin-top-105'>
          <p className='margin-0 font-size-12'>{fdicFormNumber}</p>
        </div>
        <h2>
          <i>{t('How Money Smart Are You?')}</i> {t('Satisfaction Survey')}
        </h2>
        <h3>{gameName}</h3>
        {this.surveyForm(q1, q2, surveyResponses)}
      </div>
    ) : (
      <div className='grid-container padding-bottom-5'>
        <div className='omb-information margin-top-105 text-right'>
          <p className='margin-0 font-size-12'>{ombNumber}</p>
          <p className='margin-0 font-size-12'>{ombExpDate}</p>
        </div>
        <h5 className='margin-0'>{praTitle}</h5>
        <p className='font-size-12 text-left'>{praBody}</p>
        {isSpanish && <p className='font-size-12 text-left'>{praBody2}</p>}
        <div className='grid-row text-center' style={{height: 'auto'}}>
          <div className='grid-col-2' style={{maxHeight: '100px'}}>
            <img src={chloe1} alt={moneySmartCharAltText} />
          </div>
          <div className='grid-col-8'>
            <h3 className='centered'>{t('Thank you for playing this game. We’d like your feedback!')}</h3>
            <p className='centered'>
              {t('To complete a short two-question satisfaction survey, please select the “Continue” button.')}
            </p>
            <p className='centered'>{t('To skip the satisfaction survey, please select the “Skip” button.')}</p>
          </div>
          <div className='grid-col-2'></div>
        </div>
        <div className='grid-row text-center margin-bottom-2' style={{height: 'auto'}}>
          <button type='button' className='usa-button centered' onClick={() => this.setState({showSurvey: true})}>
            {t('Continue')}
          </button>
        </div>
        <div className='grid-row text-center' style={{height: 'auto'}}>
          <button
            type='button'
            className='usa-button centered usa-button--unstyled mobile-full-width text-center'
            onClick={closeSurvey}
          >
            {t('Skip')}
          </button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(GameSurvey);
