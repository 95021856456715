import React from 'react';

const BackToTop = () => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <button className='usa-btn-link' onClick={scrollToTop}>
      Back to top
    </button>
  );
};

export default BackToTop;
