import React, {useEffect} from 'react';
import {Field, FieldArray} from 'formik';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from "@gabmarini/ckeditor5-build-classic-base64upload";

import InputError from '../../../components/Formik/InputError';
import {ACCOUNT_TYPES} from '../EditorHelpCenter';

const renderNameField = (formikProps, isSpanish) => {
  const fieldName = `name${isSpanish ? '_es' : ''}`;
  return (
    <div className="grid-row margin-bottom-2">
      <div className="grid-col-12">
        <label className="margin-right-05"><b>Topic Title{isSpanish && ' (Spanish)'}:</b></label>
        <Field name={fieldName} className="full-width"/>
      </div>
      <InputError formikProps={formikProps} fieldName={fieldName} />
    </div>
  );
};

const renderDetailsField = (showDetails, formikProps, isSpanish) => {
  if (showDetails) {
    const fieldName = `details${isSpanish ? '_es' : ''}`;
    const data = isSpanish ? formikProps.values.details_es : formikProps.values.details;
    return (
      <div className="grid-row margin-bottom-2">
        <div className="grid-col-12">
          <label className="margin-bottom-05"><b>Details{isSpanish && ' (Spanish)'}:</b></label>
        </div>
        <CKEditor
          editor={ ClassicEditor }
          data={data}
          onChange={ (event, editor) => {
            formikProps.setFieldValue(fieldName, editor.getData());
          } }
        />
        <InputError formikProps={formikProps} fieldName={fieldName} />
      </div>
    );
  }
};

const EditHelpTopicForm = ({formikProps, helpTopic, helpTopicCategories, showDelete, showSaveAndView, showDetails, onDelete, onCancel, isSpanish, saveAndViewSelected, setSaveAndViewSelected, urlParams = ''}) => {
  useEffect(() => {
    if (saveAndViewSelected) {
      setSaveAndViewSelected(false);
      window.location = `/help-center/${helpTopic.id}${urlParams}`;
    }
  });

  console.log(formikProps.values);
  
  return(
    <div className="grid-container">
      {renderNameField(formikProps, isSpanish)}
      {renderDetailsField(showDetails, formikProps, isSpanish)}
      <div className="grid-row margin-bottom-2">
        <div className="grid-col-12">
          <label className="margin-right-05"><b>Category:</b></label>
          <select onChange={formikProps.handleChange} name="help_topic_category_id" value={formikProps.values.help_topic_category_id}>
            <option disabled value="0">Please select...</option>
            {helpTopicCategories.map((category) => (
              <option key={category.id} value={category.id}>{category.name}</option>
            ))}
          </select>
        </div>
        <InputError formikProps={formikProps} fieldName="help_topic_category_id" />
      </div>
      <div className="grid-row margin-bottom-2">
        <div className="grid-col-12">
          <label className="margin-right-05"><b>Account Type:</b></label>
        </div>
        <FieldArray 
          name="account_types"
          render={arrayHelpers => (
            ACCOUNT_TYPES.map((accountType) => (
              <label key={accountType.label} className="margin-right-2">
                <input
                  className="account-type-checkbox"
                  name="account_types"
                  type="checkbox"
                  value={accountType.value}
                  checked={formikProps.values.account_types && formikProps.values.account_types.includes(accountType.value)}
                  onChange={e => {
                    if (e.target.checked) {
                      arrayHelpers.push(accountType.value);
                    } else {
                      const idx = formikProps.values.account_types.indexOf(accountType.value);
                      arrayHelpers.remove(idx);
                    }
                  }}
                />
                <span className="margin-left-05">{accountType.label}</span>
              </label>
            ))
          )}
        />
      </div>
      <div className="grid-row margin-bottom-3">
        <button type="submit" className="usa-button fdic-button" onClick={formikProps.handleSubmit}>Save</button>
        {showDelete && <button className="usa-button tan-button fdic-button margin-left-2" onClick={onDelete}>Delete</button>}
        {showSaveAndView && <button type="submit" className="usa-button fdic-button tan-button" onClick={() => {
          setSaveAndViewSelected(true);
          formikProps.handleSubmit();
        }}>Save and View</button>}
        <button type="button" className="usa-button usa-button--unstyled margin-left-2" onClick={onCancel}>Cancel</button>
      </div>
    </div>
  );
};

export default EditHelpTopicForm;