import React, {PureComponent} from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

const CustomTooltip = ({active, payload, label}) => {
  if (active) {
    return (
      <div className='custom-tooltip'>
        <h4 className='label no-margin'>{`${label}`}</h4>
        <p className='desc'>{`Won: ${payload[0] ? payload[0].value : '0'}`}</p>
        <p className='desc'>{`In progress: ${
          payload[1] ? payload[1].value : '0'
        }`}</p>
      </div>
    );
  }

  return null;
};

class CustomizedAxisTick extends PureComponent {
  render() {
    const {x, y, payload} = this.props;

    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor='end'
          fill='#666'
          transform='rotate(-45)'
        >
          {payload.value}
        </text>
      </g>
    );
  }
}

export default class SessionsBarChart extends PureComponent {
  renderLegend = (props, totals) => {
    const {payload} = props;
    return (
      <ul className='grid-row flex-justify-end margin-0 padding-0'>
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            className={`recharts-legend-item legend-item-${index} grid-row flex-no-wrap${
              index === 0 ? ' margin-right-3' : ''
            }`}
          >
            <div
              className='marker'
              style={{
                backgroundColor: entry.color,
                marginTop: '6px',
                marginRight: '6px',
                width: '24px',
                height: '12px',
              }}
            />
            <div className='totals'>
              {`${entry.value} (${
                index === 0 ? totals.won : totals.inProgress
              })`}
            </div>
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const totals = {won: 0, inProgress: 0};
    this.props.sessionsData.reduce((pv, cv) => {
      totals.won += cv['Won'];
      totals.inProgress += cv['In progress'];
      return totals;
    }, totals);
    if (isNaN(totals.won)) totals.won = 0;
    if (isNaN(totals.inProgress)) totals.inProgress = 0;
    return (
      <BarChart
        width={1000}
        height={700}
        data={this.props.sessionsData}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 240,
        }}
      >
        <CartesianGrid strokeDasharray='1 1' />
        <XAxis
          dataKey='name'
          tick={<CustomizedAxisTick />}
          textAnchor='end'
          interval={0}
        />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend
          verticalAlign='top'
          align='right'
          wrapperStyle={{paddingBottom: '24px'}}
          content={(props) => this.renderLegend(props, totals)}
        />
        <Bar
          dataKey='Won'
          stackId='a'
          fill='#124C8F'
          isAnimationActive={false}
        />
        <Bar
          dataKey='In progress'
          stackId='a'
          fill='#a8226b'
          label={{position: 'top'}}
          isAnimationActive={false}
        />
      </BarChart>
    );
  }
}
