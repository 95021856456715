import React from 'react';
import Switch from '@material-ui/core/Switch';
import {Trans} from 'react-i18next';

const CollapseAllSwitch = ({checked, onChange}) => {
  return (
    <div className='grid-row margin-top-2'>
      <label className='margin-top-1' htmlFor='toggleOpen'>
        <b>
          <Trans i18nKey='glossary.collapseAll'>Collapse all</Trans>
        </b>
      </label>
      <Switch
        id='toggleOpen'
        checked={checked}
        onChange={onChange}
        inputProps={{'aria-label': 'Expand All'}}
        color='primary'
      />
      <label className='margin-top-1' htmlFor='toggleOpen'>
        <b>
          <Trans i18nKey='glossary.expandAll'>Expand all</Trans>
        </b>
      </label>
    </div>
  );
};

export default CollapseAllSwitch;
