import React from 'react';
import {observer} from 'mobx-react';
import {withTranslation} from 'react-i18next';

import SearchField from './components/SearchField';
import {Loader} from '../../components/Loader';
import HelpTopicsList from './components/HelpTopicsList';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import config from '../../config';
import LanguageSelector from '../../components/Buttons/LanguageSelector';

class HelpTopicCategory extends React.Component {
  componentDidMount() {
    this.props.helpCenterStore.getHelpTopics(true);
  }

  getCategoryName(helpTopicCategory, isSpanish) {
    return isSpanish && helpTopicCategory.help_topic_category_name_es
      ? helpTopicCategory.help_topic_category_name_es
      : helpTopicCategory.help_topic_category_name;
  }

  renderHelpTopicCateryBreadcrumb(helpCenterStore, helpTopicCategoryId, isSpanish) {
    let name = 'Category';
    if (!helpCenterStore.helpTopicsLoading) {
      const helpTopicCategory = helpCenterStore.groupedHelpTopics[helpTopicCategoryId][0];
      name = this.getCategoryName(helpTopicCategory, isSpanish);
    }
    return (
      <BreadCrumb
        historyLinks={[
          {
            link: '/help-center',
            display: 'Help Center',
          },
          {
            link: '#',
            display: name,
          },
        ]}
      />
    );
  }

  render() {
    const {match, helpCenterStore, t, i18n} = this.props;
    const {helpTopicCategoryId} = match.params;
    if (!helpCenterStore.helpTopicsLoading) {
      config.setUniqueTitle(helpCenterStore.groupedHelpTopics[helpTopicCategoryId][0].help_topic_category_name);
    }
    const isSpanish = i18n.language === 'es';
    return (
      <div className='help-center-search grid-container'>
        {this.renderHelpTopicCateryBreadcrumb(helpCenterStore, helpTopicCategoryId, isSpanish)}
        <SearchField />
        <LanguageSelector {...this.props} />
        {helpCenterStore.helpTopicsLoading ? (
          <Loader />
        ) : (
          <div>
            <h3 className='color-alternate-tan margin-bottom-5'>
              {t('Displaying help topics for category')} '
              {this.getCategoryName(helpCenterStore.groupedHelpTopics[helpTopicCategoryId][0], isSpanish)}'
            </h3>
            <div className='condensed-topic-list'>
              <HelpTopicsList
                helpTopics={helpCenterStore.groupedHelpTopics[helpTopicCategoryId]}
                isSpanish={isSpanish}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(observer(HelpTopicCategory));
