import React from 'react';
import {Formik} from 'formik';
import {observer} from 'mobx-react';
import * as Yup from 'yup';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';

import RequestService from '../../services/RequestService';
import config from '../../config';
import {getOrganizationName} from '../../services/OrganizationUtilities';
import OrganizationInput from './OrganizationInput';
import {organizationDisclosure} from '../register/Register';

const changeOrganizationSchema = Yup.object().shape({
  organizationId: Yup.string().required('You must enter an existing Organization Display Name.'),
});

const changeOrganizationError = (formikProps, fieldName) =>
  formikProps.errors[fieldName] && formikProps.touched[fieldName] ? (
    <div className='margin-top-1 alert full-width'>{formikProps.errors[fieldName]}</div>
  ) : null;

const saveOrganization = (authStore, organizationId, history) => {
  RequestService.post(
    `${config.apiGateway.URL}/update-organization`,
    {
      user_id: authStore.userId,
      organization_id: organizationId,
    },
    (response) => {
      console.log(response.data.organization_id);
      authStore.organizationId = response.data.organization_id;
      authStore.writeToSession();
      history.push('/account');
    },
  );
};

const getOrganizationOptions = (organizationStore) =>
  organizationStore.approvedOrganizations.map((organization) => ({
    id: organization.id,
    label: organization.display_name,
  }));

const ChangeOrganizationForm = ({formikProps, ...props}) => {
  const organizationOptions = getOrganizationOptions(props.organizationStore);
  return (
    <div className='container text-align-center margin-bottom-2'>
      {
        <OrganizationInput
          organizationId={formikProps.values.organizationId}
          organizationOptions={organizationOptions}
          handleChange={(values) => values[0] && formikProps.setFieldValue('organizationId', values[0].id)}
          handleSave={formikProps.handleSubmit}
        />
      }
      {changeOrganizationError(formikProps, 'organizationId')}
    </div>
  );
};

const ChangeOrganization = observer((props) => {
  const {t} = useTranslation();
  const history = useHistory();

  const {organizationStore, authStore} = props;
  organizationStore.getOrganizations();
  const organizationOptions = getOrganizationOptions(organizationStore);
  const orgId = authStore.organizationId;
  const approvedOrgs =
    orgId &&
    organizationStore.approvedOrganizations.filter((organization) => organization.id.toString() === orgId.toString());
  const isLinkedToApprovedOrg = orgId !== null && approvedOrgs.length > 0;

  return (
    !organizationStore.organizationsLoading && (
      <div className='registration-wrapper padding-left-3 padding-right-3'>
        <Formik
          initialValues={{organizationId: ''}}
          validationSchema={changeOrganizationSchema}
          onSubmit={(values) => saveOrganization(authStore, values.organizationId, history)}
        >
          {(formikProps) => (
            <div>
              {isLinkedToApprovedOrg ? (
                <h3>
                  {t('Current organization')}: {getOrganizationName(organizationOptions, authStore.organizationId)}
                </h3>
              ) : (
                <h3>{t('You are not currently linked to an organization')}</h3>
              )}
              <div className='margin-bottom-2'>
                <i>{t(organizationDisclosure)}</i>
              </div>
              <div className='text-center margin-bottom-3 margin-x-8'>
                <i>
                  <b>
                    {t('**The organization will not have access to any information about your use of the Resources.**')}
                  </b>
                </i>
              </div>
              <ChangeOrganizationForm formikProps={formikProps} {...props} />
            </div>
          )}
        </Formik>
      </div>
    )
  );
});

export default ChangeOrganization;
export {changeOrganizationSchema};
