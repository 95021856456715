// eslint-disable-next-line no-extend-native

var withCommas = function() {
  return this.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// eslint-disable-next-line no-extend-native
String.prototype.withCommas = withCommas;

// eslint-disable-next-line no-extend-native
Number.prototype.withCommas = withCommas;