import React from 'react';
import moment from 'moment';

const renderTableRows = (sessions, showOrganizationDetails) =>
  sessions.map((session) => {
    const thClasses = session.complete_date ? 'background-color-faded-primary' : 'background-color-faded-secondary';
    return (
      <tr key={`session-row-${session.user_id}`}>
        <th scope='row' className={thClasses}>
          {session.user_id}
        </th>
        {session.start_date !== null && (
          <td className={thClasses}>{moment(session.start_date).parseZone().format('LL')}</td>
        )}
        <td className={thClasses}>
          {session.complete_date ? moment(session.complete_date).parseZone().format('LL') : 'N/A'}
        </td>
        {showOrganizationDetails && <td className={thClasses}>{session.organization_name || '-'}</td>}
        <td className={thClasses}>
          {session.complete_date ? <a href={`/certificate/${session.game_id}/${session.user_id}`}>View</a> : 'N/A'}
        </td>
      </tr>
    );
  });

const renderTableBody = (sessions, showOrganizationDetails) => {
  let completedSessions = sessions.filter((session) => session.complete_date);
  let incompleteSessions = sessions.filter((session) => !session.complete_date);
  return (
    <tbody>
      {renderTableRows(incompleteSessions, showOrganizationDetails)}
      {renderTableRows(completedSessions, showOrganizationDetails)}
    </tbody>
  );
};

const SessionsTable = ({gameStat, sessions, organizationStore, showOrganizationDetails}) => {
  // const sessions =
  //   gameStat.id === 15 ? gameStat.masterCertificatesByUserId : organizationStore.getSortedSessions(gameStat.id);
  return (
    <table className='usa-table report-table centered' title='Game sessions table'>
      <thead>
        <tr>
          <th scope='col'>Player ID</th>
          {gameStat.id !== 15 && <th scope='col'>Start Date</th>}
          <th scope='col'>Certificate Date</th>
          {showOrganizationDetails && <th scope='col'>Organization</th>}
          <th scope='col'>Certificate</th>
        </tr>
      </thead>
      {renderTableBody(sessions, showOrganizationDetails)}
    </table>
  );
};

export default SessionsTable;
