import React from 'react';

const Footer = () => (
  <div id="fdic-footer-container" className='fdic-footer hide-print' lang='en'>
    <footer className='usa-footer usa-footer--slim'>
      <div class='grid-container usa-footer__return-to-top usa-sr-only'>
        <a href='#/'>Return to top</a>
      </div>
      <div className="usa-footer__primary-section bg-informed">
        <div className='grid-container'>
          <div className='grid-row grid-gap'>
            <div className='tablet:grid-col-12'>
              <div className='grid-row grid-gap-4 margin-6'>
                <div className='mobile-lg:grid-col-9 desktop:grid-col-4 half-pixel-border-white margin-x-auto'>
                  <section className='usa-footer__primary-content usa-footer__primary-content--collapsible'>
                    <div className='usa-contact-cta margin-x-auto text-center text-white'>
                      <div className='usa-form'>
                        <h3 className='usa-contact-cta__heading font-alt-lg margin-top-0 margin-bottom-4 text-uppercase'>
                          Contact the FDIC
                        </h3>
                        <a
                          className='usa-button margin-top-1 margin-x-auto'
                          href='https://www.fdic.gov/contact'
                          aria-label='Find out how to get in contact the FDIC'
                        >
                          Contact Us
                        </a>
                      </div>
                    </div>
                  </section>
                </div>
                <div className='mobile-lg:grid-col-9 desktop:grid-col-4 half-pixel-border-white margin-x-auto'>
                  <section className='usa-footer__primary-content usa-footer__primary-content--collapsible'>
                    <div className='usa-stay-informed text-center text-white'>
                      <form
                        className='usa-form'
                        action='https://public.govdelivery.com/accounts/USFDIC/subscribers/qualify'
                        acceptCharset='UTF-8'
                        method='post'
                      >
                        <h3 className='usa-stay-informed__heading font-alt-lg margin-top-0 margin-bottom-4 text-uppercase'>
                          Stay Informed
                        </h3>
                        <input
                          type='hidden'
                          name='authenticity_token'
                          value='j1E/fYVkoSM7tHw2qwDlob2DvT78RtfQKBwd1buCKxnBBGe5cL0xfkbCHh+JMub0yU6qc8JBwQiqf0/BDff6BQ=='
                        />
                        <input type='hidden' name='topic_id' id='topic_id' value='USFDIC' />
                        <label htmlFor='fdic-email' className='usa-sr-only'>
                          Enter your email address
                        </label>
                        <input
                          className='usa-input margin-top-0 margin-x-auto'
                          id='fdic-email'
                          name='fdic-email'
                          type='email'
                          placeholder='Enter your email address:'
                        />
                        <button
                          className='usa-button margin-bottom-3 margin-x-auto'
                          type='submit'
                          aria-label='Enter email address to begin receiving updates on FDIC news, publications, and data'
                        >
                          Get Started
                        </button>
                      </form>

                      <div className='grid-container tablet:padding-x-0'>
                        <div className='grid-row grid-gap-1'>
                          <div className='grid-col-fill'></div>
                          <div className='grid-col-auto'>
                            <a
                              aria-label='Follow the FDIC on Facebook'
                              className='usa-icon usa-icon--gold-circle'
                              href='https://www.facebook.com/FDICgov/'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <i
                                aria-hidden='true'
                                className='fab fa-facebook-f'
                                title='Follow the FDIC on Facebook'
                              ></i>
                            </a>
                          </div>
                          <div className='grid-col-auto'>
                            <a
                              aria-label='Follow the FDIC on Twitter'
                              className='usa-icon usa-icon--gold-circle'
                              href='https://twitter.com/FDICgov/'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path fill="#ffffff" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/><title>Follow the FDIC on X</title></svg>
                            </a>
                          </div>
                          <div className='grid-col-auto'>
                            <a
                              aria-label='Follow the FDIC on Instagram'
                              className='usa-icon usa-icon--gold-circle'
                              href='https://www.instagram.com/fdicgov/'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <i
                                aria-hidden='true'
                                className='fab fa-instagram'
                                title='Follow the FDIC on Instagram'
                              ></i>
                            </a>
                          </div>
                          <div className='grid-col-auto'>
                            <a
                              aria-label='Follow the FDIC on LinkedIn'
                              className='usa-icon usa-icon--gold-circle'
                              href='https://www.linkedin.com/company/fdic/'
                              target='_blank'
                              rel='noopener noreferrer'
                            >
                              <i aria-hidden='true' className='fab fa-linkedin' title='Follow the FDIC on LinkedIn'></i>
                            </a>
                          </div>
                          <div className='grid-col-auto'>
                            <a
                              aria-label='Follow the FDIC on Flickr'
                              className='usa-icon usa-icon--gold-circle'
                              href='https://www.flickr.com/photos/187818557@N05/'
                              target='_blank' rel="noreferrer"
                            >
                              <i
                                aria-hidden="true"
                                class="fas fa-circle"
                                title="Follow the FDIC on Flickr"
                              ></i
                              ><i
                                aria-hidden="true"
                                class="fas fa-circle"
                                title="Follow the FDIC on Flickr"
                              ></i>
                            </a>
                          </div>
                          <div className='grid-col-auto'>
                            <a
                              aria-label='Follow the FDIC on YouTube'
                              className='usa-icon usa-icon--gold-circle'
                              href='https://youtube.com/user/FDICchannel/'
                              target='_blank' rel="noreferrer"
                            >
                              <i
                                aria-hidden="true"
                                class="fa fa-youtube"
                                title="Follow the FDIC on YouTube"
                              ></i>
                            </a>
                          </div>
                          <div className='grid-col-fill'></div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className='mobile-lg:grid-col-9 desktop:grid-col-4 margin-x-auto'>
                  <section className='usa-footer__primary-content usa-footer__primary-content--collapsible'>
                    <div className='usa-help text-center text-white width-full'>
                      <h3 className='usa-help__heading font-alt-lg margin-top-0 margin-x-auto margin-bottom-4 text-light text-uppercase'>
                        How Can We Help You?
                      </h3>
                      <form className='usa-form margin-x-auto'>
                        <label
                          className='usa-sr-only usa-label usa-help__heading font-alt-lg margin-top-0 margin-x-auto margin-bottom-4 text-light text-uppercase'
                          htmlFor='fmi4_usertype'
                        >
                          Define &ldquo;I am a . . . &rdquo; to learn more specific information
                        </label>
                        <select
                          className='usa-select margin-x-auto margin-bottom-2'
                          id='fmi4_usertype'
                          onChange={() => console.log('fmi4_update_useraction(this);')}
                        >
                          <option value=''>I am a...</option>
                          <option value='customer'>Bank Customer</option>
                          <option value='banker'>Banker</option>
                          <option value='consumer'>Consumer</option>
                          <option value='business'>Small Business Owner</option>
                          <option value='analyst'>Analyst/Researcher/Student</option>
                          <option value='legal'>Legal Professional</option>
                          <option value='regulator'>Bank Regulator</option>
                          <option value='press'>Press/Media</option>
                          <option value='vendor'>Vendor/Contractor</option>
                          <option value='legislative'>Legislative Staff Member</option>
                          <option value='jobseeker'>Jobseeker</option>
                          <option value='courtclerk'>Court Clerk</option>
                          <option value='prosprobofficer'>Prosecutor/Probation Officer</option>
                          <option value='investigator'>Investigator</option>
                        </select>
                        <label htmlFor='fmi4_useraction' className='usa-sr-only'>
                          Select the information you wish to explore based on who you are
                        </label>
                        <select
                          id='fmi4_useraction'
                          className='usa-select margin-x-auto'
                          onFocus={() => console.log('fmi4_useraction_open();')}
                          onChange={() => console.log('fmi4_useraction_chosen(this);')}
                        >
                          <option className='default' value=''>
                            I want to...
                          </option>
                        </select>
                        <button
                          className='usa-button margin-bottom-3 margin-x-auto'
                          type='button'
                          value='Go'
                          onClick={() => console.log('fmi4_click();')}
                          aria-label='Go to the FDIC webpage that answers your question'
                        >
                          Get Started
                        </button>
                      </form>
                    </div>
                    <script type='text/javascript' src='/assets/javascript/find-more-info-v5c-7.js'></script>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='usa-footer__secondary-section padding-1'>
        <div className='usa-footer__secondary-container grid-row'>
          <div className='margin-x-auto'>
            <nav className='usa-footer__nav' aria-label='Footer navigation'>
              <ul className='grid-row grid-gap'>
                <li className='mobile-lg:grid-col-6 desktop:grid-col-auto usa-footer__secondary-content'>
                  <a className='usa-footer__secondary-link' href='https://www.fdic.gov/policies/' aria-label='Policies'>
                    {' '}
                    Policies{' '}
                  </a>
                </li>

                <li className='mobile-lg:grid-col-6 desktop:grid-col-auto usa-footer__secondary-content'>
                  <a className='usa-footer__secondary-link' href='https://www.fdic.gov/help/' aria-label='Help'>
                    {' '}
                    Help{' '}
                  </a>
                </li>
                <li className='mobile-lg:grid-col-6 desktop:grid-col-auto usa-footer__secondary-content'>
                  <a className='usa-footer__secondary-link' href='https://www.fdic.gov/foia/' aria-label='FOIA'>
                    {' '}
                    FOIA{' '}
                  </a>
                </li>
                <li className='mobile-lg:grid-col-6 desktop:grid-col-auto usa-footer__secondary-content'>
                  <a className='usa-footer__secondary-link' href='https://www.fdic.gov/espanol/' aria-label='En Español'>
                    {' '}
                    En Español{' '}
                  </a>
                </li>
                <li className='mobile-lg:grid-col-6 desktop:grid-col-auto usa-footer__secondary-content'>
                  <a className='usa-footer__secondary-link' href='https://www.fdic.gov/accessibility/' aria-label='Accessibility'>
                    {' '}
                    Accessibility{' '}
                  </a>
                </li>
                <li className='mobile-lg:grid-col-6 desktop:grid-col-auto usa-footer__secondary-content'>
                  <a className='usa-footer__secondary-link' href='https://www.fdic.gov/open-government/' aria-label='Open Government'>
                    {' '}
                    Open Government{' '}
                  </a>
                </li>
                <li className='mobile-lg:grid-col-6 desktop:grid-col-auto usa-footer__secondary-content'>
                  <a className='usa-footer__secondary-link' href='https://www.fdic.gov/sitemap/' aria-label='Site Map'>
                    {' '}
                    Site Map{' '}
                  </a>
                </li>
                <li className='mobile-lg:grid-col-6 desktop:grid-col-auto usa-footer__secondary-content'>
                  <a
                    className='usa-footer__secondary-link'
                    href='https://www.usa.gov/'
                    target='_blank'
                    aria-label='USA.gov'
                    rel='noreferrer'
                  >
                    USA.gov
                  </a>
                </li>
                <li className='mobile-lg:grid-col-6 desktop:grid-col-auto usa-footer__secondary-content'>
                  <a className='usa-footer__secondary-link' href='https://www.fdic.gov/contact/' aria-label='Contact Us'>
                    Contact Us
                  </a>
                </li>
                <li className='mobile-lg:grid-col-6 desktop:grid-col-auto usa-footer__secondary-content'>
                  <a className='usa-footer__secondary-link' href='https://www.fdic.gov/policies/privacy/' aria-label='Privacy'>
                    {' '}
                    Privacy{' '}
                  </a>
                </li>
                <li className='mobile-lg:grid-col-6 desktop:grid-col-auto usa-footer__secondary-content'>
                  <a className='usa-footer__secondary-link' href='https://www.fdic.gov/policies/plain-writing/' aria-label='Plain Writing'>
                    Plain Writing
                  </a>
                </li>
                <li className='mobile-lg:grid-col-6 desktop:grid-col-auto usa-footer__secondary-content'>
                  <a className='usa-footer__secondary-link' href='https://www.fdic.gov/about/diversity/nofear/' aria-label='No Fear Act Data'>
                    No Fear Act Data
                  </a>
                </li>
                <li className='mobile-lg:grid-col-6 desktop:grid-col-auto usa-footer__secondary-content'>
                  <a className='usa-footer__secondary-link' href='https://www.fdicoig.gov/' aria-label='Inspector General'>
                    {' '}
                    Inspector General{' '}
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  </div>
);

export default Footer;
