import React from "react";
import {Formik, Form} from "formik";
import Tables from "../utilities/Tables";
import Inputs from "../utilities/Inputs";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation} from "react-i18next";

const emptyItem = (item = "") => ({
  item: {value: item, inputType: "questionlabel", columnWidth: 2},
  capacity: {value: "", inputType: "textarea", columnWidth: 2, columnName: 'Capacity'},
  capital: {value: "", inputType: "textarea", columnWidth: 2, columnName: 'Capital'},
  creditCharacter: {value: "", inputType: "textarea", columnWidth: 2, columnName: 'Credit/Character'},
  collateral: {value: "", inputType: "textarea", columnWidth: 2, columnName: 'Collateral'},
});

const initialValues = {
  questionsSections: {
    "My SMART Goals": [
      emptyItem("What will reflect positively on me?"),
      emptyItem("What will reflect negatively on me?"),
      emptyItem("What can I do to become a stronger applicant?"),
    ],
  },
};

const GetReadyToBorrowMoney = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  return (
    <div>
      <Formik initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <EditableContent
              htmlType="div"
              initialContent={
                i18n.language === "es"
                  ? tool.contentsEs || tool.contents
                  : tool.contents
              }
              fieldName={i18n.language === "es" ? "contents_es" : "contents"}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            {Tables.renderSimpleTable(
              [
                t('Question'),
                t('Capacity'),
                t('Capital'),
                t('Credit/Character'),
                t('Collateral'),
              ],
              initialValues.questionsSections,
              formikProps,
              "questionsSections",
              true,
              emptyItem,
              undefined,
              false,
              true,
              "",
              "Table"
            )}
            <div>
              {Inputs.textArea(
                "improve",
                "What can I do to improve my credit?",
                "For example: Can I apply for a small credit-building loan and make on-time payments to improve my credit? Can I work with a reputable credit counselor to help develop other credit-building strategies?"
              )}
              {Inputs.textArea(
                "payments",
                "How will I make payments on a loan if I’m approved?",
                "For example: Will I have payments automatically taken out of my account, or will I remember to write a check and mail it in each month?"
              )}
              {Inputs.textArea(
                "monitor",
                "How will I monitor the loan?",
                "For example: Will I receive paper statements, monitor my payments and balance online, or both?"
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default GetReadyToBorrowMoney;
