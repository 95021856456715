import React, {useState} from 'react';
import {Field} from 'formik';
import Tooltip from '@material-ui/core/Tooltip';
import {withStyles} from '@material-ui/core/styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/free-regular-svg-icons';
import {useTranslation} from 'react-i18next';

import InputError from './InputError';
import EyeIconButton from '../Buttons/EyeIconButton';
import {passwordRequirements, nameRequirements} from '../../containers/register/Register';

export const hasAlert = (formikProps, fieldName) => formikProps.errors[fieldName] && formikProps.touched[fieldName];

const AccountToolTip = withStyles({
  tooltip: {
    fontSize: '1em',
  },
})(Tooltip);

const displayLabel = (label, showTooltip = false) => {
  return (
    <>
      <span>{label}</span>
      {showTooltip && (
        <span>
          {' '}
          <FontAwesomeIcon icon={faQuestionCircle} />
        </span>
      )}
    </>
  );
};

const FormInput = ({formikProps, type, id, label, showTooltip = false, required = false}) => {
  const [showPwd, setShowPwd] = useState(false);
  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPwd(!showPwd);
  };
  const {t} = useTranslation();
  const alert = hasAlert(formikProps, id);
  const fillFieldText = t('Please fill out this field.');
  const labelText = t(label) + (required ? ' *' : '');
  const fieldClassName = !!alert ? 'alert' : '';

  if (type === 'email') {
    return (
      <>
        <label htmlFor={id}>{labelText}</label>
        <Field id={id} name={id} type={type} title={fillFieldText} className={fieldClassName} />
        {alert && <InputError error={t(formikProps.errors[id])} />}
      </>
    );
  }
  if (type === 'password') {
    return (
      <>
        <label htmlFor={id}>
          {showTooltip ? (
            <AccountToolTip title={t(passwordRequirements)} placement='top'>
              <span>{displayLabel(labelText, showTooltip)}</span>
            </AccountToolTip>
          ) : (
            labelText
          )}
        </label>
        <div style={{position: 'relative'}}>
          <Field
            type={showPwd ? 'text' : 'password'}
            id={id}
            name={id}
            title={fillFieldText}
            className={fieldClassName}
            autoComplete='off'
          />
          <EyeIconButton onClickToggle={toggleShowPassword} show={showPwd} />
          {alert && <InputError error={t(formikProps.errors[id])} />}
        </div>
      </>
    );
  }
  if (type === 'text') {
    return (
      <>
        <label htmlFor={id}>
          {showTooltip ? (
            <AccountToolTip title={t(nameRequirements)} placement='top'>
              <span>{displayLabel(labelText, showTooltip)}</span>
            </AccountToolTip>
          ) : (
            labelText
          )}
        </label>
        <Field type={'text'} id={id} name={id} title={fillFieldText} className={fieldClassName} />
        {alert && <InputError error={t(formikProps.errors[id])} />}
      </>
    );
  }
  return <div className='alert'>NO VALID INPUT TYPE SELECTED</div>;
};

export default FormInput;
