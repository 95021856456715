import React from 'react';
import {Formik, Form, Field} from 'formik';
import Tables from '../utilities/Tables';
import EditableContent from '../../../components/EditableContent/EditableContent';
import config from '../../../config';
import {useTranslation} from 'react-i18next';

const tableHeaders = [
  'Bills and Expenses',
  'Amount Due',
  'Date Due',
  'Did You...',
  'What Will Happen if I Don’t Pay in Full When Due?',
  'Priority Number',
];

const emptyItem = (
  item = '',
  amount = '',
  dueDate = '',
  spokeToCreditor = '',
  askedForHelp = '',
  whatWillHappen = '',
  disabled = false,
  allowRemoval = false
) => ({
  item: {
    value: item,
    inputType: 'labeNoBold',
    columnWidth: 4,
    disabled: disabled,
    allowRemoval: allowRemoval,
    title: 'Item',
    columnName: tableHeaders[0],
  },
  amount: {
    value: amount,
    inputType: 'money',
    disabled: disabled,
    title: 'Item',
    columnName: tableHeaders[1],
  },
  dueDate: {
    value: dueDate,
    inputType: 'date',
    columnWidth: 1,
    disabled: disabled,
    title: 'Item',
    columnName: tableHeaders[2],
  },
  spokeToCreditor: {
    value: spokeToCreditor,
    inputType: 'checkboxes',
    options: ['Speak to creditor?', 'Ask for help?'],
    stackButtons: true,
    columnWidth: 1,
    disabled: disabled,
    title: 'Item',
    columnName: tableHeaders[3],
  },
  whatWillHappen: {
    value: whatWillHappen,
    inputType: 'textarea',
    rows: 5,
    columnWidth: 3,
    disabled: disabled,
    title: 'Item',
    columnName: tableHeaders[4],
  },
  priorityNumber: {
    value: '',
    inputType: 'calculated',
    calculation: disabled ? () => '' : priorityNumber,
    columnName: tableHeaders[5],
  },
});

const priorityNumber = (formikProps, sectionIdentifier, key, index, t) => {
  let title = `${t(formikProps.values[sectionIdentifier][key][index].item.value)} ${
    t(formikProps.values[sectionIdentifier][key][index]['priorityNumber'].columnName) || 'priorityNumber'
  }`;
  let name = `${sectionIdentifier}.${key}.${index}.${'priorityNumber'}.value`;
  let input = (
    <Field
      title={title}
      id={`${'priorityNumber'}-${key}-${index}`}
      name={name}
      type='number'
      onChange={async (e) => priorityNumberChange(e, formikProps, index)}
    />
  );
  return input;
};

const bumpMatchingValue = (formikProps, newValue, currentIndex, originalIndex, currentValue) => {
  let matchIndex;
  formikProps.values.incomeSections.Income.forEach((row, index) => {
    if (parseInt(row.priorityNumber.value) === newValue && !(index === currentIndex) && !(index === originalIndex))
      matchIndex = index;
  });
  if (matchIndex >= 0) {
    const bumpValue = newValue + 1 < formikProps.values.incomeSections.Income.length + 1 ? newValue + 1 : currentValue;
    formikProps.setFieldValue(`incomeSections.Income.${matchIndex}.priorityNumber.value`, bumpValue);
    bumpMatchingValue(formikProps, bumpValue, matchIndex, originalIndex, currentValue);
  }
};

const priorityNumberChange = (e, formikProps, rowIndex) => {
  const currentValue = parseInt(formikProps.values.incomeSections.Income[rowIndex].priorityNumber.value);
  const newValue = parseInt(e.target.value);
  console.log(e, formikProps, rowIndex);
  if (newValue < formikProps.values.incomeSections.Income.length + 1 || e.target.value === '') {
    formikProps.setFieldValue(`incomeSections.Income.${rowIndex}.priorityNumber.value`, newValue);
    bumpMatchingValue(formikProps, newValue, rowIndex, rowIndex, currentValue);
  }
};

const initialValues = {
  month: 'January',
  incomeSections: {
    Income: [emptyItem('')],
  },
};

const PrioritizeExpenses = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  return (
    <div>
      <Formik initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <EditableContent
              htmlType='div'
              initialContent={i18n.language === 'es' ? tool.contentsEs || tool.contents : tool.contents}
              fieldName={i18n.language === 'es' ? 'contents_es' : 'contents'}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            {Tables.renderSimpleTable(
              tableHeaders.map((th) => t(th)),
              initialValues.incomeSections,
              formikProps,
              'incomeSections',
              true,
              (value) => emptyItem(value, '', '', '', '', '', '', true),
              undefined,
              false,
              true,
              '',
              'Table'
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PrioritizeExpenses;
