import React from 'react';
import {Formik, Field, Form} from 'formik';
import {useTranslation} from 'react-i18next';

const SearchField = ({handleSubmit}) => {
  const {t} = useTranslation();
  return (
    <Formik
      initialValues={{
        search: '',
      }}
      onSubmit={
        handleSubmit
          ? (values) => handleSubmit(values.search)
          : (values) => (window.location = `/help-center/search?search=${values.search}`)
      }
    >
      {(formikProps) => (
        <Form className='usa-search usa-search--small mobile-full-width'>
          <div role='search' className='grid-col-12 padding-5 mobile-no-padding'>
            <label className='usa-sr-only' htmlFor='extended-search-field-small'>
              {t('search.label')}
            </label>
            <Field
              id='extended-search-field-small'
              title={t('search.fieldTitle')}
              name='search'
              className='full-width usa-input search'
              placeholder={t('search.placeholder')}
              type='search'
            />
            <button className='usa-button' onClick={formikProps.handleSubmit} type='submit'>
              <span className='usa-sr-only'>{t('glossary.searchButton')}</span>
            </button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SearchField;
