import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';


const GameCategory = ({categoryName, games}) => {
  const {t} = useTranslation();
  return (
    <div className="grid-col-6 margin-bottom-2 game-category-container mobile-full-width">
      <div className="grid-row grid-col-12 game-category">
        <div className="grid-col game-col">
          <div className="grid-col">
            <h2 className="margin-bottom-2">{categoryName}</h2>
            {
              games && games.map((game, index) => (
                <div className="grid-row game-row" key={index}>
                  <div className="grid-col-1">
                    <i className="material-icons">play_arrow</i>
                  </div>
                  <div className="grid-col-11 font-size-20">
                    <Link to={`/game/${game.id}`} className="usa-link light-weight game-link">
                      { t(game.name) }        
                    </Link>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameCategory;