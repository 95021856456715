import React, {PureComponent} from 'react';
import {Fragment} from 'react';
import {ResponsiveContainer, PieChart, Pie, Cell, Legend} from 'recharts';

const COLORS = ['#a8226b', '#124C8F', '#007A76', '#c0cfe0'];

const RADIAN = Math.PI / 180;

export default class UserPieChart extends PureComponent {
  renderCustomizedLabel = (props) => {
    let {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      value,
      // startAngle,
      // endAngle,
    } = props;
    const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (
      <text
        x={x}
        y={y}
        fill='black'
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline='central'
      >
        {` ${this.displayValue(value)}`}
      </text>
    );
  };

  renderCustomizedLabelLine = (props) => {
    let {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      stroke,
      startAngle,
      endAngle,
    } = props;
    const diffAngle = endAngle - startAngle;
    const radius = innerRadius + (outerRadius - innerRadius);
    let path = '';
    for (let i = 0; i < (360 - diffAngle) / 15; i++) {
      path += `${cx + (radius + i) * Math.cos(-midAngle * RADIAN)},${
        cy + (radius + i * i) * Math.sin(-midAngle * RADIAN)
      } `;
    }
    return <polyline points={path} stroke={stroke} fill='none' />;
  };

  displayValue = (value) => {
    if (value > 999) return `${Math.round(value / 100) / 10}k`;
    return value;
  };

  renderLegend = (props) => {
    const {payload} = props;
    return (
      <ul style={{listStyle: 'none', padding: '0'}}>
        {payload.map((entry, index) => (
          <li
            key={`item-${index}`}
            style={{display: 'flex', alignItems: 'flex-start'}}
          >
            <div
              className='marker'
              style={{
                backgroundColor: entry.color,
                display: 'inline-block',
                marginTop: '6px',
                width: '12px',
                height: '12px',
              }}
            ></div>
            <div style={{display: 'inline-block', paddingLeft: '0.5rem'}}>
              {`${entry.value} (${this.displayValue(entry.payload.value)})`}
            </div>
          </li>
        ))}
      </ul>
    );
  };

  renderPieChart = () => (
    <PieChart
      x={'-50'}
      width={600}
      // height={550}
      // viewBox={'-50 0 550 600'}
      tabIndex='0'
    >
      <Legend
        verticalAlign='top'
        align='left'
        // height='auto'
        tabIndex='0'
        content={this.renderLegend}
      />
      <Pie
        data={this.props.data}
        cx={180}
        cy={this.props.extraSpaceForChart ? 190 : 130}
        labelLine={this.props.showLines ? this.renderCustomizedLabelLine : null}
        // label={this.props.showLines ? this.renderCustomizedLabel : ({value}) => this.displayValue(value)}
        label={({value}) => this.displayValue(value)}
        outerRadius={120}
        fill='#8884d8'
        dataKey='value'
        isAnimationActive={false}
      >
        {this.props.data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );

  renderPieChartInResponsiveContainer = () => (
    <ResponsiveContainer width='100%' height={400}>
      {this.renderPieChart()}
    </ResponsiveContainer>
  );

  // ResponsiveContainer + react-test-renderer do not play nice 
  //  https://github.com/recharts/recharts/issues/2268
  render() {
    return (
      <Fragment>
        {this.props.testing
          ? this.renderPieChart()
          : this.renderPieChartInResponsiveContainer()}
      </Fragment>
    );
  }
}
