import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import Loader from '../../../../components/Loader/Loader';
import {Accordion} from '../../../../components/Accordion';
import OrganizationRow from './OrganizationRow';
import PrintButton from '../../../../components/Buttons/PrintButton';
import queryString from 'query-string';
import CollapseAllSwitch from '../../../../components/Accordion/CollapseAllSwitch';

import OrganizationToPdfButton from './OrganizationToPdfButton';

const OrganizationList = (props) => {
  const [allOpen, setAllOpen] = useState(false);
  const tabLocationNames = {
    requests: 'requests',
    approved: 'approved',
    suspended: 'suspended',
    denied: 'denied',
  };
  let tabIndex = 0;
  if (props.location) {
    let tabLocation = queryString.parse(props.location.search);
    if (tabLocation) {
      tabLocation = tabLocation.tab;
      if (tabLocation === tabLocationNames.approved) tabIndex = 1;
      else if (tabLocation === tabLocationNames.suspended) tabIndex = 2;
      else if (tabLocation === tabLocationNames.denied) tabIndex = 3;
    }
  }
  props.organizationStore.getOrganizations();
  return props.organizationStore.organizationsLoading ? (
    <Loader />
  ) : (
    <div className='admin-account organization-list'>
      <h1>Organizations</h1>
      <p>Remember:</p>
      <ul>
        <li>
          Players can link their accounts <b>only</b> to organization accounts
          listed in the "Approved Accounts" tab.
        </li>
        <li>
          Organizations will receive automatic emails for every action you take
          on this page, with two exceptions. They will not receive an email when
          you select "Delete" from the "Requests for Accounts" or the "Denied
          Accounts" tab.
        </li>
      </ul>
      <div className='grid-row margin-bottom-2'>
        <PrintButton printText='Print' />
      </div>
      <OrganizationToPdfButton orgStore={props.organizationStore} />
      {/* Remove alert after need for XYZ Tester training org is no longer needed */}
      {/* <div className="usa-alert alert-red">
          <p>DO NOT Delete or Suspend the XYZ Testers Bank organization in the Approved Accounts tab.</p>
        </div> */}
      <CollapseAllSwitch
        checked={allOpen}
        onChange={() => setAllOpen(!allOpen)}
      />
      <Tabs defaultIndex={tabIndex}>
        <TabList>
          <Tab className='react-tabs__tab applications-tab'>
            <h3 className='color-cyan applications-header'>
              Requests for Accounts (
              {props.organizationStore.applications.length})
            </h3>
          </Tab>
          <Tab className='react-tabs__tab approved-tab'>
            <h3 className='color-primary organizations-header'>
              Approved Accounts (
              {props.organizationStore.approvedOrganizations.length})
            </h3>
          </Tab>
          <Tab className='react-tabs__tab suspended-tab'>
            <h3 className='color-dark-tan suspended-organizations-header'>
              Suspended Accounts (
              {props.organizationStore.suspendedOrganizations.length})
            </h3>
          </Tab>
          <Tab className='react-tabs__tab denied-tab'>
            <h3 className='color-dark-grey denied-organizations-header'>
              Denied Accounts (
              {props.organizationStore.deniedOrganizations.length})
            </h3>
          </Tab>
        </TabList>
        <TabPanel>
          {props.organizationStore.applications.map((organization, i) => (
            <Accordion key={`accordion-row-${i}-${organization.name}`}>
              <OrganizationRow
                organization={organization}
                organizationStore={props.organizationStore}
                index={i}
                history={props.history}
                tab={tabLocationNames.requests}
                forceOpen={allOpen}
              />
            </Accordion>
          ))}
        </TabPanel>
        <TabPanel>
          {/* <h1>Test</h1> */}
          {props.organizationStore.approvedOrganizations.map(
            (organization, i) => (
              <Accordion key={`accordion-row-${i}-${organization.name}`}>
                <OrganizationRow
                  organization={organization}
                  organizationStore={props.organizationStore}
                  index={i}
                  history={props.history}
                  tab={tabLocationNames.approved}
                  forceOpen={allOpen}
                />
              </Accordion>
            )
          )}
        </TabPanel>
        <TabPanel>
          {props.organizationStore.suspendedOrganizations.map(
            (organization, i) => (
              <Accordion key={`accordion-row-${i}-${organization.name}`}>
                <OrganizationRow
                  organization={organization}
                  organizationStore={props.organizationStore}
                  index={i}
                  history={props.history}
                  tab={tabLocationNames.suspended}
                  forceOpen={allOpen}
                />
              </Accordion>
            )
          )}
        </TabPanel>
        <TabPanel>
          {props.organizationStore.deniedOrganizations.map(
            (organization, i) => (
              <Accordion key={`accordion-row-${i}-${organization.name}`}>
                <OrganizationRow
                  organization={organization}
                  organizationStore={props.organizationStore}
                  index={i}
                  history={props.history}
                  tab={tabLocationNames.denied}
                  forceOpen={allOpen}
                />
              </Accordion>
            )
          )}
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default observer(OrganizationList);
