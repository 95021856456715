import React from "react";
import {Formik, Form} from "formik";
import Inputs, {ExpandableTextarea} from "../utilities/Inputs";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation} from "react-i18next";

const GetHelpPayingMortgage = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  return (
    <div>
      <Formik>
        {(formikProps) => (
          <div>
            <Form>
              <EditableContent
                htmlType="div"
                initialContent={
                  i18n.language === "es"
                    ? tool.contentsEs || tool.contents
                    : tool.contents
                }
                fieldName={i18n.language === "es" ? "contents_es" : "contents"}
                updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
                canEdit={canEdit}
                richText
              />
              <div className="grid-col-8 print-full-width">
                <h4>{t('Contact your lender.')}</h4>
                {Inputs.input("lenderName", t('My lender’s name:'), null, true)}
                {Inputs.input("lenderEmail", t('Email:'), null, true)}
                {Inputs.input("lenderPhone", t('Phone:'), null, true)}
                {Inputs.input(
                  "dateContacted",
                  t('Date Contacted:'),
                  "date",
                  true
                )}
                {Inputs.input("whoSpoken", t('Who I spoke to:'), null, true)}
                <br />
                <ExpandableTextarea
                  name="recomendations"
                  question={t('Recommendations from my lender:')}
                />
              </div>
              <h4 className="page-break-before-always">
                {t('Contact the home preservation hotline.')}
              </h4>
              <p>{t('You can call them 24 hours a day at 1-888-995-HOPE')}&trade;{t(' (4673) or visit the ')}<a href="http://www.995hope.org/">{t('Homeownership Preservation Foundation')}</a>
                {t(' (995hope.org).')}
              </p>
              <div className="grid-col-8 print-full-width">
                {Inputs.input(
                  "homePreservationDateContacted",
                  t('Date Contacted:'),
                  "date",
                  true
                )}
                {Inputs.input(
                  "homePreservationWhoSpoken",
                  t('Who I spoke to:'),
                  null,
                  true
                )}
                <br />
                <ExpandableTextarea
                  name="homePreservationRecomendations"
                  question={t('Recommendations from the hotline:')}
                />
              </div>
              <h4 className="page-break-before-always">{t('Contact a housing counseling agency approved by the Department of Housing and Urban Development (HUD).')}
              </h4>
              <p>
                {t('These professionals are trained to help people manage payment problems and identify resources and solutions if they exist. ')}{t('To find an agency, go to ')}<a href="https://apps.hud.gov/offices/hsg/sfh/hcc/hcs.cfm">{t('HUD Approved Housing Counseling Agencies')}</a>{t(' (hud.gov) or call 1-800-569-4287.')}</p>
              <div className="grid-col-8 print-full-width">
                {Inputs.input(
                  "counselorName",
                  t('My housing counselor’s name:'),
                  null,
                  true
                )}
                {Inputs.input("counselorEmail", t('Email:'), null, true)}
                {Inputs.input("counselorPhone", t('Phone:'), null, true)}
                {Inputs.input(
                  "counselorDateContacted",
                  t('Date Contacted:'),
                  "date",
                  true
                )}
                {Inputs.input(
                  "counselorWhoSpoken",
                  t('Who I spoke to:'),
                  null,
                  true
                )}
                <br />
                <ExpandableTextarea
                  name="counselorRecomendations"
                  question={t('Recommendations from my housing counselor:')}
                />
              </div>
              <h4 className="page-break-before-always">{t('Contact your state housing finance agency.')}</h4>
              <p>{t('They will be able to point you to state resources. You can visit FDICs web site to get ')}<a href="http://www.fdic.gov/consumers/community/mortgagelending/map.html">{t('State Links for Housing Finance Agencies')}</a> {t('(fdic.gov).')}
              </p>
              <div className="grid-col-8 print-full-width">
                {Inputs.input(
                  "housingFinanceAgentName",
                  t('My state housing finance agency contact’s name:'),
                  null,
                  true
                )}
                {Inputs.input(
                  "housingFinanceAgentEmail",
                  t('Email:'),
                  null,
                  true
                )}
                {Inputs.input(
                  "housingFinanceAgentPhone",
                  t('Phone:'),
                  null,
                  true
                )}
                {Inputs.input(
                  "housingFinanceAgentDateContacted",
                  t('Date Contacted:'),
                  "date",
                  true
                )}
                {Inputs.input(
                  "housingFinanceAgentWhoSpoken",
                  t('Who I spoke to:'),
                  null,
                  true
                )}
                <br />
                <ExpandableTextarea
                  name="housingFinanceAgentRecomendations"
                  question={t('Recommendations from my housing finance agency:')}
                />
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default GetHelpPayingMortgage;
