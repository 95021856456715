import React from 'react';
import {FieldArray, Field} from 'formik';
import Inputs, {ExpandableTextarea} from './Inputs';
import {Translation} from "react-i18next";

const inputTypesWithoutAddButton = ['label', 'labelHTML', 'labelNoBold', 'labelNoBoldHTML', 'questionlabel', 'questionlabelHTML', 'questionLabelArrayFirstLineBold', 'checkboxes', 'calculated', 'labelWithTextArea'];

const inputTypesToForceRenderRow = ['textWithLabel', 'textAreaWithLabel', 'textAreaWithLabelHTML', 'textAreaWithLabelArray', 'textareaWithQuestionLabel', 'textareaWithQuestionLabelTrans'];

class Tables {
  static renderTableSections = (sections, formikProps, renderTableSection, sectionIdentifier = 'sections', hideSectionLabel, emptyItem, verticalAlignBottom, hideBodyBorders, t, i18n) => (
    Object.keys(sections).map((key) => (
      renderTableSection(formikProps, key, sectionIdentifier, sections, hideSectionLabel, emptyItem, verticalAlignBottom, hideBodyBorders, t, i18n)
    ))
  );

  static renderTableHeader = (headers) => (
    <thead>
      <tr>
        { headers.map((header, index) => (
          <th key={`header-${header}-${index}`} scope="col">{ header }</th>
        ))}
      </tr>
    </thead>
  );

  static frequencies = {
    "Every month": (input) => input * 1,
    "Once per year": (input) => input / 12,
    "Twice per year": (input) => input / 6,
    "Four times per year": (input) => input / 3,
    "Twice per month": (input) => input * 2,
    "Every two weeks": (input) => (input * 26) / 12,
    "Every week": (input) => (input * 52) / 12,
  };

  static renderSimpleTable = (headers, section, formikProps, sectionIdentifier, 
    hideSectionLabel = false, 
    emptyItem = null, 
    renderTableSection = this.renderSimpleTableSection, 
    verticalAlignBottom = false,
    hideBodyBorders = false,
    tableClasses = '',
    tableTitle = null) => (
    <Translation>
      {(t, {i18n}) => { 
        const translatedHeaders = headers.filter(h => h !== '').map(h => t(h)).join(', ');
        const translatedTablePrefix = tableTitle !== null ? t(tableTitle) : t('Interactive Table');
        const translatedTableTitle = `${translatedTablePrefix}: ${translatedHeaders}`;
        return (
          <div className="overflow-x-scroll">
            <table className={`usa-table ${tableClasses}`} title={translatedTableTitle}>
              { this.renderTableHeader(headers) }
              { this.renderTableSections(section, formikProps, renderTableSection, sectionIdentifier, hideSectionLabel, emptyItem, verticalAlignBottom, hideBodyBorders, t, i18n) }
            </table>
          </div>
        );
      }}
    </Translation>
              
  );

  static removeRowButton = (onClick) => (
    <Translation>
      {(t) => {
        return (
          <div>
            <button
              title={t('Remove item')}
              type="button"
              className="remove-row-button usa-button float-left mobile-full-width"
              onClick={onClick}
            >
              -
            </button>
          </div>
        );
      }}
    </Translation>
  );
  
  static addRowButton = (onClick) => (
    <Translation>
      {(t) => {
        return (
          <div>
            <button
              title={t('Add item')}
              type="button"
              className="add-row-button usa-button float-left mobile-full-width"
              onClick={onClick}
            >
              +
            </button>
          </div>
        );
      }}
    </Translation>
  );
  static renderSimpleTableSection = (formikProps, key, sectionIdentifier, sections, hideSectionLabel, emptyItem, verticalAlignBottom, hideBodyBorders, t, i18n) => {
    return (
      <FieldArray
        key={`${sectionIdentifier}.${key}`}
        name={`${sectionIdentifier}.${key}`}
        render={arrayHelpers => (
          <tbody className={hideBodyBorders ? 'no-bottom-border' : ''}>
            { !hideSectionLabel && 
            <tr>
              <td><b>{key}</b></td>
              {new Array(Object.keys(formikProps.values[sectionIdentifier][key][0]).length - 1).fill(0).map((col, index) => <td key={`empty-head-${col}-${index}`}></td>)}
            </tr>
            }
            {formikProps.values[sectionIdentifier][key].map((item, index) => (
              this.renderTableRow(arrayHelpers, index, formikProps, key, sectionIdentifier, sections, emptyItem, verticalAlignBottom, false, t, i18n)
            ))}
          </tbody>
        )}
      />
    );};
  /**
   * Renders a row of a table
   * @param {Object} arrayHelpers - formikProps array helpers. See: https://jaredpalmer.com/formik/docs/api/fieldarray
   * @param {integer} index - index of the current row being rendered
   * @param {Object} formikProps - formikProps. See: https://jaredpalmer.com/formik/docs
   * @param {string} key - unique key to indentify the row. Used in sub components of the row to create a unique key for each subcomponent
   * @param {string} sectionIdentifier - identifies the current section of the formik values. formikProps.values[sectionIdentifier]
   * @param {Array} sections - array of initial values passed to be rendered. Can be an array of one length if only one table section
   * @param {function} emptyItem - function that returns an object of an empty row of data. Set in the parent component and used to render a new row
   * @param {boolean} verticalAlignBottom - determines whether or not to vertically align the cell contents on the bottom of the cell
   * @param {boolean} hideBodyBorders - determines whether or not to hide the borders of the table
   */
  static renderTableRow = (arrayHelpers, index, formikProps, key, sectionIdentifier, sections, emptyItem, verticalAlignBottom, hideBodyBorders, t, i18n) => {
    const rowObject = formikProps.values[sectionIdentifier][key][index];
    return(
      <tr id={`${key}-${index}`} key={`row-${key}-${sectionIdentifier}-${index}`} className={verticalAlignBottom ? 'vertical-align-bottom' : 'vertical-align-top'}>
        { 
          Object.keys(rowObject).map((column, colIndex) => (
            rowObject[column].inputType !== 'none' && (
              colIndex === Object.keys(rowObject).length - 1 ? ( 
                <td key={`item-${key}-${sectionIdentifier}-${index}`} className={`col-${rowObject[column].columnWidth || 4} ${rowObject[column].columnClass} item-column`}>
                  <div className="grid-row align-center">
                    {
                      index < sections[key].length - 1 && !inputTypesWithoutAddButton.includes(rowObject.item.inputType) && !inputTypesToForceRenderRow.includes(rowObject.item.inputType) ? 
                        <div className="grid-col-11 padding-right-105"><span title={`${rowObject.item.value}`}>{rowObject.item.value}</span></div> :
                        this.renderInput(key, index, sectionIdentifier, column, rowObject[column], formikProps, t, i18n)}
                    <div className="grid-col-1 mobile-full-width">
                      {
                        rowObject.item.allowRemoval &&
                      this.removeRowButton(() => 
                        arrayHelpers.remove(index)
                      )
                      }
                      { 
                        index === formikProps.values[sectionIdentifier][key].length - 1 && 
                      !inputTypesWithoutAddButton.includes(rowObject.item.inputType) && 
                        this.addRowButton(() =>
                          arrayHelpers.insert(
                            formikProps.values[sectionIdentifier][key].length, 
                            emptyItem('', rowObject.item.label, rowObject.item.increment + 1)
                          )
                        )
                      }
                    </div>
                  </div>
                </td>
              ) : 
                colIndex === 0 && !rowObject[column]['noRowHeader'] ? 
                  <th scope="row" key={`col-${key}-${sectionIdentifier}-${index}-${colIndex}`} className={`col-${rowObject[column].columnWidth || 2} ${rowObject[column].columnClass}`} colSpan={rowObject[column].columnSpan || 1}>
                    {this.renderInput(key, index, sectionIdentifier, column, rowObject[column], formikProps, t, i18n)}
                  </th>
                  :
                  <td key={`col-${key}-${sectionIdentifier}-${index}-${colIndex}`} className={`col-${rowObject[column].columnWidth || 2} ${rowObject[column].columnClass}`} colSpan={rowObject[column].columnSpan || 1}>
                    {this.renderInput(key, index, sectionIdentifier, column, rowObject[column], formikProps, t, i18n)}
                  </td>
            ))
          )
        }
      </tr>
    );
  };
  static renderInput = (key, index, sectionIdentifier, itemIdentifier, item, formikProps, t, i18n) => {
    let input;
    let itemObject = formikProps.values[sectionIdentifier][key][index][itemIdentifier];
    let preTitle = itemObject.title || formikProps.values[sectionIdentifier][key][index].item.value;
    if (Array.isArray(preTitle)) { 
      preTitle = preTitle[0];
    } else if (itemObject.disabled) {
      preTitle = '';
    }
    let title = `${t(preTitle)} ${t(itemObject.columnName) || t(itemIdentifier)}`;
    let name = `${sectionIdentifier}.${key}.${index}.${itemIdentifier}.value`;
    switch(item.inputType) {
    case('money'): {
      input = <div><span className="money-input"><Field className="leadingicon-input" title={title} id={`${itemIdentifier}-${key}-${index}`} placeholder="0" step=".01" type="number" name={name} disabled={itemObject.disabled} /></span></div>;
      break;
    }
    case('moneyTrans'): {
      input = <div><span className="money-input"><Field className="leadingicon-input" title={title} id={`${itemIdentifier}-${key}-${index}`} placeholder="0" step=".01" type="number" name={name} disabled={itemObject.disabled} /></span></div>;
      break;
    }

    case('moneyNarrow'): {
      input = <div style={{maxWidth:"200px"}}><span className="money-input"><Field className="leadingicon-input" title={title} id={`${itemIdentifier}-${key}-${index}`} placeholder="0" step=".01" type="number" name={name} disabled={itemObject.disabled} /></span></div>;
      break;
    }
    case('integer'): {
      input = <div><Field title={title} id={`${itemIdentifier}-${key}-${index}`} placeholder="0" step="1" type="number" name={name} /></div>;
      break;
    }
    case('percentage'): {
      input = <div><span className="percentage-input"><Field className="leadingicon-input" title={title} id={`${itemIdentifier}-${key}-${index}`} placeholder="0" step=".01" type="number" name={name} /></span></div>;
      break;
    }
    case('select'): {
      input = 
        <select
          title={title}
          name={name}
          value={itemObject.value}
          onChange={formikProps.handleChange}
          onBlur={formikProps.handleBlur}
          disabled={itemObject.disabled}
        >
          {
            itemObject.options.map((option) => (
              <option key={`select-${key}-${index}-${option}`} value={option}>{t(option)}</option>
            ))
          }
        </select>;
      break;
    }
    case('yesNo'): {
      input = 
        <select
          title={title}
          name={name}
          value={itemObject.value || "Please select"}
          onChange={formikProps.handleChange}
          onBlur={formikProps.handleBlur}
          disabled={itemObject.disabled}
        >
          <option value="Please select" disabled>{t('Please select')}</option>
          {
            [t('Yes'), t('No')].map((option) => (
              <option key={`select-${key}-${index}-${option}`} value={option}>{option}</option>
            ))
          }
        </select>;
      break;
    }
    case('yesNoRadio'): {
      let options = ['Yes', 'No'];
      input = Inputs.radioButtonGroup({
        id: name,
        label: itemObject.label,
        value: itemObject.value,
        options: options,
        stackbuttons: true,
        disabled: itemObject.disabled
      });
      break;
    }
    case('yesNoExplain'): {
      input = 
        <div>
          <select
            className="margin-bottom-2"
            title={title}
            name={name}
            value={itemObject.value || "Please select"}
            onChange={formikProps.handleChange}
            onBlur={formikProps.handleBlur}
            disabled={itemObject.disabled}
          >
            <option value="Please select" disabled>{t('Please select')}</option>
            {
              [t('Yes'), t('No')].map((option) => (
                <option key={`select-${key}-${index}-${option}`} value={option}>{option}</option>
              ))
            }
          </select>
          {
            (itemObject.value === 'Sí' || itemObject.value === 'Yes') &&
              <ExpandableTextarea 
                inputOnly={true}
                title={`${title} explain`} 
                id={`${itemIdentifier}-${key}-${index}`} 
                type={item.inputType} name={`${sectionIdentifier}.${key}.${index}.${itemIdentifier}.explain`} 
                maxLength={item.maxLength || '50'}
                component='textarea'
                disabled={itemObject.disabled}
                rows={2}
              />
          }
        </div>;
      break;
    }
    case('checkboxes'): {
      input = 
        itemObject.options.map((item, checkIndex) => {
          return (
            <div key={`checkbox-${item}-${checkIndex}`} className="usa-fieldset margin-bottom-1">
              <div className="fdic-checkbox">
                <Field id={item} title={`${t(title)} ${t(item)}`} className="margin-right-1" type="checkbox" name={`${sectionIdentifier}.${key}.${index}.${itemIdentifier}.${item}.value`} />
                <label className="" htmlFor={item}>{t(item)}
                  {
                    item === 'Other' && 
                    <Field title={`${title} ${item}`} 
                      className="margin-left-1" 
                      name={`${sectionIdentifier}.${key}.${index}.${itemIdentifier}.${item}.value`}
                      value={itemObject.other}/>
                  }
                </label>
              </div>
            </div>
          );
        }
        );
      break;
    }
    case('radioButtonGroup'): {
      input = Inputs.radioButtonGroup({
        id: name,
        label: itemObject.label,
        value: itemObject.value,
        options: itemObject.options,
        stackbuttons: itemObject.stackbuttons,
        includeOther: itemObject.includeOther,
        formikProps: formikProps
      });
      break;
    }
    
    case('textarea'): {
      input = 
        <div>
          {itemObject.label && <label htmlFor={`${itemIdentifier}-${key}-${index}`}><i>{itemObject.label}</i></label>}
          <ExpandableTextarea inputOnly={true} rows={itemObject.rows} title={title} id={`${itemIdentifier}-${key}-${index}`} name={name} disabled={itemObject.disabled} disabledText={itemObject.disabledText}/>
        </div>;
      break;
    }
    case('textareaTrans'): {
      input = 
        <div>
          {itemObject.label && <label htmlFor={`${itemIdentifier}-${key}-${index}`}><i>{itemObject.label}</i></label>}
          <ExpandableTextarea inputOnly={true} rows={itemObject.rows} title={t(title)} id={`${itemIdentifier}-${key}-${index}`} name={name} disabled={itemObject.disabled} disabledText={t(itemObject.disabledText)}/>
        </div>;
      break;
    }

    case('textareaAlignBottom'): {
      input = 
        <div className="margin-top-3">
          {itemObject.label && <label htmlFor={`${itemIdentifier}-${key}-${index}`}><i>{itemObject.label}</i></label>}
          <ExpandableTextarea inputOnly={true} rows={itemObject.rows} title={title} id={`${itemIdentifier}-${key}-${index}`} name={name} disabled={itemObject.disabled} />
        </div>;
      break;
    }
    case('textareaWithQuestionLabel'): {
      input = 
        <div>
          {<label htmlFor={`${itemIdentifier}-${key}-${index}`}>{itemObject.label}</label>}
          <ExpandableTextarea inputOnly={true} rows={itemObject.rows} title={title} id={`${itemIdentifier}-${key}-${index}`} name={name} disabled={itemObject.disabled} />
        </div>;
      break;
    }
    case('textareaWithQuestionLabelTrans'): {
      input = 
        <div>
          {<label htmlFor={`${itemIdentifier}-${key}-${index}`}>{itemObject.label}</label>}
          <ExpandableTextarea inputOnly={true} rows={itemObject.rows} title={title} id={`${itemIdentifier}-${key}-${index}`} name={name} disabled={itemObject.disabled} />
        </div>;
      break;
    }

    case('calculated'): {
      input = <span>{itemObject.calculation(formikProps, sectionIdentifier, key, index, t, i18n)}</span>;
      break;
    }
    case('calculatedMoney'): {
      input = <span className="money-input">{itemObject.calculation(formikProps, sectionIdentifier, key, index, t, i18n)}</span>;
      break;
    }
    case('label'): {
      input = <b>{t(itemObject.value)}</b>;
      break;
    }
    case('labelHTML'): {
      input = <b>{itemObject.value}</b>;
      break;
    }
    case('labelNoBold'): {
      input = <span>{t(itemObject.value)}</span>;
      break;
    }
    case('labelNoBoldHTML'): {
      input = <span>{itemObject.value}</span>;
      break;
    }
    case('questionlabel'): {
      input = <span>{t(itemObject.value)}</span>;
      break;
    }
    case('questionlabelHTML'): {
      input = <span>{itemObject.value}</span>;
      break;
    }
    case('questionLabelArrayFirstLineBold'): {
      input = 
        <span>
          {itemObject.value.map((v, i) => {
            if (i === 0) return <span key={i}><b>{t(v)}</b></span>;
            return <span key={i}><br />{t(v)}</span>;
          })}
        </span>;
      break;
    }
    case('labelWithAdd'): {
      input = <b tabIndex={0}>{itemObject.label}</b>;
      break;
    }
    case('labelWithAddTrans'): {
      input = <b tabIndex={0}>{t(itemObject.label)}</b>;
      break;
    }
    case('labelWithTextArea'): {
      input = <div>
        <div className="grid-row">
          <b>{itemObject.value}</b>
        </div>
        <div className="grid-row">
          <ExpandableTextarea inputOnly={true} rows={itemObject.rows} title={title} id={`${itemIdentifier}-${key}-${index}`} name={name} disabled={itemObject.disabled} />
        </div>
      </div>;
      break;
    }
    case('textWithLabel'): {
      input = <div>
        <div className="grid-row">
          <label htmlFor={name}>
            {t(itemObject.label)}
          </label>
        </div>
        <div className="grid-row">
          <Field title={t(title)} 
            id={`${itemIdentifier}-${key}-${index}`} 
            type={item.inputType} name={name} 
            disabled={itemObject.disabled}
          />
        </div>
      </div>;
      break;
    }
    case('textAreaWithLabel'): {
      input = <div>
        <div className="grid-row">
          <label htmlFor={name}>
            {t(itemObject.label)}
          </label>
        </div>
        <div className="grid-row">
          <ExpandableTextarea inputOnly={true} rows={itemObject.rows} title={title} id={`${itemIdentifier}-${key}-${index}`} name={name} disabled={itemObject.disabled} />
        </div>
      </div>;
      break;
    }
    case('textAreaWithLabelHTML'): {
      input = <div>
        <div className={`grid-row${itemObject.bold ? ' text-bold' : ''}`}>
          <label htmlFor={name}>
            {t(itemObject.label)}
          </label>
        </div>
        <div className="grid-row">
          <ExpandableTextarea inputOnly={true} rows={itemObject.rows} title={title} id={`${itemIdentifier}-${key}-${index}`} name={name} disabled={itemObject.disabled} />
        </div>
      </div>;
      break;
    }
    case('textAreaWithLabelArray'): {
      input = <div>
        <div className={`grid-row margin-bottom-1${itemObject.bold ? ' text-bold' : ''}`}>
          <label htmlFor={name}>
            {itemObject.label.map((l, i) => <p key={i} className='margin-0'>{t(l)}</p>)}
          </label>
        </div>
        <div className="grid-row">
          <ExpandableTextarea inputOnly={true} rows={itemObject.rows} title={title} id={`${itemIdentifier}-${key}-${index}`} name={name} disabled={itemObject.disabled} />
        </div>
      </div>;
      break;
    }

    case('incrementingLabel'): {
      input = <b>{`${itemObject.label} ${itemObject.increment}`}</b>;
      break;
    }
    case('lightIncrementingLabel'): {
      input = <span>{`${t(itemObject.label)} ${itemObject.increment}`}</span>;
      break;
    }
    case('contact'): {
      input = <div>
        <Field title={`${title} name`} id={`${itemIdentifier}-${key}-${index}`} placeholder="Name" type={item.inputType} name={`${name}.name`} />
        <Field title={`${title} company`} id={`${itemIdentifier}-${key}-${index}`} placeholder="Company" type={item.inputType} name={`${name}.company`} />
        <Field title={`${title} phone`} id={`${itemIdentifier}-${key}-${index}`} placeholder="Phone" type={item.inputType} name={`${name}.phone`} />
        <Field title={`${title} email`} id={`${itemIdentifier}-${key}-${index}`} placeholder="Email" type={item.inputType} name={`${name}.email`} />
        <Field title={`${title} address`} id={`${itemIdentifier}-${key}-${index}`} placeholder="Address" type={item.inputType} name={`${name}.address`} />
      </div>;
      break;
    }
    default: {
      input = <Field 
        title={title} 
        id={`${itemIdentifier}-${key}-${index}`} 
        type={item.inputType} name={name} 
        maxLength={item.maxLength || '50'}
        disabled={itemObject.disabled}
      />;
      break;
    }
    }
    return(
      <div 
        className={`${Object.keys(formikProps.values[sectionIdentifier][key][index]).indexOf(itemIdentifier) === Object.keys(formikProps.values[sectionIdentifier][key][index]).length -1 ? 'grid-col-11' : ''} padding-right-105`}
      >
        {input}
      </div>);
  };
};

export default Tables;