import React from 'react';
import moment from 'moment';

import OrganizationPDF from './OrganizationPDF';

const OrganizationToPdfButton = ({orgStore, topMargin = 0, bottomMargin = 0}) => {
  const mt = `margin-top-${topMargin}`;
  const mb = `margin-bottom-${bottomMargin}`;
  return (
    <button
      className={`usa-button display-none ${mt} ${mb}`}
      onClick={() => OrganizationPDF(orgStore, moment)}
    >
      Download Organization Report
    </button>
  );
};

export default OrganizationToPdfButton;
