import React from 'react';
import {observer} from 'mobx-react';
import moment from 'moment';
import queryString from 'query-string';
import {Loader} from '../../../../components/Loader';
import {SURVEY_QUESTION_1, SURVEY_QUESTION_2} from '../../../../stores/SurveyStore';
import SurveyPieChart from './SurveyPieChart';
import {Accordion, AccordionRowV2} from '../../../../components/Accordion';
import DateFilter from '../../../../components/DateFilter/DateFilter';
import PrintButton from '../../../../components/Buttons/PrintButton';
import {getOrganizationFromOptions} from '../../../../services/OrganizationUtilities';
import {applyFilter, getOrganizationOptions} from '../../../../containers/account/organization/OrganizationReport';
import config from '../../../../config';

const renderResults = (results, gamesStore) => {
  return Object.keys(results).map((gameId) => (
    <AccordionRowV2
      key={gameId}
      title={
        <div className='grid-row'>
          <div className='grid-col-6'>{gamesStore.getGame(gameId).name}</div>
          <div className='grid-col-6'>
            {results[gameId][SURVEY_QUESTION_1] && `Responses: ${results[gameId].gameTotal}`}
          </div>
        </div>
      }
      content={
        results[gameId][SURVEY_QUESTION_1] && results[gameId][SURVEY_QUESTION_2] ? (
          <div className='grid-row'>
            <div className='grid-col-6'>
              <h4 className='margin-bottom-0'>{SURVEY_QUESTION_1}</h4>
              <h5 className='margin-top-105'>Total responses: {results[gameId][SURVEY_QUESTION_1].questionTotal}</h5>
              {results[gameId][SURVEY_QUESTION_1] && <SurveyPieChart data={results[gameId][SURVEY_QUESTION_1]} />}
            </div>
            <div className='grid-col-6'>
              <h4 className='margin-bottom-0'>{SURVEY_QUESTION_2}</h4>
              <h5 className='margin-top-105'>Total responses: {results[gameId][SURVEY_QUESTION_2].questionTotal}</h5>
              {results[gameId][SURVEY_QUESTION_2] && <SurveyPieChart data={results[gameId][SURVEY_QUESTION_2]} />}
            </div>
          </div>
        ) : (
          <h5>No responses yet.</h5>
        )
      }
    ></AccordionRowV2>
  ));
};

const applySurveyFilter = (surveyStore, history, startDate, endDate, organizationId) => {
  surveyStore.getSurveyResults(startDate, endDate, true);
  applyFilter(history, startDate, endDate, organizationId);
};

const SurveyDashboard = observer(
  ({surveyStore, gamesStore, organizationStore, authStore, history, hideDateFilter = false}) => {
    // const defaultStartDate = moment("20210930").format('YYYY-MM-DD');
    const defaultStartDate = moment(config.defaultQueryStartDate).format('YYYY-MM-DD');

    let defaultEndDate = moment().format('YYYY-MM-DD');
    defaultEndDate = defaultEndDate < defaultStartDate ? defaultStartDate : defaultEndDate;

    let startDate = queryString.parse(history.location.search).startDate;
    if (!startDate) startDate = defaultStartDate;
    const endDate = queryString.parse(history.location.search).endDate;

    surveyStore.getSurveyResults(startDate || defaultStartDate, endDate || defaultEndDate);
    gamesStore.getGames();

    // const organizationId = queryString.parse(history.location.search).organizationId;
    const organizationOptions = getOrganizationOptions(organizationStore);
    // organizationStore.getOrganizations();
    // organizationStore.getSessions({startDate, endDate, organizationId});

    const orgFilterOptions = {
      display: false,
      isAdmin: authStore.isAdmin,
      organizationId: organizationOptions.id,
      getOrganizationFromOptions: getOrganizationFromOptions,
      organizationOptions,
    };

    const totalResponses = (question) => {
      console.log(question);
      return Object.keys(question).reduce((partialSum, a) => partialSum + question[a], 0);
    };

    return (
      <div className='survey-dashboard mobile-full-width'>
        <h1>Survey Reports</h1>
        <h3 className='no-margin'>Refine Your Reports</h3>
        <p>
          Apply this filter to refine your reports. Select the date range for the reports. When you’re done, select{' '}
          <b>Apply Filter.</b>
        </p>
        <div className='grid-col-10 margin-bottom-2'>
          <p>
            <b>REMEMBER:</b> Public launch was September 30, 2021. To remove test data, be sure to{' '}
            <b>FILTER for Start Date 9/30/2021.</b>
          </p>
        </div>
        {!hideDateFilter && (
          <DateFilter
            startDate={startDate || defaultStartDate}
            endDate={endDate || defaultEndDate}
            applyFilter={({startDate, endDate, organizationId}) =>
              applySurveyFilter(surveyStore, history, startDate, endDate, organizationId)
            }
            organizationStore={organizationStore}
            orgFilterOptions={orgFilterOptions}
            history={history}
          />
        )}
        <br />
        <PrintButton printText='Print' />
        {surveyStore.surveyResultsLoading || gamesStore.gamesLoading ? (
          <Loader />
        ) : (
          <div className='margin-top-3'>
            <h3 className='margin-bottom-0'>Overview</h3>
            <p className='margin-top-1 margin-bottom-3'>
              <strong>
                Note: Survey questions appear at the end of each game after a player has successfully completed (won)
                the game. These reports include both players with an account and without an account who choose to answer
                the survey question(s). Players may have answered these questions for the same game more than once, but
                only if they won that game more than once.
              </strong>
            </p>
            <div className='page-break-inside-avoid background-color-light-grey padding-left-3 padding-top-2 padding-right-3 padding-bottom-3 margin-bottom-8 grid-col-12'>
              <div className='grid-row'>
                <div className='grid-col-6'>
                  <h4 className='margin-bottom-0'>{SURVEY_QUESTION_1}</h4>
                  <h5 className='margin-top-105'>
                    Total responses:{' '}
                    {surveyStore.surveyResults.overall_responses[SURVEY_QUESTION_1]
                      ? totalResponses(surveyStore.surveyResults.overall_responses[SURVEY_QUESTION_1])
                      : '0'}
                  </h5>
                  <SurveyPieChart data={surveyStore.surveyResults.overall_responses[SURVEY_QUESTION_1]} />
                </div>
                <div className='grid-col-6'>
                  <h4 className='margin-bottom-0'>{SURVEY_QUESTION_2}</h4>
                  <h5 className='margin-top-105'>
                    Total responses:{' '}
                    {surveyStore.surveyResults.overall_responses[SURVEY_QUESTION_2]
                      ? totalResponses(surveyStore.surveyResults.overall_responses[SURVEY_QUESTION_2])
                      : '0'}
                  </h5>
                  <SurveyPieChart data={surveyStore.surveyResults.overall_responses[SURVEY_QUESTION_2]} />
                </div>
              </div>
            </div>
            <p>Select a game to view the data. The current date filter will be applied until it is changed.</p>
            <h3>Results by Game</h3>
            <Accordion>{renderResults(surveyStore.surveyResults.grouped_by_game_responses, gamesStore)}</Accordion>
          </div>
        )}
      </div>
    );
  },
);

export default SurveyDashboard;
