import React, {useState} from 'react';
import {Formik} from 'formik';
import {observer} from 'mobx-react';
import * as Yup from 'yup';
import Filter from 'bad-words';
import {Link, Redirect} from 'react-router-dom';

import RequestService from '../../services/RequestService';
import config from '../../config';
import {useTranslation} from 'react-i18next';
import FormInput from '../../components/Formik/FormInput';

const filter = new Filter();

const editAccountSchema = Yup.object().shape({
  editAccountEmail: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .email('Must enter a valid email address.')
    .min(2, 'Email must be at least two characters.')
    .max(50, 'Email must be at most 50 characters.'),
  editFirstName: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .min(2, 'First name must be at least two characters.')
    .max(50, 'First name must be at most 50 characters.'),
  editLastName: Yup.string()
    .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
    .min(2, 'Last name must be at least two characters.')
    .max(50, 'Last name must be at most 50 characters.'),
});

const editAccount = (values, handleErrorResponse, setRedirect, authStore) => {
  RequestService.post(
    `${config.apiGateway.URL}/update-account`,
    {email: values.editAccountEmail, first_name: values.editFirstName, last_name: values.editLastName},
    (res) => {
      authStore.updateAccount({
        email: values.editAccountEmail,
        firstName: values.editFirstName,
        lastName: values.editLastName,
      });
      setRedirect(true);
    },
    (error) => {
      handleErrorResponse(error.response);
    },
  );
};

const EditAccountForm = observer(({formikProps, hasError, errorMessage}) => {
  const {t} = useTranslation();
  const updateText = t('Update Profile');
  const cancelText = t('Cancel');
  return (
    <div>
      <form className='reg-form padding-top-2 padding-bottom-4'>
        <fieldset>
          <div className='grid-container text-align-center'>
            <h1>{updateText}</h1>
            {hasError && (
              <div className='grid-row usa-alert usa-alert--warning margin-bottom-3'>
                <div className='usa-alert__body'>
                  <p className='usa-alert__text'>{errorMessage}</p>
                </div>
              </div>
            )}
            <div className='grid-row margin-bottom-2'>
              <div className='grid-col-6 text-align-left padding-right-2 mobile-full-width'>
                <FormInput formikProps={formikProps} type='email' id='editAccountEmail' label={'Email'} />
              </div>
            </div>
            <div className='grid-row margin-bottom-2'>
              <div className='grid-col-6 text-align-left padding-right-2 mobile-full-width'>
                <FormInput formikProps={formikProps} type='text' id='editFirstName' label={'First Name:'} showTooltip />
              </div>
              <div className='grid-col-6 text-align-left mobile-full-width'>
                <FormInput formikProps={formikProps} type='text' id='editLastName' label={'Last Name:'} showTooltip />
              </div>
            </div>
            <div className='reg-form-btn'>
              <button
                type='button'
                id='change-password'
                onClick={(e) => e.preventDefault() || formikProps.handleSubmit()}
                className='usa-button fdic-button tan-button square-button'
              >
                {updateText}
              </button>
            </div>
            <div className='margin-top-2'>
              <Link to='/account'>{cancelText}</Link>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  );
});

const EditAccount = observer((props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [redirect, setRedirect] = useState(false);
  const handleErrorResponse = (response) => {
    setErrorMessage(response.data.message);
  };

  if (redirect) return <Redirect push to='/account' />;

  return (
    <div className='registration-wrapper'>
      <Formik
        initialValues={{
          editAccountEmail: props.authStore.email,
          editFirstName: props.authStore.firstName,
          editLastName: props.authStore.lastName,
        }}
        validationSchema={editAccountSchema}
        onSubmit={(values) => editAccount(values, handleErrorResponse, setRedirect, props.authStore)}
      >
        {(formikProps) => (
          <EditAccountForm
            formikProps={formikProps}
            hasError={errorMessage !== ''}
            errorMessage={errorMessage}
            {...props}
          />
        )}
      </Formik>
    </div>
  );
});

export default EditAccount;
