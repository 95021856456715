import React from 'react';
import {IconButton} from '@material-ui/core';
import EditableContent from '../../../components/EditableContent/EditableContent';
import config from '../../../config';
import {useTranslation} from 'react-i18next';

import learnPartsOfACheck from '../../../assets/images/learnPartsOfACheck.png';
import learnPartsOfACheckEs from '../../../assets/images/learnPartsOfACheck-es.png';

const checkButtons = [
  {
    number: 'A',
    className: 'a',
    text: 'This is the name and address of the person who owns the account. So, if this were your account, your name and address would be here.',
    title: 'Name and address',
  },
  {
    number: 'B',
    className: 'b',
    text: 'This is the date the check was written.',
    title: 'Date',
  },
  {
    number: 'C',
    className: 'c',
    text: 'This is the number of the check. Checks come in series. You may be contacted by your financial institution if you use checks out of order (like using check number 300 before using check number 200).',
    title: 'Check number',
  },
  {
    number: 'D',
    className: 'd',
    text: 'This is the payee—the person or business being paid by the check. That’s the person with the right to cash the check.',
    title: 'Pay to the order of',
  },
  {
    number: 'E',
    className: 'e',
    text: 'This is the amount of the check written using numbers.',
    title: 'Numeric amount box',
  },
  {
    number: 'F',
    className: 'f',
    text: 'This is the amount of the check written using words. It’s best to make sure there is no room to write anything else on this line. Draw a line from the end of your words to the end of the line. This is sometimes called the “legal line.” If the amount of the check written in numbers does not match the amount written in words, financial institutions will generally use the amount written in words.',
    title: 'Written amount',
  },
  {
    number: 'G',
    className: 'g',
    text: 'If this is one of your checks, this is your financial institution’s name and address. If you receive a check from someone, this will be their financial institution’s name and address.',
    title: 'Bank address',
  },
  {
    number: 'H',
    className: 'h',
    text: 'This is the “For” line, also called the “memo” line. You write notes to yourself or the party you are giving the check to. The financial institution doesn’t use the information you write here. For example, you may write your utility company account number on a check you use to pay a utility bill. If you receive this check from someone else, they may have written something on this line.',
    title: '“For” or memo',
  },
  {
    number: 'I',
    className: 'i',
    text: 'This is your signature (or in this example, Maya’s signature).',
    title: 'Signature line',
  },
  {
    number: 'J',
    className: 'j',
    text: 'This is the bank routing number. This isn’t unique to your account. It identifies the financial institution that issued the check. You also need this number to set up direct deposits and automated payments.',
    title: 'Routing number',
  },
  {
    number: 'K',
    className: 'k',
    text: 'This is your account number. This is unique to your account. This tells the financial institution which account the money comes from. You need this number to set up direct deposits and automated payments.',
    title: 'Account number',
  },
  {
    number: 'L',
    className: 'l',
    text: 'This is the check number. Not all checks will have the check number repeated here.',
    title: 'Check number',
  },
];

const LearnPartsOfCheck = ({tool, canEdit}) => {
  const {t, i18n} = useTranslation();
  const isSpanish = i18n.language === 'es';
  const tableH1 = t('Letter');
  const tableH2 = t('Part of check');
  const tableTitle = `${t('Table')}: ${tableH1}, ${tableH2}`;
  return (
    <div id='parts-of-check' className='learn-parts-of-a-check'>
      <EditableContent
        htmlType='div'
        initialContent={isSpanish ? tool.contentsEs || tool.contents : tool.contents}
        fieldName={isSpanish ? 'contents_es' : 'contents'}
        updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
        canEdit={canEdit}
        richText
      />
      <div className='container'>
        <img src={isSpanish ? learnPartsOfACheckEs : learnPartsOfACheck} alt='check' />
        {checkButtons.map(({number, className, text, title}) => (
          <CheckButton key={`button-${number}`} number={number} className={className} text={text} title={title} />
        ))}
      </div>
      <table className='usa-table summary-table' title={tableTitle}>
        <thead>
          <tr>
            <th scope='col'>{tableH1}</th>
            <th scope='col'>{tableH2}</th>
          </tr>
        </thead>
        <tbody>
          {checkButtons.map(({number, className, text}, index) => (
            <tr key={number} id={`${number}-definition`} tabIndex='0'>
              <th scope='row'>{number}</th>
              <td>
                {t(text)}
                <br />
                <span className='hide-print'>
                  <a href={`#${index === checkButtons.length - 1 ? 'parts-of-check' : checkButtons[index + 1].number}`}>
                    {t('Back to top')}
                  </a>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const CheckButton = ({number, className, text, title}) => (
  <IconButton id={number} className={`btn ${className}`} href={`#${number}-definition`} title={title}>
    <b>{number}</b>
  </IconButton>
);

export default LearnPartsOfCheck;
