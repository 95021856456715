async function GlossaryPDF(language, t, glossary) {
  const {default: pdfMake} = await import('pdfmake/build/pdfmake');
  const {default: pdfFonts} = await import('pdfmake/build/vfs_fonts');

  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const div = document.createElement('DIV');

  const glossaryTermTextFormat = (text) => {
    return {text, bold: true, margin: [0, 10, 0, 2], fontSize: 14};
  };

  const textFromHtml = (html) => {
    const textArray = [];
    div.innerHTML = html;
    div.childNodes.forEach((node) => {
      if (node.nodeType === 3) {
        textArray.push(node.textContent);
      } else if (node.nodeName === 'A') {
        textArray.push({
          text: node.textContent,
          link: node.href,
          color: '#005282',
          decoration: 'underline',
        });
      } else if (node.nodeName === 'I') {
        // if has another tag inside, for simplicity, let's assume it's a strong tag
        if (node.children.length > 0) {
          textArray.push({text: node.textContent, italics: true, bold: true});
        } else {
          textArray.push({text: node.textContent, italics: true});
        }
      } else if (node.nodeName === 'STRONG') {
        textArray.push({text: node.textContent, bold: true});
      } else {
        const recurseText = textFromHtml(node.innerHTML);
        if (recurseText.text && recurseText.text.length > 0) {
          textArray.push(...recurseText.text);
        }
      }
    });
    return {text: textArray};
  };

  const dd = {
    info: {
      title: 'money-smart-glossary',
      author: 'FDIC',
      subject: 'Money Smart Glossary',
      creator: 'FDIC',
      producer: 'FDIC',
    },
    pageSize: 'LETTER',
    pageMargins: 50,
    footer: function (currentPage, pageCount) {
      return {
        text: `Page ${currentPage.toString()} of ${pageCount}`,
        margin: [0, 10, 50, 0],
        alignment: 'right',
        color: '#999999',
        fontSize: 12,
      };
    },
  };

  dd.content = [];
  dd.content.push({
    text: [
      'FDIC’s ',
      {text: t('How Money Smart Are You?'), italics: true},
      ' ',
      t('glossary.title'),
    ],
    bold: true,
    fontSize: 20,
    alignment: 'center',
    margin: [0, -20, 0, 10],
    color: '#999999',
  });

  const tableBody = [];

  glossary.forEach((item, i) => {
    const td = [];
    const tdContent = {stack: []};
    if (language === 'es') {
      tdContent.stack.push(glossaryTermTextFormat(item.name_es));
      tdContent.stack.push(textFromHtml(item.details_es));
    } else {
      tdContent.stack.push(glossaryTermTextFormat(item.name));
      tdContent.stack.push(textFromHtml(item.details));
    }
    td.push(tdContent);
    tableBody.push(td);
  });

  dd.content.push({
    layout: 'noBorders',
    table: {
      headerRows: 0,
      body: tableBody,
      dontBreakRows: true,
    },
  });

  pdfMake.tableLayouts = {
    exampleLayout: {
      hLineWidth: function (i, node) {
        return 0;
      },
      vLineWidth: function (i) {
        return 0;
      },
      hLineColor: function (i) {
        return i === 1 ? 'black' : '#aaa';
      },
      paddingLeft: function (i) {
        return 0;
      },
      paddingRight: function (i, node) {
        return 0;
      },
    },
  };

  return pdfMake.createPdf(dd).download(t('glossary.printedPdfDefaultName'));
}

export default GlossaryPDF;
