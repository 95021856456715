import React from 'react';
import moment from 'moment';
import {observer} from 'mobx-react';
import {useHistory} from 'react-router-dom';

import {Loader} from '../../../../components/Loader';

const parseDetails = (details) => {
  return <span>{JSON.stringify(details, null, 2)}</span>;
};

const renderAuditLog = (auditLog, handleBack) => {
  return (
    <div className='container'>
      <div className='grid-row'>
        <div className='grid-col-2'>
          <b>User</b>
        </div>
        <div className='grid-col-9'>{auditLog.user.email}</div>
      </div>
      <div className='grid-row'>
        <div className='grid-col-2'>
          <b>Date</b>
        </div>
        <div className='grid-col-9'>{moment(auditLog.created_at).format('LLLL')}</div>
      </div>
      <div className='grid-row'>
        <div className='grid-col-2'>
          <b>Action</b>
        </div>
        <div className='grid-col-9'>
          {auditLog.action}
          {/* {parseAction(auditLog.action, auditLog.user)} */}
        </div>
      </div>
      <div className='grid-row'>
        <div className='grid-col-2'>
          <b>Details</b>
        </div>
        <div className='grid-col-9'>{parseDetails(auditLog.details)}</div>
      </div>
      <div className='grid-row'>
        <div className='grid-col'>
          <button
            type='button'
            className='hide-print usa-button usa-button--unstyled margin-top-3'
            onClick={handleBack}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

const AuditLog = ({auditLogStore, match}) => {
  const history = useHistory();

  const handleBack = () => {
    history.goBack();
  };

  auditLogStore.getAuditLogs();

  return (
    <div>
      <h1>Audit Log</h1>
      <div>
        {auditLogStore.auditLogsLoading ? (
          <Loader />
        ) : (
          renderAuditLog(auditLogStore.getAuditLogsById(match.params.logId), handleBack)
        )}
      </div>
    </div>
  );
};

export default observer(AuditLog);