import React, {Fragment, useEffect} from 'react';
import {
  PieChart, Pie, Cell, Legend
} from 'recharts';
import {SURVEY_RESPONSES} from '../../../../stores/SurveyStore';

import {reorderDomForRechartAccessibility} from '../../../util';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, percent, index, value
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN) * 2.2;
  const y = cy + radius * Math.sin(-midAngle * RADIAN) * 2.2;

  return (
    percent > 0 ? 
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}% (${displayValue(value)})`}
      </text> : ''
  );
};

const displayValue = (value) => {
  if(value > 999) return `${Math.round(value / 1000)}k`;
  return value;
};

const mapData = (data) => {
  console.log(data);
  if (!data) return null;
  let mappedData = [];
  SURVEY_RESPONSES.forEach((response) => {
    mappedData.push({
      name: response,
      value: data[SURVEY_RESPONSES.indexOf(response)]
    });
  });
  return(
    mappedData
  );
};

const coloredLegendText = (value, entry) => {
  const {payload} = entry;
  const percent = (payload.percent * 100).toFixed(0);
  return <span>{value}: {payload.value} ({percent}%)</span>;
};

const SurveyPieChart = ({data}) => {
  // for accessibility (using dom to do it)
  useEffect(() => {
    reorderDomForRechartAccessibility();
  });
  const d = mapData(data);
  if (!d) return <Fragment></Fragment>;
  return(
    <PieChart width={400} height={400}>
      <Legend 
        verticalAlign="top" 
        height={36}
        formatter={coloredLegendText}
      />
      <Pie
        data={d}
        cx={200}
        cy={200}
        labelLine={false}
        label={renderCustomizedLabel}
        outerRadius={120}
        fill="#8884d8"
        dataKey="value"
      >
        {mapData(data).map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)}
      </Pie>
    </PieChart>
  );
};

export default SurveyPieChart;