import React from 'react';
import {Formik, Form, Field} from 'formik';

const NewGlossaryItem = ({glossaryItemsStore}) => {
  return(
    <Formik
      initialValues={{
        name: '',
        details: ''
      }}
      onSubmit={(values) => {glossaryItemsStore.addGlossaryItem(values.name, values.details); window.location.reload(); }}
    >
      {
        formikProps => 
          <Form>
            <div className="container">
              <div className="grid-row margin-bottom-2">
                <h4 className="margin-top-0 margin-bottom-0 full-width">Add Glossary Item</h4>
                <i>When you add an item, the page will reload and the new item will be inserted alphabetically into the list.</i>
              </div>
              <div className="grid-row margin-bottom-2">
                <div className="grid-col-2 widescreen:grid-col-1">
                  <b>Title: </b>
                </div>
                <div className="grid-col-10 widescreen:grid-col-11">
                  <Field style={{width:"100%"}} name="name" />
                </div> 
              </div>
              <div className="grid-row">
                <div className="grid-col-2 widescreen:grid-col-1">
                  <b>Content: </b>
                </div>
                <div className="grid-col-10 widescreen:grid-col-11">
                  <Field style={{width:"100%"}} name="details" component="textarea"/>
                </div>
              </div>
              <div className="grid-row margin-bottom-2">
                <button className="usa-button fdic-button square-button" onClick={formikProps.handleSubmit}>Save</button>
              </div>
            </div>
          </Form>
      }
    </Formik>
  );
};

export default NewGlossaryItem;