import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPrint} from '@fortawesome/free-solid-svg-icons';
import {useTranslation} from 'react-i18next';

const PrintButton = ({printText}) => {
  const {t} = useTranslation();
  return (
    <a
      className='hide-print print-link'
      title={t('Print page')}
      href='/'
      onClick={(e) => e.preventDefault() || window.print()}
    >
      <FontAwesomeIcon icon={faPrint} /> {printText}
    </a>
  );
};
export default PrintButton;
