import React from 'react';
import {Formik, Form} from 'formik';
import Tables from '../utilities/Tables';
import Inputs, {ExpandableTextarea} from '../utilities/Inputs';
import EditableContent from '../../../components/EditableContent/EditableContent';
import config from '../../../config';
import {useTranslation} from 'react-i18next';

const loanEstimateHeaders = ['Loan Estimate #1', 'Loan Estimate #2', 'Loan Estimate #3'];

const emptyItem = (item = '', inputType = 'text', rows = '2', itemInputType = 'label', label = '') => ({
  item: {value: item, inputType: itemInputType, label},
  estimate1: {value: '', inputType: inputType, columnWidth: 1, rows: rows, columnName: loanEstimateHeaders[0]},
  estimate2: {value: '', inputType: inputType, columnWidth: 1, rows: rows, columnName: loanEstimateHeaders[1]},
  estimate3: {value: '', inputType: inputType, columnWidth: 1, rows: rows, columnName: loanEstimateHeaders[2]},
});

const rowLabelArray = [
  'What is the initial interest rate?',
  'How often can the interest rate change?',
  'What is the change based on?',
];
const rowEstimate = {
  value: '',
  label: rowLabelArray,
  inputType: 'textAreaWithLabelArray',
  columnWidth: 1,
};
const textWithLabelRow = (item = '') => ({
  item: {value: item, inputType: 'label'},
  estimate1: {...rowEstimate, columnName: loanEstimateHeaders[0]},
  estimate2: {...rowEstimate, columnName: loanEstimateHeaders[1]},
  estimate3: {...rowEstimate, columnName: loanEstimateHeaders[2]},
});

const checkboxEstimates = (options = [], stackbuttons = false, includeOther = false) => ({
  value: '',
  inputType: 'radioButtonGroup',
  stackbuttons: stackbuttons,
  includeOther: includeOther,
  options: options,
  columnWidth: 1,
});
const emptyItemCheckboxes = (item = '', options = [], stackbuttons = false, includeOther = false) => ({
  item: {value: item, inputType: 'label'},
  estimate1: checkboxEstimates(options, stackbuttons, includeOther),
  estimate2: checkboxEstimates(options, stackbuttons, includeOther),
  estimate3: checkboxEstimates(options, stackbuttons, includeOther),
});

const loanOptions = ['Conventional', 'FHA', 'VA', 'USDA'];

const interestOptions = ['Fixed Rate', 'Adjustable Rate (ARM)', 'Hybrid (features of fixed and adjustable)'];

const loanTermOptions = ['15-year', '30-year'];

const pg1 = '(find on page 1 of Loan Estimate)';
const pg3 = '(find on page 3 of Loan Estimate)';
const specificInfos = [
  {text: ['Loan Amount', pg1], type: 'money'},
  {text: ['Interest Rate', pg1], type: 'percentage'},
  {text: ['Prepayment Penalty', pg1], type: 'money'},
  {text: ['Balloon Payment', pg1], type: 'money'},
  {text: ['Estimated Monthly Payment', pg1], type: 'money'},
  {text: ['Estimated Closing Costs', pg1], type: 'money'},
  {text: ['Estimated Cash to Close', pg1], type: 'money'},
  {text: ['Annual Percentage Rate (APR)', pg3], type: 'percentage'},
  {text: ['In 5-years: Amount paid', pg3], type: 'money'},
  {text: ['In 5-years: Principal you will have paid', pg3], type: 'money'},
  {text: ['Rate Lock Period', pg1, 'How long is the rate quoted by the lender valid?'], type: 'text'},
];

const initialValues = {
  month: 'January',
  bestOption: '',
  whatToLookFor: {
    lender: [
      emptyItem('Lender name', 'textarea'),
      emptyItem('Lender contact information', 'textarea', 3),
      emptyItem('Date I received this information', 'date'),
      emptyItemCheckboxes('Loan Type', loanOptions, true),
      emptyItemCheckboxes('Interest Rate Type', interestOptions, true, true),
      textWithLabelRow('If mortgage has adjustable or hybrid interest rate...'),
      emptyItemCheckboxes('Loan Term', loanTermOptions, true, true),
    ],
  },
  numericalEntries: {
    lender: specificInfos.map((si) => emptyItem(si.text, si.type, null, 'questionLabelArrayFirstLineBold', si.text[0])),
  },
};

const CompareLoanEstimates = ({tool, canEdit}) => {
  const {t, i18n} = useTranslation();
  const estimateHeaders = loanEstimateHeaders.map((l) => t(l));
  const bestOption = t('What features make it the best option for you?');
  return (
    <div>
      <Formik initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <EditableContent
              htmlType='div'
              initialContent={i18n.language === 'es' ? tool.contentsEs || tool.contents : tool.contents}
              fieldName={i18n.language === 'es' ? 'contents_es' : 'contents'}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            {Tables.renderSimpleTable(
              [t('Important Information'), ...estimateHeaders],
              initialValues.whatToLookFor,
              formikProps,
              'whatToLookFor',
              true,
              emptyItem,
              undefined,
              false,
              true
            )}
            <div className='grid-col-12'>
              {Tables.renderSimpleTable(
                [t('Specific Information on the Loan Estimate'), ...estimateHeaders],
                initialValues.numericalEntries,
                formikProps,
                'numericalEntries',
                true,
                emptyItem,
                undefined,
                false,
                true
              )}
            </div>
            <p>
              <b>{t('Which loan looks like the best option for you?')}</b>
            </p>
            {Inputs.radioButtonGroup({
              id: 'bestOption',
              value: formikProps.values.bestOption,
              error: formikProps.errors.bestOption,
              touched: formikProps.touched.bestOption,
              options: ['Loan Estimate #1', 'Loan Estimate #2', 'Loan Estimate #3'],
            })}
            <p></p>
            <ExpandableTextarea name='features' title={bestOption} question={<b>{bestOption}</b>} />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompareLoanEstimates;
