import React from "react";
import {Formik, Form} from "formik";
import Tables from "../utilities/Tables";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation, Translation} from "react-i18next";

const emptyItem = (item = "", placeHolder = null, allowRemoval = false) => ({
  item: {
    value: item,
    inputType: "questionlabel",
    placeHolder: placeHolder,
    allowRemoval: allowRemoval,
  },
  value: {value: "", inputType: "money", columnName: "Value"},
});

const labelItem = (item = "") => ({
  item: {value: item, inputType: "questionlabel"},
  value: {value: "", inputType: "money", columnName: "Value"},
});

const otherItem = (item = "", label = "", allowRemoval = false) => ({
  item: {
    value: item,
    inputType: "textWithLabel",
    label: label,
    allowRemoval: allowRemoval,
    title: label,
  },
  value: {value: "", inputType: "money", columnName: "Value", title: label},
});

const assetTotalRow = (summaryTable = false, bold = true) => ({
  item: {
    value: (
      <Translation>
        {(t) => (
          <span tabIndex="0" className={!summaryTable ? "subtotal-row" : ""}>
            {t('My Total Assets')}
          </span>
        )}
      </Translation>
    ),
    inputType: bold ? "labelHTML" : "questionlabelHTML",
  },
  value: {
    inputType: "calculated",
    calculation: (formikProps) =>
      displayTotal(formikProps.values, "myAssetsSection", summaryTable),
  },
});

const liabilityTotalRow = (summaryTable = false, bold = true) => ({
  item: {
    value: (
      <Translation>
        {(t) => (
          <span tabIndex="0" className={!summaryTable ? "subtotal-row" : ""}>
            {t('My Total Liabilities')}
          </span>
        )}
      </Translation>
    ),
    inputType: bold ? "labelHTML" : "questionlabelHTML",
  },
  value: {
    inputType: "calculated",
    calculation: (formikProps) =>
      displayTotal(formikProps.values, "myLiabilitiesSection", summaryTable),
  },
});

const summaryRow = (summaryTable = false) => ({
  item: {
    value: (
      <Translation>
        {(t) => (
          <b tabIndex="0" className={!summaryTable ? "subtotal-row" : ""}>
            {t('My Net Worth')}
          </b>
        )}
      </Translation>
    ),
    inputType: "labelHTML",
    columnClass: "background-color-white",
  },
  value: {
    inputType: "calculated",
    calculation: (formikProps) => displaySummary(formikProps.values),
    columnClass: "background-color-white",
  },
});

const calculateNetWorth = (values) =>
  total(values, "myAssetsSection") - total(values, "myLiabilitiesSection");

const netWorthValue = (formikProps) => (
  calculateNetWorth(formikProps.values) === 0
    ? "Zero net worth"
    : calculateNetWorth(formikProps.values) < 0
      ? "Negative net worth"
      : "Positive net worth"
);


const displaySummary = (values) => {
  let netWorth = calculateNetWorth(values);
  return (
    <span
      tabIndex="0"
      className={`${
        netWorth !== 0 ? (netWorth < 0 ? "red-text" : "green-text") : ""
      }`}
    >
      <b>${netWorth.toFixed(2).withCommas()}</b>
    </span>
  );
};

const displayTotal = (values, section, summaryTable) => (
  <span tabIndex="0" className={!summaryTable ? "subtotal-row" : ""}>
    ${total(values, section).toFixed(2).withCommas()}
  </span>
);

const total = (values, section) => {
  let total = 0;
  Object.keys(values[section]).forEach((subSection) =>
    values[section][subSection].forEach((row) => {
      if (row.value.value) total += parseFloat(row.value.value);
    })
  );
  return Math.round(total * 100) / 100;
};

const initialValues = {
  myAssetsSection: {
    money: [
      emptyItem("Cash"),
      emptyItem("Checking accounts"),
      emptyItem("Savings accounts"),
      emptyItem("Matched savings accounts"),
      emptyItem("Savings bonds"),
      emptyItem("Stocks or mutual funds"),
      emptyItem("Certificates of deposit (CDs)"),
      emptyItem("College savings plan"),
      emptyItem("ABLE account"),
      emptyItem("401(k)"),
      emptyItem("Individual Retirement Account (IRA)"),
      otherItem("", "Other investments:"),
    ],
    insurance: [labelItem("Life insurance policies (cash value, if any)")],
    vehicls: [otherItem("", "Cars and trucks (trade-in or resale value):")],
    "recreational vehicles": [
      otherItem("", "Recreational vehicles (motor home, boat, etc.):"),
    ],
    households: [
      emptyItem("Homes I own (current market value)"),
      emptyItem("Jewelry"),
      emptyItem("Antiques"),
      emptyItem("Electronics"),
      emptyItem("Furniture"),
      emptyItem("Appliances"),
      otherItem("", "Other household items:"),
    ],
    other: [otherItem("", "Other assets:")],
    total: [assetTotalRow()],
  },
  myLiabilitiesSection: {
    "credit card": [otherItem("", "Credit card balances:")],
    purchases: [
      otherItem(
        "",
        "Balances owed on store purchases (layaway or other store credit):"
      ),
    ],
    services: [
      otherItem(
        "",
        "Bills due for services (medical, dental, electrical, gas, phone, and other services):"
      ),
    ],
    mortgage: [otherItem("", "Mortgages (principal balance):")],
    vehicles: [otherItem("", "Vehicle loans (principal balance):")],
    "student loans": [otherItem("", "Student loans (principal balance):")],
    "other loans": [
      otherItem("", "Other installment loans (principal balance):"),
    ],
    "home loans": [
      otherItem(
        "",
        "Home equity loans or lines of credit (principal balance):"
      ),
    ],
    "cash loans": [
      otherItem("", "Cash loans owed to others (principal balance):"),
    ],
    other: [otherItem("", "Other liabilities:")],
    total: [liabilityTotalRow()],
  },
  summarySection: {
    totals: [
      assetTotalRow(true, false),
      liabilityTotalRow(true, false),
      summaryRow(true),
    ],
  },
};

const CompareCreditCards = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  return (
    <div>
      <Formik initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <EditableContent
              htmlType="div"
              initialContent={
                i18n.language === "es"
                  ? tool.contentsEs || tool.contents
                  : tool.contents
              }
              fieldName={i18n.language === "es" ? "contents_es" : "contents"}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            <div className="page-break-inside-avoid">
              <h3 className="margin-top-4" id="assets">{t('My Assets')}</h3>
              <a href="#liabilities">
                <b>{t('Jump to My Liabilities')}</b>
              </a>
              <br></br>
              <a href="#myNetWorth">
                <b>{t('Jump to My Net Worth')}</b>
              </a>

              {Tables.renderSimpleTable(
                [t('Item'), t('Value')],
                initialValues.myAssetsSection,
                formikProps,
                "myAssetsSection",
                true,
                (value, label) => otherItem(value, label, true),
                undefined,
                true,
                true
              )}
            </div>
            <div>
              <h3 className="margin-top-4" id="liabilities">{t('My Liabilities')}</h3>
              <a href="#assets">
                <b>{t('Jump to My Assets')}</b>
              </a>
              <br></br>
              <a href="#myNetWorth">
                <b>{t('Jump to My Net Worth')}</b>
              </a>

              {Tables.renderSimpleTable(
                [t('Item'), t('Value')],
                initialValues.myLiabilitiesSection,
                formikProps,
                "myLiabilitiesSection",
                true,
                (value, label) => otherItem(value, label, true),
                undefined,
                true,
                true
              )}
            </div>
            <div className="page-break-inside-avoid">
              <h3 id="myNetWorth" className="margin-top-4">
                {t('My Net Worth')} ({t(netWorthValue(formikProps))})
              </h3>
              <a href="#assets">
                <b>{t('Jump to My Assets')}</b>
              </a>
              <br></br>
              <a href="#liabilities">
                <b>{t('Jump to My Liabilities')}</b>
              </a>
              {Tables.renderSimpleTable(
                [t('Category'), t('Value')],
                initialValues.summarySection,
                formikProps,
                "summarySection",
                true,
                (value, label) => emptyItem(value, "", true),
                undefined,
                true,
                false,
                "summary-table",
                "Summary table"
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CompareCreditCards;
