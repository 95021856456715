import React, {PureComponent} from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, Text
} from 'recharts';

const CustomTooltip = ({active, payload, label, units}) => {
  if (active) {
    return (
      <div className="custom-tooltip">
        <h4 className="label no-margin">{`${label} ${units}`}</h4>
        <p className="desc">{`Number of players: ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

export default class UsageBarChart extends PureComponent {
  render() {
    return (
      <BarChart
        width={1000}
        height={500}
        data={this.props.sessionsData}
        margin={{
          top: 20, right: 30, left: 20, bottom: 40,
        }}
      >
        <CartesianGrid strokeDasharray="1 1" />
        <XAxis dataKey="count" textAnchor="end" interval={0}>
          <Label value={`Number of ${this.props.units}`} position="bottom" offset={10} />
        </XAxis>
        <YAxis label={
          <Text
            x={0}
            y={0}
            dx={40}
            dy={300}
            offset={0}
            angle={-90}
          >
            Number of players
          </Text>
        }/>
        <Tooltip content={<CustomTooltip units={this.props.units}/>}/>
        <Legend verticalAlign="top" align="right" wrapperStyle={{
          paddingBottom: "15px"
        }}/>
        <Bar dataKey="Number of players" stackId="a" fill="#124C8F" label={{position: 'top'}} isAnimationActive={false}/>
      </BarChart>
    );
  }
}
