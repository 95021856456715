import React from 'react';
import {observer} from 'mobx-react';
import {Loader} from '../../components/Loader';
import PrintButton from '../../components/Buttons/PrintButton';

const callCenterHelpTopics = (helpTopics) => (
  helpTopics.filter((helpTopic) => helpTopic.account_types.includes('support'))
);

const CallCenter = (props) => {
  props.helpCenterStore.getHelpTopics();
  return(
    <div>
      <h1>Call Center Topics</h1>
      <PrintButton printText="Print this page"/> 
      {props.helpCenterStore.helpTopicsLoading ? <Loader /> : 
        Object.keys(props.helpCenterStore.groupedHelpTopics).map((helpTopicCategoryId) => {
          const helpTopicGroup = callCenterHelpTopics(props.helpCenterStore.groupedHelpTopics[helpTopicCategoryId]);
          return(
            helpTopicGroup.length > 0 &&
            <div key={helpTopicCategoryId}>
              <h2>Category: {helpTopicGroup[0].help_topic_category_name}</h2>
              {helpTopicGroup.map((helpTopic, index) => (
                <div key={index} className={`margin-bottom-9 ${index !== 0 && 'page-break-before-always'}`}>
                  <h3 className="margin-bottom-0">{helpTopic.name}</h3>
                  <div dangerouslySetInnerHTML={{__html: helpTopic.details}} />
                </div>
              ))}
            </div>
          );
        })
      }
    </div>
  );
};

export default observer(CallCenter);

