import React from 'react';
import * as Yup from 'yup';
import {Redirect} from 'react-router-dom';
import config from '../../config';
import {observer} from 'mobx-react';
import {Loader} from '../../components/Loader';
import Modal from "react-responsive-modal";
import {Formik} from 'formik';
import Filter from 'bad-words';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import EditHelpTopicForm from './components/EditHelpTopicForm';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowUp, faArrowDown} from '@fortawesome/free-solid-svg-icons';
import {withTranslation} from 'react-i18next';
import LinkButton from '../../components/Buttons/LinkButton';
import BackToTop from '../../components/Buttons/BackToTop';

const ACCOUNT_TYPES = [
  {
    label: 'Individual',
    value: 'individual'
  },
  {
    label: 'Organization',
    value: 'organization'
  },
  {
    label: 'FDIC Admin',
    value: 'admin'
  },
  {
    label: 'FDIC Editor',
    value: 'editor'
  },
];

const displayAccountTypes = (accountTypes) => {
  return(
    ACCOUNT_TYPES.map((accountType, i) => {
      return accountTypes.includes(accountType.value) ? <span key={i} className={`account-type ${accountType.value}`}>{accountType.label}</span> : <span key={i} />;
    })
  );
};

function notEqualTo(ref, msg) {
  return this.test({
    name: 'notEqualTo',
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path
    },
    test: (value) => {
      return value !== this.resolve(ref); 
    }
  });
};

Yup.addMethod(Yup.string, 'notEqualTo', notEqualTo);

const filter = new Filter();
const nameTest = Yup.string()
  .test('Vulgarity', 'This is not a valid value.', (value) => !filter.isProfane(value))
  .min(2, 'Title must be at least two characters.')
  .max(100, 'Title must be at most 100 characters.')
  .required('Title is required.');
const categoryIdTest = Yup.string()
  .notOneOf(["0"], 'Category is required.')
  .required('Category is required.');

const helpTopicSchema = Yup.object().shape({
  name: nameTest,
  help_topic_category_id: categoryIdTest,
});

const helpTopicSpanishSchema = Yup.object().shape({
  name_es: nameTest,
  help_topic_category_id: categoryIdTest,
});

class EditorHelpCenter extends React.Component {
  constructor() {
    super();
    this.state = {
      newOpen: false,
      editOpen: false,
      editItem: {},
      isSpanish: false,
      saveAndViewSelected: false,
    };
  }

  componentDidMount() {
    config.setUniqueTitle('Edit Help Center');
    this.props.helpCenterStore.getHelpTopics();
    this.props.helpCenterStore.getHelpTopicCategories();
  }

  deactivateHelpTopic = (helpTopic) => {
    let updatedHelpTopic = helpTopic;
    updatedHelpTopic.active = false;
    this.props.helpCenterStore.updateHelpTopic(updatedHelpTopic);
  };

  displaySortOrder = (field) => {
    if(this.props.helpCenterStore.sortedBy !== field) return <div />;
    return this.props.helpCenterStore.sortOrder === 'asc' ? 
      <FontAwesomeIcon className='color-cyan margin-left-2' icon={faArrowDown} /> : 
      <FontAwesomeIcon className='color-cyan margin-left-2' icon={faArrowUp} />;
  };

  mapHelpTopics = (isSpanish = false) => {
    const searchParam = isSpanish ? '?lang=es' : '';
    return (
      this.props.helpCenterStore.helpTopics.map((helpTopic, i) => {
        let name = isSpanish ? helpTopic.name_es : helpTopic.name;
        let missing = false;
        if (isSpanish && !name) {
          name = helpTopic.name;
          missing = true;
        }
        if (isSpanish && helpTopic.account_types.indexOf('individual') === -1) 
          return null; // only show individual account types for the spanish table
        return (
          <tr key={i}>
            <td>
              <a href="/" title={`Edit ${name}`} onClick={(e) => { e.preventDefault(); this.setState({editOpen: true, editItem: helpTopic, isSpanish});}}>
                <button className={"edit-button margin-right-05"}><i className="material-icons editable-edit">edit</i></button>
                {name}{missing && <i> (not yet translated)</i>}
              </a>
            </td>
            <td>{helpTopic.help_topic_category_name}</td>
            <td>{displayAccountTypes(helpTopic.account_types)}</td>
            <td><input type="checkbox" checked={helpTopic.active || false} disabled={!helpTopic.active} onChange={() => this.deactivateHelpTopic(helpTopic)}/></td>
            <td><a href={`/help-center/${helpTopic.id}${searchParam}`}>View</a> | <a href={`/help-center/${helpTopic.id}/edit${searchParam}`}>Edit</a></td>
          </tr>
        );
      })
    );
  };

  renderHelpTopicTable = (isSpanish = false) => {
    const id = `help-topic-table-${isSpanish ? 'es' : 'en'}`;
    const lang = `lang-${isSpanish ? 'es' : 'en'}`;
    const topic = `Topic${isSpanish ? ' (Spanish)' : ''}`;
    const suffix = isSpanish ? '_es' : '';
    const sortName = `name${suffix}`;
    const sortCatName = 'help_topic_category_name';

    const {sortHelpTopics} = this.props.helpCenterStore;
    return (
      <table id={id} className={`usa-table ${lang}`}>
        <thead>
          <tr>
            <th><button className="usa-button usa-button--unstyled" onClick={() => sortHelpTopics(sortName)}>{topic}</button>{this.displaySortOrder(sortName)}</th>
            <th><button className="usa-button usa-button--unstyled" onClick={() => sortHelpTopics(sortCatName)}>Category</button>{this.displaySortOrder(sortCatName)}</th>
            <th>Account Types</th>
            <th style={{minWidth: "110px"}}><button className="usa-button usa-button--unstyled" onClick={() => sortHelpTopics('active')}>Active</button>{this.displaySortOrder('active')}</th>
            <th style={{minWidth: "120px"}}></th>
          </tr>
        </thead>
        <tbody>
          {this.mapHelpTopics(isSpanish)}
        </tbody>
      </table>
    );
  };

  setSaveAndViewSelected = (selected = false) => {
    this.setState({saveAndViewSelected: selected});
  };

  renderEditModal = () => {
    const params = this.state.isSpanish ? '?lang=es' : '';
    return (
      <Modal
        open={this.state.editOpen}
        onClose={() => this.setState({editOpen: false, editItem: {}})}
      >
        <Formik
          initialValues={{
            id: this.state.editItem.id,
            name: this.state.editItem.name,
            name_es: this.state.editItem.name_es ? this.state.editItem.name_es : '',
            help_topic_category_id: this.state.editItem.help_topic_category_id,
            account_types: this.state.editItem.account_types
          }}
          onSubmit={(values) => {this.props.helpCenterStore.updateHelpTopic(values); this.setState({editOpen: false});}}
          validationSchema={this.state.isSpanish ? helpTopicSpanishSchema : helpTopicSchema}
        >
          {formikProps => 
            <EditHelpTopicForm 
              formikProps={formikProps} 
              helpTopic={this.state.editItem} 
              helpTopicCategories={this.props.helpCenterStore.helpTopicCategories}
              showDelete={false}
              showSaveAndView={true} 
              showDetails={false}
              onDelete={() => this.setState({showDeleteModal: true})} 
              onCancel={() => this.setState({editOpen: false})}
              isSpanish={this.state.isSpanish}
              urlParams={params}
              saveAndViewSelected={this.state.saveAndViewSelected}
              setSaveAndViewSelected={this.setSaveAndViewSelected}
            />
          }
        </Formik>
      </Modal>
    );
  };

  renderNewModal = () => {
    return(
      <Modal
        open={this.state.newOpen}
        onClose={() => this.setState({newOpen: false})}
      >
        <Formik
          initialValues={{
            id: '',
            name: '',
            name_es: '',
            details: '',
            details_es: '',
            help_topic_category_id: '0',
            account_types: []
          }}
          onSubmit={(values) => {this.props.helpCenterStore.createHelpTopic(values, (newItem) => window.location = `/help-center/${newItem.id}/edit`); this.setState({newOpen: false});}}
          validationSchema={helpTopicSchema}
          validateOnBlur={false}
        >
          {formikProps => 
            <EditHelpTopicForm
              formikProps={formikProps}
              helpTopic={this.state.editItem}
              helpTopicCategories={this.props.helpCenterStore.helpTopicCategories}
              showDelete={false}
              showDetails={false}
              onCancel={() => this.setState({newOpen: false})}
              isSpanish={false}
            />  
          }
        </Formik>
      </Modal>
    );
  };

  render() {
    if(!this.props.authStore.isEditor) return <Redirect to="/home" />;

    const {helpTopicsLoading, helpTopicCategoriesLoading} = this.props.helpCenterStore;
    if (helpTopicsLoading || helpTopicCategoriesLoading) return <Loader />;
    
    const {editorHelpTopics} = this.props.helpCenterStore;
    const isSpanish = this.props.i18n.language === 'es';

    const spanishTableScrollToId = 'help-topic-table-es-marker';

    return(
      <div className="help-center">
        <BreadCrumb
          historyLinks={[
            {
              link: '/help-center',
              display: 'Help Center'
            },
            {
              link: '#',
              display: 'Edit'
            },
          ]}
        />
        <h1>Edit Help Center{isSpanish && ' (Spanish)'}</h1>
        <div className="margin-bottom-2">
          {editorHelpTopics.map((helpTopic, index) => (
            <span key={index} className="margin-right-105">{index > 0 && <span className="margin-right-105">|</span>}
              <a href={`/help-center/${helpTopic.id}`}>{helpTopic.name}</a>
            </span>
          ))}
        </div>
        <div>
          {!isSpanish && 
            <button className="usa-button margin-right-5" onClick={() => this.setState({newOpen: true})}>Add Topic</button>
          }
          <a className="margin-right-105" href="/help-center/call-center">Call Center Questions</a> | 
          <a className="margin-left-105 margin-right-105" href="/help-center/help-topic-categories">Edit Categories</a> |
          <LinkButton classNamesToAdd={'margin-left-105'} id={spanishTableScrollToId} textJsx={<span>Jump to Spanish</span>}/>
        </div>

        {this.renderHelpTopicTable(false)}
        <hr id={spanishTableScrollToId} className="help-center-hr"/>
        <div className="display-flex flex-column flex-align-end">
          <BackToTop />
        </div>
        {this.renderHelpTopicTable(true)}

        {this.renderEditModal()}
        {this.renderNewModal()}
      </div>
    );
  }
}

export default withTranslation()(observer(EditorHelpCenter));
export {ACCOUNT_TYPES};