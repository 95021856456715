import React from 'react';

const StrategyCard = ({header, imageLeft, imageRight, imageAlt, withBorder=true, avoidPageBreak=true, children}) => (
  <div className={`strategy-card grid-row grid-gap content-block ${imageRight && 'flex-reverse'} ${withBorder && 'with-border'} ${avoidPageBreak && 'page-break-inside-avoid'}`}>
    {imageRight && 
      <div className="desktop:grid-col-4 strategy-card-image align-center hide-print">
        <img alt={imageAlt} src={imageRight}/>
      </div>}
    <div className={`usa-layout-docs__main desktop:grid-col-${(imageLeft || imageRight) ? '8' : '12'} usa-prose strategy-content`}>
      <div className="desktop:grid-col mobile-lg:grid-col-4 strategy-content">
        {children}
      </div>
    </div>
    {imageLeft && 
      <div className="desktop:grid-col-4 strategy-card-image align-center hide-print">
        <img alt={imageAlt} src={imageLeft}/>
      </div>}
  </div>
);

export default StrategyCard;