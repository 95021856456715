import React from 'react';
import EditableContent from '../../../components/EditableContent/EditableContent';
import config from '../../../config';
import {useTranslation, Trans} from 'react-i18next';

import readPayStatement from '../../../assets/images/PayStatement.png';
import readPayStatementEs from '../../../assets/images/PayStatement-es.png';

const tableMapping = [
  {
    letter: 'A',
    item: 'Pay Period',
    definition: (
      <p>
        <Trans i18nKey='6.a1'>
          Pay period is the time period you are being paid for. It could be one week, two weeks, a month, or some other
          length of time. In this example, the pay period is December 1 to December 14.
        </Trans>
      </p>
    ),
  },
  {
    letter: 'B',
    item: 'Pay Date',
    definition: (
      <p>
        <Trans i18nKey='6.b1'>
          Pay date is the official date you receive your net pay. If you have direct deposit, you may get your net pay
          before the official pay date. There’s often a delay between the end of the pay period and the pay date. Your
          pay date could be every Friday, or the first and fifteenth of every month, or something else.
        </Trans>
      </p>
    ),
  },
  {
    letter: 'C',
    item: 'Gross Pay',
    definition: (
      <p>
        <Trans i18nKey='6.c1'>
          Gross pay is the money you earned during the pay period before anything is taken out of it. Year-to-date gross
          pay may also be shown.
        </Trans>
      </p>
    ),
  },
  {
    letter: 'D',
    item: 'Required Deductions',
    definition: (
      <p>
        <Trans i18nKey='6.d1'>
          Deductions are the amounts withheld or subtracted from your gross pay for this pay period and maybe
          year-to-date. Required deductions include federal income tax and, if applicable, state and local income taxes.
        </Trans>
      </p>
    ),
  },
  {
    letter: 'E',
    item: 'Social Security/Medicare',
    definition: (
      <div>
        <p>
          <Trans i18nKey='6.e1'>Social Security and Medicare are also required deductions.</Trans>
        </p>
        <p>
          <Trans i18nKey='6.e2'>
            The Federal Insurance Contributions Act (FICA) requires that taxes for Social Security and Medicare be
            deducted.
          </Trans>
        </p>
        <p>
          <Trans i18nKey='6.e3'>
            The Social Security deduction is sometimes labeled FICA-SS or OASDI. The acronym OASDI reflects that your
            Social Security contributions help pay for old-age, survivors, and disability insurance.
          </Trans>
        </p>
        <p>
          <Trans i18nKey='6.e4'>
            The Medicare deduction is sometimes labeled FICA-MED or just MED. Your Medicare contributions help pay for
            Medicare. Medicare is a health insurance program for people age sixty-five or older, people under age
            sixty-five with certain disabilities, and people of all ages with end-stage renal disease.
          </Trans>
        </p>
      </div>
    ),
  },
  {
    letter: 'F',
    item: 'Employer Contributions',
    definition: (
      <p>
        <Trans i18nKey='6.f1'>
          Employer contributions are what the employer paid on your behalf, such as health insurance. The pay statement
          may also show year-to-date employer contributions.
        </Trans>
      </p>
    ),
  },
  {
    letter: 'G',
    item: 'Voluntary Deductions',
    definition: (
      <div>
        <p>
          <Trans i18nKey='6.g1'>
            Elective or voluntary deductions refer to money you decide to have taken out of your pay. This could include
            money withheld for insurance premiums, union dues, or charitable contributions.
          </Trans>
        </p>
        <p>
          <Trans i18nKey='6.g2'>
            Depending on your employer, retirement plan contributions could be required deductions or voluntary
            deductions.
          </Trans>
        </p>
      </div>
    ),
  },
  {
    letter: 'H',
    item: 'Net Pay',
    definition: (
      <p>
        <Trans i18nKey='6.h1'>Net pay is your gross pay minus deductions. It’s your take-home pay.</Trans>
      </p>
    ),
  },
];

const ReadPayStatement = ({tool, canEdit}) => {
  const {t, i18n} = useTranslation();
  const isSpanish = i18n.language === 'es';
  const tableH1 = t('Item');
  const tableH2 = t('Definition');
  const tableTitle = `${t('Table')}: ${tableH1}, ${tableH2}`;
  return (
    <div id='read-pay-statement' className='read-pay-statement'>
      <EditableContent
        htmlType='div'
        initialContent={isSpanish ? tool.contentsEs || tool.contents : tool.contents}
        fieldName={isSpanish ? 'contents_es' : 'contents'}
        updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
        canEdit={canEdit}
        richText
      />
      <div className='container' style={{maxWidth: '1054px'}}>
        <img alt='Pay Statement' src={isSpanish ? readPayStatementEs : readPayStatement} />
        {tableMapping.map((item) => (
          <a
            key={item.letter}
            id={item.letter}
            title={`${item.letter} ${item.item}`}
            className={`${item.letter}`}
            href={`#${item.letter}-definition`}
          >
            {item.letter}
          </a>
        ))}
      </div>
      <table className='usa-table summary-table' title={tableTitle}>
        <thead>
          <tr>
            <th scope='col'>{tableH1}</th>
            <th scope='col'>{tableH2}</th>
          </tr>
        </thead>
        <tbody>
          {tableMapping.map((row, index) => (
            <tr key={`row-${row.letter}`} id={`${row.letter}-definition`} tabIndex='0'>
              <th scope='row'>
                {row.letter}. {t(row.item)}
              </th>
              <td>
                {row.definition}
                <span className='hide-print'>
                  <a
                    href={`#${
                      index === tableMapping.length - 1 ? 'read-pay-statement' : tableMapping[index + 1].letter
                    }`}
                  >
                    {t('Back to top')}
                  </a>
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ReadPayStatement;
