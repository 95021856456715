import React from 'react';

const LinkButton = ({id, textJsx, classNamesToAdd}) => {
  const scrollElementIntoView = (id) => {
    const el = document.getElementById(id);
    if (el !== null) {
      el.scrollIntoView();
    }
  };

  const className = `usa-btn-link${classNamesToAdd ? ' ' + classNamesToAdd : ''}`;

  return (
    <button className={className} onClick={() => scrollElementIntoView(id)}>
      {textJsx}
    </button>
  );
};

export default LinkButton;
