import React from 'react';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

const SPANISH_TESTER_EMAIL = 'moneysmartspanish@gmail.com';
const SKIP_TO_MAIN_CONTENT_EL_ID = 'skip-to-main-content';

const LanguageSelector = observer(({featureToggleStore, authStore}) => {
  const {i18n, t} = useTranslation();
  if (!featureToggleStore || !authStore) return null;
  const showSelector = featureToggleStore.translations || authStore.email === SPANISH_TESTER_EMAIL;
  if (!showSelector) return null;

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    document.documentElement.setAttribute('lang', lng);
    updateSkipToMainContentElementLanguage(lng, t);
  };
  const isSpanish = i18n.language === 'es';
  return (
    <div className='hide-print'>
      <button
        type='button'
        className='usa-button usa-button--unstyled usa-language-link'
        onClick={() => changeLanguage(isSpanish ? 'en' : 'es')}
        lang={isSpanish ? 'en' : 'es'}
      >
        {isSpanish ? 'View this page in English' : 'Vea esta página en español'}
      </button>
    </div>
  );
});

export default LanguageSelector;

function updateSkipToMainContentElementLanguage(lang, t) {
  const skipEl = document.getElementById(SKIP_TO_MAIN_CONTENT_EL_ID);
  if (!skipEl) return;
  skipEl.setAttribute('lang', lang);
  skipEl.innerText = t('Skip to main content');
}
