import React from "react";
import {Formik, Form} from "formik";
import Tables from "../utilities/Tables";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation} from "react-i18next";

const needWantOptions = ["Need", "Want", "Not Important", "Unsure"];

const emptyItem = (
  item = "",
  inputType = "questionlabel",
  label = "",
  allowRemoval = false,
  labelTitle = false
) => ({
  item: {
    value: item,
    inputType: inputType,
    columnWidth: 2,
    label: label,
    allowRemoval: allowRemoval,
    title: labelTitle ? label : null,
  },
  needWant: {
    value: "",
    inputType: "radioButtonGroup",
    options: needWantOptions,
    label: "",
  },
});

const initialValues = {
  neighborhood: {
    Neighborhood: [
      emptyItem("Safe neighborhood—low crime, well lit"),
      emptyItem("Affordable cost of living"),
      emptyItem(
        "Diversity in neighborhood in terms of cultures, ethnicities, ages, and other diversity characteristics"
      ),
      emptyItem(
        "Strong disability community and accessibility to disability- related services"
      ),
      emptyItem("Good schools"),
      emptyItem("Sidewalks"),
      emptyItem(
        "Distance to work, childcare, other needed services, friends, family, other supportive people, stores, parks, and other places important to you"
      ),
      emptyItem("Close to health care providers and hospitals"),
      emptyItem("Access to public transportation"),
      emptyItem("Accessibility features for people with disabilities"),
      emptyItem("Access to parking"),
      emptyItem(
        "",
        "textWithLabel",
        "Other neighborhood feature:",
        false,
        true
      ),
    ],
  },
  space: {
    Space: [
      emptyItem("My own bedroom (not shared with anyone)"),
      emptyItem("My own bathroom (not shared with anyone)"),
      emptyItem("My own kitchen (not shared with anyone)"),
      emptyItem("Certain number of bathrooms"),
      emptyItem("Certain number of bedrooms"),
      emptyItem(
        "Location and size of bedrooms and bathrooms—are they accessible and large enough to accommodate mobility devices?"
      ),
      emptyItem("Living room"),
      emptyItem("Dining room"),
      emptyItem("Family, recreation room"),
      emptyItem("Workspace"),
      emptyItem("Ramps"),
      emptyItem("Wide hallways"),
      emptyItem("Yard"),
      emptyItem("Garage"),
      emptyItem("Secure doors and windows"),
      emptyItem("More than one exit to the outside"),
      emptyItem("", "textWithLabel", "Other space feature:", false, true),
    ],
  },
  additionalFeatures: {
    "Additional Features": [
      emptyItem("Affordable utilities"),
      emptyItem("Furnished"),
      emptyItem("Includes major appliances"),
      emptyItem("Accessible countertops, storage areas"),
      emptyItem("Grab railings"),
      emptyItem("", "textWithLabel", "Other additional feature:", false, true),
    ],
  },
};

const MySmartGoals = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  return (
    <div>
      <Formik initialValues={initialValues}>
        {(formikProps) => (
          <Form>
            <EditableContent
              htmlType="div"
              initialContent={
                i18n.language === "es"
                  ? tool.contentsEs || tool.contents
                  : tool.contents
              }
              fieldName={i18n.language === "es" ? "contents_es" : "contents"}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            {Tables.renderSimpleTable(
              [
                t('Neighborhood'),
                t('Need, Want, Not Important, Unsure (Choose one)'),
              ],
              initialValues.neighborhood,
              formikProps,
              "neighborhood",
              true,
              () =>
                emptyItem(
                  "",
                  "textWithLabel",
                  "Other neighborhood feature:",
                  true
                ),
              undefined,
              false,
              true,
            )}
            {Tables.renderSimpleTable(
              [t('Space'), t('Need, Want, Not Important, Unsure (Choose one)')],
              initialValues.space,
              formikProps,
              "space",
              true,
              () =>
                emptyItem("", "textWithLabel", "Other space feature:", true),
              undefined,
              false,
              true,
            )}
            {Tables.renderSimpleTable(
              [
                t('Additional Features'),
                t('Need, Want, Not Important, Unsure (Choose one)'),
              ],
              initialValues.additionalFeatures,
              formikProps,
              "additionalFeatures",
              true,
              () =>
                emptyItem(
                  "",
                  "textWithLabel",
                  "Other additional feature:",
                  true
                ),
              undefined,
              false,
              true,
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MySmartGoals;
