import React, {Component} from 'react';
import ResourceFilterContainer from './ResourceFilterContainer';
import {withTranslation} from 'react-i18next';

class ResourcePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topicItems: props.topicItems,
      filteredTopics: props.topicItems,
      gameCategories: props.gameCategories,
      games: props.games,
      selectedGames: []
    };
  }

  filterCategory = (selectedCategories) => {
    if(selectedCategories.length > 0) {
      let filteredTopics = {};
      Object.keys(this.state.topicItems).forEach((gameName) => {
        if(selectedCategories.includes(this.state.topicItems[gameName][0].gameCategory)) { filteredTopics[gameName] = this.state.topicItems[gameName]; }
      });
      this.setState({
        filteredTopics: filteredTopics,
        selectedGames: Object.keys(filteredTopics)
      });
    }
    else {
      this.resetFilter();
    }
  };

  filterGame = (selectedGames) => {
    if(selectedGames.length > 0) {
      let filteredTopics = {};
      Object.keys(this.state.topicItems).forEach((gameName) => {
        if(selectedGames.includes(gameName)) { filteredTopics[gameName] = this.state.topicItems[gameName]; }
      });
      this.setState({
        filteredTopics: filteredTopics
      });
    }
    else {
      this.resetFilter();
    }
  };

  resetFilter = () => (
    this.setState({filteredTopics: this.state.topicItems, selectedGames: []})
  );

  getGameOptions = () => (
    this.state.games.map((game) => game.name)
  );

  getSelectedGames = () => (this.state.selectedGames);
  
  render() {
    const {t} = this.props;
    return(
      <div>
        {this.props.disclaimer}
        {this.props.gameCategories && this.props.games && <ResourceFilterContainer 
          filterCategory={this.filterCategory}
          filterGame={this.filterGame}
          games={this.state.games}
          gameOptions={this.getGameOptions()}
          getSelectedGames={this.getSelectedGames}
          gameCategories={this.state.gameCategories}
          topicName={this.props.topicName}
        />}
        { this.props.children }
        { Object.keys(this.state.topicItems).map((group, index) => (
          this.state.filteredTopics[group] && <div key={`group-${index}`}>
            <h3 className="color-dark-tan game-group-header padding-bottom-1">{t(this.state.topicItems[group][0].gameName)}</h3>
            {this.props.rendering(this.state.filteredTopics[group])}
          </div>
        )) }
      </div>
    );
  }
}

export default withTranslation()(ResourcePanel);
