import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {observer} from 'mobx-react';
import {useTranslation} from 'react-i18next';

import {linkActive, getLinks, logout} from './SideNavigator';
import moneySmartLogo from '../../assets/images/money-smart.png';
import moneySmartLogoEs from '../../assets/images/money-smart-es.png';

const Navigator = observer((props) => {
  const {authStore, organizationStore} = props;
  const {t, i18n} = useTranslation();
  const history = useHistory();
  const org = !!organizationStore && !!organizationStore.organization ? organizationStore.organization : null;
  const isSpanish = i18n.language === 'es';
  const logoLink = `https://www.fdic.gov/consumers/consumer/moneysmart${isSpanish ? 'sp' : ''}/index.html`;
  const logoAltText = `${t('Link to')} ${logoLink}`;
  return (
    <div className='hide-print'>
      <div className='only-mobile'>
        {getLinks(authStore.loggedIn, authStore.isAdmin, authStore.isOrganization, org, true).map((link, index) => {
          const linkText = t(link.display);
          return (
            <Link
              key={index}
              title={linkText}
              to={link.url}
              className={`navigator-item ${linkActive(link.url) && 'active'}`}
            >
              {linkText}
            </Link>
          );
        })}
        {authStore.loggedIn && (
          <a
            id='logout'
            href='/login'
            title={t('Log Out')}
            className='navigator-item'
            onClick={(e) => e.preventDefault() || logout(authStore, history)}
          >
            {t('Log Out')}
          </a>
        )}
        <br />
      </div>
      <div className='ms-logo-container'>
        <a title={logoAltText} className='ms-logo' href={logoLink}>
          <img alt={logoAltText} className='ms-logo' src={isSpanish ? moneySmartLogoEs : moneySmartLogo} />
        </a>
      </div>
    </div>
  );
});

export default Navigator;
