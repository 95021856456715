import React from 'react';
import PropTypes from 'prop-types';
import {Formik} from 'formik';
import * as Yup from 'yup';
import AddressForm, {
  addressSchemaObject,
} from '../../../components/Formik/AddressForm';
import RequestService from '../../../services/RequestService';
import config from '../../../config';

const addressSchema = Yup.object().shape(addressSchemaObject);

class AddressShowUpdate extends React.Component {
  constructor() {
    super();
    this.state = {
      editing: false,
    };
  }
  updateAddress = (values) => {
    let updateBody = {
      organization: {
        address_line_one: values.addressLineOne,
        address_line_two: values.addressLineTwo,
        city: values.city,
        state: values.state,
        zip_code: values.zipCode,
      },
    };
    RequestService.post(
      `${config.apiGateway.URL}/organization/${values.id}`,
      updateBody,
      (response) => {
        console.log(response);
      },
      (err) => console.log(err)
    );
    return true;
  };
  render() {
    return (
      <div>
        <Formik
          validationSchema={addressSchema}
          initialValues={this.props}
          onSubmit={(values) =>
            this.updateAddress(values) && this.setState({editing: false})
          }
        >
          {(formikProps) =>
            this.state.editing ? (
              <div>
                <b className='no-margin'>Update address:</b>
                <div className='margin-bottom-2'>
                  <AddressForm formikProps={formikProps} />
                </div>
                <button
                  className='usa-button save-button'
                  onClick={formikProps.handleSubmit}
                >
                  Save
                </button>
                <button
                  className='usa-button usa-button--unstyled cancel-button'
                  onClick={() =>
                    formikProps.resetForm() || this.setState({editing: false})
                  }
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div>
                <b className='no-margin'>Address:</b>
                <div className='grid-col-12 long-info-container'>
                  <div className='grid-row'>
                    <span>{formikProps.values.addressLineOne}</span>
                  </div>
                  <div className='grid-row'>
                    <span>{formikProps.values.addressLineTwo}</span>
                  </div>
                  <div className='grid-row'>
                    <span>
                      {formikProps.values.city}
                      {formikProps.values.city &&
                        formikProps.values.state &&
                        ','}{' '}
                      {formikProps.values.state} {formikProps.values.zipCode}
                    </span>
                  </div>
                  {this.props.allowEditing && <button
                    className='edit-button'
                    onClick={() => this.setState({editing: true})}
                  >
                    <i
                      className='material-icons editable-edit'
                      aria-hidden={this.props.altText !== 'edit'}
                    >
                      edit
                    </i>
                    {this.props.altText !== 'edit' && (
                      <span
                        style={{
                          display: 'block',
                          height: '0',
                          width: '0',
                          overflow: 'hidden',
                        }}
                      >
                        {this.props.altText}
                      </span>
                    )}
                  </button>}
                </div>
              </div>
            )
          }
        </Formik>
      </div>
    );
  }
}

AddressShowUpdate.propTypes = {
  altText: PropTypes.string,
};

AddressShowUpdate.defaultProps = {
  altText: 'edit',
  allowEditing: true
};

export default AddressShowUpdate;
