import React from 'react';
import {observer} from 'mobx-react';
import {Formik, Form, Field} from 'formik';
import EditableContent from '../../../../components/EditableContent/EditableContent';
import config from '../../../../config';
import * as Yup from 'yup';

class Emails extends React.Component {
  componentDidMount() {
    this.props.emailsStore.getEmails();
  }

  validateSchema = () =>
    Yup.object().shape({
      to: Yup.string().email('Invalid email').required('Required'),
    });

  requestTestEmail = (values, setSubmitting, resetForm, emailId) => {
    this.props.emailsStore.sendTestEmail(emailId, values.to);
    setTimeout(() => {
      resetForm();
      setSubmitting(false);
    }, 3000);
  };

  renderTestForm = (emailId) => (
    <Formik
      initialValues={{to: ''}}
      validationSchema={this.validateSchema}
      onSubmit={(values, {setSubmitting, resetForm}) =>
        this.requestTestEmail(values, setSubmitting, resetForm, emailId)
      }
    >
      {({values, isSubmitting, errors, touched}) => (
        <Form>
          <h4>Send test email:</h4>
          <div className='grid-row margin-bottom-2'>
            <div className='grid-col-1'>
              <b>To: </b>
            </div>
            <div className='grid-col-11'>
              <Field id='to' name='to' />
              {errors.to && touched.to && !isSubmitting ? (
                <div>{errors.to}</div>
              ) : null}
            </div>
          </div>
          <div className='grid-row'>
            <button
              type='submit'
              className='usa-button send-email-button'
              disabled={values.to === '' || isSubmitting}
            >
              Send Test Email
            </button>
            {isSubmitting && (
              <span className='email-sent-message'>Sending test email!</span>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );

  renderEmails = (emails) => {
    return emails.map((email, i) => (
      <div className='custom-email-card margin-bottom-2' key={i}>
        <h3 className='margin-0'>{email.name}</h3>
        <div className='margin-top-0 margin-bottom-2'>
          <i>
            <EditableContent
              updateEndpoint={`${config.apiGateway.URL}/custom-emails/${email.id}`}
              fieldName='details'
              initialContent={email.details}
              htmlType='p'
              canEdit
            />
          </i>
        </div>
        <div className='grid-row margin-bottom-2'>
          <div className='grid-col-1'>
            <b>Subject: </b>
          </div>
          <div className='grid-col-11'>
            <EditableContent
              updateEndpoint={`${config.apiGateway.URL}/custom-emails/${email.id}`}
              fieldName='subject'
              initialContent={email.subject}
              htmlType='p'
              canEdit
            />
          </div>
        </div>
        <div className='grid-row margin-bottom-5'>
          <div className='grid-col-1'>
            <b>Body: </b>
          </div>
          <div className='grid-col-11'>
            <EditableContent
              updateEndpoint={`${config.apiGateway.URL}/custom-emails/${email.id}`}
              fieldName='body'
              initialContent={email.body}
              htmlType='p'
              canEdit
              richText
            />
          </div>
        </div>
        <hr />
        {this.renderTestForm(email.id)}
      </div>
    ));
  };
  render() {
    return (
      <div className='container'>
        <h1>Emails</h1>
        {!this.props.emailsStore.emailsLoading &&
          this.renderEmails(this.props.emailsStore.emails)}
      </div>
    );
  }
}

export default observer(Emails);
