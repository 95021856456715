import React from 'react';
import {observer} from 'mobx-react';
import {Formik, Form, Field} from 'formik';
import queryString from 'query-string';
import moment from 'moment';
import {Typeahead} from 'react-bootstrap-typeahead';
import * as Yup from 'yup';
import {getOrganizationName, getOrganizationFromOptions} from '../../../services/OrganizationUtilities';
import {Accordion, AccordionRowV2} from '../../../components/Accordion';
import Loader from '../../../components/Loader/Loader';
import PrintButton from '../../../components/Buttons/PrintButton';
import TemporaryOrganizationAccount from './TemporaryOrganizationAccount';
import config from '../../../config';
import GameRow from './GameRow';

const adminNoteOne =
  'These statistics only include players who have created an account and played a game while logged in to their account.';

const adminNoteTwo = "If you don't apply a filter, all games played will be included in the statistics.";

const orgNoteOne =
  'These statistics only include users who have created an account, linked their account to your organization, and played a game while logged in.';

const orgNoteTwo =
  'If you don’t apply a filter, all games played since your organization was approved will be included in the statistics.';

const testDataDisclaimer = (
  <p>
    <b>REMEMBER:</b> Public launch was September 30, 2021. To remove test data, be sure to{' '}
    <b>FILTER for Start Date 9/30/2021.</b>
  </p>
);

const reportInstructions = (
  <span>Select a game to view the data. The current date filter will be applied until it is changed or cleared.</span>
);

const filterSchema = Yup.object().shape({
  endDate: Yup.date().when(
    'startDate',
    (startDate, schema) => startDate && schema.min(startDate, 'Start date must be before end date.'),
  ),
});

const renderGameRowTitle = (gameStat) => {
  return (
    <div className='grid-row'>
      <div className='grid-col-6 mobile-full-width'>
        <span className='font-size-15 margin-right-2'>{gameStat.name}</span>
      </div>
      <div className='grid-col-3 mobile-full-width'>
        {gameStat.inProgress > 0 && <span>In progress: {gameStat.inProgress} </span>}
      </div>
      <div className='grid-col-3 mobile-full-width'>
        {gameStat.complete > 0 && <span>Earned certificate: {gameStat.complete} </span>}
      </div>
    </div>
  );
};

const renderGameRowContent = (startDate, endDate, gameStat, organizationStore, showOrganizationDetails, organizationId) => {
  return <GameRow 
    startDate={startDate}
    endDate={endDate}
    gameStat={gameStat} 
    organizationId={organizationId}
    organizationStore={organizationStore} 
    showOrganizationDetails={showOrganizationDetails} 
  />;
};

const renderGameRow = (startDate, endDate, gameStat, organizationStore, showOrganizationDetails, allOpen, organizationId) => {
  return (
    <AccordionRowV2
      key={`accordion-row-${gameStat.name}`}
      title={renderGameRowTitle(gameStat)}
      content={renderGameRowContent(startDate, endDate, gameStat, organizationStore, showOrganizationDetails, organizationId)}
      forceOpen={allOpen}
      htmlTitle={gameStat.name}
      onlyOpenOnTitle
    ></AccordionRowV2>
  );
};

export const applyFilter = (history, startDate, endDate, organizationId) => {
  let queryString = '?';
  if (startDate && endDate) queryString += `startDate=${startDate}&endDate=${endDate}&`;
  if (organizationId) queryString += `organizationId=${organizationId}`;
  history.push({search: queryString});
};

export const clearFilter = (history, filter) => {
  let {startDate, endDate, organizationId} = queryString.parse(history.location.search);
  if (filter === 'dateRange') applyFilter(history, null, null, organizationId);
  if (filter === 'organization') applyFilter(history, startDate, endDate);
};

export const getOrganizationOptions = (organizationStore) =>
  organizationStore.organizations
    .filter((org) => org.approved)
    .map((organization) => ({
      id: organization.id,
      label: `${organization.display_name} (${organization.name})`,
      displayName: organization.display_name,
    }))
    .sort((organizationA, organizationB) => {
      if (organizationA.label > organizationB.label) return 1;
      if (organizationA.label < organizationB.label) return -1;
      return 0;
    });

class OrganizationReport extends React.Component {
  constructor() {
    super();
    this.state = {
      allOpen: false,
    };
    this.renderCount = 0;
  }
  componentDidMount() {
    this.props.organizationStore.getOrganizations();
    this.props.gamesStore.getGames();
    config.setUniqueTitle('Game Reports');

    
  }
  componentDidUpdate() {
    let inputEl = document.getElementById('organization-list-choice');
    if (!inputEl) {
      inputEl = document.querySelector('#org-choic-container input');
      if (inputEl) {
        inputEl.id = 'organization-list-choice';
      }
    }
  }
  render() {
    const {authStore, organizationStore, gamesStore, history} = this.props;
    const {allOpen} = this.state;
    authStore.isOrganization && organizationStore.getOrganization(authStore.organizationId);

    if (
      authStore.isOrganization &&
      !organizationStore.organizationLoading &&
      !organizationStore.organization.approved
    ) {
      return <TemporaryOrganizationAccount {...this.props} />;
    }

    const defaultStartDate = moment(config.defaultQueryStartDate).format('YYYY-MM-DD');
    let startDate = queryString.parse(history.location.search).startDate;
    if (!startDate) startDate = defaultStartDate;
    let endDate = queryString.parse(history.location.search).endDate;
    if (!endDate) endDate = moment().format('YYYY-MM-DD');
    const organizationId = queryString.parse(history.location.search).organizationId;
    const organizationOptions = getOrganizationOptions(organizationStore);
    organizationStore.getSessions({startDate, endDate, organizationId});
    organizationStore.getOrganizations();

    if (organizationStore.organizationsLoading || organizationStore.sessionsLoading) {
      return <Loader />;
    }

    const gameStats = gamesStore.games.reduce((prev, game) => {
      const gameCounts = organizationStore.getGameCounts(game.id);
      prev.push({id: game.id, name: game.name, ...gameCounts});
      return prev;
    }, []);

    // const masterCertificatesByUserId = organizationStore.getMasterCertificatesEarnedByUserId();
    // const overallStats = {
    //   id: 15,
    //   inProgress: 0,
    //   complete: masterCertificatesByUserId.length,
    //   masterCertificatesByUserId,
    // };

    return (
      <div className='organization mobile-full-width'>
        <h1>Game Reports</h1>
        <p>
          This data only includes game play for players who linked their individual account to your organization
          account.
        </p>
        <h3 className='no-margin'>Refine Your Reports</h3>
        <p>
          Apply these filters to refine your reports. Select the date range for the reports. When you’re done, select{' '}
          <b>Apply Filters.</b>
        </p>
        <p>
          <b>Notes:</b>
        </p>
        <ul>
          <li>{authStore.isAdmin ? adminNoteOne : orgNoteOne}</li>
          <li>{authStore.isAdmin ? adminNoteTwo : orgNoteTwo}</li>
        </ul>
        <div>{authStore.isAdmin && testDataDisclaimer}</div>
        <div className='grid-row background-color-light-grey padding-left-3 padding-top-2 padding-right-3 padding-bottom-3 margin-bottom-4 margin-top-2'>
          <div className='grid-col-12'>
            <Formik
              validationSchema={filterSchema}
              initialValues={{
                startDate: startDate,
                endDate: endDate,
                organizationId: organizationId,
              }}
              onSubmit={(values) => applyFilter(history, values.startDate, values.endDate, values.organizationId)}
            >
              {(formikProps) => (
                <Form>
                  {authStore.isAdmin && (
                    <div>
                      <div className='grid-row'>
                        <h4 className='no-margin'>Filter by Organization</h4>
                      </div>
                      <div className='grid-row'>
                        <label htmlFor='organization-list-choice' className='color-primary font-size-14'>
                          Organization:
                        </label>
                      </div>
                      <div id='org-choic-container' className='grid-row margin-bottom-2 mobile-full-width'>
                        <Typeahead
                          id='organization-list-choices'
                          selected={
                            formikProps.values.organizationId &&
                            getOrganizationFromOptions(organizationOptions, formikProps.values.organizationId)
                          }
                          multiple={false}
                          options={organizationOptions}
                          onChange={(values) => values[0] && formikProps.setFieldValue('organizationId', values[0].id)}
                        />
                      </div>
                    </div>
                  )}
                  <div className='grid-row'>
                    <div className='grid-col-12'>
                      <h4 className='no-margin'>Filter by Date</h4>
                    </div>
                    <div className='grid-col-4 mobile-full-width'>
                      <div className='grid-row'>
                        <label htmlFor='startDate' className='color-primary font-size-14'>
                          Start date:
                        </label>
                      </div>
                      <div className='grid-row'>
                        <Field
                          title='Enter start date'
                          id='startDate'
                          name='startDate'
                          type='date'
                          className={`${
                            formikProps.touched.endDate && formikProps.errors.endDate && 'alert'
                          } fdic-input mobile-full-width`}
                        />
                      </div>
                    </div>
                    <div className='grid-col-4 mobile-full-width'>
                      <div className='grid-row'>
                        <label htmlFor='endDate' className='color-primary font-size-14'>
                          End date:
                        </label>
                      </div>
                      <div className='grid-row'>
                        <Field
                          title='Enter end date'
                          id='endDate'
                          name='endDate'
                          type='date'
                          className={`${
                            formikProps.touched.endDate && formikProps.errors.endDate && 'alert'
                          } fdic-input mobile-full-width`}
                          placeholder='test'
                        />
                      </div>
                    </div>
                    <div className='grid-col-4 mobile-full-width padding-top-3'>
                      <button className='usa-button full-width' onClick={formikProps.handleSubmit}>
                        Apply Filter{authStore.isAdmin ? 's' : ''}
                      </button>
                    </div>
                  </div>
                  {formikProps.touched.endDate && formikProps.errors.endDate && (
                    <div className='grid-row alert full-width margin-top-105'>{formikProps.errors.endDate}</div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </div>
        {/* eslint-disable no-mixed-operators */}
        {((endDate && startDate) || organizationId) && (
          <div>
            <p>Current filters:</p>
            <div className='grid-row background-color-light-grey padding-left-3 padding-top-2 padding-right-3 padding-bottom-3 margin-bottom-4'>
              {endDate && startDate && (
                <div className='grid-col-12 margin-bottom-3'>
                  <div className='grid-col-8'>
                    <p>
                      Date range from:{' '}
                      <b>
                        {moment(startDate).parseZone().format('LL')} to {moment(endDate).parseZone().format('LL')}
                      </b>
                    </p>
                  </div>
                  <div className='grid-col-4'>
                    <button
                      className='usa-button usa-button--outline'
                      onClick={() => clearFilter(history, 'dateRange')}
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
              )}
              {authStore.isAdmin && organizationId && (
                <div className='grid-col-12'>
                  <div className='grid-col-8'>
                    <p>
                      Organization: <b>{getOrganizationName(organizationOptions, organizationId)}</b>
                    </p>
                  </div>
                  <div className='grid-col-4'>
                    <button
                      className='usa-button usa-button--outline'
                      onClick={() => clearFilter(history, 'organization')}
                    >
                      Clear Filter
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <p className='hide-print'>{reportInstructions}</p>
        <PrintButton printText='Print' />
        {/* <div className='grid-row margin-top-2'>
          <label className='margin-top-1' htmlFor='toggleExpandCollapse'>
            <b>Expand all</b>
            <Switch
              id='toggleExpandCollapse'
              checked={allOpen}
              onChange={() => this.setState({allOpen: !allOpen})}
              inputProps={allOpen ? {'aria-label': 'Expand All'} : {'aria-label': 'Collapse All'}}
              color='primary'
            />
            <b>Collapse all</b>
          </label>
        </div> */}
        <div className='grid-col-12'>
          <Accordion>
            {/* <AccordionRowV2
              key={'accordion-row-master'}
              title={renderGameRowTitle({
                name: 'Master Certificate for all 14 Games',
                inProgress: 0,
                complete: masterCertificatesByUserId.length,
              })}
              content={renderGameRowContent(startDate, endDate, overallStats, organizationStore, authStore.isAdmin)}
              forceOpen={allOpen}
              htmlTitle={'Master certficate'}
              onlyOpenOnTitle
            /> */}
            {gamesStore.games &&
              gameStats.map((gameStat) => renderGameRow(startDate, endDate, gameStat, organizationStore, authStore.isAdmin, allOpen, authStore.organizationId || organizationId))}
          </Accordion>
        </div>
      </div>
    );
  }
}

export {filterSchema};
export default observer(OrganizationReport);
