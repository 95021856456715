import React from "react";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation} from "react-i18next";

const CompareCostsAndBenefitsOfTraining = ({strategy, canEdit}) => {
  const {i18n} = useTranslation();
  return (
    <div className="strategy">
      <EditableContent
        htmlType="div"
        initialContent={
          i18n.language === "es"
            ? strategy.contentsEs || strategy.contents
            : strategy.contents
        }
        fieldName={i18n.language === "es" ? "contents_es" : "contents"}
        updateEndpoint={`${config.apiGateway.URL}/topic-items/${strategy.id}`}
        canEdit={canEdit}
        richText
      />
    </div>
  );
};

export default CompareCostsAndBenefitsOfTraining;
