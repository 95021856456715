import React from "react";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation} from "react-i18next";

const BuildCredit = ({strategy, canEdit}) => {
  const {i18n, t} = useTranslation();
  const headers = [t('Option'), t('What It Is'), t('What to Watch Out for')];
  const title = `${t('Table')}: ${headers.join(', ')}`;
  return (
    <div className="strategy">
      <EditableContent
        htmlType="div"
        initialContent={
          i18n.language === "es"
            ? strategy.contentsEs || strategy.contents
            : strategy.contents
        }
        fieldName={i18n.language === "es" ? "contents_es" : "contents"}
        updateEndpoint={`${config.apiGateway.URL}/topic-items/${strategy.id}`}
        canEdit={canEdit}
        richText
      />
      <div>
        <table
          className="usa-table"
          title={title}
        >
          <thead>
            <tr>
              {headers.map((h, i) => <th key={i} scope='col'><p><strong>{h}</strong></p></th>)}
            </tr>
          </thead>
          <tbody className="vertical-align-top">
            <tr>
              <th scope="row">
                <p>{t('Get a secured credit card')}</p>
              </th>
              <td>
                <p>
                  {t('This is a type of credit card that requires collateral. You keep money (as collateral) in a dedicated deposit account at the financial institution issuing the card. The card is “secured” with that collateral.')}
                </p>
                <p>
                  {t('To get a secured credit card, you must make a deposit with the financial institution that issues the credit card. Often it is around $500, which is also generally the credit limit. The credit limit is the maximum you can charge on the credit card.')}
                </p>
              </td>
              <td>
                <p>
                  <strong>{t('Fees. ')}</strong>{t('Secured credit cards often have fees: annual fees, monthly fees, and others. The credit card issuer cannot charge fees that add up to more than 25% of the credit limit in the first year. Read the fine print. Make sure the fee structure does not increase after the first 12 months.')}
                </p>
                <p><strong>
                  {t('Credit utilization rate. ')}</strong>{t('Be sure to keep the amount you charge as low as possible compared to your credit limit. Borrowers who have large balances in proportion to their credit limits may see their credit scores fall.')}
                </p>
                <p><strong>
                  {t('Missing payments. ')}</strong>{t('Be sure to pay the full amount due each month. Getting a secured credit card will not improve your credit scores if you miss payment deadlines. The creditor must receive the payment by the due date.')}
                </p>
                <p><strong>
                  {t('Reporting. ')}</strong>{t('Check your credit reports to ensure the financial institution regularly reports your payments to one of the three nationwide credit reporting agencies: Equifax, Experian, or TransUnion.')}
                </p>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <p>{t('Get a credit-building loan')}</p>
              </th>
              <td>
                <p>
                  {t('These are installment loans usually for $1,000 or less. You repay them through regular, scheduled payments for 6 to 12 months.')}
                </p>
              </td>
              <td>
                <p><strong>
                  {t('Interest rates. ')}</strong>{t('For most loans, you will have to pay interest. Be sure you can afford to repay the loan, including the interest. Remember, even if the loan is deposited in an account and you make the loan payments from this deposit, you will still need additional money to cover the interest.')}
                </p>
                <p><strong>
                  {t('Missing payments. ')}</strong>{t('While regular, on-time payments can build your credit, missing or late payments will harm it. Be sure you can afford the payments before taking on this loan. Find out if you can set up automatic payments so you can be sure you will not forget to make them.')}
                </p>
                <p><strong>
                  {t('Reporting. ')}</strong>{t('Check your credit reports to ensure the financial institution regularly reports your payments to Equifax, Experian, or TransUnion.')}
                </p>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <p>{t('Become an authorized user on someone else&rsquo;s account')}</p>
              </th>
              <td>
                <p>
                  {t('This provides you with a credit card in your name, but someone else owns and is responsible for the account.')}
                </p>
                <p>
                  {t('If the primary accountholder uses it responsibly and has good credit, it may provide a slight boost to your credit scores.')}
                </p>
              </td>
              <td>
                <p><strong>
                  {t('Reporting. ')}</strong>{t('Check your credit reports to ensure the financial institution regularly reports authorized user status to Equifax, Experian, or TransUnion.')}
                </p>
                <p><strong>
                  {t('Missed payments by the accountholder. ')}</strong>{t('Late and missed payments on the account can affect your credit. Make sure the person who owns the account has good credit and always pays on time.')}
                </p>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <p>{t('Get a cosigner')}</p>
              </th>
              <td>
                <p>
                  {t('Ask a friend or relative with good credit to cosign your loan. A cosigner promises to repay the loan if you do not.')}
                </p>
              </td>
              <td>
                <p><strong>
                  {t('Your relationship. ')}</strong>{t('If you do not pay back the loan, your cosigner will be responsible for the payments. You could damage their credit as well by not paying on time.')}
                </p>
                <p><strong>
                  {t('Reporting. ')}</strong>{t('The lender should report the payment information for both you and the cosigner to the credit reporting agencies.')}
                </p>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <p>{t('Apply for a credit card at a store or gas station')}</p>
              </th>
              <td>
                <p>
                  {t('These credit cards typically have a lower credit limit and a higher annual percentage rate (APR).')}
                </p>
                <p>
                  {t('It may be easier to get approved for one of these cards than other types of credit cards.')}
                </p>
              </td>
              <td>
                <p><strong>
                  {t('Interest rates. ')}</strong>{t('The interest rate is likely to be high. Make sure you do not charge more than you can pay. Keep the balance on the card as low as possible compared to your credit limit.')}
                </p>
                <p><strong>
                  {t('Reporting. ')}</strong>{t('Check your credit reports to ensure the lender regularly reports your payments to Equifax, Experian, or TransUnion.')}
                </p>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <p>
                  {t('Make a large down payment on a purchase and negotiate a loan for the balance')}
                </p>
              </th>
              <td>
                <p>
                  {t('Offering to make a large down payment can make it more likely that a lender will approve your lending application. And, you will not have to borrow as much money.')}
                </p>
                <p>
                  {t('For example, if you are buying a used car for $5,000 and have enough cash, you might consider making a down payment of $1,000 to $3,000.')}
                </p>
                <p>
                  {t('Repaying the loan as agreed can help you build your credit history.')}
                </p>
              </td>
              <td>
                <p><strong>
                  {t('Interest rates. ')}</strong>{t('The interest rate is likely to be high. Make sure you do not borrow more money than you can pay back.')}
                </p>
                <p><strong>
                  {t('Loss of asset. ')}</strong>{t('The loan is likely to be secured by the asset you are buying. If you do not make your payments on time and as agreed, the asset may be repossessed.')}
                </p>
                <p><strong>
                  {t('Reporting. ')}</strong>{t('Check your credit reports to ensure the lender regularly reports your payments to Equifax, Experian, or TransUnion.')}
                </p>
              </td>
            </tr>
            <tr>
              <th scope="row">
                <p>{t('Create an alternative credit history')}</p>
              </th>
              <td>
                <p>
                  {t('Many of the bills you pay are not included in credit reports. For example, utility bills and cell phone bills may not be included in your credit reports. Create a record showing your timely and regular payments of other bills as an alternative to a credit report. You can use the tool: ')}<a href="/tools/15">{t('Create My Alternative Credit History.')}</a>
                </p>
              </td>
              <td>
                <p><strong>
                  {t('Acceptance. ')}</strong>{t('Many businesses will not accept an alternative credit history in place of a credit report or scores. Be sure to check with potential service providers about their policies on considering alternative credit histories.')}
                </p>
                <p><strong>
                  {t('Evidence. ')}</strong>{t('Make sure you have evidence to back up your claims of timely and regular payments, such as:')}
                </p>
                <ul>
                  <li className="font-size-14">{t('Receipts')}</li>
                  <li className="font-size-14">{t('Statements')}</li>
                  <li className="font-size-14">
                    {t('Letters from the businesses you pay regularly')}
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BuildCredit;
