import React from "react";
import {Formik, Form} from "formik";
import Inputs from "../utilities/Inputs";
import StrategyCard from "../utilities/StrategyCard";
import EditableContent from "../../../components/EditableContent/EditableContent";
import config from "../../../config";
import {useTranslation, Translation} from "react-i18next";

const insurances = [
  {
    name: "auto",
    details: (
      <Translation>
        {(t) => (
          <div>
            <h3>{t('Automobile (Vehicle) Insurance')}</h3>
            <ul>
              <li>{t('Protects you from paying the full cost for repairs to your vehicle that result from an accident')}
              </li>
              <li>{t('Protects you from paying the full cost for repairs to someone else’s vehicle that result from an accident you were involved in')}
              </li>
              <li>{t('Might help pay for damages to your vehicle due to theft, fire, or falling objects')}
              </li>
              <li>{t('Might help pay for disaster-related damage')}
              </li>
            </ul>
          </div>
        )}
      </Translation>
    ),
  },
  {
    name: "disability",
    details: (
      <Translation>
        {(t) => (
          <div>
            <h3>{t('Disability Insurance')}</h3>
            <ul>
              <li>{t('Protects you from financial hardship when a covered illness or injury prevents you from earning a living')}
              </li>
            </ul>
          </div>
        )}
      </Translation>
    ),
  },
  {
    name: "life",
    details: (
      <Translation>
        {(t) => (
          <div>
            <h3>{t('Life Insurance')}</h3>
            <ul>
              <li>{t('Pays money after you die to your designated beneficiary')}</li>
              <li>{t('May also have other benefits')}</li>
            </ul>
          </div>
        )}
      </Translation>
    ),
  },
  {
    name: "renters",
    details: (
      <Translation>
        {(t) => (
          <div>
            <h3>{t('Renter’s Insurance')}</h3>
            <ul>
              <li>{t('For people who rent their home')}</li>
              <li>{t('Insures your personal property against damage or loss')}</li>
              <li>{t('Insures you in case someone gets hurt at your home')}</li>
              <li>{t('Probably doesn’t cover damage caused by floods; ask for additional coverage if needed')}</li>
              <li>{t('May not cover expensive items, such as mobility devices like scooters, electric wheelchairs, or other accessibility devices; ask for additional coverage, if needed')}
              </li>
            </ul>
          </div>
        )}
      </Translation>
    ),
  },
  {
    name: "homeowners",
    details: (
      <Translation>
        {(t) => (
          <div>
            <h3>{t('Homeowner’s Insurance')}</h3>
            <ul>
              <li>{t('For people who own their home')}</li>
              <li>{t('Insures your home and personal property against damage or loss')}
              </li>
              <li>{t('Insures you in case someone gets hurt at your home')}</li>
              <li>{t('Probably doesn’t cover damage caused by floods or earthquakes; ask to add a rider if needed')}
              </li>
              <li>{t('Amount of insurance should include the value of any significant upgrades to your home, including accessibility upgrades, such as an entry ramp or accessible bathroom features')}
              </li>
              <li>{t('May not cover expensive items, such as mobility devices like scooters, electric wheelchairs, or other accessibility devices; ask to add a rider, if needed')}
              </li>
            </ul>
          </div>
        )}
      </Translation>
    ),
  },
];

const ReviewInsuranceCoverage = ({tool, canEdit}) => {
  const {i18n, t} = useTranslation();
  return (
    <div>
      <Formik
        initialValues={{
          autoCoverage: "",
          autoCoverageNeed: "",
          disabilityCoverage: "",
          disabilityCoverageNeed: "",
          lifeCoverage: "",
          lifeCoverageNeed: "",
          rentersCoverage: "",
          rentersCoverageNeed: "",
          homeownersCoverage: "",
          homeownersCoverageNeed: "",
        }}
      >
        {(formikProps) => (
          <Form>
            <EditableContent
              htmlType="div"
              initialContent={
                i18n.language === "es"
                  ? tool.contentsEs || tool.contents
                  : tool.contents
              }
              fieldName={i18n.language === "es" ? "contents_es" : "contents"}
              updateEndpoint={`${config.apiGateway.URL}/topic-items/${tool.id}`}
              canEdit={canEdit}
              richText
            />
            {insurances.map((insurance) => (
              <div className="strategy" key={insurance.name}>
                <StrategyCard key={insurance.name}>
                  <div>
                    {insurance.details}
                    {Inputs.textArea(
                      `${insurance.name}KeyDetails`,
                      t('If you have it, list the insurance company, coverage limits, and other key details here.')
                    )}
                    <span>
                      <div className="font-size-14">
                        {Inputs.radioButtonGroup({
                          id: `${insurance.name}Coverage`,
                          label: t('If you have it, do you need more coverage?'),
                          options: ["Yes", "No", "I don’t know"],
                          value:
                            formikProps.values[`${insurance.name}Coverage`],
                        })}
                        <br />
                        {Inputs.radioButtonGroup({
                          id: `${insurance.name}CoverageNeed`,
                          label: t('If you do not have it, do you need it?'),
                          options: ["Yes", "No", "I don’t know"],
                          value:
                            formikProps.values[`${insurance.name}CoverageNeed`],
                        })}
                        <br />
                      </div>
                    </span>
                  </div>
                </StrategyCard>
              </div>
            ))}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ReviewInsuranceCoverage;
