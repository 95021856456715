import React from 'react';
import {Loader} from '../../../components/Loader';
import {observer} from 'mobx-react';
import Modal from "react-responsive-modal";
import {Formik, Form, Field} from 'formik';

const DELETE_FAILURE_MESSAGE = "Error deleting category. Please make sure to remove all help topics from this category before deleting the category.";

const HelpTopicCategoryForm = ({formikProps}) => {
  return(
    <Form>
      <div className="grid-container">
        <h3>Add Category</h3>
        <div className="grid-row">
          <div className="grid-col-12">
            <label className="margin-right-05"><b>Category name:</b></label>
          </div>
          <div className="grid-col-12">
            <Field name="name" className="full-width" />
          </div>
        </div>
        <div className="grid-row margin-bottom-2">
          <div className="grid-col-12">
            <label className="margin-right-05"><b>Category details:</b></label>
          </div>
          <div className="grid-col-12">
            <Field name="details" component="textarea" className="full-width"/>
          </div>
        </div>
        
        <button className="usa-button" onClick={formikProps.handleSubmit}>Save</button>
      </div>
    </Form>
  );
};

const EditHelpTopicCategoryModal = ({open, onClose, onSubmit, editingItem}) => {
  return(
    <Modal
      open={open}
      onClose={onClose}
    >
      <Formik
        initialValues={{
          name: editingItem.name,
          details: editingItem.details
        }}
        onSubmit={onSubmit}
      >
        {
          formikProps => <HelpTopicCategoryForm formikProps={formikProps} />
        }
      </Formik>
    </Modal>
  );
};

const NewHelpTopicCategoryModal = ({open, onClose, onCreate}) => {
  return(
    <Modal
      open={open}
      onClose={onClose}
    >
      <Formik
        initialValues={{
          name: '',
          details: ''
        }}
        onSubmit={onCreate}
      >
        {
          formikProps => <HelpTopicCategoryForm formikProps={formikProps} />
        }
      </Formik>
    </Modal>
  );
};

class HelpTopicCategories extends React.Component {
  constructor(){
    super();
    this.state = {
      newModalOpen: false,
      editModalOpen: false,
      editingItem: {}
    };
  }

  componentDidMount() {
    this.props.helpCenterStore.getHelpTopicCategories();
  }

  render() {
    return(
      <div className="help-topic-categories">
        <h1>Edit Categories</h1>
        <button className="usa-button" onClick={() => this.setState({newModalOpen: true})}>Add Category</button>
        <button className="usa-button usa-button--unstyled" onClick={() => this.props.history.push('/help-center/edit')}>Back to Edit Help Center</button>
        <div className="grid-row">
          <p>To Delete a Category, you must first remove all Topics assigned to that Category.</p>
        </div>
        {this.props.helpCenterStore.deleteHelpTopicCategoryFailure && <div className="alert full-width">{DELETE_FAILURE_MESSAGE}</div>}
        {this.props.helpCenterStore.helpTopicCategoriesLoading ? <Loader /> : <div className="categories">
          <table className="usa-table">
            <thead>
              <tr>
                <td>Category Name</td>
                <td>Category Details</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {this.props.helpCenterStore.helpTopicCategories.map((category) => {
                return (
                  <tr key={category.id}>
                    <td>{category.name}</td>
                    <td>{category.details}</td>
                    <td>
                      <button className="usa-button" onClick={() => this.setState({editModalOpen: true, editingItem: category})}>Edit</button>
                      <button disabled={category.help_topic_count > 0} className="usa-button usa-button--base" onClick={() => this.props.helpCenterStore.deleteHelpTopicCategory(category.id)}>Delete</button>
                    </td>
                  </tr>
                ); 
              })}
            </tbody>
          </table>
        </div>
        }
        <NewHelpTopicCategoryModal 
          open={this.state.newModalOpen} 
          onClose={() => this.setState({newModalOpen: false})} 
          onCreate={(values) => this.props.helpCenterStore.createHelpTopicCategory(values, () => this.setState({newModalOpen: false}))} 
        />
        <EditHelpTopicCategoryModal 
          open={this.state.editModalOpen} 
          onClose={() => this.setState({editModalOpen: false})} 
          onSubmit={(values) => this.props.helpCenterStore.updateHelpTopicCategory(this.state.editingItem.id, values, () => this.setState({editModalOpen: false}))} 
          editingItem={this.state.editingItem}
        />
      </div>
    );
  }
}

export default observer(HelpTopicCategories);