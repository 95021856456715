import React from 'react';
import {observer} from 'mobx-react';
import GameSessionRow from './GameSessionRow';
import GameStatusInformation from './GameStatusInformation';
import {useTranslation} from 'react-i18next';
import BackToTop from '../../../components/Buttons/BackToTop';

import notStartedImage from '../../../assets/images/not_started_icon.png';
import inProgressImage from '../../../assets/images/not_complete.png';
import medalImage from '../../../assets/images/medal.png';

const gameIcon = (gameSession) => {
  if (!gameSession) return notStartedImage;
  if (gameSession.complete_date) return medalImage;
  return inProgressImage;
};

const gameIconAltText = (gameSession, t) => {
  if (!gameSession) return t('Game not started icon');
  if (gameSession.complete_date) return t('Game complete icon');
  return t('Game in progress icon');
};

const findGameSession = (gameSessionsStore, gameId) =>
  gameSessionsStore.sessions.filter((session) => session.game_id.toString() === gameId.toString())[0];

const IndividualGamePlayInfo = observer(({authStore, gamesStore, gameSessionsStore, showBackToTop = true}) => {
  const {t} = useTranslation();

  const isPrivilegedAccount = authStore.isAdmin || authStore.isOrganization;

  const masterCertificateSession = gameSessionsStore.getMasterCertificateUserSession();
  const masterCertificateGame = {
    id: 15,
    name: 'Master Certificate',
    name_es: 'Master Certificate (spanish)',
  };

  const tableHeaders = ['Status', 'Game Title', 'Certificate(s)'];

  return (
    <div id='your-game-play-information' className='page-break-inside-avoid'>
      {isPrivilegedAccount && (
        <>
          <div className='grid-col-12 text-align-right'>{showBackToTop && <BackToTop />}</div>
          <h2 tabIndex='0' className='no-margin' id='account-game-play'>
            <i>Your</i> Game Play Information
          </h2>
          <div>
            <p>
              <b>Note:</b> You can play the games too!
            </p>
            <p>
              Below is the same information that individual players can view about their progress playing games on their
              “My Account” page when they are logged into their accounts. Individual players also have a Player ID noted
              at the top of their “My Account” page. Your account does not have a Player ID. Your game play on this
              account is not included in any game play reports or statistics.
            </p>
          </div>
        </>
      )}
      <GameStatusInformation
        notStartedIcon={notStartedImage}
        inProgressIcon={inProgressImage}
        completeIcon={medalImage}
        notStartedAltText={gameIconAltText(null, t)}
        inProgressAltText={gameIconAltText({complete_date: 'done'}, t)}
        completeAltText={gameIconAltText({complete_date: null}, t)}
      />
      {!gamesStore.gamesLoading && (
        <table className='account-game-table' title='Game sessions table'>
          <thead className='background-color-blue margin-bottom-2'>
            <tr>
              {tableHeaders.map((header) => (
                <th key={header} className='padding-top-1 padding-bottom-1' scope='col'>
                  {t(header)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {gamesStore.games.map((game) => {
              let gameSession = findGameSession(gameSessionsStore, game.id);
              return (
                <GameSessionRow
                  key={`game-row-${game.name}`}
                  game={game}
                  gameSession={gameSession}
                  gameIcon={gameIcon(gameSession)}
                  gameIconAltText={gameIconAltText(gameSession, t)}
                />
              );
            })}
            <GameSessionRow
              key={'game-row-master-certficate'}
              game={masterCertificateGame}
              gameSession={masterCertificateSession}
              gameIcon={gameIcon(masterCertificateSession)}
              gameIconAltText={gameIconAltText(masterCertificateSession, t)}
            />
          </tbody>
        </table>
      )}
    </div>
  );
});

export default IndividualGamePlayInfo;
