import React, {Component} from 'react';
import PropTypes from 'prop-types';

class Accordion extends Component {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    let {children} = this.props;

    return (
      <div className="accordion">
        { children }
      </div>
    );
  }
}

export default Accordion;

